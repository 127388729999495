import React from 'react';
import FormikWrapper from 'components/Formik';
import TagRelationTypeForm, {
  getFormInitialValues,
} from './TagRelationTypeForm';
import { getValidationSchema } from './validation';
import { useTagRelationTypeCreate } from 'novaApi/hooks/tag_relation_type';
import { useDispatch } from 'react-redux';
import { goPreviousPage } from 'store/router';

export default function TagRelationTypesCreatePage() {
  const dispatch = useDispatch();
  const tagRelationTypeToCreate = getFormInitialValues();
  const {
    createTagRelationType,
    createdTagRelationType,
    isSuccess,
  } = useTagRelationTypeCreate();

  const handleFormSubmit = (formData, formActions) => {
    createTagRelationType({ formData, formActions });
  };

  if (isSuccess && createdTagRelationType) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={tagRelationTypeToCreate}
      validationSchema={getValidationSchema()}
      submitForm={(formData, formActions) =>
        handleFormSubmit(formData, formActions)
      }
      title='Create tag relation type'
      customSize='sm'
    >
      <TagRelationTypeForm />
    </FormikWrapper>
  );
}
