import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import { TableConfigEntry } from 'utils/tableConfig';
import ConnectedButton from 'components/Button/ConnectedButton';
import Table from 'components/Table/Table';
import { usePROEarnings } from 'novaApi/hooks/pro_earning';
import InternalLink from 'components/Link/InternalLink';
import PROEarningsPageFilters from './PROEarningsFilters';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';

const useStyles = makeStyles(styles);

const tableConfig: TableConfigEntry<Nl.Api.PROEarningEntry> = {
  columnData: [
    {
      id: 'work_title',
      label: 'Work Title',
      isSortable: true,
      render: (entry) =>
        entry.song_uuid ? (
          <InternalLink
            color='inherit'
            to={{ type: 'route/SONG_EDIT', payload: { uuid: entry.song_uuid } }}
            children={entry.work_title}
          />
        ) : (
          entry.work_title
        ),
    },
    { id: 'artist_name', label: 'Artist', isSortable: true },
    { id: 'ASCAP', label: 'ASCAP', isSortable: true },
    { id: 'ASCAP_domestic', label: '(dom)', isSortable: true },
    { id: 'ASCAP_international', label: '(int)', isSortable: true },
    { id: 'BMI', label: 'BMI', isSortable: true },
    { id: 'PRS', label: 'PRS', isSortable: true },
    { id: 'SESAC', label: 'SESAC', isSortable: true },
    { id: 'BUMA', label: 'BUMA', isSortable: true },
    { id: 'SOCAN', label: 'SOCAN', isSortable: true },
    { id: 'total', label: 'total', isSortable: true },
  ],
  getRowKey: (entry) => entry.song_uuid || entry.work_title,
};

export default function PROEarningsPage() {
  const classes = useStyles({});
  const { data } = usePROEarnings();

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='PRO Earnings'
        actions={
          <>
            <ConnectedButton
              route='route/PROS_EARNINGS_STATEMENTS'
              label='Statements'
            />
            <ConnectedButton
              icon='add'
              fab
              route='route/PROS_EARNINGS_ADD'
              label='Add new PRO earnings report'
              permission='CAN_CREATE_PRO_EARNINGS_STATEMENT'
            />
          </>
        }
        filters={<PROEarningsPageFilters />}
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by title',
                queryFilterKey: 'work_title',
                e2e: 'name',
              },
            ]}
          />
        }
      />
      <Table
        config={tableConfig}
        data={data?.pro_earnings}
        isLoaded={!!data}
        paginationData={data}
      />
    </Paper>
  );
}
