import * as React from 'react';
import { Form, Field } from 'formik';
import InputField from 'components/Formik/InputField';
import { multipleSelectFormat } from 'utils/utils';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import { useSongThemeParents } from 'novaApi/hooks/song_theme';

const SongThemeForm = () => {
  const songThemes = useSongThemeParents();

  return (
    <Form>
      <Field name='name' label='Name' component={InputField} />
      <Field
        name='parent_theme_uuid'
        label='Parent Song Theme'
        component={AutocompleteSelect}
        options={multipleSelectFormat(songThemes)}
      />
    </Form>
  );
};

export default SongThemeForm;
