import { ApiError } from 'novaApi/useNovaApi';

/**
 * Returns new form data object with empty password strings removed
 * {password: ''} => {}
 * This prevents the API from attempting to update the password
 * to an empty string. Also maintains the Formik initial value
 * and controlled form requirements
 * @param formData  Form data to be cleaned
 * */
export const cleanUserFormPassword = (formData: any) => {
  const result = { ...formData };
  if (!result.password) {
    delete result.password;
  }
  return result;
};

export const toFormErrors = (error: ApiError | Error | any) => {
  return {
    ...error?.response?.errors,
    _message: error?.response?.message || error?.message || 'unknown error',
  };
};
