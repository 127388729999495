import * as React from 'react';
import { Field, FieldArray, Form } from 'formik';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { multipleSelectFormat } from 'utils/utils';
import Checkbox from 'components/Formik/CheckboxField';
import InputField from 'components/Formik/InputField';
import Typeahead from 'components/Typeahead/Typeahead';
import { FormSubTitle, InputWrapper } from 'components/Formik/utils';
import { useTagCategorySelect } from 'novaApi/hooks/tag_category';
import { useExampleSelect } from 'novaApi/hooks/example';
import { useTagFieldSelect } from 'novaApi/hooks/tag_field';
import { useComparisonSelect } from 'novaApi/hooks/comparison';
import RelatedTags from './RelatedTags';
import TagTerms from './TagTerms';
import { useSongGenreSelect } from 'novaApi/hooks/song_genre';
import { useSongInstrumentSelect } from 'novaApi/hooks/song_instrument';
import { useSongThemeSelect } from 'novaApi/hooks/song_theme';

const useStyles = makeStyles(() => ({
  root: {},
  inactiveInputs: {
    pointerEvents: 'none',
  },
  checkboxGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.25rem',
  },
}));

const tagInitialValues: Partial<Nl.Api.Tag> = {
  name: '',
  tag_category: '',
  numerical_value: null,
  external_definition: '',
  internal_definition: '',
  explanation: '',
  is_problematic: false,
  is_musical: false,
  is_hidden: false,
  is_hidden_from_taggers: false,
  note: '',
  average_tempo: null,
  wikipedia_url: '',
  tagging_label: '',
  documentation: '',
  wikipedia_qid: '',
  wikidata_url: '',
  wikidata_description: '',
  discogs_genre_id: '',
  discogs_style_id: '',
  music_brainz_genre_id: '',
  wikidata_english_label: '',
  fuzzy_wikidata_relation: '',
  related_tags: [],
  tag_terms: [],
  tag_fields: [],
  examples: [],
  comparisons: [],
  genres: [],
  themes: [],
  instruments: [],
  auto_tag_as_keyword: false,
  auto_tag_as_hidden_keyword: false,
};

export const getFormInitialValues = (tag?: Nl.Api.Tag) => {
  if (!tag) return tagInitialValues;

  const seededTag = {
    ...tag,
    related_tags: [] as { uuid: string; tags: string[] }[],
    tag_terms: [] as { uuid: string; terms: string[] }[],
    tag_fields: [] as string[],
    examples: [] as string[],
    comparisons: [] as string[],
    genres: [] as string[],
    themes: [] as string[],
    instruments: [] as string[],
  };

  seededTag.related_tags = tag.related_tags.map((rel) => ({
    uuid: rel.uuid,
    tags: rel.tags.map((t) => t.uuid),
  }));
  seededTag.tag_terms = tag.tag_terms.map((rel) => ({
    uuid: rel.uuid,
    terms: rel.terms.map((t) => t.uuid),
  }));
  seededTag.comparisons = tag.comparisons.map((cmp) => cmp.uuid);
  seededTag.tag_fields = tag.tag_fields.map((tf) => tf.uuid);
  seededTag.examples = tag.examples.map((ex) => ex.uuid);
  seededTag.genres = tag.genres.map((gr) => gr.uuid);
  seededTag.themes = tag.themes.map((th) => th.uuid);
  seededTag.instruments = tag.instruments.map((ins) => ins.uuid);

  return seededTag;
};

const TagForm = ({ isEdit = false, readOnly = false }) => {
  const classes = useStyles();

  const tagCategories = useTagCategorySelect();
  const examples = useExampleSelect();
  const tagFields = useTagFieldSelect();
  const comparisons = useComparisonSelect();
  const songGenres = useSongGenreSelect();
  const songInstruments = useSongInstrumentSelect();
  const songThemes = useSongThemeSelect();

  return (
    <Form
      className={classNames(classes.root, readOnly && classes.inactiveInputs)}
      data-e2e='tag-details-form'
    >
      <FormSubTitle title='Basic Information' />
      <Field name='name' label='Tag name' component={InputField} />
      <InputWrapper>
        <Field
          name='tag_category'
          label='Tag category'
          component={Typeahead}
          options={multipleSelectFormat(tagCategories)}
        />
        <Field
          name='numerical_value'
          label='Numerical value'
          component={InputField}
        />
      </InputWrapper>

      <InputWrapper>
        <Field
          name='external_definition'
          label='External definition'
          multiline
          rows={1}
          rowsMax={4}
          component={InputField}
        />
        <Field
          name='internal_definition'
          label='Internal definition'
          multiline
          rows={1}
          rowsMax={4}
          component={InputField}
        />
      </InputWrapper>
      <Field
        name='explanation'
        label='Explanation'
        multiline
        rows={1}
        rowsMax={4}
        component={InputField}
      />
      <br />

      <Box className={classes.checkboxGroup}>
        <Field
          name='is_problematic'
          label='Is problematic'
          component={Checkbox}
        />
        <Field name='is_musical' label='Is musical' component={Checkbox} />
        <Field name='is_hidden' label='Is hidden' component={Checkbox} />
        <Field
          name='is_hidden_from_taggers'
          label='Is hidden from taggers'
          component={Checkbox}
        />
      </Box>
      <br />

      <FormSubTitle title='Wikidata' />

      <InputWrapper>
        <Box>
          <Field
            name='wikidata_url'
            label='Wikidata url'
            component={InputField}
          />
          <Field
            name='wikidata_english_label'
            label='Wikidata english label'
            component={InputField}
          />
          <Field
            name='fuzzy_wikidata_relation'
            label='Fuzzy wikidata relation'
            component={InputField}
          />
        </Box>
        <Field
          name='wikidata_description'
          label='Wikidata description'
          multiline
          rows={7}
          component={InputField}
        />
      </InputWrapper>

      <InputWrapper>
        <Field
          name='wikipedia_url'
          label='Wikipedia url'
          component={InputField}
        />
        <Field
          name='wikipedia_qid'
          label='Wikipedia qid'
          component={InputField}
        />
      </InputWrapper>
      <br />

      <FormSubTitle title='Additional details' />
      <Field name='note' label='Note' component={InputField} />
      <InputWrapper>
        <Field
          name='average_tempo'
          label='Average tempo'
          component={InputField}
        />
        <Field
          name='tagging_label'
          label='Tagging label'
          component={InputField}
        />
      </InputWrapper>

      <InputWrapper>
        <Field
          name='documentation'
          label='Documentation'
          component={InputField}
        />
        <Field
          name='music_brainz_genre_id'
          label='Music brainz genre id'
          component={InputField}
        />
      </InputWrapper>

      <InputWrapper>
        <Field
          name='discogs_genre_id'
          label='Discogs genre id'
          component={InputField}
        />
        <Field
          name='discogs_style_id'
          label='Discogs style id'
          component={InputField}
        />
      </InputWrapper>

      <InputWrapper>
        <Field
          name='comparisons'
          label='Comparisons'
          isMulti
          component={Typeahead}
          options={multipleSelectFormat(comparisons)}
        />
        <Field
          name='examples'
          label='Examples'
          isMulti
          component={Typeahead}
          options={multipleSelectFormat(examples)}
        />
      </InputWrapper>

      <InputWrapper>
        <Field
          name='tag_fields'
          label='Tag fields'
          isMulti
          component={Typeahead}
          options={multipleSelectFormat(tagFields)}
        />
      </InputWrapper>
      <br />
      {isEdit && (
        <>
          <FormSubTitle title='Auto tags' />
          <Field
            name='themes'
            label='Song themes'
            isMulti
            component={Typeahead}
            options={multipleSelectFormat(songThemes)}
          />
          <Field
            name='genres'
            label='Song genres'
            isMulti
            component={Typeahead}
            options={multipleSelectFormat(songGenres)}
          />
          <Field
            name='instruments'
            label='Song instruments'
            isMulti
            component={Typeahead}
            options={multipleSelectFormat(songInstruments)}
          />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field
                name='auto_tag_as_keyword'
                label='Add as keyword'
                component={Checkbox}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name='auto_tag_as_hidden_keyword'
                label='Add as hidden keyword'
                component={Checkbox}
              />
            </Grid>
          </Grid>
          <br />
        </>
      )}

      <FormSubTitle title='Related tags' />
      <FieldArray
        name='related_tags'
        label='related_tags'
        // @ts-ignore
        component={RelatedTags}
      />
      <br />
      <FormSubTitle title='Tag Terms' />
      <FieldArray
        name='tag_terms'
        label='tag_terms'
        // @ts-ignore
        component={TagTerms}
      />
    </Form>
  );
};

export default TagForm;
