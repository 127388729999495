import * as React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Theme,
  Tooltip,
} from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

interface AllProps {
  title: string | React.ReactElement;
  save: () => void;
  reset: () => void;
  closeModal: () => void;
  isValid: boolean;
  isSubmitting: boolean;
  isDirty: boolean;
  extraActions?: React.ReactElement;
}

const FormHeader: React.SFC<AllProps> = ({
  title,
  save,
  reset,
  closeModal,
  isSubmitting,
  isDirty,
  extraActions,
}) => {
  const classes = useStyles({});

  return (
    <AppBar color='default' elevation={0} className={classes.appBar}>
      <Toolbar>
        <Typography
          variant='h2'
          color='inherit'
          style={{
            flex: 1,
          }}
          data-e2e='form-header-title'
        >
          {title}
        </Typography>
        {extraActions &&
          React.cloneElement(extraActions, {
            disabled: isSubmitting || isDirty,
          })}
        <Tooltip title='Reset'>
          <span>
            <IconButton
              data-e2e='resetEditForm'
              color='default'
              onClick={reset}
              disabled={isSubmitting || !isDirty}
            >
              <RotateLeftIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title='Save'>
          <span>
            <IconButton
              data-e2e='submitCreateEditForm'
              color='default'
              onClick={save}
              disabled={isSubmitting || !isDirty}
            >
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title='Close'>
          <IconButton color='inherit' onClick={closeModal} data-e2e='closeForm'>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(FormHeader);
