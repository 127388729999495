import { useNovaApi } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';

export const useSongThemes = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.SongThemesResponse>(`/song_theme`, { query }).data;
};

export const useSongThemeParents = () => {
  return (
    useNovaApi<Nl.Api.SongThemesResponse>(`/song_theme/select_parents`).data
      ?.song_themes || []
  );
};

export const useSongThemeSelect = () => {
  return (
    useNovaApi<Nl.Api.SongThemesSelectResponse>('/song_theme/select').data
      ?.song_themes || []
  );
};
