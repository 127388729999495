import * as React from 'react';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import { useTagFieldSelect } from 'novaApi/hooks/tag_field';

const TagFilters = () => {
  const tagFields = useTagFieldSelect();

  return (
    <ConnectedTableFilters
      filters={[
        {
          type: 'single_select',
          label: 'Tag field',
          queryFilterKey: 'tag_field_uuid',
          optionsSelector: () => tagFields,
          e2e: 'select_tag_field',
        },
      ]}
    />
  );
};
export default TagFilters;
