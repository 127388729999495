import * as React from 'react';
import InputField from 'components/Formik/InputField';
import { FormSubTitle } from 'components/Formik/utils';
import { Field, Form } from 'formik';
import { useUniversalCategorySelect } from 'novaApi/hooks/universal_categories';
import Typeahead from 'components/Typeahead/Typeahead';
import { useLanguageSelect } from 'novaApi/hooks/language';
import { multipleSelectFormat } from 'utils/utils';
import Checkbox from 'components/Formik/CheckboxField';

const termInitialValues: Partial<Nl.Api.Term> = {
  name: '',
  sentence_case_variant: '',
  headline_case_variant: '',
  linguistic_variants: '',
  language: null,
  spelling_variants: '',
  universal_categories: [],
  is_problematic: false,
  lcid_code: '',
  wikidata_id: '',
  semi_scraping_lookup: '',
};

export const getFormInitialValues = (term?: Nl.Api.Term) => {
  if (!term) return { ...termInitialValues };
  return {
    ...term,
    language: term.language?.uuid,
    universal_categories: term.universal_categories.map((cat) => cat.uuid),
  };
};

const formatUniversalCategory = (
  universalCategory: Nl.Api.UniversalCategorySelect,
): Nl.Api.SelectorEntity => ({
  uuid: universalCategory.uuid,
  name: `${universalCategory.category} ${universalCategory.sub_category}`,
});

const TermForm = () => {
  const universalCategories = useUniversalCategorySelect();
  const languages = useLanguageSelect();

  return (
    <Form>
      <FormSubTitle title='Term Information' />
      <Field name='name' label='Term name' component={InputField} />
      <Field
        name='sentence_case_variant'
        label='Sentence case variant'
        component={InputField}
      />
      <Field
        name='headline_case_variant'
        label='Headline case variant'
        component={InputField}
      />
      <Field
        name='linguistic_variants'
        label='Linguistic variants'
        component={InputField}
      />
      <Field
        name='language'
        label='Language'
        component={Typeahead}
        options={multipleSelectFormat(languages)}
      />
      <Field
        name='spelling_variants'
        label='Spelling variants'
        component={InputField}
      />
      <Field
        name='universal_categories'
        label='Universal categories'
        component={Typeahead}
        isMulti
        options={multipleSelectFormat(
          universalCategories.map(formatUniversalCategory),
        )}
      />
      <Field
        name='is_problematic'
        label='Is problematic'
        component={Checkbox}
      />
      <Field name='lcid_code' label='LCID code' component={InputField} />
      <Field name='wikidata_id' label='Wikidata id' component={InputField} />
      <Field
        name='semi_scraping_lookup'
        label='Semi-scraping lookup'
        component={InputField}
      />
    </Form>
  );
};

export default TermForm;
