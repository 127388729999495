import { connect } from 'react-redux';
import { GlobalState } from 'store/rootReducer';
import AutocompleteSelect from './AutocompleteSelect';

interface OwnProps {
  selector: (state: GlobalState) => any[];
  name: string;
}

export default connect((state: GlobalState, ownProps: OwnProps) => ({
  options: ownProps.selector(state),
}))(AutocompleteSelect);
