import * as React from 'react';
import ProgressBar from './ProgressBar';
import { useNlSelector } from 'utils/redux';
import { useIsFetching } from 'react-query';

export default function LoadingTopBar() {
  const isFetching = useIsFetching() > 0;
  const isReduxLoading = useNlSelector((state) =>
    Object.values(state).some((slice) => slice?.isLoading),
  );
  return <ProgressBar isLoading={isReduxLoading || isFetching} />;
}
