import * as React from 'react';
import { Field, getIn } from 'formik';
import classnames from 'classnames';
import {
  Button,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InputField from 'components/Formik/InputField';
import { multipleSelectFormat } from 'utils/utils';
import DeleteButton from 'components/Button/DeleteButton';
import { useWriterSelect } from 'novaApi/hooks/writer';
import Typeahead from 'components/Typeahead/Typeahead';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    toolbar: {
      paddingLeft: 0,
    },
    select: {},
    selectCell: {
      overflow: 'inherit',
      paddingLeft: theme.spacing(1),
    },
    nameCell: {
      width: '40%',
    },
    header: {
      padding: 0,
    },
    table: {
      width: '100%',
    },
    share: {
      width: theme.spacing(24),
    },
    error: {
      // @ts-ignore
      color: theme.palette.error[400],
    },
    warning: {
      color: theme.customPalette.warning,
    },
  }),
);

interface AllProps {
  form: any;
  name: any;
  remove: any;
  push: any;
}

const SongEditWriter = ({ form, name, remove, push }: AllProps) => {
  const classes = useStyles();

  const writers = useWriterSelect();

  const error = false;
  const warning = false;
  const fields: Nl.WriterShares[] = getIn(form.values, name);

  return (
    <div className={classes.root}>
      {error && (
        <Typography variant='subtitle2' className={classes.error}>
          {error}
        </Typography>
      )}
      {warning && (
        <Typography variant='subtitle2' className={classes.error}>
          {warning}
        </Typography>
      )}

      <div className={classes.table}>
        {!!fields && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>IPI Number</TableCell>
                <TableCell>Pro</TableCell>
                <TableCell>Work Share (%)</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => {
                const writerUuid = getIn(field, 'writer_uuid');
                const writerName = getIn(field, 'name');
                const ipiNumber = getIn(field, 'ipi_number');
                const pro = getIn(field, 'pro');
                const editableWriter = getIn(field, 'editable');
                return (
                  <TableRow key={`writer_${writerUuid || index}`}>
                    {editableWriter ? (
                      <TableCell
                        className={classnames(
                          classes.nameCell,
                          classes.selectCell,
                        )}
                        padding='none'
                      >
                        <Field
                          name={`writer_shares.${index}.writer_uuid`}
                          className={classes.select}
                          label="Writer's name"
                          noClearable
                          isSubSection
                          component={Typeahead}
                          options={multipleSelectFormat(
                            // Return the available writers list (except the ones already used)
                            writers.filter(
                              ({ uuid }) =>
                                writerUuid === uuid ||
                                !fields.find((w) => w.writer_uuid === uuid),
                            ),
                          )}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        className={classes.nameCell}
                        data-e2e={`writer_shares.${index}.writer_name`}
                      >
                        {writerName}
                      </TableCell>
                    )}
                    <TableCell>{ipiNumber || '-'}</TableCell>
                    <TableCell>{pro || '-'}</TableCell>
                    <TableCell padding='none'>
                      <Field
                        className={classes.share}
                        name={`writer_shares.${index}.share`}
                        component={InputField}
                        type='number'
                        internal
                      />
                    </TableCell>
                    <TableCell padding='none'>
                      <DeleteButton
                        aria-label='Remove'
                        onClick={() => remove(index)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        <Button
          onClick={() =>
            push({
              writer_uuid: undefined,
              share: 1,
              editable: true,
              name: '',
              pro: null,
            })
          }
          data-e2e='addWriterButton'
        >
          <AddIcon />
          Add
        </Button>
      </div>
    </div>
  );
};

export default SongEditWriter;
