import * as React from 'react';
import { Paper, makeStyles, Button, Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import TableToolbar from 'components/TableToolbar';
import { get } from 'lodash';
import { createPRORegistrationExport } from 'store/proSongRegistrations';
import DownloadButton from 'components/Button/DownloadButton';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import Table from 'components/Table/Table';
import { styles } from 'components/Table/Table.styles';
import { selectIsLoaded, selectPermission } from 'store/selectors';
import { TableConfigEntry } from 'utils/tableConfig';
import { useNlSelector } from 'utils/redux';

const useStyles = makeStyles(styles);

const tableConfig: TableConfigEntry<Nl.Api.PROReport> = {
  storeKey: 'proSongRegistrations',
  columnData: [
    { id: 'pro_name', label: 'PRO', render: (r) => r.parent_name },
    { id: 'name', label: 'Name' },
    { id: 'user', label: 'User', render: (r) => r?.user?.full_name },
    {
      id: 'url',
      label: 'Status',
      render: (r) => (r.url ? <DownloadButton href={r.url} /> : r.status),
    },
  ],
};

export default function PROSongRegistrationsPage() {
  const proReports = useNlSelector(
    (state) => state.proSongRegistrations.data.reports,
  );
  const isExportable = useNlSelector(
    (state) =>
      selectPermission(state, 'CAN_CREATE_PRO_REPORT') &&
      state.proSongRegistrations.isExportable,
  );
  const isLoaded = useNlSelector((state) =>
    selectIsLoaded(state, 'proSongRegistrations'),
  );
  const dispatch = useDispatch();
  const onSubmit = () => dispatch(createPRORegistrationExport());
  const classes = useStyles({});
  if (!isLoaded) {
    return null;
  }
  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='PRO Song Registrations'
        actions={
          <Tooltip
            title={isExportable ? 'Export CSV' : 'Choose a PRO to export CSV'}
          >
            <div>
              <Button
                aria-label='Export CSV'
                disabled={!isExportable}
                onClick={onSubmit}
              >
                Export
              </Button>
            </div>
          </Tooltip>
        }
        filters={
          <ConnectedTableFilters
            filters={[
              {
                type: 'single_select',
                label: 'PRO',
                queryFilterKey: 'pro_uuid',
                optionsSelector: (state) =>
                  get(state, 'proSongRegistrations.proSelect', []),
                e2e: 'select_exportable_pro',
              },
              {
                type: 'date',
                label: 'Published Since',
                queryFilterKey: 'initial_published_date_ms',
                e2e: 'select_published_date',
              },
            ]}
          />
        }
        alwaysExpanded
      />
      <Table config={tableConfig} data={proReports} isLoaded={isLoaded} />
    </Paper>
  );
}
