import * as React from 'react';
import { connect } from 'react-redux';
import { NOT_FOUND } from 'redux-first-router';
import asyncComponent from './AsyncComponent';
import { routesMap } from '../routes';
import { GlobalState } from 'store/rootReducer';

const mapStateToProps = (state: GlobalState) => ({
  route: state.location.type as Nl.RouteMapType,
});

interface StateProps {
  route: Nl.RouteMapType;
}

type AllProps = StateProps;

class PageSwitcher extends React.Component<AllProps> {
  state = {
    path: '',
  };

  static getDerivedStateFromProps(props: AllProps) {
    const route = routesMap[props.route];
    /*
     * Save the current page's path in the state to compare during a future rendering
     */
    if (route.type === 'page') {
      return {
        path: route.path,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps: AllProps) {
    const route = routesMap[nextProps.route];
    // Compare the current and the next page's path to determine if the page is already rendered
    const isSamePage = routesMap[nextProps.route].path === this.state.path;
    // Re-render the component only if the route display a page and if the page is not already rendered
    return route.type === 'page' && !isSamePage;
  }

  render() {
    const { route } = this.props;
    const targetRoute: Nl.RouteMapProps = routesMap[route];
    const pageComponent =
      (targetRoute.type === 'page'
        ? targetRoute.component
        : routesMap[targetRoute.parent].component) ||
      routesMap[NOT_FOUND].component;
    const Page = asyncComponent(pageComponent);
    return <Page />;
  }
}

export default connect(mapStateToProps)(PageSwitcher);
