import React from 'react';
import { useDispatch } from 'react-redux';
import {
  uploadFinalSourceFiles,
  uploadMyTrackSubmission,
} from 'store/singleTrackSubmission';
import { mimeTypes } from 'constants/mimeTypeConfig';
import UploadDropzone from 'components/Dropzone/UploadDropzone';
import { findIndex } from 'lodash';

interface AllProps {
  trackUuid?: string;
  state?: Nl.SourceFileUploadState;
  form: any;
  isFinalSourceFile?: boolean;
  fileType: Nl.FinalTrackSourceFileType | 'mp3';
  setIsUploadInProgressOrCompleted?: (state: boolean) => void;
  original_filename?: string;
  isEdit?: boolean;
  isRequiredError?: boolean;
  humanReadableFiletype?: Nl.SongSourceFileType;
  channelLayout?: Nl.ChannelLayoutType;
}

const MyTrackUploadDropzone: React.FunctionComponent<AllProps> = ({
  state,
  setIsUploadInProgressOrCompleted,
  original_filename,
  trackUuid,
  form,
  isFinalSourceFile,
  fileType,
  isEdit,
  isRequiredError,
  humanReadableFiletype,
  channelLayout,
}) => {
  const dispatch = useDispatch();
  const audioMimeType =
    fileType === 'mp3' ? mimeTypes.mp3.join(', ') : mimeTypes.zip.join(', ');
  // This is for the user to see what mime type it is, ie. mp3 or zip
  const humanReadableMimeType = fileType === 'mp3' ? '.mp3' : '.zip';

  const onClear = () => {
    if (isFinalSourceFile) {
      const fieldIndex = findIndex(form.values.files[channelLayout!], [
        'file_type',
        fileType,
      ]);
      const fieldName = `files.['${channelLayout}'].${fieldIndex}`;
      form.setFieldValue(`${fieldName}.state`, 'init');
      form.setFieldValue(`${fieldName}.original_filename`, '');
      form.setFieldValue(`${fieldName}.original_url`, '');
      form.setFieldValue(`${fieldName}.url`, '');
      form.setFieldValue(`${fieldName}.error`, '');
    } else {
      form.setFieldValue('source_file.state', 'init');
    }
    if (setIsUploadInProgressOrCompleted) {
      setIsUploadInProgressOrCompleted(false);
    }
  };

  const renderDoneMessage = () => {
    return isEdit && !isFinalSourceFile ? (
      <div>Your track will be replaced by {original_filename} on save</div>
    ) : (
      original_filename
    );
  };

  return (
    <UploadDropzone
      accept={audioMimeType}
      fileType={fileType}
      displayFileType={humanReadableMimeType}
      onDrop={(accepted) => {
        if (accepted.length > 0) {
          const file = accepted[0];
          if (isEdit) {
            setIsUploadInProgressOrCompleted?.(true);
          }
          dispatch(
            isFinalSourceFile
              ? uploadFinalSourceFiles({
                  file,
                  form,
                  fileType,
                  humanReadableFiletype,
                  channelLayout,
                  trackUuid,
                })
              : uploadMyTrackSubmission({ trackUuid, file, form }),
          );
        }
      }}
      onClear={onClear}
      state={state}
      isRequiredError={isRequiredError}
      doneMessage={renderDoneMessage()}
    />
  );
};

export default MyTrackUploadDropzone;
