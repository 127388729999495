import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Box } from '@material-ui/core';
import { GlobalState } from 'store/rootReducer';
import {
  selectCanSetReadyForPublication,
  readyForPublicationSong,
  publishSong,
  takeDownSong,
} from 'store/singleSong';
import { selectors as authSelectors } from 'store/auth';
import PublicationMeter from 'components/Chip/PublicationMeter';
import { get } from 'lodash';
import ArtistIndications from 'pages/Artists/ArtistIndications';

interface OwnProps {
  className?: string;
  songUuid: string;
  disabled?: boolean;
}

interface StateProps {
  canSetReadyForPublication: boolean;
  canPublish: boolean;
  canTakeDown: boolean;
  isAllowedToPublish: boolean;
  publishState?: Nl.PublishState;
  isRoyalty: boolean;
}

interface DispatchProps {
  onSetReadyForPublication: () => void;
  onPublish: () => void;
  onTakeDown: () => void;
}

type AllProps = OwnProps & StateProps & DispatchProps;

export const SongEditPushActionsView: React.FunctionComponent<AllProps> = ({
  className,
  canSetReadyForPublication,
  canPublish,
  canTakeDown,
  onSetReadyForPublication,
  onPublish,
  onTakeDown,
  disabled,
  isAllowedToPublish,
  publishState,
  isRoyalty,
}) => (
  <div className={className}>
    <ArtistIndications isRoyalty={isRoyalty} />
    {publishState && (
      <Box component='span' marginRight={1}>
        <PublicationMeter publishState={publishState} />
      </Box>
    )}
    {canSetReadyForPublication && (
      <Button
        onClick={onSetReadyForPublication}
        color='primary'
        disabled={disabled}
      >
        Ready For Publication
      </Button>
    )}
    {isAllowedToPublish && (
      <>
        <Button onClick={onTakeDown} color='primary' disabled={!canTakeDown}>
          Take Down
        </Button>
        <Button onClick={onPublish} color='primary' disabled={!canPublish}>
          Publish
        </Button>
      </>
    )}
  </div>
);

export default connect(
  (state: GlobalState, { disabled }: OwnProps) => {
    return {
      publishState: state.singleSong.data.song
        ? state.singleSong.data.song.publish_state
        : undefined,
      canSetReadyForPublication: selectCanSetReadyForPublication(state),
      isAllowedToPublish: authSelectors.isUserAllowed(
        state,
        'CAN_PUBLISH_SONGS',
      ),
      canPublish:
        !disabled && get(state, 'singleSong.data.song.publish_allowed', false),
      canTakeDown:
        !disabled &&
        get(state, 'singleSong.data.song.take_down_allowed', false),
      isRoyalty: get(state, 'singleSong.data.song.artist.is_royalty', false),
    };
  },
  (dispatch, { songUuid }) => ({
    onSetReadyForPublication: () =>
      dispatch(readyForPublicationSong({ songUuid })),
    onPublish: () => dispatch(publishSong({ songUuid })),
    onTakeDown: () => dispatch(takeDownSong({ songUuid })),
  }),
)(SongEditPushActionsView);
