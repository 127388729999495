import * as React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { Typography, withStyles } from '@material-ui/core';
import { Theme, createStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      padding: '8px',
      marginBottom: '8px',
      border: '1px solid',
      borderColor: theme.palette.grey[300],
      borderRadius: '8px',
    },
    alignLeft: {
      marginLeft: theme.spacing(2),
      '&:before': {
        position: 'absolute',
        content: '"."',
        top: 4,
        left: -theme.spacing(4),
        fontSize: 48,
        lineHeight: 0,
        fontFamily: 'monospace',
        color: theme.palette.grey[300],
      },
    },
    alignRight: {
      marginRight: theme.spacing(2),
      '&:before': {
        position: 'absolute',
        content: '"."',
        top: 4,
        right: -theme.spacing(4),
        fontSize: 48,
        lineHeight: 0,
        fontFamily: 'monospace',
        color: theme.palette.grey[300],
      },
    },
    timestamp: {
      marginTop: theme.spacing(1),
      fontSize: 11,
      color: theme.palette.secondary.dark,
    },
  });

interface OwnProps {
  alignRight?: boolean;
  timestamp?: number;
}

type AllProps = OwnProps & WithStyles<typeof styles>;

const UserLogItem: React.SFC<AllProps> = ({
  classes,
  alignRight,
  children,
  timestamp,
}) => {
  const date = timestamp ? moment(timestamp) : null;
  return (
    <div
      className={classnames(
        classes.root,
        alignRight ? classes.alignRight : classes.alignLeft,
      )}
      data-e2e='user-activity-logs'
    >
      <div>{children}</div>
      {date && (
        <Typography className={classes.timestamp}>
          {date.format('LLL')} - {date.fromNow()}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(UserLogItem);
