import * as React from 'react';
import { convertSecondsToMinutes } from 'utils/utils';
import { makeStyles } from '@material-ui/core';
import { useNlSelector } from 'utils/redux';

const useStyles = makeStyles({
  container: {
    display: 'inline-flex',
    alignItems: 'baseline',
  },
  trackName: {
    margin: 0,
    fontSize: '1.5em',
  },
  artistName: {
    fontSize: '1.2em',
  },
  duration: {
    fontSize: 14,
    marginLeft: 16,
  },
});

export default function PlayerInfo() {
  const position = useNlSelector(({ audioPlayer }) => audioPlayer.position);
  const trackName = useNlSelector(
    ({ audioPlayer }) => audioPlayer.track.trackName,
  );
  const artistName = useNlSelector(
    ({ audioPlayer }) => audioPlayer.track.artistName,
  );
  const duration = useNlSelector(({ audioPlayer }) => audioPlayer.duration);
  const classes = useStyles();
  return (
    <div data-e2e='playerInfo' className={classes.container}>
      <div className={classes.trackName}>{trackName}</div>
      {artistName ? (
        <div className={classes.artistName}>{artistName}</div>
      ) : null}
      <div data-e2e='playerTime' className={classes.duration}>
        <span data-e2e='playerTimePosition'>
          {convertSecondsToMinutes(position)}{' '}
        </span>
        /
        <span data-e2e='playerTimeDuration'>
          {' '}
          {convertSecondsToMinutes(duration)}
        </span>
      </div>
    </div>
  );
}
