const notesConfig: Nl.NotesConfig = {
  new_artist_submission: {
    parentType: 'new_artist_submission',
    noteTypes: [
      {
        type: 'internal',
        label: 'Internal Notes',
        apiNoteType: 2,
      },
    ],
  },
  new_track_submission: {
    parentType: 'new_track_submission',
    noteTypes: [
      {
        type: 'internal',
        label: 'Internal Notes',
        apiNoteType: 2,
      },
      {
        type: 'external',
        label: 'Communication',
        apiNoteType: 1,
      },
    ],
  },
  my_track_submission: {
    parentType: 'new_track_submission',
    noteTypes: [
      {
        type: 'external',
        label: 'Communication',
        apiNoteType: 1,
      },
    ],
  },
};

export default notesConfig;
