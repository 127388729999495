import * as React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from '../../components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { TableConfigEntry } from 'utils/tableConfig';
import DownloadButton from 'components/Button/DownloadButton';
import { useReports } from 'novaApi/hooks/report';

const useStyles = makeStyles(styles);

const config: TableConfigEntry<Nl.Api.Report> = {
  columnData: [
    { id: 'name', label: 'Name' },
    {
      id: 'download',
      label: '',
      render: ({ url, status }) =>
        url ? <DownloadButton href={url} /> : status,
    },
  ],
};

export default function ReportsPage() {
  const data = useReports();
  const classes = useStyles({});
  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar title='Reports' />
      <Table
        config={config}
        data={data?.reports}
        isLoaded={!!data}
        paginationData={data}
      />
    </Paper>
  );
}
