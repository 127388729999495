import { put, takeLatest } from 'redux-saga/effects';
import { createAction, SimpleActionType } from 'utils/storeUtils';
import { fetchWaveforms } from './songWaveform';
import { fetchSingleTrackSubmissionWaveform } from './singleTrackSubmission';

// Action Constants
const LOAD_PLAYER_TRACK = 'LOAD_PLAYER_TRACK';
const CLOSE_PLAYER = 'CLOSE_PLAYER';

const SET_VOLUME = 'SET_VOLUME';
const SET_DURATION = 'SET_DURATION';

const PLAY_AUDIO_TRACK = 'PLAY_AUDIO_TRACK';
const PAUSE_AUDIO_TRACK = 'PAUSE_AUDIO_TRACK';
const SEEK_AUDIO_TRACK = 'SEEK_AUDIO_TRACK';

const TRACK_PLAYED = 'TRACK_PLAYED';
const TRACK_STOPPED = 'TRACK_STOPPED';

// Action Creators
export const loadPlayerTrack = createAction(LOAD_PLAYER_TRACK);
export const closePlayer = createAction(CLOSE_PLAYER);

export const setVolume = createAction(SET_VOLUME);
export const setDuration = createAction(SET_DURATION);

export const playAudioTrack = createAction(PLAY_AUDIO_TRACK);
export const pauseAudioTrack = createAction(PAUSE_AUDIO_TRACK);
export const seekAudioTrack = createAction(SEEK_AUDIO_TRACK);

export const trackPlayed = createAction(TRACK_PLAYED);
export const trackStopped = createAction(TRACK_STOPPED);

// Reducer
const initialState = {
  isOpen: false,
  position: 0,
  duration: 0,
  isPlaying: false,
  volume: 1,
  isLoop: false,
  track: {} as Nl.AudioPlayerTrack,
};

export type AudioPlayerState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case LOAD_PLAYER_TRACK: {
      return {
        ...state,
        isOpen: true,
        isPlaying: true,
        position: 0,
        isLoop: action.payload.isLoop || false,
        track: action.payload.track,
      };
    }

    case PLAY_AUDIO_TRACK: {
      return {
        ...state,
        isPlaying: true,
      };
    }

    case TRACK_STOPPED:
    case PAUSE_AUDIO_TRACK: {
      return {
        ...state,
        isPlaying: false,
      };
    }

    case CLOSE_PLAYER: {
      return {
        ...state,
        isPlaying: false,
        isOpen: false,
        track: {},
      };
    }

    case SEEK_AUDIO_TRACK:
    case TRACK_PLAYED: {
      return {
        ...state,
        position: action.payload.position,
      };
    }

    case SET_DURATION: {
      return {
        ...state,
        duration: action.payload,
      };
    }

    case SET_VOLUME: {
      return {
        ...state,
        volume: action.payload,
      };
    }

    default:
      return state;
  }
};

const sagas = {
  *loadPlayerTrack(action: SimpleActionType) {
    const { track } = action.payload;
    // if we have waveform_json_url in the track info, request it directly
    if (track.waveform_json_url) {
      yield put(
        fetchSingleTrackSubmissionWaveform({
          waveform_json_url: track.waveform_json_url,
          uuid: track.uuid,
        }),
      );
    } else {
      yield put(fetchWaveforms({ uuids: [track.uuid] }));
    }
  },
};

export function* rootSaga() {
  yield takeLatest(LOAD_PLAYER_TRACK, sagas.loadPlayerTrack);
}
export { sagas };

export default reducer;
