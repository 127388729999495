import { useNovaApi, useNovaApiUpdate } from '../useNovaApi';
import { useDispatch } from 'react-redux';
import { addSuccessNotification } from '../../store/notifications';
import { useNlSelector } from '../../utils/redux';
import { getSearchParamsFromCurrentUrl } from '../apiUtils';

export const useMySongRevision = (uuid: string) => {
  return useNovaApi<Nl.Api.SongRevisionResponse>(
    `/my_song_revision/${uuid} `,
    {},
  );
};

export const useMySongRevisions = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.SongRevisionsResponse>('/my_song_revision', {
    query,
  });
};

export const useMySongRevisionUpdate = (uuid: string) => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiUpdate<
    Nl.Api.SongRevisionResponse,
    Nl.Api.SongRevision
  >(`/my_song_revision/${uuid}`, () => {
    dispatch(
      addSuccessNotification({
        message: 'Revisions for your song have been submitted',
      }),
    );
  });

  return {
    updateSongRevision: mutation.mutate,
    updatedSongRevision: data,
    isSuccess,
  };
};
