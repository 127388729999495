import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const Approve = React.forwardRef((props: {}, ref: any) => (
  <SvgIcon
    ref={ref}
    {...props}
    viewBox='0 0 124 124'
    style={{ transform: 'scale(0.8)' }}
  >
    <linearGradient
      id='prefix__a'
      gradientUnits='userSpaceOnUse'
      x1={63.997}
      x2={63.997}
      y1={114.685}
      y2={18.798}
    >
      <stop offset={0} stopColor='#ffb300' />
      <stop offset={0.401} stopColor='#ffca28' />
    </linearGradient>
    <path
      d='M107.76 122.55c-23.67 0-65.6-.42-80.43-3.25-20.5-3.91-22.03-11.96-21.84-19.87.16-6.73 3.24-29.26 6.28-38.48 4.6-13.94 11.63-27.8 15.91-33.76 5.87-8.17 20.75-21.3 35.71-21.75h.14c1.98 0 3.95 1.3 5.13 3.39l5.85 10.32a7.964 7.964 0 0 1-.08 7.97c-1.19 2-4.65 2.54-4.68 2.55l-2.75.4 1.85 2.09c-.01 0 .61.98-.85 3.1-1.3 1.89-3.83 2.8-7.72 2.8-3.65 0-7.81-.83-11.15-1.49-.43-.08-.84-.13-1.22-.13-2.88 0-3.89 2.22-4.96 4.56-.17.37-.34.75-.54 1.15-1.84 3.84-1.89 11.31-1.93 17.9-.01 2.34-.03 4.55-.12 6.35-.33 6.25-2.59 14.25-2.61 14.33l-1.51 5.31 3.99-3.82c.2-.19 19.82-18.75 39.63-18.75 10.89 0 21.54 3.96 28.46 10.58 4.25 4.06 8.31 4.92 10.97 4.92 1.18 0 2.22-.17 3.05-.37l.18 43.88c-2.47.04-7.82.07-14.76.07z'
      fill='url(#prefix__a)'
    />
    <path
      d='M63.54 6.95c1.42 0 2.92 1.03 3.83 2.63l5.85 10.32a6.474 6.474 0 0 1-.06 6.47c-.57.96-2.5 1.66-3.6 1.83l-5.53.78 3.44 3.88c-.01.2-.12.71-.7 1.55-.99 1.43-3.17 2.16-6.49 2.16-3.5 0-7.58-.81-10.85-1.46-.52-.1-1.03-.16-1.51-.16-3.84 0-5.22 3.02-6.33 5.44-.16.36-.33.73-.52 1.13-1.99 4.15-2.04 11.46-2.08 18.54-.01 2.32-.03 4.51-.12 6.28-.32 6.08-2.53 13.93-2.56 14.01L33.3 90.96l7.97-7.62c.19-.18 19.4-18.33 38.59-18.33 10.52 0 20.77 3.8 27.43 10.17 4.61 4.41 9.07 5.33 12.01 5.33.55 0 1.07-.03 1.56-.09l.17 40.61c-2.82.02-7.49.05-13.25.05-23.62 0-65.43-.42-80.14-3.23-8.97-1.71-14.79-4.3-17.82-7.91-2.61-3.11-2.88-6.86-2.8-10.45.16-6.66 3.2-28.94 6.21-38.04 4.39-13.3 11.43-27.4 15.71-33.35 5.7-7.94 20.12-20.69 34.53-21.12l.07-.03m0-3h-.18c-15.81.47-31.1 14.31-36.88 22.37-4.41 6.15-11.54 20.3-16.12 34.16C7.34 69.64 4.17 92.25 4 99.39c-.21 8.73 1.85 17.33 23.06 21.38 14.99 2.86 57.22 3.28 80.7 3.28 9.75 0 16.27-.07 16.27-.07l-.19-47.5s-1.78 1-4.54 1c-2.64 0-6.18-.92-9.93-4.5-7.5-7.17-18.76-11-29.5-11-20.67 0-40.67 19.17-40.67 19.17s2.32-8.17 2.67-14.67c.36-6.79-.42-18.82 1.91-23.68 1.35-2.81 1.9-4.86 4.14-4.86.28 0 .59.03.93.1 3.46.69 7.66 1.52 11.44 1.52 3.78 0 7.14-.83 8.95-3.45 2.25-3.25.74-4.95.74-4.95s4.17-.59 5.76-3.27a9.449 9.449 0 0 0 .1-9.48L69.97 8.09c-1.33-2.35-3.74-4.14-6.43-4.14z'
      fill='#eda600'
    />
    <path
      d='M62.78 18.52c-2.51-.47-5.5-.57-7.83.61-2.89 1.47-3.9 5.28-2.22 8.05 2.52 4.13 9.33 4.53 13.64 4.36 1.92-.08 1.93-3.08 0-3-3.11.12-6.99-.04-9.7-1.69-1.94-1.19-2.6-3.91-.21-5.12 1.54-.79 3.85-.62 5.51-.31 1.9.34 2.71-2.55.81-2.9zM37.53 83.13c-5.05 6.35-6.49 13.53-5.72 21.52.18 1.9 3.19 1.92 3 0-.69-7.12.29-13.67 4.84-19.4 1.19-1.5-.92-3.64-2.12-2.12zM47.77 34.91c-2.22-.32-5.39-3.01-6.71-5.85-.4-.86-.23-2.08-1.8-2.17-2.17-.12-.58 4.24.16 5.31 2.03 2.91 4.78 4.54 8.38 4.8 1.93.14 1.95-1.81-.03-2.09zM48.72 100.01c10.42 8.38 20.88 13.82 34.65 12 10.45-1.38 21.6-6.22 26.48-16.1.85-1.73-1.73-3.25-2.59-1.51-4.65 9.42-15.64 13.84-25.61 14.82-12.24 1.21-21.54-3.87-30.8-11.32-1.5-1.21-3.64.9-2.13 2.11z'
      fill='#eda600'
    />
  </SvgIcon>
));

export default Approve;
