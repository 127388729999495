import * as React from 'react';
import ConnectedTableFilters from '../Table/ConnectedTableFilters';
import { useUserRoles } from 'novaApi/hooks/user_role';

const UserFilters = () => {
  const userRoles = useUserRoles();
  return (
    <div>
      <ConnectedTableFilters
        filters={[
          {
            type: 'single_select',
            label: 'Filter by Roles',
            queryFilterKey: 'user_role_uuid',
            optionsSelector: () => userRoles,
            e2e: 'user_role_uuid',
          },
        ]}
      />
    </div>
  );
};

export default UserFilters;
