import * as React from 'react';
import { connect } from 'react-redux';
import { Tooltip, IconButton } from '@material-ui/core';
import ResetIcon from '@material-ui/icons/ClearAll';
import { getNumOfFilterSelected, resetFilters } from 'store/queryParams';
import { GlobalState } from 'store/rootReducer';

interface StateProps {
  numOfFiltersSelected: number;
}

interface DispatchProps {
  onResetFilters(): void;
}

const FilterIndicator: React.SFC<StateProps & DispatchProps> = ({
  numOfFiltersSelected,
  onResetFilters,
}) => {
  return numOfFiltersSelected > 0 ? (
    <Tooltip title='Reset the filters' placement='right'>
      <IconButton
        color='primary'
        onClick={onResetFilters}
        data-e2e='resetFilters'
      >
        <ResetIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};

export default connect(
  (state: GlobalState) => ({
    numOfFiltersSelected: getNumOfFilterSelected(state),
  }),
  (dispatch) => ({
    onResetFilters: () => dispatch(resetFilters()),
  }),
)(FilterIndicator);
