import * as React from 'react';
import ConnectedTableFilters from '../Table/ConnectedTableFilters';

const PublisherFilters = () => (
  <ConnectedTableFilters
    filters={[
      {
        type: 'search',
        size: 6,
        label: 'Search by name',
        queryFilterKey: 'name',
        e2e: 'name',
      },
    ]}
  />
);

export default PublisherFilters;
