import React from 'react';
import FormikWrapper from 'components/Formik';
import TagForm, { getFormInitialValues } from './TagsForm';
import { useTagCreate } from 'novaApi/hooks/tag';
import { goPreviousPage } from 'store/router';
import { useDispatch } from 'react-redux';
import { getValidationSchema } from './validation';

const TagCreatePage = () => {
  const dispatch = useDispatch();

  const { createTag, createdTag, isSuccess } = useTagCreate();

  const handleFormSubmit = (formData, formActions) => {
    createTag({ formData, formActions });
  };

  if (createdTag && isSuccess) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      submitForm={(formData, formActions) => {
        handleFormSubmit(formData, formActions);
      }}
      title='Create tag'
      customSize='md'
    >
      <TagForm />
    </FormikWrapper>
  );
};

export default TagCreatePage;
