import { Dispatch } from 'react';
import { FormikHelpers } from 'formik';
import { addSuccessNotification } from 'store/notifications';
import { goPreviousPage } from 'store/router';
import { fetchNovaApi } from 'novaApi/useNovaApi';
import checksumWorker from 'utils/checksum/checksumWorker';
import { uploadSourceFile } from 'novaApi/NovaApi';

export type ProEarningsFormValues = {
  source_file: { url: string };
  date: string;
  region: string;
  pro: string;
};

export async function uploadEarningsReport(
  file: File,
  form: FormikHelpers<ProEarningsFormValues>,
) {
  form.setFieldError('source_file.url', undefined);
  try {
    const checksum = await checksumWorker(file);
    form.setFieldValue('source_file.state', 'uploading');

    const earningStatement = await fetchNovaApi<Nl.Api.UploadPresignedPostKeyResponse>(
      '/upload/presigned_post_key/pro_earnings_statement',
      {
        method: 'POST',
        body: {
          original_filename: file.name,
          checksum,
        },
      },
    );
    const { finalS3Url, eTag } = await uploadSourceFile(
      earningStatement.url,
      earningStatement.fields,
      file,
    );

    if (eTag !== checksum) {
      throw new Error('chekcum does not match');
    }
    form.setFieldValue('source_file', {
      state: 'uploaded',
      original_filename: file.name,
      url: finalS3Url,
    });
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    form.setFieldError(
      'source_file.url',
      'There was an issue uploading your CSV. Please try again.',
    );
    form.setFieldValue('source_file.state', 'init');
  }
}

export const submitEarningsReport = (dispatch: Dispatch<any>) => async (
  { pro, region, source_file, date }: ProEarningsFormValues,
  _: FormikHelpers<ProEarningsFormValues>,
) => {
  await fetchNovaApi('/pro_earnings_statement', {
    method: 'POST',
    body: {
      url: source_file.url,
      statement_date: parseInt(date, 10) / 1000,
      pro,
      region,
    },
  });
  dispatch(
    addSuccessNotification({
      message: `The report is being uploaded and analyzed. You'll get an email when it's done.`,
    }),
  );
  dispatch(goPreviousPage());
};
