import React from 'react';
import { Tooltip } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { passwordHelp } from 'utils/form/validators';

export default () => (
  <Tooltip title={passwordHelp}>
    <HelpOutlineOutlinedIcon color='primary' />
  </Tooltip>
);
