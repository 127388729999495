import classnames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import Select, { createFilter } from 'react-select';
import Creatable from 'react-select/creatable';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { CSSObject } from '@emotion/serialize';

const createdOption = (value: string) => {
  return (
    <span
      style={{
        fontWeight: 'bold',
        backgroundColor: 'yellow',
        padding: '.25rem',
      }}
    >
      Create &quot;{value}&quot;
    </span>
  );
};

const NoOptionsMessage = React.memo((props: any) => {
  return (
    <MenuItem disabled component='div'>
      <Typography noWrap>{props.children}</Typography>
    </MenuItem>
  );
});

const Option = React.memo((props: any) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = {
    ...props,
    innerProps: rest,
  };
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      data-e2e={props.children}
      {...newProps.innerProps}
    >
      <Typography noWrap>{props.children}</Typography>
    </MenuItem>
  );
});

const ValueContainer = React.memo((props: any) => {
  const { classes } = props.selectProps;
  return <div className={classes.valueContainer}>{props.children}</div>;
});

const Menu = React.memo((props: any) => {
  const { classes } = props.selectProps;
  return (
    <Paper square className={classes.menu} {...props.innerProps}>
      {props.children}
    </Paper>
  );
});

const SingleValue = React.memo((props: any) => {
  const { classes } = props.selectProps;
  return (
    <Typography
      data-e2e='singleValue'
      {...props.innerProps}
      className={
        props.selectProps.isDisabled
          ? classnames(classes.disable, classes.singleValue)
          : classes.singleValue
      }
    >
      {props.children}
    </Typography>
  );
});

const inputComponent = React.memo(({ inputRef, children, ...props }: any) => {
  return (
    <div ref={inputRef} {...props}>
      {children}
    </div>
  );
});

const Control = React.memo((props: any) => {
  const { classes } = props.selectProps;
  return (
    <TextField
      fullWidth
      name={props.selectProps.name}
      label={props.selectProps.label}
      InputLabelProps={{
        shrink: props.getValue().length > 0 || props.isFocused,
      }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.textFieldInput,
          inputRef: props.innerRef,
          'data-e2e': props.selectProps.e2e,
          children: props.children,
          ...props.innerProps,
        },
      }}
      disabled={props.selectProps.isDisabled}
      error={props.selectProps.error}
      helperText={props.selectProps.helperText}
      {...props.selectProps.textFieldProps}
      variant='outlined'
    />
  );
});

const MultiValue = React.memo((props: any) => {
  const { classes } = props.selectProps;
  return (
    <Chip
      tabIndex={-1}
      data-e2e='multiValue'
      label={props.children}
      classes={{
        label: classes.multiValueLabel,
        root: classes.multiValue,
      }}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      variant='outlined'
    />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: ({ internal }: any) => (internal ? undefined : '1rem'),
  },
  menu: {
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    width: '100%',
  },
  multiValue: {
    maxWidth: '95%',
  },
  multiValueLabel: {
    width: '100%',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textFieldInput: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    margin: 0,
    fontWeight: 400,
    font: 'inherit',
    alignItems: 'center',
    ...theme.overrides?.MuiInputBase?.input,
  },
  singleValue: {
    position: 'absolute',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  valueContainer: {
    width: 'calc(100% - 24px)',
    position: 'relative',
    paddingRight: '8px',
  },
  disable: {
    color: theme.palette.action.disabled,
  },
}));

export interface OwnProps {
  form: any;
  field: any;
  e2e?: string;
  label?: string;
  placeholder?: string;
  isCreatable?: boolean;
  options: Nl.SelectFieldOptionsType[];
  selected?: string | string[];
  isForm?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  noClearable?: boolean;
  internal?: boolean;
  getNewOptionData?: (value: string) => any;
  format?: (entities: any[]) => Nl.SelectFieldOptionsType[];
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?(selected: string | string[], name: string): void;
}

const calculateCurrentValue = (
  safeAssignment: boolean,
  isCreatable: boolean | undefined,
  getNewOptionData: ((value: string) => any) | undefined,
  isMulti: boolean | undefined,
  field: any,
  options: Nl.SelectFieldOptionsType[],
) => {
  let currentValue;

  if (safeAssignment) {
    // Allow the creation of new values
    if (isCreatable && getNewOptionData) {
      currentValue = isMulti
        ? field.value && field.value.map((v: string) => getNewOptionData(v))
        : getNewOptionData(field.value);

      // Only allow static values
    } else {
      currentValue = isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    }
  } else {
    // Assign empty value while field and options empty
    currentValue = isMulti ? [] : ('' as any);
  }

  return currentValue;
};

const SelectField: React.SFC<OwnProps> = ({
  isCreatable,
  options,
  label,
  field,
  form,
  placeholder,
  isMulti,
  onBlur,
  e2e,
  disabled,
  noClearable,
  format,
  getNewOptionData,
  internal,
}) => {
  const classes: any = useStyles({ internal });
  const formattedOptions = format ? format(options) : options;
  const ReactSelect: any = isCreatable ? Creatable : Select;
  const safeAssignment: boolean = options && field && field.value;

  const memoizedCurrentValue = useMemo(
    () =>
      calculateCurrentValue(
        safeAssignment,
        isCreatable,
        getNewOptionData,
        isMulti,
        field,
        options,
      ),
    [safeAssignment, isCreatable, getNewOptionData, isMulti, field, options],
  );

  return (
    <div className={classes.root}>
      <ReactSelect
        name={field.name}
        options={formattedOptions}
        formatCreateLabel={(value: string) => createdOption(value)}
        styles={{
          menuPortal: (base: CSSObject) => ({
            ...base,
            zIndex: 2000,
          }),
          clearIndicator: (base: CSSObject) => ({
            ...base,
            padding: '6px',
            cursor: 'pointer',
          }),
          dropdownIndicator: (base: CSSObject) => ({
            ...base,
            padding: '6px',
            cursor: 'pointer',
          }),
          indicatorsContainer: (base: CSSObject) => ({
            ...base,
            margin: '-6px',
          }),
          input: (base: CSSObject) => ({
            ...base,
            display: 'inline-block',
            margin: 0,
            padding: 0,
          }),
        }}
        components={{
          Control,
          Menu,
          MultiValue,
          NoOptionsMessage,
          Option,
          SingleValue,
          ValueContainer,
        }}
        placeholder={placeholder || ''}
        autoFocus={false}
        backspaceRemovesValue
        filterOption={createFilter({ ignoreAccents: false })}
        isClearable={!isMulti && !noClearable}
        value={memoizedCurrentValue || null}
        menuPortalTarget={document.body}
        onChange={(
          option: Nl.SelectFieldOptionsType | Nl.SelectFieldOptionsType[],
        ) => {
          if (Array.isArray(option)) {
            form.setFieldValue(
              field.name,
              option.map((o) => o.value),
            );
          } else if (option) {
            form.setFieldValue(field.name, option.value);
          } else if (!option) {
            form.setFieldValue(field.name, null);
          }
        }}
        isSearchable
        onBlur={onBlur}
        isMulti={isMulti}
        isDisabled={disabled}
        // @ts-ignore
        // getNewOptionData={getNewOptionData}
        error={Boolean(form.errors[field.name])}
        helperText={form.errors[field.name]}
        classes={classes}
        e2e={e2e}
        label={label}
        aria-label={label}
      />
    </div>
  );
};

function areEqual(prevProps: OwnProps, nextProps: OwnProps) {
  /*
    The Autocomplete Select is an expensive component, we only want
    it to re-render when given the following conditions
  */
  const modifiedField: boolean =
    prevProps.field.value !== nextProps.field.value;
  const modifiedOptions: boolean =
    prevProps.options.length !== nextProps.options.length;
  const disabledState: boolean = prevProps.disabled !== nextProps.disabled;
  const newErrors: boolean =
    prevProps.form.errors[prevProps.field.name] !==
    nextProps.form.errors[nextProps.field.name];

  const modified =
    modifiedField || modifiedOptions || disabledState || newErrors;

  // If 'modified', meangingful props have changed, update the component
  return !modified;
}

export default React.memo(SelectField, areEqual);
