import * as React from 'react';
import { useDispatch } from 'react-redux';
import { editSong, getEditFormInitialValues } from 'store/singleSong';
import FormikWrapper from 'components/Formik';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import SongForm from './SongForm';
import SongEditActionRequired from './SongEditActionRequired';
import SongPushActions from './SongEditPushActions';
import SongSyncWarning from './SongSyncWarning';
import validationSchema from './validation';
import { selectIsLoaded, selectPermission } from 'store/selectors';
import { useNlSelector } from 'utils/redux';

export default function SongEditPage() {
  const song = useNlSelector((state) => state.singleSong.data.song);
  const isLoaded = useNlSelector((state) =>
    selectIsLoaded(state, 'singleSong'),
  );
  const canGetIngestionLogs = useNlSelector((state) =>
    selectPermission(state, 'CAN_GET_SONG_INGESTION_LOGS'),
  );
  const dispatch = useDispatch();
  if (!isLoaded || !song) {
    return null;
  }
  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues(song)}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) =>
        dispatch(editSong({ formData, formActions }))
      }
      title='Edit Song'
      extraActions={<SongPushActions songUuid={song.uuid} />}
      customSize='xl'
    >
      <SongSyncWarning />
      <SongEditActionRequired />
      <SongForm isEdit />
      <CollapsableUserLogs
        withSongIngestionLogs={canGetIngestionLogs}
        activityType='songs'
        resourceUuid={song.uuid}
      />
    </FormikWrapper>
  );
}
