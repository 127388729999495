import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import {
  middleware as routesMiddleware,
  enhancer as routesEnhancer,
} from '../routes';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export const createNlStore = () => {
  const middlewareComposer =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
    compose;
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, routesMiddleware];

  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger();
    middleware.push(logger);
  }

  const store = createStore(
    rootReducer,
    middlewareComposer(routesEnhancer, applyMiddleware(...middleware)),
  );

  // Start our sagas for async action flows
  sagaMiddleware.run(rootSaga, store.dispatch);
  return store;
};
