import * as React from 'react';
import { connect } from 'react-redux';
import FilterListIcon from '@material-ui/icons/FilterList';
import Badge from 'components/Badge';
import { getNumOfFilterSelected } from 'store/queryParams';
import { GlobalState } from 'store/rootReducer';

interface StateProps {
  numOfFiltersSelected: number;
}

const FilterIndicator: React.SFC<StateProps> = ({ numOfFiltersSelected }) => {
  return numOfFiltersSelected > 0 ? (
    <Badge badgeContent={numOfFiltersSelected} color='primary'>
      <FilterListIcon />
    </Badge>
  ) : (
    <FilterListIcon />
  );
};

export default connect((state: GlobalState) => ({
  numOfFiltersSelected: getNumOfFilterSelected(state),
}))(FilterIndicator);
