import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from 'utils/storeUtils';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';

import { goPreviousPage } from 'store/router';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

// Action Constants
const CREATE_SONG_INSTRUMENT = 'CREATE_SONG_INSTRUMENT';
const CREATE_SONG_INSTRUMENT_ERROR = 'CREATE_SONG_INSTRUMENT_ERROR';
const DELETE_SONG_INSTRUMENT = 'DELETE_SONG_INSTRUMENT';
const DELETE_SONG_INSTRUMENT_SUCCESS = 'DELETE_SONG_INSTRUMENT_SUCCESS';
const DELETE_SONG_INSTRUMENT_ERROR = 'DELETE_SONG_INSTRUMENT_ERROR';
const FETCH_SONG_INSTRUMENT_ENTITIES = 'FETCH_SONG_INSTRUMENT_ENTITIES';
const FETCH_SONG_INSTRUMENT_ENTITIES_SUCCESS =
  'FETCH_SONG_INSTRUMENT_ENTITIES_SUCCESS';
const FETCH_SONG_INSTRUMENT_ENTITIES_ERROR =
  'FETCH_SONG_INSTRUMENT_ENTITIES_ERROR';

// Action Creators
export const createSongInstrument = createAction(CREATE_SONG_INSTRUMENT);
const createSongInstrumentError = createAction(CREATE_SONG_INSTRUMENT_ERROR);
export const deleteSongInstrument = createAction(DELETE_SONG_INSTRUMENT);
const deleteSongInstrumentSuccess = createAction(
  DELETE_SONG_INSTRUMENT_SUCCESS,
);
const deleteSongInstrumentError = createAction(DELETE_SONG_INSTRUMENT_ERROR);
export const fetchSongInstrumentEntities = createAction(
  FETCH_SONG_INSTRUMENT_ENTITIES,
);
const fetchSongInstrumentEntitiesSuccess = createAction(
  FETCH_SONG_INSTRUMENT_ENTITIES_SUCCESS,
);
const fetchSongInstrumentEntitiesError = createAction(
  FETCH_SONG_INSTRUMENT_ENTITIES_ERROR,
);

const songInstrumentInitialValues = {
  uuid: '' as string,
  name: '' as string,
  parent_instrument_uuid: '' as string, // this might not be right
};

// Selector
export const getEditFormInitialValues = (): {
  uuid: string;
  name: string;
  parent_instrument_uuid: string;
} => songInstrumentInitialValues;

// Reducer
const initialState = {
  isLoading: false,
  isLoaded: false,
  entities: [] as Nl.Api.SelectorEntity[],
};

export type SongInstruments = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_SONG_INSTRUMENT_ENTITIES: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SONG_INSTRUMENT_ENTITIES_SUCCESS: {
      return assign(state, {
        entities: action.payload.data.instruments,
        isLoading: false,
      });
    }

    case FETCH_SONG_INSTRUMENT_ENTITIES_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSongInstrumentEntitiesSaga() {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/instrument/select',
    );
    if (results.success) {
      yield put(fetchSongInstrumentEntitiesSuccess({ data: results.data }));
    } else {
      const message = results.errors?.name || results.msg;
      yield put(fetchSongInstrumentEntitiesError());
      yield put(addErrorNotification({ message }));
    }
  },
  *createSongInstrumentSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(apiFetch, '/instrument', {
      method: 'POST',
      body: { ...formData },
    });
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been created`,
        }),
      );
      invalidateNovaQueries('/instrument');
      yield put({ type: 'route/SONG_INSTRUMENTS' });
      yield put(goPreviousPage());
    } else {
      yield put(createSongInstrumentError(results.msg));
      if (results.errors.name) {
        yield put(addErrorNotification({ message: results.errors.name }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
    }
    formActions.setSubmitting(false);
  },
  *deleteSongInstrumentSaga(action: SimpleActionType) {
    try {
      yield call(apiFetch, `/instrument/${action.payload.uuid}`, {
        method: 'DELETE',
      });
      yield put(deleteSongInstrumentSuccess());
      invalidateNovaQueries('/instrument');
      yield put(
        addSuccessNotification({ message: 'Song Instrument has been deleted' }),
      );
    } catch (error) {
      yield put(deleteSongInstrumentError());
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the songGenre',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(
      FETCH_SONG_INSTRUMENT_ENTITIES,
      sagas.fetchSongInstrumentEntitiesSaga,
    ),
    takeEvery(CREATE_SONG_INSTRUMENT, sagas.createSongInstrumentSaga),
    takeLatest(DELETE_SONG_INSTRUMENT, sagas.deleteSongInstrumentSaga),
  ]);
}

export { sagas };
export default reducer;
