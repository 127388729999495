import * as Yup from 'yup';

export const editArtistSubmissionSchema = Yup.object().shape({
  artist_band_name: Yup.string().nullable(),
});

export const editTrackSubmissionSchema = Yup.object().shape({
  is_surround_5_1: Yup.boolean(),
  is_ambisonic: Yup.boolean(),
  has_lyrics: Yup.boolean().test(
    'lyrics and is immersive are both checked',
    'Immersive tracks cannot have a lyrics requirement',
    function test(hasLyricsChecked: boolean | undefined) {
      const isImmersive =
        this.parent.is_ambisonic || this.parent.is_surround_5_1;
      return !(isImmersive && hasLyricsChecked);
    },
  ),
});
