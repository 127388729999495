import * as React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'store/rootReducer';

interface OwnProps {
  children: any;
  pathname: string;
  pagenum: string;
}

class ScrollToTop extends React.PureComponent<OwnProps> {
  componentDidUpdate(prevProps: any) {
    if (
      !prevProps.pathname.includes(this.props.pathname) ||
      this.props.pagenum !== prevProps.pagenum
    ) {
      const scrollYTarget =
        document.getElementById('anchor')?.offsetTop ?? 0 - 92;
      window.scrollTo(0, scrollYTarget);
    }
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state: GlobalState) => ({
  pathname: state.location?.pathname ?? '',
  pagenum: state.location?.query?.pagenum ?? '1',
});

export default connect(mapStateToProps)(ScrollToTop);
