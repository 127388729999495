import { useState } from 'react';
import batchUpdateStages from 'constants/batchUpdateStages';

const getSelectionStatus = (selected: string[], currentSize: number) => {
  if (!selected.length) return 0;
  if (selected.length && selected.length < currentSize) return 1;
  if (selected.length === currentSize) return 2;
};

function useSelectableTable(
  data: { uuid: string }[],
  currentSize: number,
  onSelectAll: (stage: Nl.BatchUpdateStages) => void = () => {},
) {
  const [selected, setSelected] = useState<string[]>([]);
  const selectionStatus = getSelectionStatus(selected, currentSize);

  const handleSelection = (items: string[]): void => {
    if (selectionStatus === 2) onSelectAll(batchUpdateStages.initial);
    setSelected(items);
  };

  const handleSelectAll = (): void => {
    if (selected.length) {
      setSelected([]);
      onSelectAll(batchUpdateStages.initial);
    } else {
      setSelected(data.map((obj) => obj.uuid));
      onSelectAll(batchUpdateStages.check);
    }
  };

  return {
    selectionStatus,
    selected,
    handleSelection,
    handleSelectAll,
  } as const;
}

export default useSelectableTable;
