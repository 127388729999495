import * as React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import PlayArrow from '@material-ui/icons/PlayCircleOutline';
import Pause from '@material-ui/icons/PauseCircleOutline';
import { GlobalState } from 'store/rootReducer';
import {
  loadPlayerTrack,
  playAudioTrack,
  pauseAudioTrack,
} from '../store/audioPlayer';

interface OwnProps {
  audioTrack: Nl.AudioPlayerTrack;
  isLoop?: boolean;
  style?: object;
  waveform_json_url?: URL;
}

interface StateProps {
  isAlreadyOpenInPlayer: boolean;
  isPlayingThisTrack: boolean;
}

interface DispatchProps {
  onPlayAudioToggle: (
    isPlaying: boolean,
    isThisTrackOpenInPlayer: boolean,
    track: Nl.AudioPlayerTrack,
  ) => void;
}

export const AudioControlIconView: React.SFC<
  OwnProps & StateProps & DispatchProps
> = ({
  isAlreadyOpenInPlayer,
  isPlayingThisTrack,
  onPlayAudioToggle,
  audioTrack,
  style,
}) => {
  const IconComponent = isPlayingThisTrack ? Pause : PlayArrow;
  const defaultStyle = { minWidth: 0 };
  return (
    <IconButton
      data-e2e='audioControlIcon'
      disabled={!audioTrack.url}
      title={`Listen to this ${audioTrack.trackType}`}
      style={style || defaultStyle}
      onClick={() => {
        onPlayAudioToggle(
          isPlayingThisTrack,
          isAlreadyOpenInPlayer,
          audioTrack,
        );
      }}
    >
      <IconComponent color={audioTrack.url ? 'action' : 'disabled'} />
    </IconButton>
  );
};

export default connect(
  (state: GlobalState, { audioTrack }: OwnProps) => {
    const audioIsPlaying = state.audioPlayer.isPlaying;
    const playingAudioTrack = state.audioPlayer.track;
    const isAlreadyOpenInPlayer =
      playingAudioTrack && playingAudioTrack.uuid === audioTrack.uuid;
    return {
      isAlreadyOpenInPlayer,
      isPlayingThisTrack: isAlreadyOpenInPlayer && audioIsPlaying,
    };
  },
  (dispatch, { isLoop }) => ({
    onPlayAudioToggle: (
      isPlaying: boolean,
      isAlreadyOpenInPlayer: boolean,
      track: Nl.AudioPlayerTrack,
    ) => {
      if (isAlreadyOpenInPlayer && isPlaying) {
        dispatch(pauseAudioTrack());
      } else if (isAlreadyOpenInPlayer) {
        dispatch(playAudioTrack());
      } else {
        dispatch(loadPlayerTrack({ track, isLoop }));
      }
    },
  }),
)(AudioControlIconView);
