import * as React from 'react';
import ConnectedPagination from 'components/Pagination/ConnectedPagination';
import {
  TableBody as Body,
  TableRow as Row,
  TableCell as Cell,
  TableFooter,
  makeStyles,
} from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { isUserPermitted } from 'utils/authUtils';
import { TableConfigEntry } from 'utils/tableConfig';
import { mapColumnIdToDateE2e } from 'utils/tableUtils';

import { useNlSelector } from 'utils/redux';
import { selectors as authSelectors } from 'store/auth';
import { ClassNameMap } from '@material-ui/styles';

const useStyles = makeStyles(styles);

export interface BaseRowData {
  uuid?: string;
}

interface AllProps<RowData> {
  config: TableConfigEntry<RowData>;
  data: (RowData & BaseRowData)[];
  selected?: string[];
  paginationData?: Nl.Api.PaginationData;
}

function TableData<RowData>({ config, data }: AllProps<RowData>) {
  const classes = useStyles({}) as ClassNameMap;
  const userPermissions = useNlSelector(authSelectors.getUserPermissions);

  return (
    <>
      {data.map((rowData, i) => {
        const key = config.getRowKey?.(rowData, i) || rowData.uuid || i;
        return (
          <Row hover data-e2e={`row_${key}`} key={key}>
            {config.columnData.map(
              ({ id, permission, cellProps = {}, render }) =>
                isUserPermitted(userPermissions, permission) && (
                  <Cell
                    className={classes[id]}
                    key={id}
                    data-e2e={mapColumnIdToDateE2e(id)}
                    {...cellProps}
                  >
                    {render ? render(rowData) : (rowData as any)[id]}
                  </Cell>
                ),
            )}
          </Row>
        );
      })}
    </>
  );
}

function TableBody<RowData>({
  config,
  data,
  paginationData,
}: AllProps<RowData>) {
  return (
    <>
      <Body data-e2e='table_body'>
        <TableData data={data} config={config} />
      </Body>
      <TableFooter>
        <Row>
          <ConnectedPagination
            storeKey={config.storeKey}
            {...{ paginationData }}
          />
        </Row>
      </TableFooter>
    </>
  );
}

export default TableBody;
