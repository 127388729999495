import { put, call, select, takeLatest, all } from 'redux-saga/effects';
import { ActionsUnion, createTypedAction, assign } from 'utils/storeUtils';
import { getUrlQueryParams, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification } from './notifications';

import { getSalesTotal } from 'novaApi/NovaApi';

/**
 * Action Constants
 */
const FETCH_SALES_TOTAL = 'FETCH_SALES_TOTAL';
const FETCH_SALES_TOTAL_SUCCESS = 'FETCH_SALES_TOTAL_SUCCESS';
const FETCH_SALES_TOTAL_ERROR = 'FETCH_SALES_TOTAL_ERROR';

/**
 * Action Creators
 */
export const actions = {
  fetchSalesTotal: () => createTypedAction(FETCH_SALES_TOTAL),
  fetchSalesTotalSuccess: (payload: { data: Nl.Api.SalesTotalResponse }) =>
    createTypedAction(FETCH_SALES_TOTAL_SUCCESS, payload),
  fetchSalesTotalError: (payload: { error: string }) =>
    createTypedAction(FETCH_SALES_TOTAL_ERROR, payload),
};

export const { fetchSalesTotal } = actions;
export const { fetchSalesTotalSuccess } = actions;

/**
 * Reducer
 */
const initialState = {
  data: {
    earning: {
      all_time_totals: 0,
      current_totals: 0,
    },
  } as Nl.Api.SalesTotalResponse,
  isLoading: false,
  isLoaded: false,
};

export type SalesTotalState = Readonly<typeof initialState>;

type Actions = ActionsUnion<typeof actions>;

const reducer = (state: SalesTotalState = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_SALES_TOTAL: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SALES_TOTAL_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSalesTotalSaga() {
    const queryParams: string = yield select(getUrlQueryParams);
    const results: FetchResponse<Nl.Api.SalesTotalResponse> = yield call(
      getSalesTotal,
      queryParams,
    );
    if (results.success) {
      yield put(actions.fetchSalesTotalSuccess({ data: results.data }));
    } else {
      yield put(
        addErrorNotification({
          message: 'Impossible to fetch the sales total data.',
        }),
      );
      yield put(actions.fetchSalesTotalError({ error: results.msg }));
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([takeLatest(FETCH_SALES_TOTAL, sagas.fetchSalesTotalSaga)]);
}

export { sagas };
export default reducer;
