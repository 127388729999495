import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { TextField, Typography, Button } from '@material-ui/core';
import { get } from 'lodash';
import { selectUserUuid } from 'store/selectors';

interface OwnProps {
  notes: Nl.Api.Note[];
  label: string;
  sendNote: (text: string) => void;
}

export default function NoteBox({ notes, label, sendNote }: OwnProps) {
  const [text, setText] = useState('');
  const userUuid = useSelector(selectUserUuid);

  return (
    <>
      <div style={{ marginTop: '1rem', marginRight: '1rem' }}>
        <Typography variant='h6'>{label}</Typography>
        <TextField
          multiline
          rows={4}
          label='Type your note here'
          variant='outlined'
          value={text}
          onChange={(e) => setText(e.target.value)}
          margin='normal'
          fullWidth
        />
      </div>
      <Button
        onClick={() => {
          sendNote(text);
          setText('');
        }}
        variant='contained'
        color='primary'
        size='small'
        aria-label='sendNote'
      >
        Leave note
      </Button>
      <div
        style={{ marginTop: '1rem', marginLeft: '1rem', marginRight: '2rem' }}
      >
        {notes.map((note) => {
          const textAlign: 'left' | 'right' =
            get(note, 'user.uuid', '') === userUuid ? 'right' : 'left';

          return (
            <div key={note.uuid} style={{ padding: '.25rem', textAlign }}>
              <Typography>{note.text}</Typography>
              <Typography variant='caption' color='secondary'>
                {moment.utc(note.created_at).local().fromNow()} by{' '}
                {get(note, 'user.full_name', '')}
              </Typography>
            </div>
          );
        })}
      </div>
    </>
  );
}
