import { findIndex } from 'lodash';

export interface SimpleActionType {
  type: string;
  payload?: any;
  meta?: any;
}

export const createAction = (type: string) => (
  args?: any,
): SimpleActionType => ({
  type,
  payload: args,
});

export function assign<T>(o: T, newValues: Partial<T>): T {
  return { ...o, ...newValues };
}

export type Dispatch = (action: SimpleActionType) => any;

export const replaceObjInArrayByUuid = <E extends { uuid: string }>(
  array: E[],
  newObject: E,
): E[] => {
  const objIdx = findIndex(array, (obj) => obj.uuid === newObject.uuid);
  return [...array.slice(0, objIdx), newObject, ...array.slice(objIdx + 1)];
};

// Bellow are utils for a typesafe implementation of the ActionCreators
export interface Action<T extends string> {
  type: T;
}
export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}
export function createTypedAction<T extends string>(type: T): Action<T>;
// TODO https://shutterstock-jira.codefactori.com/browse/MTN-1714
// eslint-disable-next-line no-redeclare
export function createTypedAction<T extends string, P>(
  type: T,
  payload: P,
): ActionWithPayload<T, P>;
// eslint-disable-next-line no-redeclare
export function createTypedAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

export type ActionCreatorsMapObject = {
  [actionCreator: string]: (...args: any[]) => any;
};

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;
