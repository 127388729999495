const isValid = (formData: Nl.Api.SongRevision) => {
  return formData.source_files.every(
    (sourceFile) =>
      sourceFile.revision_filename && sourceFile.revision_storage_key,
  );
};

export const validate = (values: Nl.Api.SongRevision) => {
  if (!isValid(values)) {
    return { source_files: 'All files are required' };
  }
  return {};
};
