import * as React from 'react';
import { Theme, createStyles, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import InternalLink from 'components/Link/InternalLink';
import { makeStyles } from '@material-ui/styles';
import { useNlSelector } from 'utils/redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
    icon: {
      color: theme.customPalette.warning,
    },
    link: {
      display: 'block',
      color: '#FFFFFF',
    },
    htmlTooltip: {
      fontSize: '12px',
      maxWidth: 'none',
    },
  }),
);

const DuplicatedWorkTitleWarning = () => {
  const classes = useStyles();

  const conflictingSongs = useNlSelector(
    (state) => state.singleSong.data.song?.conflicting_songs ?? [],
  );

  const links = conflictingSongs.map((song) => (
    <InternalLink
      key={song.uuid}
      to={{ type: 'route/SONG_EDIT', payload: { uuid: song.uuid } }}
      className={classes.link}
    >
      {song.work_title} by {song.artist?.name}
    </InternalLink>
  ));
  return (
    <>
      {conflictingSongs.length > 0 ? (
        <Tooltip
          classes={{
            tooltip: classes.htmlTooltip,
          }}
          interactive
          title={<>Already used in: {links}</>}
          className={classes.root}
          arrow
        >
          <WarningIcon className={classes.icon} aria-label='conflictingSongs' />
        </Tooltip>
      ) : null}
    </>
  );
};

export default DuplicatedWorkTitleWarning;
