import * as React from 'react';
import { connect } from 'react-redux';
import { Tooltip, IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import ArtistIndications from './ArtistIndications';
import { onboardArtist } from 'store/artists';
import moment from 'moment';
import { Dispatch } from 'redux';

interface OwnProps {
  artist: Nl.Api.Artist;
}

interface DispatchProps {
  invite: (uuid: string) => void;
}

type AllProps = OwnProps & DispatchProps;

const ArtistActions: React.FunctionComponent<AllProps> = ({
  artist,
  invite,
}) => (
  <>
    <ArtistIndications
      isRoyalty={artist.is_royalty}
      isPROFree={artist.is_pro_free}
      isDormant={artist.is_dormant}
    />
    <Tooltip
      title={`${
        artist.registration_email_sent ? 'Re-send' : 'Send'
      } invitation email to artist`}
    >
      <span>
        <IconButton
          disabled={!artist.is_onboardable}
          onClick={() => {
            const lastSent = artist.registration_email_sent
              ? `An invitation was last sent on ${moment(
                  artist.registration_email_sent,
                ).format('D MMM YYYY')}.`
              : 'An invitation has never been sent previously.';
            if (window.confirm(`Send a registration email?\n${lastSent}`)) {
              invite(artist.uuid);
            }
          }}
        >
          <EmailIcon />
        </IconButton>
      </span>
    </Tooltip>
  </>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  invite: (uuid: string) => dispatch(onboardArtist({ uuid })),
});

export default connect(null, mapDispatchToProps)(ArtistActions);
