import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import {
  createMyWriter,
  editFormInitialValues,
  addSuggestedWriter,
} from 'store/myWriters';
import MyWriterForm from './MyWriterForm';
import validationSchema from './validation';
import SuggestedWriter from './SuggestedWriter';
import { selectMyWriterSuggested } from 'store/selectors';
import { useNlSelector } from 'utils/redux';

const MyWriterCreatePage = () => {
  const dispatch = useDispatch();

  const suggestedWriter = useNlSelector(
    selectMyWriterSuggested,
  ) as Nl.Api.MyWriter;

  return (
    <FormikWrapper
      title='Create Writer'
      initialValues={editFormInitialValues}
      submitForm={(formData, formActions) =>
        dispatch(createMyWriter({ formData, formActions }))
      }
      validationSchema={validationSchema}
    >
      <SuggestedWriter
        suggestedWriter={suggestedWriter}
        onSubmit={(writer) => dispatch(addSuggestedWriter({ writer }))}
      />
      <br />
      <MyWriterForm />
    </FormikWrapper>
  );
};

export default MyWriterCreatePage;
