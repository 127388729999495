import React from 'react';
import { Paper, makeStyles, List, ListItem } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { useDispatch } from 'react-redux';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import { TableConfigEntry } from 'utils/tableConfig';
import { getReadableDate } from 'utils/utils';
import { useTerms } from 'novaApi/hooks/term';
import { useTagTermRelationTypeSelect } from 'novaApi/hooks/tag_term_relation_type';
import InternalLink from 'components/Link/InternalLink';
import ConnectedButton from 'components/Button/ConnectedButton';
import DeleteButton from 'components/Button/DeleteButton';
import { deleteTerm } from 'store/terms';

const useStyles = makeStyles(styles);

const formatRelatedTags = (
  related_tags: (Nl.Api.TermTagRelationType & Nl.Api.TermTagSelectResponse)[],
) => (
  <List
    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}
    disablePadding
  >
    {related_tags
      .filter((item) => item.is_qualifier_for_term)
      .map((item) => item.tags)
      .flat(1)
      .map(({ name, uuid }) => (
        <ListItem
          style={{ width: 'auto' }}
          key={name}
          data-e2e='qualified_tags'
        >
          <InternalLink
            color='inherit'
            to={{ type: 'route/TAGS_EDIT', payload: { uuid } }}
            target='_blank'
            data-e2e='qualified_tag_link'
          >
            {name}
          </InternalLink>
        </ListItem>
      ))}
  </List>
);

const DeleteTermButton = ({ uuid }: { uuid: string }) => {
  const dispatch = useDispatch();
  const data = useTerms();
  return (
    <DeleteButton
      aria-label='Delete'
      onClick={() => {
        if (window.confirm('Are you sure you want to delete this term?')) {
          dispatch(deleteTerm({ uuid }));
        }
      }}
      disabled={!data}
      data-e2e='delete_term_button'
    />
  );
};

const tableConfig = (
  qualifierRelationTypeColumnLabel: string,
): TableConfigEntry<Nl.Api.TermBase> => ({
  columnData: [
    {
      id: 'name',
      label: 'Name',
      isSortable: true,
      cellProps: { width: '20%' },
      render: ({ uuid, name }) => (
        <InternalLink
          color='inherit'
          to={{ type: 'route/TERMS_EDIT', payload: { uuid } }}
        >
          {name}
        </InternalLink>
      ),
    },
    {
      id: 'qualified_tags',
      label: qualifierRelationTypeColumnLabel,
      cellProps: { width: '50%' },
      render: ({ related_tags }) => formatRelatedTags(related_tags) || '',
    },
    {
      id: 'created_at',
      label: 'Created',
      isSortable: true,
      cellProps: { width: '12%' },
      render: ({ created_at }) => getReadableDate(created_at) || '-',
    },
    {
      id: 'updated_at',
      label: 'Last Modified',
      isSortable: true,
      cellProps: { width: '12%' },
      render: ({ updated_at }) => getReadableDate(updated_at) || '-',
    },
    {
      id: 'delete',
      label: '',
      permission: 'CAN_DELETE_TERM',
      render: ({ uuid }) => <DeleteTermButton uuid={uuid} />,
    },
  ],
});

export default function TermsPage() {
  const classes = useStyles();
  const { data, isLoaded } = useTerms();
  const tagTermRelationTypes = useTagTermRelationTypeSelect();
  const qualifierRelationType = tagTermRelationTypes.filter(
    (item) => item.is_qualifier_for_term,
  )[0];
  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Terms'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'term-search-name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/TERMS_CREATE'
            label='Create new Term'
            permission='CAN_CREATE_TERM'
          />
        }
      />
      <Table
        config={tableConfig(qualifierRelationType?.name)}
        data={data?.terms}
        isLoaded={isLoaded}
        paginationData={data}
      />
    </Paper>
  );
}
