import * as React from 'react';
import UserLogItem from '../UserLogItem';
import Link from 'components/Link/InternalLink';
import { Typography } from '@material-ui/core';

interface OwnProps {
  activity: Nl.Api.UserActivity;
  alignRight?: boolean;
}

type AllProps = OwnProps;

const ActivityLogItem = ({
  alignRight,
  activity: {
    user_uuid,
    user_label,
    timestamp,
    action_string,
    action_target_type,
    action_target_labels,
    action_target_uuids,
    resource_uuid,
    resource_type,
    resource_label,
    permission_keys,
  },
}: AllProps) => {
  const renderActionTargetLinks = () => {
    if (!action_target_labels || !action_target_labels.length) {
      return null;
    }

    return (
      <span>
        {action_target_labels.map((atl, idx) => {
          const separator = idx < action_target_labels.length - 1 ? ', ' : '';
          const copy = `${atl}${separator}`;
          if (action_string === 'has_deleted') {
            return copy;
          }
          return (
            <Link
              key={idx}
              to={`/${action_target_type}/edit/${action_target_uuids[idx]}`}
            >
              {copy}
            </Link>
          );
        })}
        &nbsp;in
      </span>
    );
  };

  const renderResourceLink = () => {
    return (
      <Link
        to={
          resource_type === action_target_type
            ? `/${resource_type}`
            : `/${resource_type}/edit/${resource_uuid}`
        }
      >
        {resource_label}
      </Link>
    );
  };

  const renderUserLink = () => {
    return <Link to={`/users/edit/${user_uuid}`}>{user_label}</Link>;
  };

  return (
    <UserLogItem timestamp={timestamp} alignRight={alignRight}>
      <Typography>
        {renderUserLink()}
        &nbsp;
        {action_string}
        &nbsp;
        {renderActionTargetLinks()}
        &nbsp;
        {renderResourceLink()}
      </Typography>
    </UserLogItem>
  );
};

export default ActivityLogItem;
