import { put, call, select, takeLatest, all } from 'redux-saga/effects';
import {
  createAction,
  createTypedAction,
  SimpleActionType,
} from 'utils/storeUtils';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { fetchSingleTrackSubmission } from 'store/singleTrackSubmission';
import { approveTrack, rejectTrack, destroyTrack } from 'novaApi/NovaApi';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';
import { goPreviousPage } from './router';

/**
 * Route Designators
 */
const trackSubmissionEdit: keyof Nl.RouteMapList =
  'route/TRACK_SUBMISSION_EDIT_PAGE';
const trackSubmissionsPage: keyof Nl.RouteMapList = 'route/TRACK_SUBMISSIONS';

// Atomic Track Actions
const APPROVE_TRACK_SUBMISSION = 'APPROVE_TRACK_SUBMISSION';
const DECLINE_TRACK_SUBMISSION = 'DECLINE_TRACK_SUBMISSION';
const DESTROY_TRACK_SUBMISSION = 'DESTROY_TRACK_SUBMISSION';
const REQUEST_TRACK_SUBMISSION_REVISION = 'REQUEST_TRACK_SUBMISSION_REVISION';
const TRACK_ACTION_SUCCESS = 'TRACK_ACTION_SUCCESS';

/**
 * Action Creators
 */
export const approveTrackSubmission = createAction(APPROVE_TRACK_SUBMISSION);
export const declineTrackSubmission = createAction(DECLINE_TRACK_SUBMISSION);
export const destroyTrackSubmission = createAction(DESTROY_TRACK_SUBMISSION);
export const requestTrackSubmissionRevision = createAction(
  REQUEST_TRACK_SUBMISSION_REVISION,
);
const trackActionSuccess = (payload: {
  type: keyof Nl.RouteMapList;
  message: string;
}) => createTypedAction(TRACK_ACTION_SUCCESS, payload);

const sagas = {
  *requestRevisionTrackSubmission(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<Nl.Api.TrackSubmissionResponse> = yield call(
      apiFetch,
      `/new_track_submission/${uuid}/request_revision`,
      { method: 'POST' },
    );
    const { type } = yield select((state) => state.location);
    if (results.success) {
      yield put(
        trackActionSuccess({ type, message: 'Revision has been requested' }),
      );
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *approveTrackSubmission(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<Nl.Api.TrackSubmissionResponse> = yield call(
      approveTrack,
      uuid,
    );
    const { type } = yield select((state) => state.location);

    if (results.success) {
      yield put(trackActionSuccess({ type, message: 'Track approved' }));
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *declineTrackSubmission(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<Nl.Api.TrackSubmissionResponse> = yield call(
      rejectTrack,
      uuid,
    );
    const { type } = yield select((state) => state.location);

    if (results.success) {
      yield put(trackActionSuccess({ type, message: 'Track declined' }));
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *destroyTrackSubmission(action: SimpleActionType) {
    const modalRoutes = [
      'route/MY_TRACK_EDIT_PAGE',
      'route/TRACK_SUBMISSION_EDIT_PAGE',
    ];
    const { uuid } = action.payload;
    const {
      success,
      msg,
    }: FetchResponse<Nl.Api.TrackSubmissionResponse> = yield call(
      destroyTrack,
      uuid,
    );
    const { type } = yield select((state) => state.location);
    if (success) {
      yield put(addSuccessNotification({ message: 'Track deleted' }));
      invalidateNovaQueries('/new_track_submission');
      if (modalRoutes.includes(type)) {
        yield put(goPreviousPage());
      }
    } else {
      yield put(addErrorNotification({ message: msg }));
    }
  },
  *trackActionSuccess(action: ReturnType<typeof trackActionSuccess>) {
    const { type, message } = action.payload;

    try {
      if (type === trackSubmissionEdit) {
        const {
          payload: { uuid },
        } = yield select((state) => state.location);
        yield put(fetchSingleTrackSubmission({ uuid }));
      } else if (type === trackSubmissionsPage) {
        invalidateNovaQueries('/new_track_submission');
      }

      yield put(addSuccessNotification({ message }));
    } catch (err) {
      yield put(addErrorNotification({ message: err }));
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(APPROVE_TRACK_SUBMISSION, sagas.approveTrackSubmission),
    takeLatest(DECLINE_TRACK_SUBMISSION, sagas.declineTrackSubmission),
    takeLatest(DESTROY_TRACK_SUBMISSION, sagas.destroyTrackSubmission),
    takeLatest(
      REQUEST_TRACK_SUBMISSION_REVISION,
      sagas.requestRevisionTrackSubmission,
    ),
    takeLatest(TRACK_ACTION_SUCCESS, sagas.trackActionSuccess),
  ]);
}

export { sagas };
