/**
 * This file should contain all the methods allowing
 * to convert the object returned by the api to object
 * that we can consume in the app
 */

import { map } from 'lodash';

export const convertSongConstants = (
  response: Nl.Api.SongConstants,
): Nl.SongConstants => {
  return {
    publishStates: Object.values(response.publish_states),
    inspectionStates: Object.values(response.inspection_states),
    versionTypes: Object.values(response.version_types),
    states: map(response.states, (value, index) => ({
      name: index,
      uuid: value.toString(),
    })),
  };
};
