import { useNovaApi } from 'novaApi/useNovaApi';
import config from 'config';

export const useCountries = () => {
  const query = { pagesize: config.clients.api.maxPageSize };
  return (
    useNovaApi<Nl.Api.CountriesResponse>('/country', {
      query,
      staleTime: Infinity,
    }).data?.countries ?? []
  );
};
