import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import { TableConfigEntry } from 'utils/tableConfig';
import Table from 'components/Table/Table';
import { usePROEarningStatement } from 'novaApi/hooks/pro_earning_statement';
import DownloadButton from 'components/Button/DownloadButton';
import { getReadableDate } from 'utils/utils';

const useStyles = makeStyles(styles);

const tableConfig: TableConfigEntry<Nl.Api.PROEarningsStatement> = {
  columnData: [
    {
      id: 'statement_date',
      label: 'Date',
      render: ({ statement_date }) => getReadableDate(statement_date),
    },
    {
      id: 'PRO',
      label: 'PRO',
      render: ({ pro }) => pro.name,
    },
    { id: 'region', label: 'Region' },
    {
      id: 'user',
      label: 'User',
      render: ({ created_by }) => created_by.full_name,
    },
    {
      id: 'download',
      label: 'Download',
      render: ({ url }) => <DownloadButton href={url} />,
    },
  ],
};

export default function PROEarningsStatementsPage() {
  const classes = useStyles({});
  const { data } = usePROEarningStatement();
  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar title='PRO Earnings Statements' />
      <Table
        config={tableConfig}
        data={data?.pro_earnings_statements}
        isLoaded={!!data}
        paginationData={data}
      />
    </Paper>
  );
}
