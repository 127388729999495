import React from 'react';
import { Field, FieldArray, Form } from 'formik';
import InputField from 'components/Formik/InputField';
import { Grid, Typography } from '@material-ui/core';
import MyTrackEditWriter from './MyTrackEditWriter';
import MyTrackFinalDeliveryTable from './MyTrackFinalDeliveryTable';
import MyTrackMP3Submission from '../MyTrackMP3Submission';
import MyTrackImmersiveTabs from './MyTrackImmersiveTabs';
import contributorGuidePdf from 'documents/contributor_guide.pdf';
import { FormSubTitle, InputWrapper } from 'components/Formik/utils';
import {
  useTagFieldEntities,
  useTagFieldEntitiesExtramusic,
  useTagFieldEntitiesInstruments,
  useTagFieldEntitiesLyrics,
  useTagFieldSelect,
} from 'novaApi/hooks/tag_field';
import Typeahead from 'components/Typeahead/Typeahead';
import { multipleSelectFormat } from 'utils/utils';
import MoreInfoLabelLink from 'components/Formik/MoreInfoLabelLink';

interface AllProps {
  isNameEditable: boolean;
  isReuploadAllowed: boolean;
  isReadyForDelivery?: boolean;
  hasLyrics?: boolean;
  requiredSourceFiles?: Nl.FinalTrackRequiredSourceFileType[];
  channelLayoutTypes: Nl.ChannelLayoutType[];
  trackUuid?: string;
}

const FormGroup: React.FunctionComponent = ({ children }) => (
  <div style={{ marginTop: '1rem', marginRight: '1rem' }}>{children}</div>
);

const ContributorGuidelines = () => (
  <div style={{ marginBottom: '1rem' }}>
    {
      'Please upload .zip folders containing 16-Bit, 48KHz PCM WAV files. For full details, see our '
    }
    <a href={contributorGuidePdf} target='_blank' rel='noopener noreferrer'>
      Contributor Guide
    </a>
    .
  </div>
);

const MyTrackEditForm: React.FunctionComponent<AllProps> = ({
  isNameEditable,
  isReuploadAllowed,
  isReadyForDelivery,
  hasLyrics,
  requiredSourceFiles,
  channelLayoutTypes,
  trackUuid,
}) => {
  const tagFieldsData = useTagFieldSelect();
  const instrumentTags = useTagFieldEntitiesInstruments();
  const extramusicTags = useTagFieldEntitiesExtramusic();
  const lyricsTags = useTagFieldEntitiesLyrics();
  const locations = useTagFieldEntities('location');
  const typeTags = useTagFieldEntities('type');
  const culturesReligionsTags = useTagFieldEntities('culture_religion');
  const erasTags = useTagFieldEntities('eras');
  const acousticElectricTags = useTagFieldEntities('acoustic_electric');

  const getTagFieldLink = (tagName: string) => {
    const tag = tagFieldsData.find((t) => t.name === tagName);
    return tag ? `/tags?tag_field_uuid=${tag.uuid}` : '/tags';
  };

  return (
    <Form>
      <Typography variant='h6'>Basic Info</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            name='name'
            label='Track Name'
            component={InputField}
            disabled={!isNameEditable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name='status' label='Status' component={InputField} disabled />
        </Grid>
      </Grid>

      <FormGroup>
        <Typography variant='h6'>Track Details</Typography>
        <MyTrackMP3Submission isReuploadAllowed={isReuploadAllowed} />
      </FormGroup>
      {isReadyForDelivery && (
        <>
          <FormGroup>
            <Typography variant='h6'>Writer Shares</Typography>
            <FieldArray
              name='share_data'
              // @ts-ignore
              component={MyTrackEditWriter}
            />
          </FormGroup>
          <FormGroup>
            <Typography variant='h6'>Upload Details</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <InputWrapper>
                  <Field name='bpm' label='BPM' component={InputField} />
                  <Field
                    name='taxonomy.location'
                    label='Location(s)'
                    component={Typeahead}
                    isMulti
                    options={multipleSelectFormat(locations)}
                  />
                </InputWrapper>

                <div data-e2e='taxonomyTags-emotional'>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormSubTitle title='Emotional Tags' />
                      <InputWrapper>
                        <Field
                          name='taxonomy.eras'
                          label='Eras'
                          component={Typeahead}
                          isMulti
                          options={multipleSelectFormat(erasTags)}
                        />
                        <Field
                          name='taxonomy.acoustic_electric'
                          label='Acoustic/Electric'
                          component={Typeahead}
                          options={multipleSelectFormat(acousticElectricTags)}
                        />
                      </InputWrapper>
                    </FormGroup>
                  </Grid>
                </div>

                <div data-e2e='taxonomyTags-descriptive'>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormSubTitle title='Descriptive Tags' />
                      <InputWrapper>
                        <Field
                          name='taxonomy.type'
                          label='Type(s)'
                          component={Typeahead}
                          isMulti
                          options={multipleSelectFormat(typeTags)}
                        />
                        <Field
                          name='taxonomy.culture_religion'
                          label='Culture(s) & religion(s)'
                          component={Typeahead}
                          isMulti
                          options={multipleSelectFormat(culturesReligionsTags)}
                        />
                      </InputWrapper>
                    </FormGroup>
                  </Grid>
                </div>

                {hasLyrics && (
                  <Grid item xs={12} data-e2e='taxonomyTags-lyrics'>
                    <FormGroup>
                      <FormSubTitle title='Lyrics' />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name='lyrics'
                            label='Lyrics'
                            component={InputField}
                            multiline
                            rows={6}
                            rowsMax={6}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputWrapper>
                            <Field
                              name='taxonomy.lyrics_language'
                              label='Lyrics language(s)'
                              component={Typeahead}
                              isMulti
                              options={multipleSelectFormat(
                                lyricsTags.lyricsLanguage,
                              )}
                            />
                            <Field
                              name='taxonomy.lyrics_subject'
                              label='Lyrics subject(s)'
                              component={Typeahead}
                              isMulti
                              options={multipleSelectFormat(
                                lyricsTags.lyricsSubject,
                              )}
                            />
                          </InputWrapper>
                          <Field
                            name='additional_lyrics_subjects'
                            label='Additional lyrics subjects'
                            component={InputField}
                            multiline
                            rows={2}
                            rowsMax={2}
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>
                )}

                <div data-e2e='taxonomyTags-instrument'>
                  <FormGroup>
                    <FormSubTitle title='Instrument Tags' />
                    <Typography>
                      Only enter instruments applicable to this track
                    </Typography>
                    <InputWrapper>
                      <Field
                        name='taxonomy.ensemble'
                        label='Ensemble(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(instrumentTags.ensemble)}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Field
                        name='taxonomy.nonstandard_instrument'
                        label='Nonstandard instrument(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(
                          instrumentTags.nonstandardInstrument,
                        )}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Field
                        name='taxonomy.guitar_bass_instrument'
                        label='Guitar/bass instrument(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(
                          instrumentTags.guitarBassInstrument,
                        )}
                      />
                      <Field
                        name='taxonomy.guitar_bass_behavior'
                        label={
                          <MoreInfoLabelLink
                            label='Guitar/bass behavior(s)'
                            href={getTagFieldLink('guitar_bass_behavior')}
                          />
                        }
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.guitarBassBehavior,
                        )}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Field
                        name='taxonomy.brass_instrument'
                        label='Brass instrument(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(
                          instrumentTags.brassInstrument,
                        )}
                      />
                      <Field
                        name='taxonomy.brass_behavior'
                        label={
                          <MoreInfoLabelLink
                            label='Brass behavior(s)'
                            href={getTagFieldLink('brass_behavior')}
                          />
                        }
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.brassBehavior,
                        )}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Field
                        name='taxonomy.keyboard_instrument'
                        label='Keyboard instrument(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(
                          instrumentTags.keyboardInstrument,
                        )}
                      />
                      <Field
                        name='taxonomy.keyboard_behavior'
                        label={
                          <MoreInfoLabelLink
                            label='Keyboard behavior(s)'
                            href={getTagFieldLink('keyboard_behavior')}
                          />
                        }
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.keyboardBehavior,
                        )}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Field
                        name='taxonomy.percussion_instrument'
                        label='Percussion instrument(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(
                          instrumentTags.percussionInstrument,
                        )}
                      />
                      <Field
                        name='taxonomy.percussion_behavior'
                        label={
                          <MoreInfoLabelLink
                            label='Percussion behavior(s)'
                            href={getTagFieldLink('percussion_behavior')}
                          />
                        }
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.percussionBehavior,
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Field
                        name='taxonomy.string_instrument'
                        label='String instrument(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(
                          instrumentTags.stringInstrument,
                        )}
                      />
                      <Field
                        name='taxonomy.string_behavior'
                        label={
                          <MoreInfoLabelLink
                            label='String behavior(s)'
                            href={getTagFieldLink('string_behavior')}
                          />
                        }
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.stringBehavior,
                        )}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Field
                        name='taxonomy.synth_instrument'
                        label='Synth instrument(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(
                          instrumentTags.synthInstrument,
                        )}
                      />
                      <Field
                        name='taxonomy.synth_behavior'
                        label={
                          <MoreInfoLabelLink
                            label='Synth behavior(s)'
                            href={getTagFieldLink('synth_behavior')}
                          />
                        }
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.synthBehavior,
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Field
                        name='taxonomy.voice_instrument'
                        label='Voice instrument(s)'
                        component={Typeahead}
                        isMulti
                        helperText=''
                        options={multipleSelectFormat(
                          instrumentTags.voiceInstrument,
                        )}
                      />
                      <Field
                        name='taxonomy.voice_behavior'
                        label={
                          <MoreInfoLabelLink
                            label='Voice behavior(s)'
                            href={getTagFieldLink('voice_behavior')}
                          />
                        }
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.voiceBehavior,
                        )}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Field
                        name='taxonomy.woodwind_instrument'
                        label='Woodwind instrument(s)'
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.woodwindInstrument,
                        )}
                      />
                      <Field
                        name='taxonomy.woodwind_behavior'
                        label={
                          <MoreInfoLabelLink
                            label='Woodwind behavior(s)'
                            href={getTagFieldLink('woodwind_behavior')}
                          />
                        }
                        component={Typeahead}
                        isMulti
                        options={multipleSelectFormat(
                          instrumentTags.woodwindBehavior,
                        )}
                      />
                    </InputWrapper>
                  </FormGroup>
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <div data-e2e='taxonomyTags-extramusic'>
                  <FormSubTitle title='Extramusic Tags' />
                  <div style={{ paddingBottom: '5px' }}>
                    <ul>
                      <li>No over-tagging please.</li>
                      <li>
                        No need to enter the same tag more than once, i.e. if
                        you have entered a tag in one field, no need to use the
                        same tag again.
                      </li>
                      <li>Only relevant tags pertaining to track.</li>
                      <li>At most 6 tags can be applied per field.</li>
                    </ul>
                  </div>
                  <Field
                    name='taxonomy.creative_inspiration'
                    label='Creative inspiration(s) '
                    component={Typeahead}
                    isMulti
                    isCreatable
                    options={multipleSelectFormat(
                      extramusicTags.creativeInspiration,
                    )}
                  />
                  <InputWrapper>
                    <Field
                      name='taxonomy.production_use_case'
                      label='Film/TV/Game Usage'
                      component={Typeahead}
                      isMulti
                      options={multipleSelectFormat(
                        extramusicTags.productionUseCase,
                      )}
                    />
                    <Field
                      name='taxonomy.use_case'
                      label='Use case(s)'
                      component={Typeahead}
                      isMulti
                      options={multipleSelectFormat(extramusicTags.useCase)}
                    />
                  </InputWrapper>
                </div>
              </Grid>

              <Grid item xs={12}>
                <Field
                  name='description'
                  label='Description'
                  component={InputField}
                  multiline
                  maxrows={4}
                  rows={4}
                />
                <Field
                  name='notes'
                  label='Notes'
                  component={InputField}
                  multiline
                  maxrows={4}
                  rows={4}
                />
              </Grid>
            </Grid>
          </FormGroup>
          <FormGroup>
            <Typography variant='h6'>Upload Final Source Files</Typography>
            <ContributorGuidelines />
            {channelLayoutTypes.length > 1 ? (
              <MyTrackImmersiveTabs
                requiredSourceFiles={requiredSourceFiles}
                channelLayoutTypes={channelLayoutTypes}
              />
            ) : (
              <FieldArray name='files'>
                {(props) => (
                  // @ts-ignore
                  <MyTrackFinalDeliveryTable
                    {...props}
                    requiredSourceFiles={requiredSourceFiles}
                    channelLayout='Stereo'
                    trackUuid={trackUuid}
                  />
                )}
              </FieldArray>
            )}
          </FormGroup>
        </>
      )}
    </Form>
  );
};

export default MyTrackEditForm;
