import React from 'react';
import { Paper, makeStyles, Typography } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import OverflowTip from 'components/OverflowTip/OverflowTip';
import { TableConfigEntry } from 'utils/tableConfig';
import { getReadableDate } from 'utils/utils';
import { useTags } from 'novaApi/hooks/tag';
import InternalLink from 'components/Link/InternalLink';
import ConnectedButton from 'components/Button/ConnectedButton';
import TagFilters from './TagFilters';
import DeleteButton from 'components/Button/DeleteButton';
import { useDispatch } from 'react-redux';
import { deleteTag } from 'store/tags';

const useStyles = makeStyles(styles);

const DeleteTagButton = ({
  uuid,
  song_count,
}: {
  uuid: string;
  song_count: number;
}) => {
  const dispatch = useDispatch();
  const data = useTags();
  let msg = 'Are you sure you want to delete this tag?';

  if (song_count) {
    msg = `This Tag is applied to ${song_count} song(s).\n${msg}`;
  }
  return (
    <DeleteButton
      aria-label='Delete'
      onClick={() => {
        if (window.confirm(msg)) {
          dispatch(deleteTag({ uuid }));
        }
      }}
      disabled={!data}
      data-e2e='delete_tag_button'
    />
  );
};

const tableConfig = (): TableConfigEntry<Nl.Api.TagBase> => ({
  columnData: [
    {
      id: 'name',
      label: 'Name',
      isSortable: true,
      cellProps: { width: '20%' },
      render: ({ uuid, name }) => (
        <InternalLink
          color='inherit'
          to={{ type: 'route/TAGS_EDIT', payload: { uuid } }}
        >
          {name}
        </InternalLink>
      ),
    },
    {
      id: 'external_definition',
      label: 'Definition',
      cellProps: { width: '50%' },
      render: ({ external_definition }) => (
        <OverflowTip
          tooltipText={<Typography>{external_definition}</Typography>}
          tooltipPosition='top-start'
        >
          {external_definition}
        </OverflowTip>
      ),
    },
    {
      id: 'created_at',
      label: 'Created',
      isSortable: true,
      cellProps: { width: '12%' },
      render: ({ created_at }) => getReadableDate(created_at) || '-',
    },
    {
      id: 'updated_at',
      label: 'Last Modified',
      isSortable: true,
      cellProps: { width: '12%' },
      render: ({ updated_at }) => getReadableDate(updated_at) || '-',
    },
    {
      id: 'delete',
      label: '',
      permission: 'CAN_DELETE_TAG',
      render: ({ uuid, song_count }) => (
        <DeleteTagButton uuid={uuid} song_count={song_count} />
      ),
    },
  ],
});

export default function TagsPage() {
  const classes = useStyles();
  const { data, isLoaded } = useTags();

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Tags'
        filters={<TagFilters />}
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'tag-search-name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/TAGS_CREATE'
            label='Create new Tag'
            permission='CAN_CREATE_TAG'
          />
        }
      />
      <Table
        config={tableConfig()}
        data={data?.tags}
        isLoaded={isLoaded}
        paginationData={data}
      />
    </Paper>
  );
}
