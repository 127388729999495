import React from 'react';
import { Tooltip } from '@material-ui/core';

interface OwnProps {
  bitDepth: string;
  channels: number;
  originalFileName?: string | null;
  sampleRate: string;
  uuid: string;
}

const SourceFileInfo: React.SFC<OwnProps> = ({
  sampleRate,
  channels,
  bitDepth,
  children,
  originalFileName,
  uuid,
}) => {
  return (
    <Tooltip
      interactive
      title={
        <div>
          {originalFileName ? (
            <div>original name: {originalFileName}</div>
          ) : null}
          <div>sample rate: {sampleRate}</div>
          <div>channels: {channels}</div>
          <div>bit depth: {bitDepth}</div>
          <div>uuid: {uuid}</div>
        </div>
      }
      arrow
    >
      <div>{children}</div>
    </Tooltip>
  );
};

export default SourceFileInfo;
