import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  SvgIcon,
} from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useHeaderStyles } from './Header.styles';
import { isUserPermitted } from 'utils/authUtils';
import { HeaderProps } from './Header';
import { FeatureFlag } from 'utils/featureFlags';

const Drawer: React.FunctionComponent<HeaderProps> = ({
  tabData,
  userPermissions,
  toggleDrawer,
}) => {
  const classes = useHeaderStyles();
  const menusInitialState = tabData.reduce((acc, current) => {
    if (current.menu && current.menu.length) {
      acc[current.section] = false;
    }
    return acc;
  }, {} as { [section: string]: boolean });
  const [menusOpenMap, setMenusOpenMap] = useState(menusInitialState);
  const [activeMenuTab, setActiveMenuTab] = useState('');
  const dispatch = useDispatch();

  const navigate = ({ e2e, route }: Nl.HeaderTab) => {
    setActiveMenuTab(e2e);
    toggleDrawer();
    dispatch({ type: route });
  };

  const tabIsPermitted = ({ menu, permission }: Nl.HeaderTab) => {
    if (menu) {
      return menu.some((menuItem) =>
        isUserPermitted(userPermissions, menuItem.permission),
      );
    }
    return isUserPermitted(userPermissions, permission);
  };

  const handleClick = (tab: Nl.HeaderTab & Partial<Nl.HeaderTopLevelTab>) => {
    const { menu, section } = tab;
    const hasMenu = Boolean(menu && menu.length);
    if (section && hasMenu) {
      return setMenusOpenMap({
        ...menusOpenMap,
        [section]: !menusOpenMap[section],
      });
    }
    navigate(tab);
  };

  const renderTab = (tab: Nl.HeaderTab | Nl.HeaderTopLevelTab) => {
    const { menu, e2e, label } = tab;
    const section = 'section' in tab ? tab.section : undefined;
    // Empty icon since ListItemIcon requires child
    const icon = 'icon' in tab ? tab.icon : <SvgIcon />;
    const hasMenu = Boolean(menu && menu.length);
    const menuOpen = Boolean(section && menusOpenMap[section]);

    if (!tabIsPermitted(tab)) {
      return null;
    }

    return (
      <FeatureFlag flag={tab.featureFlag} key={e2e}>
        {() => (
          <>
            <ListItem
              button
              key={e2e}
              data-e2e={e2e}
              data-section={section}
              selected={activeMenuTab === e2e}
              onClick={() => handleClick(tab)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
              {hasMenu && (menuOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {hasMenu && (
              <Collapse in={menuOpen} timeout='auto' unmountOnExit>
                <List className={classes.nested}>{menu!.map(renderTab)}</List>
              </Collapse>
            )}
          </>
        )}
      </FeatureFlag>
    );
  };

  return (
    <>
      <List>{tabData.map(renderTab)}</List>
      <Divider />
      <List>
        <ListItem
          button
          key='logout'
          onClick={() => dispatch({ type: 'route/LOGOUT' })}
          data-e2e='logout'
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItem>
      </List>
    </>
  );
};

export default Drawer;
