import * as React from 'react';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';

const proOptions = [
  {
    name: 'Pro-Affiliated only',
    uuid: 'true',
  },
  {
    name: 'Pro-Free only',
    uuid: 'false',
  },
];

const royaltyOptions = [
  {
    name: 'Royalty only',
    uuid: 'true',
  },
  {
    name: 'Buyout only',
    uuid: 'false',
  },
];

const ArtistFilters = () => (
  <ConnectedTableFilters
    filters={[
      {
        type: 'single_select',
        label: 'PRO',
        queryFilterKey: 'is_pro_affiliated',
        optionsSelector: () => proOptions,
        e2e: 'select_artist',
      },
      {
        type: 'single_select',
        label: 'Royalty',
        queryFilterKey: 'is_royalty',
        optionsSelector: () => royaltyOptions,
        e2e: 'select_artist',
      },
    ]}
  />
);

export default ArtistFilters;
