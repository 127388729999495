import * as React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { useDispatch } from 'react-redux';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { TableConfigEntry } from 'utils/tableConfig';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import ConnectedButton from 'components/Button/ConnectedButton';
import { getReadableDate } from 'utils/utils';
import EditButton from 'components/Button/EditButton';
import { usePROs } from 'novaApi/hooks/performing_rights_organization';

const useStyles = makeStyles(styles);

const EditPROButton = ({ uuid }: { uuid: string }) => {
  const dispatch = useDispatch();
  const data = usePROs();
  return (
    <EditButton
      onClick={() => {
        dispatch({
          type: 'route/PROS_EDIT',
          payload: { uuid },
        });
      }}
      disabled={!data}
    />
  );
};

const tableConfig: TableConfigEntry<Nl.Api.PRO> = {
  columnData: [
    { id: 'name', label: 'Name', isSortable: true },
    {
      id: 'country',
      label: 'Country',
      render: ({ country }) => country.name || '-',
    },
    {
      id: 'writers',
      label: 'Writers',
      render: ({ writers }) => writers.length,
    },
    {
      id: 'publishers',
      label: 'Publishers',
      render: ({ publishers }) => publishers.length,
    },
    {
      id: 'modified',
      label: 'Last Modified At',
      render: ({ updated_at }) => getReadableDate(updated_at),
    },
    {
      id: 'edit',
      label: '',
      render: ({ uuid }) => <EditPROButton uuid={uuid} />,
    },
  ],
};

export default function PROsPage() {
  const data = usePROs();
  const classes = useStyles({});

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Performing Rights Organizations'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/PROS_CREATE'
            label='Create new PRO'
            permission='CAN_CREATE_PERFORMING_RIGHTS_ORGANIZATIONS'
          />
        }
      />
      <Table
        config={tableConfig}
        data={data?.performing_rights_organizations}
        isLoaded={!!data}
        paginationData={data}
      />
    </Paper>
  );
}
