import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import theme from 'assets/theme';
import batchStages from 'constants/batchUpdateStages';
import { useNlSelector } from 'utils/redux';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1rem',
    padding: '1rem',
    textAlign: 'center',
    backgroundColor: '#F9F9F9',
  },
  selectAll: () => ({
    fontWeight: 500,
    paddingLeft: '1rem',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }),
  dialog: {
    padding: '1rem',
  },
  progress: {
    paddingBottom: '1rem',
    marginBottom: '1rem',
  },
});

const Alert = styled('div')({
  backgroundColor: '#FDECEA',
  padding: '1rem',
  marginBottom: '.5rem',
});

interface AllProps {
  type: string;
  totalSize: number;
  currentSize: number;

  setStage(stage: Nl.BatchUpdateStages): void;

  onReset(): void;
}

export const UpdateAllNotification: React.SFC<AllProps> = ({
  type,
  currentSize,
  totalSize,
  setStage,
  onReset,
}) => {
  const classes = useStyles({});

  const { stage, asyncTask, action, attribute } = useNlSelector(
    (state) => state.batchUpdate,
  );

  switch (stage) {
    case batchStages.check:
      return (
        <div className={classes.root}>
          <div>
            All <b>{currentSize}</b> {type} on this page are selected.
          </div>
          <div
            className={classes.selectAll}
            data-e2e='selectAll'
            onClick={() => setStage(batchStages.selected)}
          >
            Select all {totalSize} {type}
          </div>
        </div>
      );

    case batchStages.selected:
      return (
        <div className={classes.root}>
          <div>
            All <b>{totalSize}</b> {type} are selected.
          </div>
          <div
            className={classes.selectAll}
            data-e2e='clearSelection'
            onClick={() => {
              onReset();
              setStage(batchStages.initial);
            }}
          >
            Clear selection
          </div>
        </div>
      );

    case batchStages.confirmation:
      return (
        <Dialog open>
          <DialogTitle>Confirm Batch Update</DialogTitle>
          <DialogContent>
            This action will {action} {attribute} for all <b>{totalSize}</b>{' '}
            {type}. Are you sure you want to continue?
          </DialogContent>
          <DialogActions className={classes.dialog}>
            <Button
              color='primary'
              data-e2e='execute'
              onClick={() => {
                setStage(batchStages.execution);
              }}
            >
              OK
            </Button>
            <Button
              color='default'
              data-e2e='cancel'
              onClick={() => {
                setStage(batchStages.initial);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      );

    case batchStages.results: {
      if (asyncTask === null) {
        return (
          <Dialog open>
            <DialogTitle>Error finding batch update results</DialogTitle>
            <DialogContent>
              <Button
                color='primary'
                data-e2e='finished'
                variant='contained'
                onClick={() => setStage(batchStages.initial)}
              >
                OK
              </Button>
            </DialogContent>
          </Dialog>
        );
      }

      const { status, errors, progress } = asyncTask;
      const titleText = {
        pending: 'Batch Update In Progress...',
        success: 'Batch Update Successful ✔️',
        error: 'Batch Update Errors ❌',
      };

      return (
        <Dialog open>
          <DialogTitle>{titleText[status]}</DialogTitle>
          <DialogContent>
            <LinearProgress
              className={classes.progress}
              variant='determinate'
              value={progress * 100}
            />
            {!!errors.length &&
              errors.map((err) => (
                <Alert id='error' data-e2e='error' key={err.song}>
                  ❌ {err.song}: {err.error}
                </Alert>
              ))}
            {status !== 'pending' && (
              <Button
                color='primary'
                data-e2e='finished'
                variant='contained'
                onClick={() => setStage(batchStages.initial)}
              >
                OK
              </Button>
            )}
          </DialogContent>
        </Dialog>
      );
    }
    default:
      return null;
  }
};

export default UpdateAllNotification;
