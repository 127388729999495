import { useNovaApi } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';

export const usePRO = (uuid: string) => {
  return useNovaApi<Nl.Api.PROResponse>(
    `/performing_rights_organization/${uuid}`,
  ).data?.performing_rights_organization;
};

export const usePROs = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.PROsResponse>('/performing_rights_organization', {
    query,
  }).data;
};

export const usePROSelect = (query: { earnable_only?: boolean } = {}) => {
  return (
    useNovaApi<Nl.Api.PROSelectResponse>(
      '/performing_rights_organization/select',
      { query },
    ).data?.performing_rights_organizations || []
  );
};
