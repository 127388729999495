import { GlobalState } from 'store/rootReducer';
import { useNlSelector } from 'utils/redux';

// Example: type FeatureFlag = 'proEarnings' | 'secretPage'
export type FeatureFlag = 'taxonomy' | 'revision';

export function selectFeatureFlag(state: GlobalState, feature: FeatureFlag) {
  const features = state.location?.query?.features;
  const featuresArray = Array.isArray(features) ? features : [features];
  return featuresArray.includes('all') || featuresArray.includes(feature);
}

/** Returns true if the passed feature flag is either enabled or undefined */
export function useFeatureFlag(flag: FeatureFlag | undefined) {
  return useNlSelector(
    (state) =>
      flag === undefined || selectFeatureFlag(state, flag as FeatureFlag),
  );
}
/** Component calls it's render prop only if the passed feature flag is either enabled or undefined */
// TODO: https://shutterstock-jira.codefactori.com/browse/MTN-1713
// eslint-disable-next-line no-redeclare
export function FeatureFlag({
  flag,
  children,
}: {
  flag: FeatureFlag | undefined;
  children: () => React.ReactElement;
}) {
  const enabled = useFeatureFlag(flag);
  return enabled ? children() : null;
}
