import {
  put,
  call,
  select,
  takeLatest,
  all,
  takeLeading,
} from 'redux-saga/effects';
import { createSelector, defaultMemoize } from 'reselect';
import {
  apiFetch,
  getUrlQueryParams,
  convertQueryObjToUrlString,
  FetchResponse,
} from 'novaApi/apiUtils';
import { selectors as authSelectors } from './auth';
import {
  assign,
  SimpleActionType,
  ActionsUnion,
  createTypedAction,
} from 'utils/storeUtils';
import { convertSongConstants } from 'novaApi/NovaApiAdapter';
import {
  postPublishSong,
  postTakeDownSong,
  getCurationStateOptions,
  patchSongs,
} from 'novaApi/NovaApi';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { GlobalState } from './rootReducer';

/**
 * Action Constants
 */
const FETCH_SONGS = 'FETCH_SONGS';
const FETCH_SONGS_SUCCESS = 'FETCH_SONGS_SUCCESS';
const FETCH_SONGS_ERROR = 'FETCH_SONGS_ERROR';

const DELETE_SONG = 'DELETE_SONG';
const DELETE_SONG_SUCCESS = 'DELETE_SONG_SUCCESS';
const DELETE_SONG_ERROR = 'DELETE_SONG_ERROR';

const FETCH_SONG_CONSTANTS = 'FETCH_SONG_CONSTANTS';
const FETCH_SONG_CONSTANTS_SUCCESS = 'FETCH_SONG_CONSTANTS_SUCCESS';
const FETCH_SONG_CONSTANTS_ERROR = 'FETCH_SONG_CONSTANTS_ERROR';

const FETCH_CURATION_STATE_OPTIONS = 'FETCH_CURATION_STATE_OPTIONS';
const FETCH_CURATION_STATE_OPTIONS_SUCCESS =
  'FETCH_CURATION_STATE_OPTIONS_SUCCESS';
const FETCH_CURATION_STATE_OPTIONS_ERROR = 'FETCH_CURATION_STATE_OPTIONS_ERROR';

const GENERATE_SONG_REPORT = 'GENERATE_SONG_REPORT';
const GENERATE_SONG_REPORT_SUCCESS = 'GENERATE_SONG_REPORT_SUCCESS';
const GENERATE_SONG_REPORT_ERROR = 'GENERATE_SONG_REPORT_ERROR';

const PUBLISH_SONGS = 'PUBLISH_SONGS';
const TAKE_DOWN_SONGS = 'TAKE_DOWN_SONGS';
const CURATE_SONGS = 'CURATE_SONGS';

const UPDATE_SONGS_SUCCESS = 'UPDATE_SONGS_SUCCESS';
const UPDATE_SONGS_ERROR = 'UPDATE_SONGS_ERROR';
const HANDLE_UPDATE_SONGS_RESPONSE = 'HANDLE_UPDATE_SONGS_RESPONSE';

/**
 * Action Creators
 */
export const actions = {
  fetchSongs: () => createTypedAction(FETCH_SONGS),
  fetchSongsSuccess: (payload: { data: Nl.Api.SongsResponse }) =>
    createTypedAction(FETCH_SONGS_SUCCESS, payload),
  fetchSongsError: (payload: { error: string }) =>
    createTypedAction(FETCH_SONGS_ERROR, payload),

  deleteSong: (payload: { uuid: string }) =>
    createTypedAction(DELETE_SONG, payload),
  deleteSongSuccess: () => createTypedAction(DELETE_SONG_SUCCESS),
  deleteSongError: (payload: { error: string }) =>
    createTypedAction(DELETE_SONG_ERROR, payload),

  fetchSongConstants: () => createTypedAction(FETCH_SONG_CONSTANTS),
  fetchSongConstantsSuccess: (payload: { data: Nl.Api.SongConstants }) =>
    createTypedAction(FETCH_SONG_CONSTANTS_SUCCESS, payload),
  fetchSongConstantsError: (payload: { error: string }) =>
    createTypedAction(FETCH_SONG_CONSTANTS_ERROR, payload),

  fetchCurationStateOptions: () =>
    createTypedAction(FETCH_CURATION_STATE_OPTIONS),
  fetchCurationStateOptionsSuccess: (payload: { data: any }) =>
    createTypedAction(FETCH_CURATION_STATE_OPTIONS_SUCCESS, payload),
  fetchCurationStateOptionsError: (payload: { error: string }) =>
    createTypedAction(FETCH_CURATION_STATE_OPTIONS_ERROR, payload),

  generateSongReport: () => createTypedAction(GENERATE_SONG_REPORT),
  generateSongReportSuccess: (payload: { data: Nl.Api.ReportsResponse }) =>
    createTypedAction(GENERATE_SONG_REPORT_SUCCESS, payload),
  generateSongReportError: (payload: { error: string }) =>
    createTypedAction(GENERATE_SONG_REPORT_ERROR, payload),

  publishSongs: (payload: { songUuids: string[] }) =>
    createTypedAction(PUBLISH_SONGS, payload),
  updateSongsSucess: (payload: { data: Nl.Api.Song[] }) =>
    createTypedAction(UPDATE_SONGS_SUCCESS, payload),
  updateSongsError: (payload: { error: string }) =>
    createTypedAction(UPDATE_SONGS_ERROR, payload),

  takeDownSongs: (payload: { songUuids: string[] }) =>
    createTypedAction(TAKE_DOWN_SONGS, payload),
  curateSongs: (payload: Nl.BulkActionsBarCallback) =>
    createTypedAction(CURATE_SONGS, payload),
  handleUpdateSongs: (payload: {
    results: FetchResponse<{ songs: Nl.Api.Song[] }>;
    notification: string;
  }) => createTypedAction(HANDLE_UPDATE_SONGS_RESPONSE, payload),
};

export const { fetchSongs } = actions;
export const { fetchSongConstants } = actions;
export const { fetchCurationStateOptions } = actions;
export const { deleteSong } = actions;
export const { generateSongReport } = actions;
export const { publishSongs } = actions;
export const { takeDownSongs } = actions;
export const { curateSongs } = actions;
export const { handleUpdateSongs } = actions;

// Selector
export const canSongBePublish = (state: GlobalState, songUuids: string[]) =>
  state.songs.data?.songs
    ?.filter((song) => songUuids.includes(song.uuid))
    .every((song) => song.publish_allowed) ?? false;

export const canSongBeTakedown = (state: GlobalState, songUuids: string[]) =>
  state.songs.data?.songs
    ?.filter((song) => songUuids.includes(song.uuid))
    .every((song) => song.take_down_allowed) ?? false;

const selectAreSongsFiltered = (state: GlobalState) => state.location?.query;
const selectTotalSongs = (state: GlobalState): number =>
  state.songs.data.total_size;
const selectCurrentSize = (state: GlobalState): number =>
  state.songs.data.current_size;

// Exportable Memoized Selectors
export const selectSongs = defaultMemoize(
  (state: GlobalState) => state.songs?.data?.songs ?? [],
);

export const areSongsLoaded = defaultMemoize(
  (state: GlobalState) => !!state.songs?.isLoaded,
);

export const totalSongsSelector = createSelector(
  selectTotalSongs,
  (result) => result,
);

export const currentSizeSelector = createSelector(
  selectCurrentSize,
  (result) => result,
);

export const canUserBatchCurate = createSelector(
  authSelectors.getUserPermissions,
  (permissions: string[]) => permissions.includes('CAN_BATCH_UPDATE_SONGS'),
);

export const areSongsFilteredSelector = createSelector(
  selectAreSongsFiltered,
  (queries) => {
    if (!queries) return false;
    // Check if there are any queries besides pagenum, indicating that songs are filtered;
    return Object.keys(queries).some(
      (key) => queries[key] && key !== 'pagenum' && key !== 'pagesize',
    );
  },
);

/**
 * Reducer
 */
const initialState = {
  data: {
    songs: [],
    total_size: 0,
    current_size: 0,
    current_page: 0,
    offset: 0,
    total_pages: 0,
  } as Nl.Api.SongsResponse,
  isLoading: false,
  isLoaded: false,
  constants: {
    versionTypes: [],
    states: [],
    publishStates: [],
    inspectionStates: [],
  } as Nl.SongConstants,
  curationStateOptions: [] as { name: string; uuid: string }[],
  stats: {
    publishErrorCount: 0,
    publishInProgressCount: 0,
  } as Nl.SongStats,
};

export type SongsState = Readonly<typeof initialState>;

type Actions = ActionsUnion<typeof actions>;

const reducer = (state: SongsState = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_SONGS: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SONGS_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    case FETCH_SONG_CONSTANTS_SUCCESS: {
      return assign(state, {
        constants: convertSongConstants(action.payload.data),
      });
    }

    case FETCH_CURATION_STATE_OPTIONS_SUCCESS: {
      return assign(state, {
        curationStateOptions: action.payload.data,
      });
    }

    case FETCH_SONGS_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }

    case UPDATE_SONGS_SUCCESS: {
      const { payload } = action;
      // If a song data was updated, load the new song
      return {
        ...state,
        data: {
          ...state.data,
          songs: state.data.songs?.map((song) => {
            const updatedSong = payload.data.find((s) => s.uuid === song.uuid);
            return updatedSong || song;
          }),
        },
      };
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSongsSaga(action: SimpleActionType) {
    const queryParams: string = action?.payload
      ? convertQueryObjToUrlString(action.payload)
      : yield select(getUrlQueryParams);
    const results: FetchResponse<Nl.Api.SongsResponse> = yield call(
      apiFetch,
      `/song${queryParams}`,
    );
    if (results.success) {
      yield put(actions.fetchSongsSuccess({ data: results.data }));
    } else {
      yield put(
        addErrorNotification({ message: 'Impossible to fetch the songs.' }),
      );
      yield put(actions.fetchSongsError({ error: results.msg }));
    }
  },
  *deleteSongSaga(action: ReturnType<typeof actions.deleteSong>) {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/song/${action.payload.uuid}`,
      {
        method: 'DELETE',
      },
    );
    if (results.success) {
      yield put(actions.deleteSongSuccess());
      yield put(actions.fetchSongs());
      yield put(
        addSuccessNotification({ message: 'The Song has been deleted' }),
      );
    } else {
      yield put(actions.deleteSongError({ error: results.msg }));
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the Song',
        }),
      );
    }
  },
  *fetchSongConstants() {
    const results: FetchResponse<{
      song_constants: Nl.Api.SongConstants;
    }> = yield call(apiFetch, '/song/constants');
    if (results.success) {
      yield put(
        actions.fetchSongConstantsSuccess({
          data: results.data.song_constants,
        }),
      );
    } else {
      yield put(actions.fetchSongConstantsError({ error: results.msg }));
    }
  },
  *fetchCurationStateOptions() {
    const results: FetchResponse<any> = yield call(getCurationStateOptions);
    if (results.success) {
      yield put(
        actions.fetchCurationStateOptionsSuccess({
          data: results.data.song_curation_states,
        }),
      );
    } else {
      yield put(actions.fetchCurationStateOptionsError({ error: results.msg }));
    }
  },
  *publishSongs({ payload }: ReturnType<typeof actions.publishSongs>) {
    const { songUuids } = payload;
    const results: FetchResponse<{ songs: Nl.Api.Song[] }> = yield call(
      postPublishSong,
      songUuids,
    );

    yield put(
      actions.handleUpdateSongs({
        results,
        notification: 'The songs are publishing',
      }),
    );
  },
  *takeDownSongs({ payload }: ReturnType<typeof actions.takeDownSongs>) {
    const { songUuids } = payload;
    const results: FetchResponse<{ songs: Nl.Api.Song[] }> = yield call(
      postTakeDownSong,
      songUuids,
    );

    yield put(
      actions.handleUpdateSongs({
        results,
        notification: 'The songs are taken down',
      }),
    );
  },
  *curateSongs({ payload }: ReturnType<typeof actions.curateSongs>) {
    const request = payload.selected.map((current) => ({
      song: current,
      curation_states: [payload.targetSelected!],
    }));

    const results: FetchResponse<{ songs: Nl.Api.Song[] }> = yield call(
      patchSongs,
      request,
    );

    yield put(
      actions.handleUpdateSongs({
        results,
        notification: 'The songs curration state is set',
      }),
    );
  },
  *generateReport(action: SimpleActionType) {
    const queryParams: string = action?.payload
      ? convertQueryObjToUrlString(action.payload)
      : yield select(getUrlQueryParams);
    const results: FetchResponse<Nl.Api.ReportsResponse> = yield call(
      apiFetch,
      `/song/report${queryParams}`,
      { method: 'POST' },
    );
    if (results.success) {
      yield put(actions.generateSongReportSuccess({ data: results.data }));
      yield put(
        addSuccessNotification({ message: 'Your report is being generated.' }),
      );
    } else {
      yield put(actions.generateSongReportError({ error: results.msg }));
      yield put(
        addErrorNotification({
          message: 'An error occurs. Impossible to generate the report.',
        }),
      );
    }
  },
  *onUpdateSongs({ payload }: SimpleActionType) {
    const { results, notification } = payload;
    if (results.success) {
      const { songs } = results.data;
      yield put(actions.updateSongsSucess({ data: songs }));
      yield put(addSuccessNotification({ message: notification }));
    } else {
      yield put(actions.updateSongsError({ error: results.msg }));
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(FETCH_SONGS, sagas.fetchSongsSaga),
    takeLatest(DELETE_SONG, sagas.deleteSongSaga),
    takeLatest(FETCH_SONG_CONSTANTS, sagas.fetchSongConstants),
    takeLatest(FETCH_CURATION_STATE_OPTIONS, sagas.fetchCurationStateOptions),
    takeLatest(GENERATE_SONG_REPORT, sagas.generateReport),
    takeLatest(CURATE_SONGS, sagas.curateSongs),
    takeLatest(HANDLE_UPDATE_SONGS_RESPONSE, sagas.onUpdateSongs),
    takeLeading(PUBLISH_SONGS, sagas.publishSongs),
    takeLeading(TAKE_DOWN_SONGS, sagas.takeDownSongs),
  ]);
}

export { sagas };
export default reducer;
