import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Link,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import InputField from 'components/Formik/InputField';
import { actions } from 'store/auth';
import { validationSchema } from './validation';
import { useStyles, Wrapper } from './styles';
import { Dispatch } from 'redux';

const { onLogin } = actions;

interface LoginData {
  email: string;
  password: string;
}
const initialData: LoginData = { email: '', password: '' };

interface AllProps {
  submitForm: (formData: LoginData) => void;
}

const Login: React.SFC<AllProps> = ({ submitForm }) => {
  const classes = useStyles({});

  return (
    <Wrapper>
      <Card className={classes.root}>
        <Formik
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={submitForm}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <Form>
            <CardContent>
              <Typography variant='h5' gutterBottom>
                Login
              </Typography>
              <Field
                name='email'
                label='Email'
                type='email'
                component={InputField}
              />
              <Field
                name='password'
                label='Password'
                type='password'
                component={InputField}
              />
            </CardContent>
            <CardActions>
              <Button color='primary' type='submit' data-e2e='loginButton'>
                Log In
              </Button>
              <Button>
                <Link href='/forgot-password'>Forgot Password</Link>
              </Button>
            </CardActions>
          </Form>
        </Formik>
      </Card>
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submitForm: (formData: LoginData) => dispatch(onLogin({ formData })),
});

export default connect(null, mapDispatchToProps)(Login);
