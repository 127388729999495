import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '300px',
      marginTop: theme.spacing(2),
    },
    table: {
      width: '100%',
    },
    noResult: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 'inherit',
      width: '100%',
    },
    row: {
      display: 'flex',
      width: '100%',
      padding: '4px 20px',
      borderBottom: '1px solid rgba(224, 224, 224, 1);',
    },
    cell: {
      display: 'flex',
      height: '50px',
      alignItems: 'center',
      padding: '5px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
    checkbox: {
      width: '36px',
      flex: '0 0 30px',
    },
    title: {},
    name: {},
    full_name: {},
    email: {},
    country: {},
    parent: {},
    songs: {},
    countries: {},
    artist: {},
    ipi_number: {},
    pro: {},
    song_count: {},
    is_visible: {},
    writers: {},
    publishers: {},
    role: {},
    active: {},
    category: {},
    collection: {},
    source: {},
    state: {},
    last_active_at: {},
    toggle_active: {
      padding: 0,
    },
    make_visible: {},
    edit: {
      padding: 0,
    },
    remove: {
      padding: 0,
    },
    delete: {
      padding: 0,
    },
    download: {
      padding: 0,
    },
    manage: {
      padding: 0,
    },
    song_collection_count: {
      maxWidth: 100,
    },
    source_files_count: {
      maxWidth: 120,
    },
    play_track: {
      padding: '0px !important',
    },
    actions: {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
    },
    salesTotal: {
      width: theme.spacing(50),
    },
    infoWrapper: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    infoText: {
      border: '1px solid #58c55d',
      borderRadius: '5px',
      backgroundColor: 'rgba(57, 187, 61, 0.05)',
      padding: '2rem',
      maxWidth: '500px',
      marginRight: '1rem',
      marginLeft: '1rem',
    },
    infoTextInForm: {
      border: '1px solid #58c55d',
      borderRadius: '5px',
      backgroundColor: 'rgba(57, 187, 61, 0.05)',
      padding: '2rem',
    },
  });
