import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { GlobalState } from 'store/rootReducer';
import FormikWrapper from 'components/Formik';
import MyTrackCreateForm from './MyTrackCreateForm';
import { createMyTrackSubmission } from 'store/singleTrackSubmission';
import { getValidationSchema } from './validation';
import { myTrackInitialValues } from './MyTracksData';
import { selectSingleTrackSubmission } from 'store/selectors';

interface AllProps {
  trackSubmission?: Nl.Api.SingleTrackSubmission;
}

const MyTrackCreatePage: React.FunctionComponent<AllProps> = ({
  trackSubmission,
}) => {
  const dispatch = useDispatch();
  const initialValues = myTrackInitialValues(trackSubmission);

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      submitForm={(formData, formActions) =>
        dispatch(createMyTrackSubmission({ formActions, formData }))
      }
      title='Submit New Track'
      customSize='lg'
    >
      <MyTrackCreateForm />
    </FormikWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  trackSubmission: selectSingleTrackSubmission(state),
});

export default connect(mapStateToProps, null)(MyTrackCreatePage);
