import * as React from 'react';
import { Paper, Tooltip, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { useDispatch } from 'react-redux';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { deleteWriter } from 'store/writers';
import { TableConfigEntry } from 'utils/tableConfig';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import ConnectedButton from 'components/Button/ConnectedButton';
import InternalLink from 'components/Link/InternalLink';
import { getReadableDate } from 'utils/utils';
import EditButton from 'components/Button/EditButton';
import DeleteButton from 'components/Button/DeleteButton';
import { useWriters } from 'novaApi/hooks/writer';

const useStyles = makeStyles(styles);

const EditWriterButton = ({ uuid }: { uuid: string }) => {
  const dispatch = useDispatch();
  const data = useWriters();
  return (
    <EditButton
      aria-label='Edit'
      onClick={() => {
        dispatch({
          type: 'route/WRITERS_EDIT',
          payload: { uuid },
        });
      }}
      disabled={!data}
    />
  );
};

const DeleteWriterButton = ({ uuid }: { uuid: string }) => {
  const dispatch = useDispatch();
  const data = useWriters();
  return (
    <DeleteButton
      aria-label='Delete'
      onClick={() => {
        if (window.confirm('Are you sure you want to delete this writer?')) {
          dispatch(deleteWriter({ uuid }));
        }
      }}
      disabled={!data}
    />
  );
};

const tableConfig: TableConfigEntry<Nl.Api.Writer> = {
  columnData: [
    { id: 'full_name', label: 'Name', isSortable: true },
    {
      id: 'ipi_number',
      label: 'IPI Number',
      render: ({ ipi_number }) => ipi_number || '-',
    },
    {
      id: 'pro',
      label: 'PRO',
      render: ({ performing_rights_organization: pro }) => pro?.name || '-',
    },
    {
      id: 'song_count',
      label: 'Songs',
      render: ({ uuid, song_count }) =>
        song_count ? (
          <Tooltip title='Go to songs' placement='left'>
            <InternalLink
              color='inherit'
              to={{
                type: 'route/SONGS',
                payload: { query: { writer_uuid: [uuid] } },
              }}
            >
              {`${song_count}`}
            </InternalLink>
          </Tooltip>
        ) : (
          song_count
        ),
    },
    {
      id: 'is_visible',
      label: 'Visible',
      render: ({ is_visible }) => (is_visible ? 'Yes' : 'No'),
    },
    {
      id: 'modified',
      label: 'Last Modified',
      render: ({ updated_at }) => getReadableDate(updated_at),
    },
    {
      id: 'edit',
      label: '',
      permission: 'CAN_UPDATE_WRITERS',
      render: ({ uuid }) => <EditWriterButton uuid={uuid} />,
    },
    {
      id: 'delete',
      label: '',
      permission: 'CAN_DELETE_WRITERS',
      render: ({ uuid, is_deletable }) =>
        is_deletable && <DeleteWriterButton uuid={uuid} />,
    },
  ],
};

export default function WritersPage() {
  const data = useWriters();
  const classes = useStyles({});

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Writers'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'full_name',
                e2e: 'name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/WRITERS_CREATE'
            label='Add a writer'
            permission='CAN_CREATE_WRITERS'
          />
        }
      />
      <Table
        config={tableConfig}
        data={data?.writers}
        isLoaded={!!data}
        paginationData={data}
      />
    </Paper>
  );
}
