import { takeLatest, call, select, all, put } from 'redux-saga/effects';
import { redirect } from 'redux-first-router';
import { createSelector } from 'reselect';
import { pick } from 'lodash';
import config from '../config';
import { createTypedAction } from '../utils/storeUtils';
import { getPageSizeCookie, setPageSizeCookie } from '../utils/cookies';
import { GlobalState } from './rootReducer';

const queryParamsThatAreNotFilters = [
  'sort_by',
  'sort_order',
  'pagenum',
  'pagesize',
  'work_title',
  'title',
  'name',
  'full_name',
];

// Action Constants
const RESET_FILTERS = 'RESET_FILTERS';
const FILTER_BY = 'FILTER_BY';
const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE';

// Action Creators
export const resetFilters = () => createTypedAction(RESET_FILTERS);
export const updateFilter = (
  route: string,
  filterName: string,
  value: string,
) => ({
  type: route,
  payload: {
    useSearchSaga: true,
  },
  query: {
    [filterName]: value,
  },
});
export const filterBy = (payload: {
  query: Nl.QueryFilter;
  useSearchSaga?: boolean;
}) => createTypedAction(FILTER_BY, payload);

export const changePageSize = (payload: { pageSize: number }) =>
  createTypedAction(CHANGE_PAGE_SIZE, payload);

const getQueryWithoutFilter = (query: Nl.QueryFilter) =>
  pick(query, queryParamsThatAreNotFilters);

const getActiveFiltersFromQuery = (query: Nl.QueryFilter) =>
  Object.entries(query).filter(
    // excludes the query that are not filters and check if the query is defined
    (entry) =>
      !queryParamsThatAreNotFilters.includes(entry[0]) &&
      entry[1] &&
      entry[1].length > 0,
  );

// Selector
export const getNumOfFilterSelected = (state: GlobalState): number => {
  const query = state.location.query as Nl.QueryFilter;
  // will be superior to 0 if there is at least one filter defined in the query
  const numberOfFilterSelected =
    query && getActiveFiltersFromQuery(query).length;

  return numberOfFilterSelected || 0;
};

export const getQueryParams = createSelector(
  (state: GlobalState) => state.location.query,
  (res) => (res ? getActiveFiltersFromQuery(res) : []),
);

export const getPageSizeFromQueryParam = ({
  location,
}: GlobalState): number => {
  const pageSize =
    location?.query?.pagesize ||
    getPageSizeCookie() ||
    config.clients.api.defaultPageSize;
  return parseInt(pageSize as string, 10);
};

// Sagas
const sagas = {
  *resetFilters() {
    const { type, query, payload } = yield select(
      (state: GlobalState) => state.location,
    );

    yield put(
      redirect({
        type,
        query: {
          ...getQueryWithoutFilter(query),
          // @ts-ignore
          pagenum: 1,
        },
        payload: {
          ...payload,
          useSearchSaga: true,
        },
      }),
    );
  },
  *filterBy(action: ReturnType<typeof filterBy>) {
    const { type, query, payload } = yield select(
      (state: GlobalState) => state.location,
    );
    yield put(
      redirect({
        type,
        query: {
          ...query,
          ...action.payload.query,
          pagenum: action.payload.query.pagenum || 1,
        },
        payload: {
          ...payload,
          useSearchSaga: action.payload.useSearchSaga,
        },
      }),
    );
  },
  *changePageSizeSaga(action: ReturnType<typeof changePageSize>) {
    const { pageSize } = action.payload;
    const { type, query, payload } = yield select(
      (state: GlobalState) => state.location,
    );
    yield call(setPageSizeCookie, pageSize);
    yield put(
      redirect({
        type,
        query: {
          ...query,
          pagenum: 1,
          pagesize: pageSize,
        },
        payload: {
          ...payload,
          useSearchSaga: true,
        },
      }),
    );
  },
};

// Root Saga
export function* rootSaga() {
  yield all([takeLatest(RESET_FILTERS, sagas.resetFilters)]);
  yield all([takeLatest(FILTER_BY, sagas.filterBy)]);
  yield all([takeLatest(CHANGE_PAGE_SIZE, sagas.changePageSizeSaga)]);
}

export { sagas };
