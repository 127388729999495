import {
  all,
  fork,
  call,
  cancel,
  cancelled,
  delay,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { createAction, SimpleActionType } from '../utils/storeUtils';
import { addNotification } from './notifications';
import { FetchResponse } from 'novaApi/apiUtils';
import * as NovaAPi from 'novaApi/NovaApi';
import { Task } from 'redux-saga';

// Action Constants
const START_POLLING_USER_MESSAGES = 'START_POLLING_USER_MESSAGES';
const STOP_POLLING_USER_MESSAGES = 'STOP_POLLING_USER_MESSAGES';
const FETCH_USER_MESSAGES = 'FETCH_USER_MESSAGES';

// Action Creators
export const startPollingUserMessages = createAction(
  START_POLLING_USER_MESSAGES,
);
export const stopPollingUserMessages = createAction(STOP_POLLING_USER_MESSAGES);
export const fetchUserMessages = createAction(FETCH_USER_MESSAGES);

function* pollUserMessages() {
  try {
    while (true) {
      // If the song data is already loaded then update the source files data
      yield put(fetchUserMessages());
      // Poll with an interval of 10 seconds
      yield delay(10000);
    }
  } finally {
    // Dispatch an action to log if the loop stop because it was cancelled
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put({ type: 'USER_MESSAGES_LOOP_CANCELLED' });
    } else {
      yield put({ type: 'USER_MESSAGES_LOOP_ENDED' });
    }
  }
}

// Sagas
const sagas = {
  *startPollingUserMessages() {
    // starts the task in the background
    const pollForEventsTask: Task = yield fork(pollUserMessages);

    // wait for the user stop action
    yield take(
      (takenAction: SimpleActionType) =>
        takenAction.type === STOP_POLLING_USER_MESSAGES,
    );
    // user clicked stop. cancel the background task
    // this will throw a SagaCancellationException into the forked bgSync task
    yield cancel(pollForEventsTask);
  },
  *fetchUserMessages() {
    const response: FetchResponse<Nl.Api.UserMessagesResponse> = yield call(
      NovaAPi.fetchUserMessage,
    );

    if (response.success) {
      yield all(
        response.data.user_messages.map((userMessage) =>
          put(
            addNotification({
              persist: true,
              message: userMessage.payload.message,
              url: userMessage.payload.url,
            }),
          ),
        ),
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn('Impossible to fetch the user message', response.msg);
      yield put(stopPollingUserMessages());
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(START_POLLING_USER_MESSAGES, sagas.startPollingUserMessages),
    takeLatest(FETCH_USER_MESSAGES, sagas.fetchUserMessages),
  ]);
}

export { sagas };
