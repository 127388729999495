import React, { useState } from 'react';
import { uniqueId, groupBy, isEqual } from 'lodash';
import { Button, Tab, Tabs } from '@material-ui/core';
import ReplaceIcon from '@material-ui/icons/Replay';
import { getIn } from 'formik';

import DeleteButton from 'components/Button/DeleteButton';
import SongEditSourceFileHeader from './SongEditSourceFile';
import SongEditSourceFileRow from './SongEditSourceFileRow';
import SongEditUploadDropzone from './SongEditUploadDropzone';
import { allTypeOptions, sourceFileTabsConfig } from './constants';
import { useTabStyles } from 'styles/tab';

export const buildSourceFilesTable = (sourceFiles: any[]): any[] => {
  const remainingTypes: any[] = [];
  const groupedSourceFiles = groupBy(sourceFiles, 'file_type');

  // Ensure we have added all types to the array to start.
  allTypeOptions.forEach((type) => {
    if (!groupedSourceFiles[type.value]) {
      remainingTypes.push({
        id: type.value,
        state: 'init',
        url_zip: null,
        file_type: type.value,
      });
    }
  });
  return remainingTypes;
};

interface AllProps {
  songUuid: string;
  form: any;
  name: string;
  sourceFileUploads: Nl.Api.SourceFileUpload[];
  remove: (index: number) => void;
  replace: (index: number) => void;
  isEditable: boolean;
}

const SourceFilesTable: React.FunctionComponent<AllProps> = ({
  songUuid,
  form,
  name,
  sourceFileUploads,
  remove,
  replace,
  isEditable,
}) => {
  const classes = useTabStyles();

  // Transform formik fields array into a grouped hashmap
  const fields: Nl.SourceFileExtended[] = getIn(form.values, name);
  const groupedFields = groupBy(fields, 'file_type');
  const [safeFields, setSafeFields] = useState<
    Record<string, Nl.SourceFileExtended[]>
  >({});

  const renderReplaceGroupButton = (group: string) => {
    // A button to replace an entire group
    const resetFields: object[] = [
      ...fields.filter((f) => f.file_type !== group),
      {
        id: uniqueId(),
        state: 'init',
        url_zip: null,
        file_type: group,
      },
    ];

    return (
      <Button
        aria-label='Replace'
        style={{
          paddingLeft: 0,
          paddingTop: '.5rem',
        }}
        onClick={() => {
          // Before we replace the group in formik, we keep it in local state so we can undo
          setSafeFields({
            ...safeFields,
            [group]: fields.filter((f) => f.file_type === group),
          });
          form.setFieldValue(name, resetFields);
        }}
      >
        <ReplaceIcon />
        Replace {group}
      </Button>
    );
  };

  const undoGroupReplace = (group: Nl.SongSourceFileType): void => {
    // Put the replaced group back into formik from local state
    const resetFields = [
      ...fields.filter((f) => f.file_type !== group),
      ...safeFields[group],
    ];
    form.setFieldValue(name, resetFields);
  };

  // Setup local states and handlers relevant to the Tab control
  const startingTabIndex = 0;
  const [currentTabValue, setCurrentTabValue] = React.useState(
    startingTabIndex,
  );
  const [currentTypeOptions, setCurrentTypeOptions] = React.useState(
    sourceFileTabsConfig[startingTabIndex].typeOptions,
  );
  const onTabChange = (_: any, newTabValue: number) => {
    setCurrentTabValue(newTabValue);
    setCurrentTypeOptions(sourceFileTabsConfig[newTabValue].typeOptions);
  };

  return (
    <div>
      <SongEditSourceFileHeader
        songUuid={songUuid}
        form={form}
        fields={fields}
        name={name}
        replace={replace}
      />
      <Tabs
        className={classes.root}
        value={currentTabValue}
        onChange={onTabChange}
        indicatorColor='primary'
        textColor='primary'
      >
        {sourceFileTabsConfig.map((tabConfig) => (
          <Tab label={tabConfig.label} key={tabConfig.label} />
        ))}
      </Tabs>
      {currentTypeOptions.map((type) => {
        // See what groups have source files in them, render them as rows
        const group = type.value;
        const groupArray = groupedFields[group];
        const processedGroup = Boolean(
          groupArray?.length && groupArray[0].uuid,
        );

        return (
          groupArray && (
            <div key={group} className='groupWrapper'>
              <h4 style={{ marginBottom: 0 }}>{group}</h4>
              {groupArray.map((field) => {
                // Check for files already uploaded that are inbound
                const incomingField = sourceFileUploads.find(
                  (upload) =>
                    upload.status === 'pending' && upload.file_type === group,
                );
                if (incomingField) return <div key={group}>Incoming... 🚀</div>;

                const index: number = fields.indexOf(field);
                const formValues: Nl.UploadFormValues = {
                  field: `${name}.${index}`,
                  set: form.setFieldValue,
                };

                return field.uuid ? (
                  <SongEditSourceFileRow
                    key={field.uuid}
                    songUuid={songUuid}
                    onRemove={() => remove(index)}
                    sourceFile={field}
                    sourceFileIndex={index}
                    isEditable={isEditable}
                    formValues={formValues}
                  />
                ) : (
                  isEditable && ( // non-editable applies to consultants
                    <div
                      style={{ display: 'flex', alignItems: 'center' }}
                      key={group}
                    >
                      <SongEditUploadDropzone
                        songUuid={songUuid}
                        contentType='application/zip'
                        sourceFile={field}
                        state={field.state}
                        type={group}
                        formValues={formValues}
                      />
                      {!!safeFields[group] && (
                        <DeleteButton
                          aria-label='Undo'
                          onClick={() => undoGroupReplace(group)}
                        />
                      )}
                    </div>
                  )
                );
              })}
              {processedGroup && isEditable && renderReplaceGroupButton(group)}
            </div>
          )
        );
      })}
    </div>
  );
};

function areEqual(prevProps: AllProps, nextProps: AllProps): boolean {
  return isEqual(
    prevProps.form.values.source_files,
    nextProps.form.values.source_files,
  );
}

export default React.memo(SourceFilesTable, areEqual);
