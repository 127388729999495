import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import { createPro } from 'store/pros';
import { selectNotificationStatus } from 'store/selectors';
import PROForm, { getEditFormInitialValues } from './PROForm';
import validationSchema from './validation';
import { useNlSelector } from 'utils/redux';

export default function PROCreatePage() {
  const notificationStatus = useNlSelector(selectNotificationStatus);
  const dispatch = useDispatch();
  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues()}
      validationSchema={validationSchema(notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(createPro({ formData, formActions }))
      }
      title='Create Performing Rights Organization'
    >
      <PROForm />
    </FormikWrapper>
  );
}
