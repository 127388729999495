import * as React from 'react';
import { Field, Form } from 'formik';
import InputField from 'components/Formik/InputField';
import CheckboxField from 'components/Formik/CheckboxField';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import { FormSubTitle, InputWrapper } from 'components/Formik/utils';
import { Typography } from '@material-ui/core';
import { multipleSelectFormat } from 'utils/utils';
import { usePROSelect } from 'novaApi/hooks/performing_rights_organization';

const MyWriterForm = () => {
  const pros = usePROSelect();

  return (
    <Form>
      <FormSubTitle title='Basic Information' />
      <InputWrapper>
        <Field name='first_name' label='First name' component={InputField} />
        <Field name='last_name' label='Last name' component={InputField} />
      </InputWrapper>
      <br />
      <InputWrapper>
        <Field
          name='is_default'
          label='Set as a default writer'
          component={CheckboxField}
        />
      </InputWrapper>
      <br />
      <FormSubTitle title='Performing Rights Organization' />
      <Typography variant='caption'>
        Only fill this out if this writer belongs to a PRO
      </Typography>
      <InputWrapper>
        <Field
          name='performing_rights_organization_uuid'
          label='PRO'
          component={AutocompleteSelect}
          options={multipleSelectFormat(pros)}
        />
        <Field
          name='ipi_number'
          label='IPI Number'
          component={InputField}
          helperText='Please ensure your name and IPI number align'
        />
      </InputWrapper>
    </Form>
  );
};

export default MyWriterForm;
