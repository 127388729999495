import { useNovaApi } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';

export const useSongGenres = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.SongGenresResponse>(`/song_genre`, { query }).data;
};

export const useSongGenreParents = () => {
  return (
    useNovaApi<Nl.Api.SongGenresResponse>(`/song_genre/select_parents`).data
      ?.song_genres || []
  );
};

export const useSongGenreSelect = () => {
  return (
    useNovaApi<Nl.Api.SongGenresSelectResponse>('/song_genre/select').data
      ?.song_genres || []
  );
};
