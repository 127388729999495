import * as React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';

interface OwnProps {
  href: string;
  disabled?: boolean;
  tooltip?: string;
}

const defaultTooltip = 'Download';

const DownloadButton: React.SFC<OwnProps> = ({
  href,
  disabled,
  tooltip = defaultTooltip,
}) => {
  const DownloadLink: React.SFC<any> = React.forwardRef<any, {}>(
    (props, ref) => <a ref={ref} href={href} {...props} />,
  );

  return (
    <Tooltip title={tooltip}>
      <IconButton
        aria-label='Download'
        component={DownloadLink}
        disabled={disabled}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadButton;
