import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';

export default Yup.object().shape({
  first_name: Yup.string().required(errorMessages.basicRequired),
  last_name: Yup.string().required(errorMessages.basicRequired),
  designation_code: Yup.string()
    .required(errorMessages.basicRequired)
    .nullable(),
});
