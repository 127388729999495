import * as React from 'react';
import { Form, Field } from 'formik';
import InputField from 'components/Formik/InputField';
import {
  selectIdenticalValueLabelFormat,
  multipleSelectFormat,
} from 'utils/utils';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import Checkbox from 'components/Formik/CheckboxField';
import { InputWrapper } from 'components/Formik/utils';
import { useLanguages } from 'novaApi/hooks/language';
import { usePROSelect } from 'novaApi/hooks/performing_rights_organization';
import { useDesignationCodes } from 'novaApi/hooks/writer';

const writerInitialValues = {
  uuid: '' as string,
  first_name: '' as string,
  last_name: '' as string,
  is_visible: false as boolean,
  ipi_number: '' as string,
  designation_code: '' as string,
  preferred_language_uuid: '' as string,
  performing_rights_organization_uuid: '' as string,
  performing_rights_organization_member_id: '' as string,
  notes: '' as string,
};

export const getEditFormInitialValues = (
  writer: Nl.Api.Writer | undefined,
  isEdit = false,
) => {
  if (!isEdit || !writer) return writerInitialValues;

  return {
    uuid: writer.uuid,
    first_name: writer.first_name,
    last_name: writer.last_name,
    is_visible: writer.is_visible || false,
    ipi_number: writer.ipi_number || '',
    designation_code: writer.designation_code,
    preferred_language_uuid: writer?.preferred_language?.uuid,
    performing_rights_organization_uuid:
      writer?.performing_rights_organization?.uuid,
    performing_rights_organization_member_id:
      writer.performing_rights_organization_member_id || '',
    notes: writer.notes || '',
  };
};

const WriterForm = () => {
  const pros = usePROSelect();
  const languages = useLanguages();
  const designationCodes = useDesignationCodes();

  return (
    <Form>
      <Field name='first_name' label='First Name' component={InputField} />
      <Field name='last_name' label='Last Name' component={InputField} />
      <InputWrapper>
        <Field
          name='designation_code'
          label='Designation Code'
          options={selectIdenticalValueLabelFormat(designationCodes)}
          component={AutocompleteSelect}
        />
        <Field name='ipi_number' label='IPI Number' component={InputField} />
      </InputWrapper>
      <InputWrapper>
        <Field
          name='performing_rights_organization_uuid'
          label='PRO'
          options={multipleSelectFormat(pros)}
          component={AutocompleteSelect}
        />
      </InputWrapper>
      <Field
        name='preferred_language_uuid'
        label='Preferred Language'
        options={multipleSelectFormat(languages)}
        component={AutocompleteSelect}
      />
      <Field
        name='notes'
        label='Notes'
        component={InputField}
        multiline
        rows={4}
        rowsMax={6}
      />
      <Field name='is_visible' label='Is visible' component={Checkbox} />
    </Form>
  );
};

export default WriterForm;
