import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import { TableConfigEntry } from 'utils/tableConfig';
import { getReadableDate } from 'utils/utils';
import { useExamples } from 'novaApi/hooks/example';
import InternalLink from 'components/Link/InternalLink';
import ConnectedButton from 'components/Button/ConnectedButton';

const useStyles = makeStyles(styles);

const tableConfig = (): TableConfigEntry<Nl.Api.ExampleBase> => ({
  columnData: [
    {
      id: 'name',
      label: 'Name',
      isSortable: true,
      render: ({ uuid, name }) => (
        <InternalLink
          color='inherit'
          to={{ type: 'route/EXAMPLES_EDIT', payload: { uuid } }}
        >
          {name}
        </InternalLink>
      ),
    },
    {
      id: 'created_at',
      label: 'Created',
      isSortable: true,
      render: ({ created_at }) => getReadableDate(created_at) || '-',
    },
    {
      id: 'updated_at',
      label: 'Last Modified',
      isSortable: true,
      render: ({ updated_at }) => getReadableDate(updated_at) || '-',
    },
  ],
});

export default function ExamplesPage() {
  const classes = useStyles({});
  const { data, isLoaded } = useExamples();

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Examples'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'example-search-name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/EXAMPLES_CREATE'
            label='Create new Example'
            permission='CAN_CREATE_EXAMPLE'
          />
        }
      />
      <Table
        config={tableConfig()}
        data={data?.examples}
        isLoaded={isLoaded}
        paginationData={data}
      />
    </Paper>
  );
}
