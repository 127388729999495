import React from 'react';
import { Form } from 'formik';
import {
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';
import { EditMyProfile } from 'pages/MyProfile/EditMyProfileForm';

const RegistrationMyProfileForm = ({
  isSubmitting,
}: {
  isSubmitting: boolean;
}) => {
  // Reuses the regular MyProfileEditForm but presents it as its own page
  // (Problem with dialog is it makes cancellation seem acceptable)
  return (
    <Form>
      <CardContent>
        <Typography variant='h5' gutterBottom>
          How can we get in touch?
        </Typography>
        <br />
        <EditMyProfile />
      </CardContent>
      <CardActions>
        <Button color='primary' type='submit' disabled={isSubmitting}>
          Submit
        </Button>
      </CardActions>
    </Form>
  );
};

export default RegistrationMyProfileForm;
