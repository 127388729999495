import Pagination from './Pagination';
import { connect } from 'react-redux';
import { GlobalState } from '../../store/rootReducer';
import {
  changePageSize,
  getPageSizeFromQueryParam,
  filterBy,
} from 'store/queryParams';
import { StateKeyWithPaginationData } from 'utils/tableConfig';

interface OwnProps {
  storeKey?: StateKeyWithPaginationData;
  paginationData?: Nl.Api.PaginationData;
}

export default connect(
  (state: GlobalState, { storeKey, paginationData }: OwnProps) => {
    const data = paginationData || state[storeKey!].data;
    return {
      totalSize: data.total_size,
      currentSize: data.current_size,
      currentPage: data.current_page,
      offset: data.offset,
      totalPages: data.total_pages,
      query: state.location.query,
      pageSize: getPageSizeFromQueryParam(state),
    };
  },
  (dispatch) => ({
    onDispatchFilters: (filters: Nl.QueryFilter) => {
      dispatch(filterBy({ query: filters, useSearchSaga: true }));
    },
    onChangeRowPerPage: (pageSize: number) => {
      dispatch(changePageSize({ pageSize }));
    },
  }),
)(Pagination);
