// Used to populate status dropdown in track submissions filters
export const STATUS_VALUES = [
  {
    name: 'New',
    uuid: '1',
  },
  {
    name: 'Pending final feedback',
    uuid: '2',
  },
  {
    name: 'Approved and ready for delivery',
    uuid: '3',
  },
  {
    name: 'Revision requested',
    uuid: '4',
  },
  {
    name: 'Revision submitted',
    uuid: '5',
  },
  {
    name: 'Delivery complete',
    uuid: '6',
  },
  {
    name: 'Rejected',
    uuid: '8',
  },
];

export const MY_TRACK_OPTIONS = [
  {
    name: 'New',
    uuid: '1',
  },
  {
    name: 'Pending Final Feedback',
    uuid: '2',
  },
  {
    name: 'Approved and Ready for Delivery',
    uuid: '3',
  },
  {
    name: 'Revision Requested',
    uuid: '4',
  },
  {
    name: 'Revision Submitted',
    uuid: '5',
  },
  {
    name: 'Delivery Complete',
    uuid: '6',
  },
  {
    name: 'Acquisition Complete',
    uuid: '7',
  },
  {
    name: 'Declined',
    uuid: '8',
  },
  {
    name: 'Delivery in Progress',
    uuid: '9',
  },
  {
    name: 'Error in Delivery',
    uuid: '10',
  },
];

export const PRO_TYPE_OPTIONS = [
  {
    name: 'Pro-Affiliated only',
    uuid: 'true',
  },
  {
    name: 'Pro-Free only',
    uuid: 'false',
  },
];

export const OLD_PRO_OPTIONS = [
  {
    name: 'Pro-Affiliated only',
    uuid: 'PRO_AFFILIATED_ONLY',
  },
  {
    name: 'Pro-Free only',
    uuid: 'PRO_FREE_ONLY',
  },
];

export const assignedTo = (userUuid: string) => [
  {
    name: 'Show only my artists',
    uuid: userUuid,
  },
  {
    name: 'Show only other artists',
    uuid: encodeURI(`!${userUuid}`),
  },
];

export const NAS_STATUS_VALUES = [
  {
    name: 'New',
    uuid: '1',
  },
  {
    name: 'First Approval',
    uuid: '2',
  },
  {
    name: 'Second Approval',
    uuid: '3',
  },
  {
    name: 'Declined',
    uuid: '4',
  },
  {
    name: 'Onboarding Complete',
    uuid: '5',
  },
];

export const NAS_CAME_FROM_VALUES = [
  {
    name: 'Online',
    uuid: '1',
  },
  {
    name: 'Referral',
    uuid: '2',
  },
  {
    name: 'Music School',
    uuid: '3',
  },
  {
    name: 'Event',
    uuid: '4',
  },
  {
    name: 'Competition',
    uuid: '5',
  },
  {
    name: 'WOM',
    uuid: '6',
  },
];

export const ROYALTY_FILTER_OPTIONS = [
  {
    name: 'Royalty only',
    uuid: 'true',
  },
  {
    name: 'Buyout only',
    uuid: 'false',
  },
];
