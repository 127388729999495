import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';

type SchemaOptions = 'EDIT' | 'CREATE';

export const getValidationSchema = (option: SchemaOptions = 'CREATE') => {
  const baseShape = {
    name: Yup.string().required(errorMessages.basicRequired),
    icon: Yup.string().required(errorMessages.basicRequired),
  };

  const editShape = {
    is_derived: Yup.boolean().required(errorMessages.basicRequired),
    is_organizational: Yup.boolean().required(errorMessages.basicRequired),
    is_internal: Yup.boolean().required(errorMessages.basicRequired),
  };

  return Yup.object().shape({
    ...baseShape,
    ...(option === 'EDIT' ? editShape : {}), // add edit shape in EDIT mode
  });
};
