import * as React from 'react';
import { TablePagination, withStyles, WithStyles } from '@material-ui/core';
import { Theme, StyleRules } from '@material-ui/core/styles';
import TablePaginationActions from './TablePaginationActions';

const rowsPerPageOptions = [25, 50, 100, 200];

interface OwnProps {
  totalSize: number;
  currentSize: number;
  currentPage: number;
  offset: number;
  totalPages: number;
  pageSize: number;
  classes?: any;
  onChangeRowPerPage: (value: number) => void;
  onDispatchFilters(filters: Nl.QueryFilter): void;
}

const styles = (theme: Theme): StyleRules => ({
  root: {
    borderBottom: 'none',
  },
  text: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  button: {
    minWidth: '0',
    width: '32px',
    height: '32px',
  },
  buttonArrow: {
    margin: '4px',
  },
  buttonActive: {
    color: '#FFF',
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  icon: {
    fontSize: '18px',
  },
  spacer: { display: 'none' },
  caption: {
    '&:nth-child(4)': { marginLeft: 'auto' },
  },
});

type AllProps = OwnProps & WithStyles<typeof styles>;

const Pagination = ({
  totalSize = 0,
  currentPage = 0,
  pageSize,
  onDispatchFilters,
  onChangeRowPerPage,
  classes,
}: AllProps) => {
  return (
    <TablePagination
      classes={{
        root: classes.root,
        spacer: classes.spacer,
        caption: classes.caption,
      }}
      count={totalSize}
      rowsPerPage={pageSize}
      page={currentPage - 1}
      rowsPerPageOptions={rowsPerPageOptions}
      onChangePage={(event, page) => {
        if (page > 0) {
          // this condition is necessary because in some case the component triggering a non-expected event
          onDispatchFilters({ pagenum: page });
        }
      }}
      onChangeRowsPerPage={(event) =>
        onChangeRowPerPage(Number(event.target.value))
      }
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default withStyles(styles)(Pagination);
