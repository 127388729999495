import React from 'react';
import { Form, Field } from 'formik';
import {
  CardContent,
  Typography,
  Grid,
  CardActions,
  Button,
} from '@material-ui/core';
import InputField from 'components/Formik/InputField';
import PasswordHelper from 'components/PasswordHelper';

const RegistrationPasswordForm = (
  { isSubmitting }: { isSubmitting: boolean },
  user: Nl.Api.User,
) => {
  return (
    <Form>
      <CardContent>
        <Typography variant='h5' gutterBottom>
          Welcome {user.first_name}
        </Typography>
        <Grid container>
          <Grid item xs={11}>
            <Typography>
              Let&apos;s set up your account. Please enter a secure password:
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <PasswordHelper />
          </Grid>
        </Grid>
        {/*
         * Displaying the email field so that the user has no trouble
         * saving their password through their password manager/browser
         * and no ambiguity about which email address to use when logging in.
         * They should not be able to change their email in this step.
         */}
        <Field
          type='email'
          name='email'
          label='Email'
          component={InputField}
          disabled
        />
        <Field
          type='password'
          name='new_password'
          label='Password'
          component={InputField}
        />
        <Field
          type='password'
          name='confirm_password'
          label='Confirm Password'
          component={InputField}
        />
      </CardContent>
      <CardActions>
        <Button
          data-e2e='submitRegistration'
          color='primary'
          type='submit'
          disabled={isSubmitting}
        >
          Submit
        </Button>
      </CardActions>
    </Form>
  );
};

export default RegistrationPasswordForm;
