import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Popover,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import { setVolume } from 'store/audioPlayer';
import { addErrorNotification } from 'store/notifications';
import { useNlSelector } from 'utils/redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    // @ts-ignore
    popoverModal: {
      // @ts-ignore
      zIndex: `${theme.zIndex.tooltip} !important`,
    },
    popover: {
      overflow: 'visible',
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    sliderContainer: {
      height: 150,
      padding: 10,
    },
    track: {
      backgroundColor: 'grey',
    },
  }),
);

interface Props {
  className?: string;
}

const ratio = 20;

const VolumeIcon: React.SFC<{ relativeVolume: number }> = ({
  relativeVolume,
}) => {
  if (relativeVolume === 0) return <VolumeMuteIcon />;
  if (relativeVolume > ratio / 2) return <VolumeUpIcon />;
  return <VolumeDownIcon />;
};

const PlayerVolume = ({ className }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const volume = useNlSelector((state) => state.audioPlayer.volume);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  const open = Boolean(anchorEl);
  const relativeVolume = volume * ratio;

  return (
    <div data-e2e='playerVolume' className={className}>
      <Popover
        id='simple-popper'
        className={classes.popoverModal}
        classes={{
          paper: classes.popover,
        }}
        elevation={0}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.sliderContainer}>
          <Slider
            classes={{
              root: classes.root,
              track: classes.track,
            }}
            min={0}
            max={ratio}
            step={1}
            value={relativeVolume}
            onChange={(event, value: number | number[]) => {
              if (Array.isArray(value)) {
                dispatch(
                  addErrorNotification({ message: 'Error changing volume' }),
                );
                return;
              }

              const nextVolume = value / ratio;
              dispatch(setVolume(nextVolume));
            }}
            color='secondary'
            orientation='vertical'
            data-e2e='sliderContainer'
          />
        </div>
      </Popover>
      <IconButton
        aria-label='volumeBotton'
        aria-owns={open ? 'simple-popper' : undefined}
        aria-haspopup='true'
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size='medium'
      >
        <VolumeIcon relativeVolume={relativeVolume} />
      </IconButton>
    </div>
  );
};

export default PlayerVolume;
