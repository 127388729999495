import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Tooltip } from '@material-ui/core';
import LightButton from 'components/Button/LightButton';
import HighlightOnLoad from 'components/animation/HighlightOnLoad';
import WarningIcon from '@material-ui/icons/Warning';
import ConnectedButton from 'components/Button/ConnectedButton';
import { updateFilter } from 'store/queryParams';
import { generateSongReport } from 'store/songs';
import { useSongStats } from 'novaApi/hooks/song_stats';

const SongsActions = () => {
  const dispatch = useDispatch();
  const { data, isLoaded } = useSongStats();
  if (!isLoaded || !data) return null;
  const {
    publish_error_count,
    publish_in_progress_count,
    publish_blocked_count,
  } = data.song_stats;
  return (
    <>
      {publish_blocked_count > 0 && (
        <LightButton
          data-e2e='warning-publish-blocked'
          aria-label='Songs that are missing data to publish'
          onClick={() =>
            dispatch(updateFilter('route/SONGS', 'is_publish_blocked', 'true'))
          }
        >
          <WarningIcon /> {publish_blocked_count} Publish Blocked
        </LightButton>
      )}
      {publish_error_count > 0 && (
        <HighlightOnLoad>
          <LightButton
            data-e2e='warning-publish-errors'
            aria-label='See the songs that failed being published'
            onClick={() =>
              dispatch(updateFilter('route/SONGS', 'publish_state', 'Error'))
            }
          >
            <WarningIcon /> {publish_error_count} Publish failure
            {publish_error_count > 1 && 's'}
          </LightButton>
        </HighlightOnLoad>
      )}
      {publish_in_progress_count > 0 && (
        <LightButton
          data-e2e='warning-publish-in-progress'
          aria-label='See the songs that are publishing'
          onClick={() =>
            dispatch(
              updateFilter('route/SONGS', 'publish_state', 'In Progress'),
            )
          }
        >
          {publish_in_progress_count} Publish in progress
        </LightButton>
      )}
      <Tooltip title='Export CSV'>
        <Button
          data-e2e='export-button'
          aria-label='Export CSV'
          onClick={() => dispatch(generateSongReport())}
        >
          Export
        </Button>
      </Tooltip>
      <ConnectedButton
        icon='add'
        fab
        route='route/SONG_CREATE'
        label='Add a song'
        permission='CAN_CREATE_SONGS'
      />
    </>
  );
};

export default SongsActions;
