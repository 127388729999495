import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { GlobalState } from 'store/rootReducer';
import {
  Typography,
  Grid,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { formatNumber } from 'utils/utils';
import { compose } from 'redux';

const styles = createStyles({
  container: {
    padding: 12,
  },
  grid: {},
  gridItem: {
    paddingLeft: '24px !important',
    borderBottom: '1px solid lightgrey',
  },
});

interface OwnProps {
  className: string;
}

interface StateProps {
  allTimeTotal: number;
  currentTotal: number;
}

type AllProps = OwnProps & StateProps & WithStyles<typeof styles>;

class SalesTotal extends React.PureComponent<AllProps> {
  render() {
    const { className, classes, allTimeTotal, currentTotal } = this.props;
    return (
      <div className={classnames(classes.container, className)}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={3} sm={6} className={classes.gridItem}>
            <Typography variant='body1'>All-time totals:</Typography>
          </Grid>
          <Grid
            data-e2e='allTimeTotals'
            item
            xs={3}
            sm={6}
            className={classes.gridItem}
          >
            <Typography variant='body1' align='right'>
              {`$${allTimeTotal ? formatNumber(allTimeTotal.toFixed(2)) : 0}`}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={6} className={classes.gridItem}>
            <Typography variant='body1'>Current totals:</Typography>
          </Grid>
          <Grid
            data-e2e='currentTotals'
            item
            xs={3}
            sm={6}
            className={classes.gridItem}
          >
            <Typography variant='body1' align='right'>
              {`$${currentTotal ? formatNumber(currentTotal.toFixed(2)) : 0}`}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default (compose(
  connect((state: GlobalState) => ({
    allTimeTotal: state.salesTotal.data.earning.all_time_totals,
    currentTotal: state.salesTotal.data.earning.current_totals,
  })),
  withStyles(styles),
)(SalesTotal) as unknown) as React.ComponentClass<OwnProps>;
