import * as React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { TableConfigEntry } from 'utils/tableConfig';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import ConnectedButton from 'components/Button/ConnectedButton';
import InternalLink from 'components/Link/InternalLink';
import { useSongInstruments } from 'novaApi/hooks/song_instrument';

const useStyles = makeStyles(styles);

const tableConfig: TableConfigEntry<Nl.Api.SongInstrument> = {
  columnData: [
    { id: 'name', label: 'Name', isSortable: true },
    {
      id: 'songs',
      label: 'Songs',
      render: ({ song_count, uuid }) =>
        song_count < 1 ? (
          song_count
        ) : (
          <InternalLink
            color='inherit'
            to={{
              type: 'route/SONGS',
              payload: { query: { instrument_uuid: uuid } },
            }}
          >
            {song_count}
          </InternalLink>
        ),
    },
  ],
};

export default function SongInstrumentPage() {
  const data = useSongInstruments();
  const classes = useStyles({});

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Song Instruments'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/SONG_INSTRUMENT_CREATE'
            label='Create song Instrument'
            permission='CAN_CREATE_INSTRUMENTS'
          />
        }
      />
      <Table
        config={tableConfig}
        data={data?.instruments ?? []}
        isLoaded={!!data}
        paginationData={data}
      />
    </Paper>
  );
}
