import {
  all,
  takeLatest,
  select,
  call,
  put,
  delay,
  cancel,
} from 'redux-saga/effects';
import { defaultMemoize, createSelector } from 'reselect';
import {
  assign,
  createTypedAction,
  ActionsUnion,
  createAction,
} from 'utils/storeUtils';
import { GlobalState } from './rootReducer';
import { FetchResponse, getUrlQueryParams } from 'novaApi/apiUtils';
import { addErrorNotification } from './notifications';
import { postSongBatchUpdate, pollBatchUpdateTask } from 'novaApi/NovaApi';
import batchStages from 'constants/batchUpdateStages';

/**
 * Action Constants
 */
const SET_BATCH_UPDATE_STAGE = 'SET_BATCH_UPDATE_STAGE';
const SET_BATCH_UPDATE_DATA = 'SET_BATCH_UPDATE_DATA';
const SET_BATCH_UPDATE_ASYNC_TASK = 'SET_BATCH_UPDATE_ASYNC_TASK';
const START_BATCH_UPDATE_POLLING = 'START_BATCH_UPDATE_POLLING';

/**
 * Action Creators
 */
export const actions = {
  setBatchUpdateStage: (payload: { stage: Nl.BatchUpdateStages }) =>
    createTypedAction(SET_BATCH_UPDATE_STAGE, payload),
  setBatchUpdateData: (payload: {
    action: Nl.BatchUpdateActions;
    attribute: Nl.BatchUpdateAttributes;
    values: string[];
  }) => createTypedAction(SET_BATCH_UPDATE_DATA, payload),
  setBatchUpdateAsyncTask: (payload: { asyncTask: Nl.BatchUpdateAsyncTask }) =>
    createTypedAction(SET_BATCH_UPDATE_ASYNC_TASK, payload),
};

export const { setBatchUpdateAsyncTask } = actions;
export const { setBatchUpdateStage } = actions;
export const { setBatchUpdateData } = actions;
export const startBatchUpdatePolling = createAction(START_BATCH_UPDATE_POLLING);

/**
 * Selectors
 */
export const selectBatchUpdateStage = defaultMemoize(
  (state: GlobalState) => state.batchUpdate.stage,
);
export const selectBatchUpdateData = createSelector(
  (state: GlobalState) => state.batchUpdate,
  ({ action, attribute, values }) => {
    return {
      action,
      attribute,
      values,
    };
  },
);
export const selectBatchUpdateAsyncTask = defaultMemoize(
  (state: GlobalState) => state.batchUpdate.asyncTask,
);

/**
 * Reducer
 */
const initialState = {
  stage: 0 as Nl.BatchUpdateStages,
  action: '' as Nl.BatchUpdateActions,
  attribute: '' as Nl.BatchUpdateAttributes,
  values: [] as string[],
  asyncTask: null as Nl.BatchUpdateAsyncTask | null,
};

export type BatchUpdateState = Readonly<typeof initialState>;
type Actions = ActionsUnion<typeof actions>;

const reducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case SET_BATCH_UPDATE_STAGE: {
      if (action.payload.stage === batchStages.initial) {
        return initialState;
      }

      return assign(state, {
        stage: action.payload.stage,
      });
    }

    case SET_BATCH_UPDATE_DATA: {
      return assign(state, {
        action: action.payload.action,
        attribute: action.payload.attribute,
        values: action.payload.values,
      });
    }

    case SET_BATCH_UPDATE_ASYNC_TASK: {
      return assign(state, {
        asyncTask: action.payload.asyncTask,
      });
    }

    default:
      return state;
  }
};

const sagas = {
  *performBatchUpdate({ payload }: any) {
    if (payload.stage !== batchStages.execution) return;

    const data: Nl.BatchUpdateData = yield select(selectBatchUpdateData);
    const queryParams: string = yield select(getUrlQueryParams);
    const results: FetchResponse<{
      batch_operation: Nl.Api.SongBatchUpdateResponse;
    }> = yield call(postSongBatchUpdate, data, queryParams);

    if (results.success) {
      // Start polling until completion
      yield put(
        setBatchUpdateAsyncTask({
          asyncTask: results.data.batch_operation.async_task,
        }),
      );
      yield put(startBatchUpdatePolling());
      yield put(setBatchUpdateStage({ stage: batchStages.results }));
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *pollBatchUpdate() {
    try {
      while (true) {
        const { uuid } = yield select(selectBatchUpdateAsyncTask);
        const results: FetchResponse<{
          async_task: Nl.BatchUpdateAsyncTask;
        }> = yield call(pollBatchUpdateTask, uuid);

        if (results.success) {
          yield put(
            setBatchUpdateAsyncTask({ asyncTask: results.data.async_task }),
          );
        } else {
          yield put(
            addErrorNotification({
              message: `Batch Update Failed: ${results.msg}`,
            }),
          );
        }

        const taskComplete: boolean =
          results.data.async_task.status === 'success' ||
          results.data.async_task.status === 'error';

        if (taskComplete) yield cancel();
        yield delay(2000);
      }
    } catch (e) {
      yield put(setBatchUpdateStage({ stage: batchStages.initial }));
      yield cancel();
    }
  },
};

export function* rootSaga() {
  yield all([
    takeLatest(SET_BATCH_UPDATE_STAGE, sagas.performBatchUpdate),
    takeLatest(START_BATCH_UPDATE_POLLING, sagas.pollBatchUpdate),
  ]);
}

export { sagas };
export default reducer;
