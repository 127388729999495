import { useNovaApi } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';

export const useSongInstruments = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.SongInstrumentsResponse>(`/instrument`, {
    query,
  }).data;
};

export const useSongInstrumentSelect = () => {
  return (
    useNovaApi<Nl.Api.SongInstrumentsSelectResponse>('/instrument/select').data
      ?.instruments || []
  );
};
