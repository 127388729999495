import { get } from 'lodash';

export const buildFormTitle = (artistSubmission: Nl.Api.ArtistSubmission) => {
  const {
    first_name,
    last_name,
    artist_band_name,
    status,
    deleted_at,
  } = artistSubmission;
  const displayStatus = deleted_at ? 'Deleted' : status;
  if (artist_band_name) {
    return `${artist_band_name} (${displayStatus})`;
  }
  return `${first_name} ${last_name} (${displayStatus})`;
};

export const formatAsForm = (artistSubmission: Nl.Api.ArtistSubmission) => {
  return {
    ...artistSubmission,
    assigned_user: get(artistSubmission, 'assigned_user.uuid', ''),
    came_from: artistSubmission.came_from || '',
    email: artistSubmission.email || '',
    first_name: artistSubmission.first_name || '',
    hear_about_us: artistSubmission.hear_about_us || '',
    is_pro_free: artistSubmission.is_pro_member ? 'No' : 'Yes',
    last_name: artistSubmission.last_name || '',
    music_sounds_like: artistSubmission.music_sounds_like || '',
    source: artistSubmission.source || '',
    status: artistSubmission.status || '',
  };
};
