import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import { getEditFormInitialValues, createSongTheme } from 'store/songTheme';
import { selectNotificationStatus } from 'store/selectors';
import SongThemeForm from './SongThemeForm';
import validationSchema from 'utils/basicYupValidation';
import { useNlSelector } from 'utils/redux';

const CreateSongThemePage = () => {
  const dispatch = useDispatch();

  const initialValues = useNlSelector(getEditFormInitialValues);
  const notificationStatus = useNlSelector(selectNotificationStatus);

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={validationSchema(notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(createSongTheme({ formData, formActions }))
      }
      title='Create New Song Theme'
    >
      <SongThemeForm />
    </FormikWrapper>
  );
};

export default CreateSongThemePage;
