import React from 'react';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import { usePROSelect } from 'novaApi/hooks/performing_rights_organization';
import { useArtistEntities } from 'novaApi/hooks/artist';
import { usePROEarningsQuarters } from 'novaApi/hooks/pro_earning_quarter';

export default function PROEarningsFilters() {
  const pros = usePROSelect({ earnable_only: true });
  const quarters = usePROEarningsQuarters();
  const artists = useArtistEntities();
  return (
    <ConnectedTableFilters
      filters={[
        {
          type: 'single_select',
          label: 'Artist',
          queryFilterKey: 'artist_uuid',
          optionsSelector: () => artists,
          e2e: 'select_artist',
        },
        {
          type: 'single_select',
          label: 'PRO',
          queryFilterKey: 'pro_uuid',
          optionsSelector: () => pros,
          e2e: 'select_pro',
        },
        {
          type: 'single_select',
          label: 'Quarter',
          queryFilterKey: 'quarter',
          optionsSelector: () =>
            quarters.map(({ name }) => ({
              name,
              uuid: name,
            })),
          e2e: 'select_pro',
        },
      ]}
    />
  );
}
