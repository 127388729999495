import * as React from 'react';
import { isEmpty } from 'lodash';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Approve from 'components/icons/Approve';
import Decline from 'components/icons/Decline';
import { FormSubTitle } from 'components/Formik/utils';
import ActionsSourceFiles from './Actions/ActionsSourceFiles';
import ActionDownloadZips from './Actions/ActionDownloadZips';
import {
  getPopulatedFields,
  getSourceFileSegmentTypes,
} from './SongEditSourceFileData';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    actions: {
      display: 'inline-flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    table: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    type: {
      paddingRight: 0,
    },
    error: {
      // @ts-ignore
      color: theme.palette.error[400],
    },
    warning: {
      color: theme.customPalette.warning,
    },
  });

interface AllProps {
  songUuid: string;
  form: any;
  fields: Nl.Api.SourceFileUpload[];
  name: string;
  replace: (index: number, field: any) => void;
  classes: any;
}

const SongEditSourceFile: React.SFC<AllProps> = ({
  classes,
  songUuid,
  form,
  fields,
  name,
  replace,
}) => {
  const handleApproveByType = (type: string) => {
    fields.forEach((field, index) => {
      if (field.file_type === type || type === 'all') {
        replace(index, { ...field, status: 'approved' });
      }
    });
  };

  const handleDeclineByType = (type: string) => {
    fields.forEach((field, index) => {
      if (field.file_type === type || type === 'all') {
        replace(index, { ...field, status: 'declined' });
      }
    });
  };

  const handleRemoveByType = (type: string) => {
    if (type === 'all') {
      form.setFieldValue(name, []);
    } else {
      form.setFieldValue(
        name,
        fields.filter((field) => field.file_type !== type),
      );
    }
  };

  // List source files' types that are populated
  const sourceFilesExist = !isEmpty(getPopulatedFields(fields));
  const types: Nl.SongSourceFileType[] = getSourceFileSegmentTypes(fields);

  if (!sourceFilesExist) {
    return <FormSubTitle title='Source Files' />;
  }

  return (
    <div className={classes.table}>
      <div style={{ width: '30%' }}>
        <FormSubTitle title='Source Files' />
      </div>
      <div className={classes.actions}>
        <ActionsSourceFiles
          title='Approve'
          icon={<Approve />}
          onClickAction={handleApproveByType}
          types={types}
        />
        <ActionsSourceFiles
          title='Decline'
          icon={<Decline />}
          onClickAction={handleDeclineByType}
          types={types}
        />
        <ActionsSourceFiles
          title='Delete'
          icon={<DeleteIcon />}
          onClickAction={handleRemoveByType}
          types={types}
        />
        <ActionDownloadZips songUuid={songUuid} types={types} />
      </div>
    </div>
  );
};

export default withStyles(styles)(SongEditSourceFile);
