import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import { getEditFormInitialValues, editUser } from 'store/users';
import { selectNotificationStatus } from 'store/selectors';
import UserForm from './UserForm';
import validationSchema from './validation';
import { useNlSelector } from 'utils/redux';

export default function UserEditPage() {
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const user = useNlSelector((state) => state.users.data.user);
  const notificationStatus = useNlSelector(selectNotificationStatus);
  const dispatch = useDispatch();

  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues(user)}
      validationSchema={validationSchema(isPasswordEdit, notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(editUser({ formData, formActions }))
      }
      title='Edit User'
    >
      <UserForm
        isEdit
        isPasswordEdit={isPasswordEdit}
        setIsPasswordEdit={setIsPasswordEdit}
      />
      {user && (
        <CollapsableUserLogs activityType='users' resourceUuid={user.uuid} />
      )}
    </FormikWrapper>
  );
}
