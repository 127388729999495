import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';
/**
 * For the taxonomy instruments tag fields we want to validate 4 things:
 * - At least one instrument should be selected
 * - If instrument behavior selected then instrument should also have a value picked.
 * - Descriptive Tag "Type" must be selected
 * - Emotional Tag "Acoustic/Electric" must be selected
 *
 * On MyTrackEditForm we're hiding fields error message (helperText='') for instruments,
 * because when 'atLeastOneInstrumentRequired' rule is working and instruments are missing
 * it invalidates all instrument fields (highlight them red).
 * In case if messages are not hidden we see message 'At least one instrument is required'
 * under each instrument tag field. That might confuse users and they can think that every
 * instrument tag has to have at least 1 item. We left a message visible only for the last
 * field because it will let users think about invalid fields like about a group.
 * Also we provide an empty string for the second rule. In this case we can see
 * 'At least one instrument is required' when instrument not selected and do not see any error
 * message with rule pickInstrumentForBehavior, the instrument field will be highlighted with red
 *  */

export const getValidationSchema = (
  isReadyForDelivery = false,
  hasLyrics = false,
) => {
  const baseShape = {
    name: Yup.string().required(errorMessages.basicRequired),
  };
  if (!isReadyForDelivery) {
    return Yup.object().shape(baseShape);
  }
  let lyrics = Yup.string();
  if (hasLyrics) {
    lyrics = lyrics.required(errorMessages.basicRequired);
  }
  return Yup.object().shape({
    ...baseShape,
    description: Yup.string().required(errorMessages.basicRequired).nullable(),
    bpm: Yup.number()
      .integer()
      .positive()
      .typeError('BPM must be a number')
      .required(errorMessages.basicRequired),
    lyrics,
    taxonomy: Yup.object()
      .shape({
        ensemble: testInstrumentField(),
        nonstandard_instrument: testInstrumentField(),
        guitar_bass_instrument: testInstrumentField('guitar_bass_behavior'),
        brass_instrument: testInstrumentField('brass_behavior'),
        keyboard_instrument: testInstrumentField('keyboard_behavior'),
        percussion_instrument: testInstrumentField('percussion_behavior'),
        string_instrument: testInstrumentField('string_behavior'),
        synth_instrument: testInstrumentField('synth_behavior'),
        voice_instrument: testInstrumentField('voice_behavior'),
        woodwind_instrument: testInstrumentField('woodwind_behavior'),
        creative_inspiration: testExtramusicField(),
        production_use_case: testExtramusicField(),
        use_case: testExtramusicField(),
        type: Yup.array()
          .of(Yup.string())
          .required(errorMessages.basicRequired),
        acoustic_electric: Yup.string().required(errorMessages.basicRequired),
      })
      .default({}),
  });
};

const testInstrumentField = (instrumentBehavior = '') => {
  const atLeastFields = [
    'ensemble',
    'nonstandard_instrument',
    'guitar_bass_instrument',
    'brass_instrument',
    'keyboard_instrument',
    'percussion_instrument',
    'string_instrument',
    'synth_instrument',
    'voice_instrument',
    'woodwind_instrument',
  ];
  const errorMessage = 'At least one instrument is required';

  return (
    Yup.array()
      // check that at least one field from the list is filled
      .test(
        'atLeastOneInstrumentRequired',
        errorMessage,
        (value, testContext) => {
          return !!atLeastFields.find((f) => testContext.parent[f]?.length > 0);
        },
      )
      // check if dependent field is provided then parent field has to be filled
      .when(instrumentBehavior || [], {
        is: (val) => val && val.length > 0,
        then: Yup.array().min(1, errorMessages.basicRequired).required(' '), // no message
      })
  );
};

const testExtramusicField = () => {
  // optional field - use can leave it empty
  // if filled, user can choose up to 6 tags
  return Yup.array().test({
    message: 'At most 6 tags can be chosen',
    test: (arr) => !arr || arr.length <= 6,
  });
};
