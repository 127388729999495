import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';
import { passwordSchema } from 'pages/MyProfile/validation';

export const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.basicRequired)
      .nullable(),
    password: Yup.string().required(errorMessages.basicRequired).nullable(),
  });

export const forgotPasswordValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.basicRequired)
      .nullable(),
  });

export const resetPasswordValidationSchema = () =>
  Yup.object().shape({
    new_password: passwordSchema,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('new_password'), null], "Passwords don't match")
      .required('Confirm Password is required'),
  });
