import * as React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { useDispatch } from 'react-redux';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { TableConfigEntry } from 'utils/tableConfig';
import {
  selectPublishersPageData,
  selectPublishersLoadingStatus,
} from 'store/selectors';
import PublisherFilters from 'components/PublisherFilters/PublisherFilters';
import ConnectedButton from 'components/Button/ConnectedButton';
import { getReadableDate, truncateCountryIsos } from 'utils/utils';
import { sortBy } from 'lodash';
import EditButton from 'components/Button/EditButton';
import { useNlSelector } from 'utils/redux';

const useStyles = makeStyles(styles);

const formatCountry = (countries: Nl.Api.Country[]) => {
  const sortedCountries = sortBy(countries, ['name']);
  const title = sortedCountries.map((country) => country.name).join('\n');
  return <div title={title}>{truncateCountryIsos(sortedCountries)}</div>;
};

const EditPublisherButton = ({ uuid }: { uuid: string }) => {
  const dispatch = useDispatch();
  const isLoaded = useNlSelector(selectPublishersLoadingStatus);
  return (
    <EditButton
      aria-label='Edit'
      onClick={() => {
        dispatch({
          type: 'route/PUBLISHERS_EDIT',
          payload: { uuid },
        });
      }}
      disabled={!isLoaded}
    />
  );
};

const tableConfig: TableConfigEntry<Nl.Api.Publisher> = {
  storeKey: 'publishers',
  columnData: [
    {
      id: 'name',
      label: 'Name',
      isSortable: true,
      render: ({ name }) => <div className='ellipsis'>{name}</div>,
    },
    {
      id: 'pro',
      label: 'PRO',
      render: ({ performing_rights_organization: pro }) => pro?.name || '-',
    },
    {
      id: 'countries',
      label: 'Territories',
      render: ({ countries }) => formatCountry(countries),
    },
    {
      id: 'is_visible',
      label: 'Visible',
      render: ({ is_visible }) => (is_visible ? 'Yes' : 'No'),
    },
    {
      id: 'modified',
      label: 'Last Modified',
      render: ({ updated_at }) => getReadableDate(updated_at),
    },
    {
      id: 'edit',
      label: '',
      permission: 'CAN_UPDATE_PUBLISHERS',
      render: ({ uuid }) => <EditPublisherButton uuid={uuid} />,
    },
  ],
};

export default function PublishersPage() {
  const data = useNlSelector(selectPublishersPageData);
  const isLoaded = useNlSelector(selectPublishersLoadingStatus);
  const classes = useStyles({});

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Publishers'
        search={<PublisherFilters />}
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/PUBLISHERS_CREATE'
            label='Create new Publisher'
            permission='CAN_CREATE_PUBLISHERS'
          />
        }
      />
      <Table config={tableConfig} data={data} isLoaded={isLoaded} />
    </Paper>
  );
}
