import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { styles } from 'components/Table/Table.styles';
import { ResponsivePaper } from 'components/ResponsiveLayout';
import Table from 'components/Table/Table';
import {
  selectIsLoaded,
  selectMyWriters,
  selectMyProfile,
} from 'store/selectors';
import TableToolbar from 'components/TableToolbar';
import { TableConfigEntry } from 'utils/tableConfig';
import MyWritersInfoBox from './MyWritersInfoBox';
import { useNlSelector } from 'utils/redux';
import ConnectedButton from 'components/Button/ConnectedButton';
import { getReadableDate } from 'utils/utils';
import ChipMeter from 'components/Chip/ChipMeter';

const useStyles = makeStyles(styles);

const config: TableConfigEntry<Nl.Api.Writer> = {
  storeKey: 'myWriters',
  columnData: [
    { id: 'full_name', label: 'Name' },
    {
      id: 'is_default',
      label: '',
      render: ({ is_default }) =>
        is_default && <ChipMeter label='Default' indicator='high' />,
    },
    {
      id: 'ipi_number',
      label: 'IPI Number',
      render: ({ ipi_number }) => ipi_number || '-',
    },
    {
      id: 'pro',
      label: 'PRO',
      render: ({ performing_rights_organization: pro }) => pro?.name || '-',
    },
    {
      id: 'created_at',
      label: 'Created at',
      render: ({ created_at }) => getReadableDate(created_at),
    },
  ],
};

export default function MyWritersPage() {
  const data = useNlSelector(selectMyWriters);
  const myProfile = useNlSelector(selectMyProfile);
  const isLoaded = useNlSelector((state) => selectIsLoaded(state, 'myWriters'));

  const classes = useStyles({});
  return (
    <ResponsivePaper elevation={1} className={classes.root}>
      <TableToolbar
        title='My Writers'
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/MY_WRITER_CREATE'
            label='Create new writer'
            permission='CAN_ADD_WRITERS_TO_ARTIST'
          />
        }
      />
      <MyWritersInfoBox
        artistRepEmail={myProfile?.assigned_to.email || '-'}
        writerCount={data.length}
      />
      <Table {...{ config, data, isLoaded }} />
    </ResponsivePaper>
  );
}
