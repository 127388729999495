import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import {
  SimpleActionType,
  createTypedAction,
  assign,
} from '../utils/storeUtils';
import {
  apiFetch,
  FetchResponse,
  getRawUrlQueryParams,
  getUrlQueryParams,
} from '../novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { isNil } from 'lodash';
import { awaitAsyncResult } from './asyncResult';

const FETCH_PRO_SONG_REGS = 'FETCH_PRO_SONG_REGS';
const FETCH_PRO_SONG_REGS_SUCCESS = 'FETCH_PRO_SONG_REGS_SUCCESS';
const CREATE_PRO_SONG_REG_EXPORT = 'CREATE_PRO_SONG_REG_EXPORT';
const FETCH_EXPORTABLE_PROS_SELECT = 'FETCH_EXPORTABLE_PROS_SELECT';
const FETCH_EXPORTABLE_PROS_SELECT_SUCCESS =
  'FETCH_EXPORTABLE_PROS_SELECT_SUCCESS';

const initialState = {
  data: {
    reports: [],
    total_size: 0,
    current_size: 0,
    current_page: 0,
    offset: 0,
    total_pages: 0,
  } as Nl.Api.PROReportsResponse,
  proSelect: [] as Nl.Api.SelectorEntity[],
  isExportable: false as boolean,
  isLoading: false as boolean,
  isLoaded: false as boolean,
};

type InitialStateType = typeof initialState;
export type PROSongRegistrationsState = Readonly<InitialStateType>;
type FetchPROReportsSuccessPayload = Pick<
  InitialStateType,
  'data' | 'isExportable'
>;
type PROSelectPayload = Pick<InitialStateType, 'proSelect'>;
type FetchPROReportResponse = FetchResponse<{ report: Nl.Api.PROReport }>;
type FetchPROReportsReponse = FetchResponse<Nl.Api.PROReportsResponse>;
type FetchPROSelectResponse = FetchResponse<{
  performing_rights_organizations: Nl.Api.SelectorEntity[];
}>;
const baseUrl = '/performing_rights_organization';

export const fetchPROSongRegistrationExports = () =>
  createTypedAction(FETCH_PRO_SONG_REGS);

const fetchPROSongRegistrationExportsSuccess = (
  payload: FetchPROReportsSuccessPayload,
) => createTypedAction(FETCH_PRO_SONG_REGS_SUCCESS, payload);

export const createPRORegistrationExport = () =>
  createTypedAction(CREATE_PRO_SONG_REG_EXPORT);

export const fetchExportablePROsSelect = () =>
  createTypedAction(FETCH_EXPORTABLE_PROS_SELECT);

const fetchExportablePROsSelectSuccess = (payload: PROSelectPayload) =>
  createTypedAction(FETCH_EXPORTABLE_PROS_SELECT_SUCCESS, payload);

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_PRO_SONG_REGS_SUCCESS: {
      return assign(state, {
        ...action.payload,
        isLoaded: true,
        isLoading: false,
      });
    }
    case FETCH_EXPORTABLE_PROS_SELECT_SUCCESS: {
      const { proSelect } = action.payload;
      return assign(state, { proSelect });
    }
    default:
      return state;
  }
};

const sagas = {
  *createPROSongRegistrationExport() {
    const body: ReturnType<typeof getRawUrlQueryParams> = yield select(
      getRawUrlQueryParams,
    );
    const { success, msg, data }: FetchPROReportResponse = yield call(
      apiFetch,
      `${baseUrl}/report`,
      {
        method: 'POST',
        body,
      },
    );
    if (success) {
      yield put(
        addSuccessNotification({ message: 'PRO Export is being generated' }),
      );
      yield put(
        awaitAsyncResult({
          task_id: data.report.task_id,
          callback: fetchPROSongRegistrationExports,
        }),
      );
    } else {
      yield put(addErrorNotification({ message: msg }));
    }
  },
  *fetchPROSongRegistrationExports() {
    const queryParams: string = yield select(getUrlQueryParams);
    const { success, msg, data }: FetchPROReportsReponse = yield call(
      apiFetch,
      `${baseUrl}/report${queryParams}`,
    );
    if (success) {
      const rawQueryParams: ReturnType<
        typeof getRawUrlQueryParams
      > = yield select(getRawUrlQueryParams);
      const isExportable = !isNil(rawQueryParams.pro_uuid);
      yield put(fetchPROSongRegistrationExportsSuccess({ data, isExportable }));
    } else {
      yield put(addErrorNotification({ message: msg }));
    }
  },
  *fetchExportablePROsSelect() {
    const queryParams = '?exportable_only=true'; // We only need exportable PROs in this context
    const { success, msg, data }: FetchPROSelectResponse = yield call(
      apiFetch,
      `${baseUrl}/select${queryParams}`,
    );
    if (success) {
      const proSelect = data.performing_rights_organizations;
      yield put(fetchExportablePROsSelectSuccess({ proSelect }));
    } else {
      yield put(addErrorNotification({ message: msg }));
    }
  },
};

export function* rootSaga() {
  yield all([
    takeLatest(FETCH_PRO_SONG_REGS, sagas.fetchPROSongRegistrationExports),
    takeLatest(
      CREATE_PRO_SONG_REG_EXPORT,
      sagas.createPROSongRegistrationExport,
    ),
    takeLatest(FETCH_EXPORTABLE_PROS_SELECT, sagas.fetchExportablePROsSelect),
  ]);
}

export { sagas };
export default reducer;
