import config from 'config';
import { Dispatch } from './storeUtils';
import { StateGetter, Bag, Action } from 'redux-first-router';

interface GoogleAnalyticsPayload {
  custom_map: Record<string, any>;
  linker: Record<string, any>;
  page_path: string;
  user_id?: string;
}

export const afterRouteChangeHandler = (
  dispatch: Dispatch,
  getState: StateGetter,
  { action }: Bag,
) => {
  const { domains } = config.ga;
  const gaId = config.ga.id;
  const gaNoLoaded = !window.ga;
  const isDisabled = config.ga.disable;
  const page_path: string =
    (action as Action)?.meta?.location?.current?.pathname ?? '/';

  // TODO / Q Sometimes this can be in an isLoading state - not a _huge_ deal, since adding the ID even later
  // should associate the actions in the session - but would be preferable if we could wait till the loading is done?
  const authedUser: Nl.Api.AuthUser = getState().auth || {};
  const user_role =
    authedUser?.user_role?.name ?? ('' as Nl.Api.UserRoleType | '');

  const payload: GoogleAnalyticsPayload = {
    page_path,
    custom_map: {
      dimension1: 'user_role',
    },
    linker: {
      domains,
    },
  };

  if (isDisabled || gaNoLoaded) {
    return;
  }

  if (authedUser.uuid) {
    payload.user_id = authedUser.uuid;
  }

  window.gtag('config', gaId, payload);

  if (user_role) {
    window.gtag('event', 'user_role_dimension', { user_role });
  }
};
