import React from 'react';
import { makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import { TableConfigEntry } from 'utils/tableConfig';
import { ResponsivePaper } from 'components/ResponsiveLayout';
import { getReadableDate } from 'utils/utils';
import Table from 'components/Table/Table';
import InternalLink from 'components/Link/InternalLink';
import { useMySongRevisions } from 'novaApi/hooks/my_song_revisions';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(styles);

const config: TableConfigEntry<Nl.Api.SongRevisionListItem> = {
  columnData: [
    {
      id: 'name',
      label: 'Song Name',
      isSortable: false,
      cellProps: { width: '25%' },
      render: ({ uuid, work_title }) => (
        <InternalLink
          color='inherit'
          to={{
            type: 'route/REVISIONS_REQUESTED_EDIT',
            payload: { uuid },
          }}
        >
          {work_title}
        </InternalLink>
      ),
    },
    {
      id: 'revision_note',
      label: 'Revision Note',
      isSortable: false,
      cellProps: { width: '65%' },
      render: ({ revision_note }) => (
        <TextField
          value={revision_note}
          multiline
          rowsMax={4}
          fullWidth
          InputProps={{ disableUnderline: true, readOnly: true }}
        />
      ),
    },
    {
      id: 'revision_requested_at',
      label: 'Requested On',
      isSortable: false,
      cellProps: { width: '10%' },
      render: ({ revision_requested_at }) =>
        getReadableDate(revision_requested_at),
    },
  ],
};

const SongRevisionsPage = () => {
  const classes = useStyles({});
  const { data, isLoaded } = useMySongRevisions();
  if (!isLoaded) return null;
  return (
    <ResponsivePaper elevation={1} className={classes.root}>
      <TableToolbar title='Revisions Requested' />
      <Table
        config={config}
        isLoaded={!!data}
        data={data?.song_revisions}
        paginationData={data}
      />
    </ResponsivePaper>
  );
};

export default SongRevisionsPage;
