import React from 'react';
import { makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import Table from 'components/Table/Table';
import TableToolbar from 'components/TableToolbar';
import { TableConfigEntry } from 'utils/tableConfig';
import { ResponsivePaper } from 'components/ResponsiveLayout';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import { MY_TRACK_OPTIONS } from 'constants/staticData';
import ConnectedButton from 'components/Button/ConnectedButton';
import InternalLink from 'components/Link/InternalLink';
import { getReadableDate } from 'utils/utils';
import MyTracksActions from 'pages/MyTracks/MyTracksActions';
import { useTrackSubmissions } from 'novaApi/hooks/new_track_submission';

const useStyles = makeStyles(styles);

export const config: TableConfigEntry<Nl.Api.TrackSubmission> = {
  columnData: [
    {
      id: 'name',
      label: 'Track Name',
      isSortable: true,
      render: (obj) => (
        <InternalLink
          color='inherit'
          to={{ type: 'route/MY_TRACK_EDIT_PAGE', payload: { uuid: obj.uuid } }}
        >
          {obj.name}
        </InternalLink>
      ),
    },
    { id: 'status', label: 'Status' },
    {
      id: 'updated_at',
      label: 'Updated At',
      isSortable: true,
      render: (obj) => getReadableDate(obj.updated_at),
    },
    {
      id: 'actions',
      label: 'Actions',
      cellProps: { style: { width: '250px' } },
      render: (obj) => <MyTracksActions track={obj} />,
    },
  ],
};

const MyTracksPage = () => {
  const classes = useStyles({});

  const data = useTrackSubmissions();

  return (
    <ResponsivePaper elevation={1} className={classes.root}>
      <TableToolbar
        title='My Tracks'
        filters={
          <ConnectedTableFilters
            filters={[
              {
                type: 'single_select',
                label: 'Status',
                queryFilterKey: 'status',
                e2e: 'status',
                optionsSelector: () => MY_TRACK_OPTIONS,
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/MY_TRACK_CREATE_PAGE'
            label='Create new track submission'
            permission='CAN_CREATE_NEW_TRACK_SUBMISSIONS'
          />
        }
      />
      {data?.new_track_submissions?.length === 0 && data !== undefined && (
        <div className={classes.infoText} style={{ float: 'right' }}>
          Click + above to create your first track submission
        </div>
      )}
      <Table
        config={config}
        isLoaded={!!data}
        data={data?.new_track_submissions ?? []}
        paginationData={data}
      />
    </ResponsivePaper>
  );
};

export default MyTracksPage;
