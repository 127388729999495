import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { useNlSelector } from 'utils/redux';
import { useNovaApi } from '../useNovaApi';

export const usePROEarningStatement = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.PROEarningsStatementsResponse>(
    '/pro_earnings_statement',
    { query },
  );
};
