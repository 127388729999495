import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Paper } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper/Paper';

const MOBILE_SCREEN_SIZE = 'max-width:799px';
const DESKTOP_SCREEN_SIZE = 'min-width:800px';

export const useGetScreenSize = () => {
  const isMobile = Boolean(useMediaQuery(`(${MOBILE_SCREEN_SIZE})`));
  const isDesktop = Boolean(useMediaQuery(`(${DESKTOP_SCREEN_SIZE})`));
  return { isMobile, isDesktop };
};

export const DesktopWrapper = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = Boolean(useMediaQuery(`(${DESKTOP_SCREEN_SIZE})`));
  return isDesktop ? <div>{children}</div> : null;
};

export const MobileWrapper = ({ children }: { children: React.ReactNode }) => {
  const isMobile = Boolean(useMediaQuery(`(${MOBILE_SCREEN_SIZE})`));
  return isMobile ? <div>{children}</div> : null;
};

export const ResponsivePaper = ({ children, ...props }: PaperProps) => {
  const { isDesktop } = useGetScreenSize();
  return isDesktop ? <Paper {...props}>{children}</Paper> : <>{children}</>;
};
