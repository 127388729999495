import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';
import moment from 'moment';

const timeFormat = 'H:mm:ss';

export const getValidationSchema = () =>
  Yup.object()
    .shape({
      name: Yup.string().required(errorMessages.basicRequired),
      start_point: Yup.string().test(
        'start_time_test',
        errorMessages.timeFormatRequired,
        (value) => (value ? moment(value, timeFormat, true).isValid() : true),
      ),
      end_point: Yup.string().test(
        'end_time_test',
        errorMessages.timeFormatRequired,
        (value) => (value ? moment(value, timeFormat, true).isValid() : true),
      ),
    })
    .test(
      'end_time_past_start_time',
      ({ start_point, end_point }, { createError }) => {
        if (!start_point || !end_point) return true;
        const start = moment(start_point, timeFormat, true);
        const end = moment(end_point, timeFormat, true);
        if (end.diff(start) > 0) return true;
        return createError({
          path: 'end_point',
          message: errorMessages.endPointMustBePastStartPoint,
        });
      },
    );
