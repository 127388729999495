import * as React from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core';

const styles = createStyles({
  root: {},
  header: {
    padding: 0,
  },
  item: {
    padding: 0,
  },
  pro: {
    display: 'inline-block',
  },
});

interface OwnProps {
  publishers: Nl.Api.Publisher[];
}

type AllProps = OwnProps & WithStyles<typeof styles>;

const SongEditPublisher: React.SFC<AllProps> = ({ classes, publishers }) => (
  <div className={classes.root}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>PRO</TableCell>
          <TableCell>Territories</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {publishers.map((publisher) => (
          <TableRow key={publisher.name}>
            <TableCell>{publisher.name}</TableCell>
            <TableCell>
              {publisher.performing_rights_organization.name}
            </TableCell>
            <TableCell>
              {publisher.countries.map((c) => c.name).join(', ')}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

export default withStyles(styles)(SongEditPublisher);
