import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import { Box, Link } from '@material-ui/core';

interface AllProps {
  label: string;
  href: string;
  newTab?: boolean;
}

const MoreInfoLabelLink: React.FunctionComponent<AllProps> = ({
  label,
  href,
  newTab = true,
}: AllProps) => (
  <Box style={{ display: 'flex', alignItems: 'center', height: 14 }}>
    {label}
    <Link
      href={href}
      target={newTab ? '_blank' : undefined}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <HelpIcon style={{ marginLeft: 5 }} fontSize='small' color='secondary' />
    </Link>
  </Box>
);

export default MoreInfoLabelLink;
