import React from 'react';
import { useDispatch } from 'react-redux';
import {
  selectIsLoaded,
  selectTrackSubmissionSourceFile,
  selectSingleTrackSubmission,
} from 'store/selectors';
import FormikWrapper from 'components/Formik';
import NotesWrapper from 'components/Notes/NotesWrapper';
import ArtistIndications from 'pages/Artists/ArtistIndications';
import TrackSubmissonsTable from './TrackSubmissionsTable';
import TrackSubmissionForm from './TrackSubmissionForm';
import TrackSubmissionsActions from './TrackSubmissionsActions';
import { editTrackSubmissionSchema } from './validation';
import { editTrackSubmission } from 'store/singleTrackSubmission';
import { useNlSelector } from 'utils/redux';
import { get } from 'lodash';

const trackSubmissionInitialValues = (
  trackSubmission: Nl.Api.SingleTrackSubmission,
) => ({
  artist_name: get(trackSubmission, 'artist.name', ''),
  is_royalty: get(trackSubmission, 'artist.is_royalty', false),
  is_pro_free: get(trackSubmission, 'artist.is_pro_free', false),
  ...trackSubmission,
});

const TrackSubmissionEditPage = () => {
  const dispatch = useDispatch();

  const trackSubmission = useNlSelector(
    selectSingleTrackSubmission,
  ) as Nl.Api.SingleTrackSubmission;
  const sourceFile = useNlSelector(selectTrackSubmissionSourceFile);
  const isLoaded = useNlSelector((state) =>
    selectIsLoaded(state, 'singleTrackSubmission'),
  );

  const initialValues = trackSubmissionInitialValues(trackSubmission);
  const {
    name,
    status,
    artist_name,
    is_pro_free,
    is_royalty,
    music_team_can_edit_name,
  } = initialValues;
  const title = name && `${name} (${status}) by ${artist_name} `;
  const parentType: Nl.ParentNoteType = 'new_track_submission';
  if (!isLoaded) {
    return null;
  }
  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={editTrackSubmissionSchema}
      submitForm={(formData, formActions) =>
        dispatch(editTrackSubmission({ formData, formActions }))
      }
      title={
        <>
          <>{title}</>
          <ArtistIndications isPROFree={is_pro_free} isRoyalty={is_royalty} />
        </>
      }
      customSize='xl'
      extraActions={
        <TrackSubmissionsActions track={trackSubmission} disablePlayBtn />
      }
    >
      <TrackSubmissonsTable sourceFiles={[sourceFile!]} />
      <br />
      <TrackSubmissionForm isNameEditable={!!music_team_can_edit_name} />
      <NotesWrapper config={parentType} uuid={trackSubmission.uuid} />
    </FormikWrapper>
  );
};

export default TrackSubmissionEditPage;
