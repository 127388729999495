import { put, call, takeLatest, all } from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from 'utils/storeUtils';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification } from './notifications';

// Action Constants
const FETCH_PUBLISHER_RULESET_ENTITIES = 'FETCH_PUBLISHER_RULESET_ENTITIES';
const FETCH_PUBLISHER_RULESET_ENTITIES_SUCCESS =
  'FETCH_PUBLISHER_RULESET_ENTITIES_SUCCESS';
const FETCH_PUBLISHER_RULESET_ENTITIES_ERROR =
  'FETCH_PUBLISHER_RULESET_ENTITIES_ERROR';

// Action Creators
export const fetchPublisherRulesetEntities = createAction(
  FETCH_PUBLISHER_RULESET_ENTITIES,
);
const fetchPublisherRulesetEntitiesSuccess = createAction(
  FETCH_PUBLISHER_RULESET_ENTITIES_SUCCESS,
);
const fetchPublisherRulesetEntitiesError = createAction(
  FETCH_PUBLISHER_RULESET_ENTITIES_ERROR,
);

// Reducer
const initialState = {
  entities: [] as Nl.Api.SelectorEntity[],
};

export type PublisherRulesetState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_PUBLISHER_RULESET_ENTITIES_SUCCESS: {
      return assign(state, {
        entities: action.payload.data.publisher_rulesets,
      });
    }
    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchPublisherRulesetEntitiesSaga() {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/publisher_ruleset/select',
    );
    if (results.success) {
      yield put(fetchPublisherRulesetEntitiesSuccess({ data: results.data }));
    } else {
      yield put(fetchPublisherRulesetEntitiesError());
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(
      FETCH_PUBLISHER_RULESET_ENTITIES,
      sagas.fetchPublisherRulesetEntitiesSaga,
    ),
  ]);
}

export { sagas };
export default reducer;
