import React from 'react';
import { useNlSelector } from 'utils/redux';

import { getUrlPayloadParams } from 'novaApi/apiUtils';
import FormikWrapper from 'components/Formik';
import TagRelationTypeForm, {
  getFormInitialValues,
} from './TagRelationTypeForm';
import { getValidationSchema } from './validation';
import {
  useTagRelationType,
  useTagRelationTypeUpdate,
} from 'novaApi/hooks/tag_relation_type';
import { useDispatch } from 'react-redux';
import { goPreviousPage } from 'store/router';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';

export default function TagRelationTypesEditPage() {
  const dispatch = useDispatch();
  const { uuid } = useNlSelector(getUrlPayloadParams);
  const { data } = useTagRelationType(uuid!);
  const tagRelationTypeToEdit = getFormInitialValues(data?.tag_relation_type);
  const {
    updateTagRelationType,
    isSuccess,
    updatedTagRelationType,
  } = useTagRelationTypeUpdate(uuid!);

  const handleFormSubmit = (formData, formActions) => {
    updateTagRelationType({ formData, formActions });
  };

  if (!data) return null;
  if (updatedTagRelationType && isSuccess) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={tagRelationTypeToEdit}
      validationSchema={getValidationSchema()}
      submitForm={(formData, formActions) =>
        handleFormSubmit(formData, formActions)
      }
      title='Edit tag relation type'
      customSize='sm'
    >
      <TagRelationTypeForm />

      {uuid && (
        <CollapsableUserLogs
          activityType='tag-relation-types'
          resourceUuid={uuid}
        />
      )}
    </FormikWrapper>
  );
}
