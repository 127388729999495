import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Icon } from '@material-ui/core';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { selectors as authSelectors } from 'store/auth';
import { selectors as headerSelectors } from 'store/header';
import { GlobalState } from 'store/rootReducer';
import MusicNotes from 'components/icons/MusicNotes';
import Header from './Header';

const tabData: Nl.HeaderTopLevelTab[] = [
  {
    e2e: 'submissions_menu',
    section: 'submissions',
    label: 'Submissions',
    icon: <MoveToInboxIcon />,
    menu: [
      {
        e2e: 'track_submissions',
        label: 'Track Submissions',
        route: 'route/TRACK_SUBMISSIONS',
        // to see this item, user must not be an artist
        // using an NTS curation permission does the job
        permission: [
          'CAN_GET_NEW_TRACK_SUBMISSIONS',
          'CAN_APPROVE_NEW_TRACK_SUBMISSIONS',
        ],
      },
      {
        e2e: 'artist_submissions',
        label: 'Artist Submissions',
        route: 'route/ARTIST_SUBMISSIONS',
        permission: 'CAN_GET_NEW_ARTIST_SUBMISSIONS',
      },
    ],
  },
  {
    e2e: 'song_menu',
    section: 'song',
    label: 'Songs',
    icon: <Icon className='tabIcon'>music_note</Icon>,
    menu: [
      {
        e2e: 'song',
        label: 'Songs',
        route: 'route/SONGS',
        permission: ['CAN_GET_SONGS', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'song_finder',
        label: 'Song Finder',
        route: 'route/SONG_FINDER',
        permission: ['CAN_GET_SONGS', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'song_collections',
        label: 'Song Collections',
        route: 'route/SONG_COLLECTIONS',
        permission: ['CAN_GET_SONG_COLLECTIONS', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'song_genres',
        label: 'Genres',
        route: 'route/SONG_GENRES',
        permission: ['CAN_GET_SONG_GENRES', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'song_themes',
        label: 'Themes',
        route: 'route/SONG_THEMES',
        permission: ['CAN_GET_SONG_THEMES', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'song_instruments',
        label: 'Instruments',
        route: 'route/SONG_INSTRUMENTS',
        permission: ['CAN_GET_SONG_INSTRUMENTS', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
    ],
  },
  {
    e2e: 'taxonomy_menu',
    section: 'taxonomy',
    label: 'Taxonomy',
    icon: <LocalOfferIcon />,
    menu: [
      {
        e2e: 'tag_categories',
        label: 'Tag Categories',
        route: 'route/TAG_CATEGORIES',
        permission: ['CAN_GET_TAG_CATEGORY_LIST', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'tags',
        label: 'Tags',
        route: 'route/TAGS',
        permission: ['CAN_GET_TAG_LIST', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'terms',
        label: 'Terms',
        route: 'route/TERMS',
        permission: ['CAN_GET_TERM_LIST', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'comparisons',
        label: 'Comparisons',
        route: 'route/COMPARISONS',
        permission: ['CAN_GET_COMPARISON_LIST', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'examples',
        label: 'Examples',
        route: 'route/EXAMPLES',
        permission: ['CAN_GET_EXAMPLE_LIST', 'CAN_LOGIN_TO_NOVALIBRARY'],
      },
      {
        e2e: 'tag_relation_types',
        label: 'Tag Relation Types',
        route: 'route/TAG_RELATION_TYPES',
        permission: [
          'CAN_GET_TAG_RELATION_TYPE_LIST',
          'CAN_LOGIN_TO_NOVALIBRARY',
        ],
      },
      {
        e2e: 'tag_term_relation_types',
        label: 'Tag-Term Relation Types',
        route: 'route/TAG_TERM_RELATION_TYPES',
        permission: [
          'CAN_GET_TAG_TERM_RELATION_TYPE_LIST',
          'CAN_LOGIN_TO_NOVALIBRARY',
        ],
      },
    ],
  },
  {
    e2e: 'artist_menu',
    section: 'artists',
    label: 'Artists',
    icon: <Icon className='tabIcon'>brush</Icon>,
    route: 'route/ARTISTS',
    permission: 'CAN_GET_ARTISTS',
  },
  {
    e2e: 'writer_menu',
    section: 'writers',
    label: 'Writers',
    route: 'route/WRITERS',
    permission: ['CAN_LOGIN_TO_NOVALIBRARY', 'CAN_GET_WRITERS'],
    icon: <Icon className='tabIcon'>edit</Icon>,
  },
  {
    e2e: 'performing_rights_organization_menu',
    section: 'pros',
    label: 'PROs',
    icon: <Icon className='tabIcon'>copyright</Icon>,
    menu: [
      {
        e2e: 'performing_rights_organization',
        label: 'Manage PROs',
        route: 'route/PROS',
        permission: [
          'CAN_LOGIN_TO_NOVALIBRARY',
          'CAN_GET_PERFORMING_RIGHTS_ORGANIZATIONS',
        ],
      },
      {
        e2e: 'performing_rights_organization_song_registrations',
        label: 'Song Registrations',
        route: 'route/PROS_SONG_REGISTRATIONS',
        permission: ['CAN_GET_PRO_REPORT'],
      },
      {
        e2e: 'performing_rights_organization_earnings',
        label: 'PRO Earnings',
        route: 'route/PROS_EARNINGS',
        permission: ['CAN_LOGIN_TO_NOVALIBRARY', 'CAN_GET_PRO_EARNINGS'],
      },
    ],
  },
  {
    e2e: 'publisher_menu',
    section: 'publishers',
    label: 'Publishers',
    icon: <Icon className='tabIcon'>language</Icon>,
    permission: 'CAN_GET_PUBLISHERS',
    route: 'route/PUBLISHERS',
  },
  {
    e2e: 'users_menu',
    section: 'users',
    label: 'Users',
    permission: 'CAN_GET_USERS',
    icon: <Icon className='tabIcon'>person</Icon>,
    menu: [
      {
        e2e: 'users',
        label: 'Manage Users',
        route: 'route/USERS',
        permission: 'CAN_GET_USERS',
      },
      {
        e2e: 'user_activity',
        label: 'User Activity',
        route: 'route/USER_ACTIVITY',
        permission: 'CAN_GET_USERS',
      },
    ],
  },
  {
    e2e: 'my_tracks',
    section: 'my_submissions',
    label: 'Tracks',
    icon: <MusicNotes />,
    permission: ['CAN_GET_MY_NEW_TRACK_SUBMISSIONS'],
    route: 'route/MY_TRACKS',
  },
  {
    e2e: 'my_writers',
    section: 'my_writers',
    label: 'Writers',
    icon: <Icon className='tabIcon'>edit</Icon>,
    permission: ['CAN_GET_MY_WRITERS'],
    route: 'route/MY_WRITERS',
  },
  {
    e2e: 'dashboard',
    section: 'dashboard',
    label: 'Dashboard',
    icon: <Icon className='tabIcon'>dashboard</Icon>,
    permission: ['CAN_LOGIN_TO_ARTIST_PORTAL', 'CAN_GET_MY_SONG_PERFORMANCE'],
    menu: [
      /*
        Hiding until further notice MIT-3791
      */
      // {
      //   e2e: 'sales_per_track',
      //   label: 'Sales Performance Per Track',
      //   route: 'route/DASHBOARD_SALES_PER_TRACK',
      //   permission: 'CAN_GET_MY_SONG_PERFORMANCE',
      // },
      {
        e2e: 'sales_per_track',
        label: 'Sales Reports',
        route: 'route/DASHBOARD_SALES_REPORTS',
        permission: 'CAN_GET_MY_EARNINGS_SUMMARY',
      },
    ],
  },
  {
    e2e: 'report',
    section: 'report',
    label: 'Reports',
    route: 'route/REPORTS',
    permission: ['CAN_GET_REPORTS'],
    icon: <Icon className='tabIcon'>library_books</Icon>,
  },
  {
    e2e: 'song_revisions',
    section: 'song_revisions',
    label: 'Revisions',
    route: 'route/REVISIONS_REQUESTED',
    permission: ['CAN_GET_MY_SONG_REVISIONS'],
    icon: <Icon className='tabIcon'>warning_amber</Icon>,
  },
  {
    e2e: 'my_profile_menu',
    section: 'my_profile',
    label: 'Profile',
    icon: <Icon className='tabIcon'>person</Icon>,
    menu: [
      {
        e2e: 'update_my_profile',
        label: 'Update my profile',
        route: 'route/MY_PROFILE_EDIT',
        permission: ['CAN_UPDATE_ARTIST_PROFILE'],
      },
      {
        e2e: 'change_password',
        label: 'Change my password',
        route: 'route/CHANGE_MY_PASSWORD',
        permission: ['CAN_RESET_PASSWORD'],
      },
    ],
  },
];

export default compose(
  connect(
    (state: GlobalState) => {
      const {
        location: { routesMap, type },
      } = state;
      const route = routesMap[type] as Nl.RouteMapProps;
      const { section } = route;
      return {
        tabData,
        activeSection: section,
        isLoggedIn: authSelectors.isLoggedIn(state),
        isLoading: authSelectors.isLoading(state),
        userPermissions: authSelectors.getUserPermissions(state),
        isDrawerOpen: headerSelectors.isDrawerOpen(state),
      };
    },
    (dispatch) => ({
      onTabMenuChange: (type: Nl.RouteMapType) => {
        dispatch({ type });
      },
    }),
  ),
)(Header);
