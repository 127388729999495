import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { CssBaseline, Theme } from '@material-ui/core';
import ConnectedHeader from 'components/Header/ConnectedHeader';
import PageSwitcher from 'components/PageSwitcher';
import ModalSwitcher from 'components/ModalSwitcher';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import Notification from 'components/Notification/Notification';
import LoadingTopBar from 'components/LoadingTopBar';
import './App.css';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
    },
    main: {
      flexGrow: 1,
      maxWidth: theme.layout.maxWidth,
      margin: '0 auto',
      padding: '0',
      [theme.breakpoints.up('sm')]: {
        padding: `0 ${theme.spacing(6)}px`,
      },
      marginBottom: `${theme.spacing(18)}px`,
    },
    toolbar: theme.mixins.toolbar,
  });
};

interface OwnProps {
  classes?: any;
  children?: React.Component;
}

const App = ({ children, classes }: OwnProps) => (
  <div className={classes.root}>
    <CssBaseline />
    <LoadingTopBar />
    <ConnectedHeader toggleDrawer={() => {}} />
    <main className={classes.main}>
      <div className={classes.toolbar} />
      <PageSwitcher />
      <ModalSwitcher />
      {children}
    </main>
    <AudioPlayer />
    <Notification />
  </div>
);

export default withStyles(styles)(App);
