import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import ArtistForm, { getEditFormInitialValues } from './ArtistForm';
import validationSchema from './validation';
import { createArtist } from 'store/artists';

export default function ArtistCreatePage() {
  const dispatch = useDispatch();
  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues()}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) => {
        dispatch(createArtist({ formData, formActions }));
      }}
      title='Create Artist'
    >
      <ArtistForm />
    </FormikWrapper>
  );
}
