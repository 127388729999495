import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, getIn } from 'formik';
import classnames from 'classnames';
import {
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  FormHelperText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InputField from 'components/Formik/InputField';
import SelectField from 'components/Form/AutocompleteSelect';
import { GlobalState } from 'store/rootReducer';
import DeleteButton from 'components/Button/DeleteButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { get, find, isArray, isEmpty } from 'lodash';
import { selectMyWriters, selectMyWritersIsLoaded } from 'store/selectors';
import CircularLoading from 'components/CircularLoading/CircularLoading';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  toolbar: {
    paddingLeft: 0,
  },
  selectCell: {
    overflow: 'inherit',
    paddingLeft: theme.spacing(1),
  },
  nameCell: {
    width: '40%',
  },
  header: {
    padding: 0,
  },
  table: {
    width: '100%',
  },
  share: {
    width: theme.spacing(24),
  },
  warnText: {
    border: '1px solid #ff5770',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 80, 112, 0.2)',
    padding: '2rem',
  },
}));

interface AllProps {
  writerOptions: Nl.Api.Writer[];
  myWritersIsLoaded: boolean;
  form: any;
  name: string;
  remove: (index: number) => void;
  push: (object: Record<string, any>) => void;
}

const MyTrackEditWriter: React.FunctionComponent<AllProps> = ({
  writerOptions,
  myWritersIsLoaded,
  form,
  name,
  remove,
  push,
}) => {
  const classes = useStyles({});
  const fields: any[] = getIn(form.values, name);

  const removeRowErrors = (index: number) => {
    const { errors } = form;
    if (get(form, `errors.share_data[${index}]`, false)) {
      form.errors.share_data[index] = [];
    }
    form.setErrors({ errors });
  };

  const { setFieldValue } = form;
  useEffect(() => {
    for (let index = 0; index < fields.length; index += 1) {
      const newSharePercentage =
        index === 0
          ? Math.floor(100 / fields.length) + (100 % fields.length)
          : Math.floor(100 / fields.length);
      setFieldValue(`share_data.${index}.share`, newSharePercentage);
    }
  }, [fields.length, setFieldValue]);

  const multipleSelectFormat = (
    resultArr: { full_name: string; uuid?: string }[] = [],
  ): Nl.SelectFieldOptionsType[] =>
    resultArr.map((result) => ({
      label: result.full_name,
      value: get(result, 'uuid', result.full_name),
    }));

  /*
  When writer array is empty, we display a warning message that tells the user
  to create some writers before completing their submission.

  2 scenarios where the array is empty:
    1. There really are no writers linked to this artist
      - this is a legitimate error and the error message is the correct CTA
    2. The list of writers is still being fetched from server
      - CTA should not be shown, just wait for data to arrive
  */

  if (!myWritersIsLoaded) {
    return <CircularLoading />;
  }

  if (isEmpty(writerOptions)) {
    return (
      <div className={classes.warnText}>
        You currently have no writers in your profile. Click here to{' '}
        <a href='/my-writers'>manage writers</a> before submitting your files.
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <FormHelperText error>
        {isArray(form.errors.share_data) && form.errors.share_data[0]}
      </FormHelperText>
      <div className={classes.table}>
        {!!fields && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>IPI Number</TableCell>
                <TableCell>PRO</TableCell>
                <TableCell>Work Share (%)</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index: number) => {
                const writerUuid: string = getIn(field, 'writer_uuid');
                const writerName: string = getIn(field, 'name');
                const pro: string = getIn(field, 'pro');
                const ipi: number = getIn(field, 'ipi');
                const editableWriter: boolean = getIn(field, 'editable');

                return (
                  <TableRow key={`writer_${writerUuid || index}`}>
                    {editableWriter ? (
                      <TableCell
                        className={classnames(
                          classes.nameCell,
                          classes.selectCell,
                        )}
                        padding='none'
                      >
                        <SelectField
                          name={`share_data.${index}.writer_uuid`}
                          label={"Writer's name"}
                          selected={writerUuid}
                          noClearable
                          error={get(
                            form,
                            `errors.share_data[${index}].writer_uuid`,
                            false,
                          )}
                          options={multipleSelectFormat(
                            writerOptions.filter(
                              ({ uuid }) =>
                                writerUuid === uuid ||
                                !fields.find((w) => w.writer_uuid === uuid),
                            ),
                          )}
                          onChange={(selected: string) => {
                            const selectedWriter = find(writerOptions, [
                              'uuid',
                              selected,
                            ]);
                            form.setFieldValue(
                              `share_data.${index}.name`,
                              get(selectedWriter, 'full_name', ''),
                            );
                            form.setFieldValue(
                              `share_data.${index}.pro`,
                              get(
                                selectedWriter,
                                'performing_rights_organization.name',
                                '-',
                              ),
                            );
                            form.setFieldValue(
                              `share_data.${index}.ipi`,
                              get(selectedWriter, 'ipi_number', '-'),
                            );
                            form.setFieldValue(
                              `share_data.${index}.writer_uuid`,
                              get(selectedWriter, 'uuid', ''),
                            );
                            removeRowErrors(index);
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.nameCell}>
                        {writerName}
                      </TableCell>
                    )}
                    <TableCell>{ipi || '-'}</TableCell>
                    <TableCell>{pro || '-'}</TableCell>
                    <TableCell padding='none'>
                      <Field
                        className={classes.share}
                        name={`share_data.${index}.share`}
                        component={InputField}
                        type='number'
                        internal
                      />
                    </TableCell>
                    <TableCell padding='none'>
                      <DeleteButton
                        aria-label='Remove'
                        onClick={() => {
                          removeRowErrors(index);
                          remove(index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        <Button
          onClick={() => {
            push({
              writer_uuid: '',
              share: Math.floor(100 / fields.length),
              editable: true,
              name: '',
              pro: null,
            });
          }}
        >
          <AddIcon />
          Add
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  writerOptions: selectMyWriters(state),
  myWritersIsLoaded: selectMyWritersIsLoaded(state),
});

export default connect(mapStateToProps)(MyTrackEditWriter);
