import React from 'react';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import InputField from 'components/Formik/InputField';
import RevisionSourceFileRow from './SourceFileRevisionRow';
import { FormHelperText, Grid } from '@material-ui/core';

interface RevisionRowsProps {
  uuid: string;
}

export const SongRevisionForm: React.FunctionComponent<RevisionRowsProps> = ({
  uuid,
}) => {
  const {
    values,
    initialValues,
    errors,
  } = useFormikContext<Nl.Api.SongRevision>();
  return (
    <Form>
      <Grid container direction='column' spacing={4}>
        <Grid item xs={12}>
          <Field
            label='Revision Note'
            name='revision_note'
            data-e2e='song-revision-note'
            component={InputField}
            InputProps={{
              readOnly: true,
              multiline: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormHelperText error>{errors.source_files}</FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <FieldArray name='source_files'>
            {(props) => {
              if (!values.source_files) {
                return null;
              }
              return values.source_files.map((sourceFile, index) => {
                const formValues: Nl.UploadFormValues = {
                  field: `${props.name}.${index}`,
                  set: props.form.setFieldValue,
                };
                return (
                  <div key={sourceFile.uuid} style={{ marginBottom: '3rem' }}>
                    <RevisionSourceFileRow
                      {...props}
                      key={index}
                      sourceFile={sourceFile}
                      sourceFileIndex={index}
                      revisionUuid={uuid}
                      isEditable
                      formValues={formValues}
                      init={initialValues}
                    />
                  </div>
                );
              });
            }}
          </FieldArray>
        </Grid>
      </Grid>
    </Form>
  );
};
