import { NovaApiPath } from 'novaApi/NovaApiPath';
import { useNovaApi } from '../useNovaApi';

export type UserActivityType =
  | 'artists'
  | 'instruments'
  | 'performing-rights-organizations'
  | 'publishers'
  | 'song-collections'
  | 'song-genres'
  | 'song-themes'
  | 'songs'
  | 'users'
  | 'writers'
  | 'tag-categories'
  | 'tags'
  | 'terms'
  | 'tag-relation-types'
  | 'tag-term-relation-types'
  | 'tags'
  | 'comparisons'
  | 'examples';

export const useUserActivity = (type?: UserActivityType, uuid?: string) => {
  const path = ['/user_activity', type, uuid]
    .filter(Boolean)
    .join('/') as NovaApiPath;

  const {
    data,
    isLoaded,
    isFetching,
  } = useNovaApi<Nl.Api.UserActivityResponse>(path);
  return {
    userActivity: data?.user_activity || [],
    isLoaded,
    isFetching,
  };
};
