import * as React from 'react';
import BulkActionsBar from 'components/Table/BulkActionsBar';
import BatchUpdatesBar, { BatchAction } from 'components/Table/BatchUpdatesBar';
import { selectTags, selectGenres } from 'store/selectors';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Layers from '@material-ui/icons/Layers';
import LibraryMusic from '@material-ui/icons/LibraryMusic';
import { useDispatch } from 'react-redux';
import {
  publishSongs,
  takeDownSongs,
  canSongBePublish,
  canSongBeTakedown,
  canUserBatchCurate,
  curateSongs,
  handleUpdateSongs,
} from 'store/songs';
import {
  selectBatchUpdateStage,
  setBatchUpdateData,
  setBatchUpdateStage,
} from 'store/batchUpdate';
import { isUserPermitted } from 'utils/authUtils';
import { useNlSelector } from 'utils/redux';
import { useContentTiersSelect } from 'novaApi/hooks/content_tier';
import { patchSongs } from 'novaApi/NovaApi';

interface OwnProps {
  selected: string[];
  userPermissions: Nl.UserPermissionType[];
  onReset(): void;
}

export default function SongBulkActionBar({
  selected = [],
  userPermissions = [],
  onReset = () => {},
}: OwnProps) {
  const canPublish = useNlSelector((state) =>
    canSongBePublish(state, selected),
  );
  const canTakedown = useNlSelector((state) =>
    canSongBeTakedown(state, selected),
  );
  const canBatchCurate = useNlSelector(canUserBatchCurate);
  const batchUpdateStage = useNlSelector(selectBatchUpdateStage);
  const curationStates = useNlSelector(
    (state) => state.songs.curationStateOptions,
  );
  const contentTiers = useContentTiersSelect();
  const tags = useNlSelector(selectTags);
  const genres = useNlSelector(selectGenres);
  const dispatch = useDispatch();
  const onBatchTag = (...data: any) => {
    onReset();
    dispatch(
      setBatchUpdateData({
        action: data[0],
        attribute: 'tags',
        values: data[1],
      }),
    );
    dispatch(setBatchUpdateStage({ stage: 3 }));
  };
  const onBatchGenre = (...data: any) => {
    onReset();
    dispatch(
      setBatchUpdateData({
        action: data[0],
        attribute: 'genres',
        values: data[1],
      }),
    );
    dispatch(setBatchUpdateStage({ stage: 3 }));
  };
  const batchUpdateInProgress =
    batchUpdateStage === 2 &&
    isUserPermitted(userPermissions, 'CAN_BATCH_UPDATE_SONGS');
  // Batch update in progress, render specific batch actions toolbar
  if (batchUpdateInProgress) {
    const batchActions: BatchAction[] = [
      {
        name: 'Batch-Content-Tier',
        icon: <Layers />,
        requiredPermission: 'CAN_PUBLISH_SONGS',
        e2e: 'Batch-Content-Tier',
        interface: {
          label: 'Select Content Tier',
          type: 'single-select',
          data: contentTiers || [],
          buttons: [
            {
              action: 'set',
              dispatch: (action, values) => {
                onReset();
                dispatch(
                  setBatchUpdateData({
                    action,
                    attribute: 'content_tier',
                    values,
                  }),
                );
                dispatch(setBatchUpdateStage({ stage: 3 }));
              },
            },
          ],
          isCreatable: false,
        },
      },
      {
        name: 'Batch-Tag',
        icon: <LocalOffer />,
        requiredPermission: 'CAN_UPDATE_SONG_BASIC_INFORMATION',
        e2e: 'Batch-Tag',
        interface: {
          label: 'Select Tags',
          type: 'multi-select',
          data: tags,
          buttons: [
            {
              action: 'add',
              dispatch: onBatchTag,
            },
            {
              action: 'remove',
              dispatch: onBatchTag,
            },
          ],
          isCreatable: true,
        },
      },
      {
        name: 'Batch-Genre',
        icon: <LibraryMusic />,
        requiredPermission: 'CAN_UPDATE_SONG_METADATA',
        e2e: 'Batch-Genre',
        interface: {
          label: 'Select Genres',
          type: 'multi-select',
          data: genres,
          buttons: [
            {
              action: 'add',
              dispatch: onBatchGenre,
            },
            {
              action: 'remove',
              dispatch: onBatchGenre,
            },
          ],
          isCreatable: false,
        },
      },
    ];
    return (
      <BatchUpdatesBar
        actionsList={batchActions.filter((action) =>
          isUserPermitted(userPermissions, action.requiredPermission),
        )}
      />
    );
  }

  const allActions: Nl.BulkActionsListItem[] = [
    {
      name: 'Publish',
      buttonText: 'Publish',
      e2e: 'Publish',
      uuid: 'publish-songs',
      requiredPermission: 'CAN_PUBLISH_SONGS',
      dispatch: (data) => {
        onReset();
        dispatch(
          publishSongs({
            songUuids: data.selected,
          }),
        );
      },
      disabled: !canPublish,
      color: 'primary',
    },
    {
      name: 'TakeDown',
      buttonText: 'TakeDown',
      e2e: 'TakeDown',
      uuid: 'takedown-songs',
      requiredPermission: 'CAN_PUBLISH_SONGS',
      dispatch: (data) => {
        onReset();
        dispatch(
          takeDownSongs({
            songUuids: data.selected,
          }),
        );
      },
      disabled: !canTakedown,
      color: 'primary',
    },
    {
      name: 'Curate',
      buttonText: 'Set Curation State',
      e2e: 'Curate',
      uuid: 'curate-songs',
      requiredPermission: 'CAN_PUBLISH_SONGS',
      dispatch: (data) => {
        onReset();
        dispatch(curateSongs(data));
      },
      disabled: !canBatchCurate,
      color: 'primary',
      target: {
        label: 'Select a curation state',
        data: curationStates,
      },
    },
    {
      name: 'Set Content Tier',
      buttonText: 'Set Content Tier',
      e2e: 'ContentTier',
      uuid: 'set-content-songs-tier',
      requiredPermission: 'CAN_UPDATE_SONG_BASIC_INFORMATION',
      dispatch: async (data) => {
        onReset();

        const results = await patchSongs(
          data.selected.map((current) => ({
            song: current,
            content_tier: data.targetSelected,
          })),
        );

        return dispatch(
          handleUpdateSongs({
            results,
            notification: 'The songs content tier is set',
          }),
        );
      },
      color: 'primary',
      target: {
        label: 'Select a content tier',
        data: contentTiers,
      },
    },
  ];

  const actionsList = allActions.filter((action) =>
    isUserPermitted(userPermissions, action.requiredPermission),
  );

  return <BulkActionsBar selected={selected} actionsList={actionsList} />;
}
