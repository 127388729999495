import {
  NOT_FOUND,
  connectRoutes,
  redirect,
  Options,
  ActionMetaLocation,
} from 'redux-first-router';
import { getAuthToken } from 'utils/storedToken';
import { createBrowserHistory } from 'history';
import { actions } from './store/auth';
import sagas from './store/onRouteSagas';
import queryString from 'query-string';
import { get } from 'lodash';
import { afterRouteChangeHandler } from './utils/analytics';
import * as HomePage from './pages/Home/Home';
import * as ArtistPage from 'pages/Artists/ArtistsPage';
import * as ArtistCreatePage from 'pages/Artists/ArtistCreatePage';
import * as ArtistEditPage from 'pages/Artists/ArtistEditPage';
import * as LoginPage from './pages/Login/Login';
import * as PublishersPage from './pages/Publishers/PublishersPage';
import * as PublisherCreatePage from './pages/Publishers/PublisherCreatePage';
import * as PublisherEditPage from './pages/Publishers/PublisherEditPage';
import * as PROCreatePage from './pages/PROs/PROCreatePage';
import * as PROEditPage from './pages/PROs/PROEditPage';
import * as ReportsPage from './pages/Reports/ReportsPage';
import * as PROsPage from './pages/PROs/PROsPage';
import * as WritersPage from './pages/Writers/WritersPage';
import * as UsersPage from './pages/Users/UsersPage';
import * as UserCreatePage from './pages/Users/UserCreatePage';
import * as UserEditPage from './pages/Users/UserEditPage';
import * as WriterCreatePage from './pages/Writers/WriterCreatePage';
import * as WriterEditPage from './pages/Writers/WriterEditPage';
import * as SongsPage from './pages/Songs/SongsPage';
import * as SongCreatePage from './pages/Songs/SongCreatePage';
import * as SongEditPage from './pages/Songs/SongEditPage';
import * as SongCollectionPage from './pages/SongCollections/SongCollectionPage';
import * as SongCollectionCreatePage from './pages/SongCollections/SongCollectionCreatePage';
import * as SongCollectionEditPage from './pages/SongCollections/SongCollectionEditPage';
import * as SongGenrePage from './pages/SongGenre/SongGenrePage';
import * as SongGenresCreatePage from './pages/SongGenre/CreateSongGenrePage';
import * as SongThemePage from './pages/SongTheme/SongThemePage';
import * as SongThemesCreatePage from './pages/SongTheme/CreateSongThemePage';
import * as SongInstrumentPage from './pages/SongInstrument/SongInstrumentPage';
import * as SongInstrumentsCreatePage from './pages/SongInstrument/CreateSongInstrumentPage';
import * as NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import * as ChangeMyPasswordPage from 'pages/MyProfile/ChangeMyPasswordPage';
import * as SalesReportsPage from 'pages/Dashboard/SalesReportsPage';
import * as TrackSubmissionsPage from 'pages/Submissions/TrackSubmissionsPage';
import * as ArtistSubmissionsPage from 'pages/Submissions/ArtistSubmissionsPage';
import * as TrackSubmissionsEditPage from 'pages/Submissions/TrackSubmissionEditPage';
import * as MyTracksPage from 'pages/MyTracks/MyTracksPage';
import * as MyTrackEditPage from 'pages/MyTracks/MyTrackEdit/MyTrackEditPage';
import * as MyTrackCreatePage from 'pages/MyTracks/MyTrackCreatePage';
import * as MyWritersPage from 'pages/MyWriters/MyWritersPage';
import * as MyWriterCreatePage from 'pages/MyWriters/MyWriterCreatePage';
import * as EditMyProfilePage from 'pages/MyProfile/EditMyProfilePage';
import * as RegistrationPage from 'pages/Registration/RegistrationPage';
import * as ArtistSubmissionEditPage from 'pages/Submissions/ArtistSubmissionEditPage';
import * as RegistrationMyProfilePage from 'pages/Registration/RegistrationMyProfilePage';
import * as LinkExpiredPage from 'pages/Registration/LinkExpiredPage';
import * as ForgotPasswordPage from 'pages/Login/ForgotPasswordPage';
import * as ResetPasswordPage from 'pages/Login/ResetPasswordPage';
import * as UserActivityPage from 'pages/Users/UserActivityPage';
import * as SongFinderPage from 'pages/Songs/SongFinderPage';
import * as PROSongRegistrationsPage from 'pages/PROs/PROSongRegistrationsPage';
import * as PROEarningsPage from 'pages/PROs/PROEarningsPage';
import * as PROEarningsAddPage from 'pages/PROs/PROEarningsAdd/PROEarningsAddPage';
import * as PROEarningsStatementsPage from 'pages/PROs/PROEarningsStatementsPage';
import * as TagCategoriesPage from 'pages/TagCategories/TagCategoriesPage';
import * as TagCategoriesEditPage from 'pages/TagCategories/TagCategoriesEditPage';
import * as TagCategoriesCreatePage from 'pages/TagCategories/TagCategoriesCreatePage';
import * as TagRelationTypesPage from 'pages/TagRelationTypes/TagRelationTypesPage';
import * as ExamplesPage from 'pages/Examples/ExamplesPage';
import * as ExamplesCreatePage from 'pages/Examples/ExamplesCreatePage';
import * as ExamplesEditPage from 'pages/Examples/ExamplesEditPage';
import * as TagsPage from 'pages/Tags/TagsPage';
import * as TagTermRelationTypesPage from 'pages/TagTermRelationTypes/TagTermRelationTypesPage';
import * as TagTermRelationTypesCreatePage from 'pages/TagTermRelationTypes/TagTermRelationTypesCreatePage';
import * as TagTermRelationTypesEditPage from 'pages/TagTermRelationTypes/TagTermRelationTypesEditPage';
import * as TagsEditPage from 'pages/Tags/TagsEditPage';
import * as TagsCreatePage from 'pages/Tags/TagsCreatePage';
import * as TermsPage from 'pages/Terms/TermsPage';
import * as TermsCreatePage from 'pages/Terms/TermsCreatePage';
import * as TermsEditPage from 'pages/Terms/TermsEditPage';
import * as TagRelationTypesCreatePage from 'pages/TagRelationTypes/TagRelationTypesCreatePage';
import * as TagRelationTypesEditPage from 'pages/TagRelationTypes/TagRelationTypesEditPage';
import * as ComparisonsPage from 'pages/Comparisons/ComparisonsPage';
import * as UpsertComparisonPage from 'pages/Comparisons/UpsertComparison';
import * as SongRevisionsPage from 'pages/SongRevisions/SongRevisionsPage';
import * as SongRevisionEditPage from 'pages/SongRevisions/SongRevisionEditPage';
// Hiding until further notice MIT-3791
// import * as SalesPerTrackPage from 'pages/Dashboard/SalesPerTrackPage';

// TODO: break this out into routesMap, so we can then test our beforeChangeHandler
// TODO: saga should always be defined but it's undefined on some tests probably because of some circular dependancies
const routesMap =
  sagas &&
  ({
    'route/TAG_TERM_RELATION_TYPES': {
      type: 'page',
      path: '/tag-term-relation-types',
      component: () => TagTermRelationTypesPage,
    },
    'route/TAG_TERM_RELATION_TYPES_CREATE': {
      type: 'modal',
      parent: 'route/TAG_TERM_RELATION_TYPES',
      path: '/tag-term-relation-types/create',
      component: () => TagTermRelationTypesCreatePage,
    },
    'route/TAG_TERM_RELATION_TYPES_EDIT': {
      type: 'modal',
      parent: 'route/TAG_TERM_RELATION_TYPES',
      path: '/tag-term-relation-types/edit/:uuid',
      component: () => TagTermRelationTypesEditPage,
    },
    'route/TAG_RELATION_TYPES': {
      type: 'page',
      path: '/tag-relation-types',
      component: () => TagRelationTypesPage,
    },
    'route/TAG_RELATION_TYPES_CREATE': {
      type: 'modal',
      parent: 'route/TAG_RELATION_TYPES',
      path: '/tag-relation-types/create',
      component: () => TagRelationTypesCreatePage,
    },
    'route/TAG_RELATION_TYPES_EDIT': {
      type: 'modal',
      parent: 'route/TAG_RELATION_TYPES',
      path: '/tag-relation-types/edit/:uuid',
      component: () => TagRelationTypesEditPage,
    },
    'route/TAGS': {
      type: 'page',
      path: '/tags',
      component: () => TagsPage,
    },
    'route/TAGS_EDIT': {
      type: 'modal',
      parent: 'route/TAGS',
      path: '/tags/edit/:uuid',
      component: () => TagsEditPage,
    },
    'route/TAGS_CREATE': {
      type: 'modal',
      parent: 'route/TAGS',
      path: '/tags/create',
      component: () => TagsCreatePage,
    },
    'route/TERMS': {
      type: 'page',
      path: '/terms',
      component: () => TermsPage,
    },
    'route/TERMS_CREATE': {
      type: 'modal',
      parent: 'route/TERMS',
      path: '/terms/create',
      component: () => TermsCreatePage,
    },
    'route/TERMS_EDIT': {
      type: 'modal',
      parent: 'route/TERMS',
      path: '/terms/edit/:uuid',
      component: () => TermsEditPage,
    },
    'route/EXAMPLES': {
      type: 'page',
      path: '/examples',
      component: () => ExamplesPage,
    },
    'route/EXAMPLES_CREATE': {
      type: 'modal',
      parent: 'route/EXAMPLES',
      path: '/examples/create',
      component: () => ExamplesCreatePage,
    },
    'route/EXAMPLES_EDIT': {
      type: 'modal',
      parent: 'route/EXAMPLES',
      path: '/examples/edit/:uuid',
      component: () => ExamplesEditPage,
    },
    'route/COMPARISONS': {
      type: 'page',
      path: '/comparisons',
      component: () => ComparisonsPage,
    },
    'route/COMPARISONS_CREATE': {
      type: 'modal',
      parent: 'route/COMPARISONS',
      path: '/comparisons/create',
      component: () => UpsertComparisonPage,
    },
    'route/COMPARISONS_EDIT': {
      type: 'modal',
      parent: 'route/COMPARISONS',
      path: '/comparisons/edit/:uuid',
      component: () => UpsertComparisonPage,
    },
    'route/TAG_CATEGORIES': {
      type: 'page',
      path: '/tag-categories',
      component: () => TagCategoriesPage,
    },
    'route/TAG_CATEGORIES_EDIT': {
      type: 'modal',
      parent: 'route/TAG_CATEGORIES',
      path: '/tag-categories/edit/:uuid',
      component: () => TagCategoriesEditPage,
    },
    'route/TAG_CATEGORIES_CREATE': {
      type: 'modal',
      parent: 'route/TAG_CATEGORIES',
      path: '/tag-categories/create',
      component: () => TagCategoriesCreatePage,
    },
    'route/ARTISTS': {
      type: 'page',
      path: '/artists',
      component: () => ArtistPage,
    },
    'route/ARTIST_CREATE': {
      type: 'modal',
      path: '/artists/create',
      parent: 'route/ARTISTS',
      component: () => ArtistCreatePage,
    },
    'route/ARTIST_EDIT': {
      type: 'modal',
      path: '/artists/edit/:uuid',
      parent: 'route/ARTISTS',
      component: () => ArtistEditPage,
    },
    'route/LOGIN': {
      authRequired: false,
      type: 'page',
      path: '/login',
      component: () => LoginPage,
    },
    'route/LOGOUT': {
      type: 'page',
      path: '/logout',
      component: () => LoginPage,
      saga: sagas.onLogout,
    },
    'route/FORGOT_PASSWORD': {
      authRequired: false,
      type: 'page',
      path: '/forgot-password',
      component: () => ForgotPasswordPage,
    },
    'route/RESET_PASSWORD': {
      authRequired: false,
      type: 'page',
      path: '/reset-password',
      component: () => ResetPasswordPage,
    },
    'route/PUBLISHERS': {
      type: 'page',
      path: '/publishers',
      section: 'publishers',
      component: () => PublishersPage,
      saga: sagas.onPublisherRoute,
    },
    'route/PUBLISHERS_CREATE': {
      type: 'modal',
      path: '/publishers/create',
      section: 'publishers',
      component: () => PublisherCreatePage,
      parent: 'route/PUBLISHERS',
      saga: sagas.onPublisherCreateRoute,
    },
    'route/PUBLISHERS_EDIT': {
      type: 'modal',
      path: '/publishers/edit/:uuid',
      section: 'publishers',
      component: () => PublisherEditPage,
      parent: 'route/PUBLISHERS',
      saga: sagas.onPublisherEditRoute,
    },
    'route/PROS': {
      type: 'page',
      path: '/performing-rights-organizations',
      section: 'pros',
      component: () => PROsPage,
    },
    'route/PROS_CREATE': {
      type: 'modal',
      path: '/performing-rights-organizations/create',
      section: 'pros',
      component: () => PROCreatePage,
      parent: 'route/PROS',
    },
    'route/PROS_EARNINGS': {
      type: 'page',
      path: '/performing-rights-organizations/earnings',
      section: 'pros',
      component: () => PROEarningsPage,
    },
    'route/PROS_EARNINGS_STATEMENTS': {
      type: 'page',
      path: '/performing-rights-organizations/earnings-statements',
      section: 'pros',
      component: () => PROEarningsStatementsPage,
    },
    'route/PROS_EARNINGS_ADD': {
      type: 'modal',
      path: '/performing-rights-organizations/earnings/add',
      section: 'pros',
      component: () => PROEarningsAddPage,
      parent: 'route/PROS_EARNINGS',
    },
    'route/PROS_EDIT': {
      type: 'modal',
      path: '/performing-rights-organizations/edit/:uuid',
      section: 'pros',
      component: () => PROEditPage,
      parent: 'route/PROS',
    },
    'route/PROS_SONG_REGISTRATIONS': {
      type: 'page',
      path: '/performing-rights-organizations/song-registrations',
      section: 'pros',
      saga: sagas.onPROSongRegistrationRoute,
      component: () => PROSongRegistrationsPage,
    },
    'route/USERS': {
      type: 'page',
      path: '/users',
      section: 'users',
      component: () => UsersPage,
    },
    'route/USERS_CREATE': {
      type: 'modal',
      path: '/users/create',
      section: 'users',
      component: () => UserCreatePage,
      parent: 'route/USERS',
    },
    'route/USERS_EDIT': {
      type: 'modal',
      path: '/users/edit/:uuid',
      section: 'users',
      component: () => UserEditPage,
      parent: 'route/USERS',
      saga: sagas.onUserEditRoute,
    },
    'route/USER_ACTIVITY': {
      type: 'page',
      path: '/user-activity',
      section: 'users',
      component: () => UserActivityPage,
    },
    'route/REPORTS': {
      type: 'page',
      path: '/reports',
      section: 'report',
      component: () => ReportsPage,
    },
    'route/CHANGE_MY_PASSWORD': {
      type: 'modal',
      path: '/change_my_password',
      section: 'my_profile',
      component: () => ChangeMyPasswordPage,
      parent: 'route/HOME',
    },
    'route/MY_PROFILE_EDIT': {
      type: 'modal',
      path: '/my-profile',
      section: 'my_profile',
      component: () => EditMyProfilePage,
      parent: 'route/MY_TRACKS',
      saga: sagas.onMyProfileEditRoute,
    },
    'route/MY_PROFILE_EDIT_REGISTRATION': {
      type: 'page',
      path: '/my-profile-registration',
      component: () => RegistrationMyProfilePage,
      saga: sagas.onMyProfileEditRoute,
    },
    'route/REVISIONS_REQUESTED': {
      type: 'page',
      path: '/revisions-requested',
      section: 'revisions',
      component: () => SongRevisionsPage,
    },
    'route/REVISIONS_REQUESTED_EDIT': {
      type: 'modal',
      path: '/revisions-requested/edit/:uuid',
      section: 'revisions',
      component: () => SongRevisionEditPage,
      parent: 'route/REVISIONS_REQUESTED',
    },
    'route/WRITERS': {
      type: 'page',
      path: '/writers',
      section: 'writers',
      component: () => WritersPage,
    },
    'route/WRITERS_CREATE': {
      type: 'modal',
      path: '/writers/create',
      section: 'writers',
      component: () => WriterCreatePage,
      parent: 'route/WRITERS',
    },
    'route/WRITERS_EDIT': {
      type: 'modal',
      path: '/writers/edit/:uuid',
      section: 'writers',
      component: () => WriterEditPage,
      parent: 'route/WRITERS',
    },
    'route/TRACK_SUBMISSIONS': {
      type: 'page',
      path: '/track-submissions',
      section: 'submissions',
      component: () => TrackSubmissionsPage,
    },
    'route/ARTIST_SUBMISSIONS': {
      type: 'page',
      path: '/artist-submissions',
      section: 'submissions',
      component: () => ArtistSubmissionsPage,
      saga: sagas.onArtistSubmissionsRoute,
    },
    'route/TRACK_SUBMISSION_EDIT_PAGE': {
      type: 'modal',
      path: '/track-submissions/edit/:uuid',
      component: () => TrackSubmissionsEditPage,
      parent: 'route/TRACK_SUBMISSIONS',
      saga: sagas.onTrackSubmissionEditRoute,
    },
    'route/MY_TRACKS': {
      type: 'page',
      path: '/my-tracks',
      section: 'my_submissions',
      component: () => MyTracksPage,
    },
    'route/MY_TRACK_EDIT_PAGE': {
      type: 'modal',
      path: '/my-tracks/edit/:uuid',
      section: 'my_submissions',
      component: () => MyTrackEditPage,
      parent: 'route/MY_TRACKS',
      saga: sagas.onMyTrackEditRoute,
    },
    'route/MY_TRACK_CREATE_PAGE': {
      type: 'modal',
      path: '/my-tracks/create',
      section: 'my_submissions',
      component: () => MyTrackCreatePage,
      parent: 'route/MY_TRACKS',
      saga: sagas.onMyTrackCreateRoute,
    },
    'route/ARTIST_SUBMISSION_EDIT_PAGE': {
      type: 'modal',
      path: '/artist-submissions/edit/:uuid',
      component: () => ArtistSubmissionEditPage,
      parent: 'route/ARTIST_SUBMISSIONS',
      saga: sagas.onArtistSubmissionEditRoute,
    },
    'route/SONGS': {
      type: 'page',
      path: '/songs',
      section: 'song',
      component: () => SongsPage,
      saga: sagas.onSongRoute,
    },
    'route/SONG_CREATE': {
      type: 'modal',
      path: '/songs/create',
      section: 'song',
      component: () => SongCreatePage,
      parent: 'route/SONGS',
      saga: sagas.onSongCreateRoute,
    },
    'route/SONG_EDIT': {
      type: 'modal',
      path: '/songs/edit/:uuid',
      section: 'song',
      component: () => SongEditPage,
      parent: 'route/SONGS',
      saga: sagas.onSongEditRoute,
      onLeaveSaga: sagas.onSongEditLeave,
    },
    'route/SONG_FINDER': {
      type: 'page',
      path: '/songs/finder',
      section: 'songs',
      component: () => SongFinderPage,
      onLeaveSaga: sagas.onSongFinderLeave,
    },
    'route/SONG_COLLECTIONS': {
      type: 'page',
      path: '/song-collections',
      section: 'song',
      component: () => SongCollectionPage,
      saga: sagas.onSongCollectionRoute,
    },
    'route/SONG_COLLECTIONS_CREATE': {
      type: 'modal',
      path: '/song-collections/create',
      section: 'song',
      component: () => SongCollectionCreatePage,
      parent: 'route/SONG_COLLECTIONS',
    },
    'route/SONG_COLLECTIONS_EDIT': {
      type: 'modal',
      path: '/song-collections/edit/:uuid',
      section: 'song',
      component: () => SongCollectionEditPage,
      parent: 'route/SONG_COLLECTIONS',
      saga: sagas.onSongCollectionEditRoute,
    },
    'route/SONG_GENRES': {
      type: 'page',
      path: '/metadata/genres',
      section: 'song',
      component: () => SongGenrePage,
    },
    'route/SONG_GENRE_CREATE': {
      type: 'modal',
      path: '/metadata/genres/create',
      section: 'song',
      component: () => SongGenresCreatePage,
      parent: 'route/SONG_GENRES',
    },
    'route/SONG_THEMES': {
      type: 'page',
      path: '/metadata/themes',
      section: 'song',
      component: () => SongThemePage,
    },
    'route/SONG_THEME_CREATE': {
      type: 'modal',
      path: '/metadata/themes/create',
      section: 'song',
      component: () => SongThemesCreatePage,
      parent: 'route/SONG_THEMES',
    },
    'route/SONG_INSTRUMENTS': {
      type: 'page',
      path: '/metadata/instruments',
      section: 'song',
      component: () => SongInstrumentPage,
    },
    'route/SONG_INSTRUMENT_CREATE': {
      type: 'modal',
      path: '/metadata/instruments/create',
      section: 'song',
      component: () => SongInstrumentsCreatePage,
      parent: 'route/SONG_INSTRUMENTS',
    },
    /*
    Hiding until further notice MIT-3791
  */
    // 'route/DASHBOARD_SALES_PER_TRACK': {
    //   type: 'page',
    //   path: '/artist-portal/dashboard/sales-per-track',
    //   section: 'dashboard',
    //   component: () => SalesPerTrackPage,
    //   saga: sagas.onSalesPerTrack,
    // },
    'route/DASHBOARD_SALES_REPORTS': {
      type: 'page',
      path: '/artist-portal/dashboard/sales-reports',
      section: 'dashboard',
      component: () => SalesReportsPage,
      saga: sagas.onEarningsSummary,
    },
    'route/MY_WRITERS': {
      type: 'page',
      path: '/my-writers',
      section: 'my_writers',
      component: () => MyWritersPage,
      saga: sagas.onMyWritersRoute,
    },
    'route/MY_WRITER_CREATE': {
      type: 'modal',
      path: '/my-writers/create',
      section: 'my_writers',
      component: () => MyWriterCreatePage,
      onLeaveSaga: sagas.onMyWriterCreateLeave,
      parent: 'route/MY_WRITERS',
    },
    'route/REGISTER': {
      authRequired: false,
      type: 'page',
      path: '/register',
      component: () => RegistrationPage,
    },
    'route/LINK_EXPIRED': {
      authRequired: false,
      type: 'page',
      path: '/link-expired',
      component: () => LinkExpiredPage,
    },
    'route/HOME': {
      type: 'page',
      path: '/',
      section: false, // no tabs selected
      component: () => HomePage,
    },
    [NOT_FOUND]: {
      type: 'page',
      path: '/',
      component: () => NotFoundPage,
    },
  } as const);

export type RouteMapType = keyof typeof routesMap;

/**
 * @param route route/XXXX key to lookup authRequired prop on the NL.RouteMapList
 */
const isAuthRequired = (route: string) => {
  return get(routesMap, `${route}.authRequired`, true) as boolean;
};

/**
 * Only one case to handle right now.
 *
 * @param route route/XXXX key to lookup authRequired prop on the NL.RouteMapList
 */
const validRedirectTarget = (route: string) => {
  return route !== 'route/LOGOUT';
};

const options: Options<Nl.RouteMapProps> = {
  onBeforeChange: (dispatch, getState, { action }) => {
    const { type } = action;
    const requestedLocation = get(
      action,
      'meta.location',
      {},
    ) as ActionMetaLocation;
    const { auth } = getState();
    const isLoggedIn = getAuthToken();
    const redirectTo = auth?.postLoginRedirect;

    if (!isLoggedIn && isAuthRequired(type)) {
      if (validRedirectTarget(type)) {
        // we still shove this in state since requestedLocation.prev will == login if fail first login attempt
        dispatch(actions.setPostLoginRedirect(requestedLocation.current));
      }
      dispatch(redirect({ type: 'route/LOGIN' }));
      return;
    }

    if (isLoggedIn && redirectTo) {
      dispatch(actions.setPostLoginRedirect(false));
      dispatch(redirect(redirectTo));
      return;
    }

    if (isLoggedIn && type === 'route/LOGIN') {
      dispatch(redirect({ type: 'route/HOME' }));
    }
  },
  onAfterChange: afterRouteChangeHandler,
  querySerializer: queryString,
  createHistory: createBrowserHistory,
};

const { reducer, middleware, enhancer } = connectRoutes(routesMap, options);

export { reducer, middleware, enhancer, routesMap };
