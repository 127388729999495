import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const Decline = React.forwardRef((props: {}, ref: any) => (
  <SvgIcon
    ref={ref}
    {...props}
    viewBox='0 0 124 124'
    style={{ transform: 'scale(0.8)' }}
  >
    <symbol id='prefix__g' viewBox='-64.5 -64.5 129 129'>
      <g fill='none' stroke='#4285f4' strokeMiterlimit={10}>
        <path d='M-64-64H64V64H-64z' />
        <path d='M36.95 37.82C27.32 46.32 14.2 51 0 51c-14.27 0-27.39-4.62-36.96-13.01C-47.45 28.79-53 15.65-53 0c0-15.58 5.55-28.69 16.04-37.92C-27.36-46.35-14.24-51 0-51c14.17 0 27.29 4.71 36.95 13.25C47.45-28.45 53-15.4 53 0c0 15.47-5.55 28.55-16.05 37.82z' />
        <path d='M0 55h0c-29.59 0-57-19.01-57-55 0-35.8 27.41-55 57-55h0c29.59 0 57 19.69 57 55 0 35.51-27.41 55-57 55z' />
        <path d='M0-43c-12.29 0-23.54 3.94-31.68 11.09C-40.39-24.25-45-13.21-45 0c0 29.7 22.6 43 45 43 21.67 0 45-13.46 45-43S21.67-43 0-43h0zM-.01 51V-51M-16 48.95v-97.88M15.99 48.91v-97.84M53 .08H-53' />
      </g>
    </symbol>
    <path
      d='M63.93 122.33c-33.25 0-42.68-2.73-49.56-4.73-.75-.22-1.47-.43-2.2-.63-6.32-1.74-6.79-9.97-5.59-14.75 1.31-5.24 5.78-9.35 11.95-11a3.64 3.64 0 0 0 2.46-2.17c.44-1.08.34-2.27-.26-3.28-1.63-2.72-2.08-5.93-1.25-8.82.83-2.9 3-5.59 5.93-7.37 1.16-.7 2.28-1.04 3.83-1.37.07-.01.16-.03.28-.05 1.16-.19 2.91-.48 3.61-1.9.35-.71.34-1.54-.05-2.28-1.73-3.29-1.85-7.09-.35-10.69 1.65-3.97 5.04-7.07 9.06-8.3.12-.04.51-.09.77-.12 1.16-.15 2.6-.34 3.23-1.62.35-.7.31-1.51-.1-2.22-4.24-7.4.07-10.67 3.87-13.55 1.79-1.36 3.2-2.43 3.47-4.02.57-3.33.2-5.82-1.32-8.63 6.1 1.84 10.72 3.55 15.66 6.5 10 5.97 14.17 11.34 12.76 16.42-.04.13-.08.26-.12.4-.18.58-.4 1.3-.39 2.12.01 3.22 4.06 4.5 7.02 5.43.74.23 1.5.47 1.8.63 2.5 1.27 4.59 3.3 5.9 5.73 1.2 2.23 1.76 4.87 1.59 7.44-.02.24-.26.96-.42 1.44-.59 1.74-1.25 3.71-.43 5.37.4.8 1.08 1.38 1.97 1.67 5.86 1.94 9.05 4.54 10.38 8.44.83 2.46.77 5.25-.17 7.67-.07.18-.17.39-.27.62-.58 1.28-1.46 3.22-.6 4.92.33.65 1.04 1.49 2.54 1.79 6.39 1.3 10.96 5.23 12.53 10.78.07.24.13.5.18.79.82 4.37.18 11.65-4.92 13.84-6.22 2.67-26.84 5.5-52.79 5.5z'
      fill='#8c6319'
    />
    <path
      d='M55.01 17.99c4.16 1.39 7.67 2.88 11.33 5.06 8.99 5.37 12.98 10.14 11.86 14.17-.03.12-.07.23-.1.35-.2.67-.48 1.58-.48 2.71.01 4.68 5.07 6.27 8.42 7.33.54.17 1.29.41 1.5.5 2.14 1.09 3.93 2.82 5.04 4.89 1.01 1.87 1.49 4.09 1.37 6.26-.05.22-.22.71-.33 1.04-.64 1.9-1.52 4.5-.32 6.91.63 1.27 1.74 2.22 3.13 2.68 5.2 1.72 8.01 3.94 9.11 7.19.68 2.02.63 4.32-.14 6.3-.06.15-.14.33-.23.52-.7 1.53-1.86 4.1-.56 6.65.75 1.47 2.14 2.48 3.92 2.84 3.31.67 9.14 2.79 11 9.37.05.17.1.38.14.62.67 3.59.25 9.92-3.74 11.63-4.82 2.06-23.2 5.34-52.01 5.34-32.96 0-42.23-2.69-49.01-4.65-.76-.22-1.49-.43-2.22-.63-5.14-1.41-5.03-8.92-4.18-12.33 1.13-4.52 5.07-8.09 10.53-9.55a5.62 5.62 0 0 0 3.8-3.35c.68-1.67.53-3.51-.4-5.06-1.34-2.24-1.72-4.88-1.05-7.23.7-2.42 2.54-4.69 5.05-6.22.92-.56 1.84-.83 3.19-1.12.06-.01.13-.02.21-.04 1.37-.23 3.92-.65 5.08-2.98.64-1.29.62-2.79-.07-4.09-1.45-2.76-1.54-5.95-.27-8.99 1.42-3.4 4.31-6.06 7.73-7.14.11-.02.35-.05.51-.07 1.37-.18 3.66-.48 4.76-2.72.64-1.29.58-2.82-.15-4.11-3.2-5.59-.82-7.81 3.34-10.96 1.97-1.49 3.83-2.9 4.23-5.28.37-2.17.38-4.04.01-5.84m-4-5.36c-1.23 0-2.11 1.38-1.41 2.54 1.58 2.66 2 4.8 1.46 7.96-.5 2.94-14.16 6.57-7.1 18.9.49.86-1.81.77-2.75 1.06-8.94 2.74-14.42 13.22-9.9 21.84.52.99-1.88 1.2-2.5 1.34-1.66.36-3.01.75-4.44 1.62-3.34 2.03-5.83 5.1-6.81 8.53-1 3.46-.44 7.23 1.46 10.4.58.96.08 2.2-1 2.49-6.25 1.67-11.76 5.98-13.38 12.45-1.41 5.65-.62 15.07 7 17.16 7.62 2.1 15.29 5.43 52.29 5.43 27.17 0 47.34-2.98 53.58-5.66 6.25-2.67 7.03-11.04 6.1-16.04-.07-.36-.14-.68-.22-.97-1.9-6.72-7.39-10.84-14.05-12.2-2.49-.51-.73-3.27-.2-4.65a13.34 13.34 0 0 0 .19-9.03c-1.82-5.37-6.55-8.01-11.64-9.7-2.3-.76.14-4.8.25-6.46.19-2.94-.43-5.93-1.83-8.52-1.51-2.81-3.91-5.12-6.75-6.56-1.69-.86-7.72-1.87-7.73-4.28 0-.68.26-1.32.44-1.98 2.31-8.32-7.7-15.11-13.67-18.67-5.48-3.28-10.54-5.03-16.88-6.91-.18-.07-.35-.09-.51-.09z'
      fill='#703d2b'
    />
    <circle cx={80.13} cy={69.49} r={12.4} fill='#fff' />
    <circle cx={80.13} cy={69.49} r={12.4} fill='none' />
    <path
      d='M80.13 63.67c-3.16 0-5.73 1.88-5.73 5.82s2.56 5.82 5.73 5.82c3.16 0 5.73-1.88 5.73-5.82s-2.57-5.82-5.73-5.82z'
      fill='#242424'
    />
    <circle cx={47.87} cy={69.49} r={12.4} fill='#fff' />
    <circle cx={47.87} cy={69.49} r={12.4} fill='none' />
    <path
      d='M47.87 63.67c-3.16 0-5.73 1.88-5.73 5.82s2.56 5.82 5.73 5.82c3.16 0 5.73-1.88 5.73-5.82s-2.57-5.82-5.73-5.82z'
      fill='#242424'
    />
    <g>
      <defs>
        <path
          id='prefix__b'
          d='M63.99 89.95c-9.52 0-18.36-.8-25.74-2.16-1.83-.34-3.45 1.27-3.08 3.09 2.45 12 14.27 21.86 28.81 21.86 15.91 0 26.63-10.18 28.85-21.9.34-1.81-1.27-3.39-3.08-3.05-7.38 1.36-16.23 2.16-25.76 2.16z'
        />
      </defs>
      <clipPath id='prefix__c'>
        <use xlinkHref='#prefix__b' overflow='visible' />
      </clipPath>
      <path
        d='M63.99 89.95c-9.52 0-18.36-.8-25.74-2.16-1.83-.34-3.45 1.27-3.08 3.09 2.45 12 14.27 21.86 28.81 21.86 15.91 0 26.63-10.18 28.85-21.9.34-1.81-1.27-3.39-3.08-3.05-7.38 1.36-16.23 2.16-25.76 2.16z'
        clipPath='url(#prefix__c)'
      />
      <path
        d='M96.26 89.25v-9.02H31.74v9.02c9.92 5.83 20.82 9.07 32.26 9.07s22.34-3.24 32.26-9.07z'
        clipPath='url(#prefix__c)'
        fill='#fff'
      />
      <g clipPath='url(#prefix__c)'>
        <defs>
          <path
            id='prefix__d'
            d='M31.74 80.23v9.02c9.92 5.83 20.82 9.07 32.26 9.07 11.44 0 22.33-3.24 32.26-9.07v-9.02H31.74zM64 104.42c-7.14 0-13.36 3.89-16.68 9.66 4.31 2.36 10.19 3.81 16.68 3.81s12.37-1.45 16.68-3.81c-3.32-5.77-9.54-9.66-16.68-9.66z'
          />
        </defs>
      </g>
    </g>
  </SvgIcon>
));

export default Decline;
