import { useNovaApi, useNovaApiCreate, useNovaApiUpdate } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { useDispatch } from 'react-redux';
import { addSuccessNotification } from 'store/notifications';

export const useTags = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.TagsResponse>(`/tag`, {
    query,
  });
};

export const useTag = (uuid: string) => {
  return useNovaApi<Nl.Api.TagResponse>(`/tag/${uuid}`);
};

export const useTagSelect = () => {
  return useNovaApi<Nl.Api.TagSelectResponse>('/tag/select').data?.tags || [];
};

export const useTagUpdate = (uuid: string) => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiUpdate<
    Nl.Api.TagResponse,
    Nl.Api.Tag
  >(`/tag/${uuid}`, (updatedTag) => {
    dispatch(
      addSuccessNotification({
        message: `Tag ${updatedTag.tag.name} has been updated`,
      }),
    );
  });

  return {
    updateTag: mutation.mutate,
    updatedTag: data,
    isSuccess,
  };
};

export const useTagCreate = () => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiCreate<
    Nl.Api.TagResponse,
    Nl.Api.Tag
  >(`/tag`, (createdTag) => {
    dispatch(
      addSuccessNotification({
        message: `Tag ${createdTag.tag.name} has been created`,
      }),
    );
  });

  return {
    createTag: mutation.mutate,
    createdTag: data,
    isSuccess,
  };
};
