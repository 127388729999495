import React, { useEffect, useRef, useState } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

/**
 * This component shows Material-UI Tooltip only for text that has ellipsis
 */

interface AllProps {
  tooltipText: string | React.ReactNode;
  tooltipPosition?: Nl.Api.PropType<TooltipProps, 'placement'>;
  style?: React.CSSProperties;
}

const OverflowTip: React.FC<AllProps> = ({
  tooltipText,
  tooltipPosition,
  style,
  children,
}) => {
  const textElementRef = useRef<HTMLDivElement>(null);

  const compareSize = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const textContainerWidth = textElementRef.current?.clientWidth!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const textWidth = textElementRef.current?.scrollWidth!;
    setHover(textWidth > textContainerWidth);
  };

  useEffect(() => {
    compareSize();
    // add listener when component mounts
    window.addEventListener('resize', compareSize);
    return () => {
      // remove listener when component unmounts
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      title={tooltipText!}
      interactive
      disableHoverListener={!hoverStatus}
      placement={tooltipPosition}
      style={style}
      data-e2e='overflow-tip'
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
