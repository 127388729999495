import * as React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

interface Props extends ButtonProps {
  'aria-label': string;
}

const LightButton = (props: Props) => (
  <Tooltip title={props['aria-label']} placement='bottom'>
    <Button
      style={{ fontWeight: 'inherit', whiteSpace: 'nowrap' }}
      {...props}
    />
  </Tooltip>
);

export default LightButton;
