import { all, put, select, takeLatest } from 'redux-saga/effects';
import { SimpleActionType, createAction } from 'utils/storeUtils';
import { GlobalState } from './rootReducer';
import { routesMap } from '../routes';

const initialState = {
  previousLocation: null as Nl.RouteMapProps | null,
};

export type RouterState = typeof initialState;

const GO_PREVIOUS_PAGE = 'GO_PREVIOUS_PAGE';

export const goPreviousPage = createAction(GO_PREVIOUS_PAGE);

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  if (action.type) {
    const route = routesMap[action.type as Nl.RouteMapType];

    if (route?.type === 'modal') {
      const previousRoute =
        action.meta.location.prev.type &&
        routesMap[action.meta.location.prev.type as Nl.RouteMapType];

      if (previousRoute?.type === 'page') {
        return {
          ...state,
          previousLocation: action.meta.location.prev,
        };
      }
    }
  }
  return state;
};

// Sagas
const sagas = {
  *goPreviousRoute() {
    const selector = (state: GlobalState) => state.router.previousLocation;
    const previousLocation: ReturnType<typeof selector> = yield select(
      selector,
    );

    if (previousLocation?.type) {
      yield put(previousLocation);
    } else {
      const currentRoute: Nl.RouteMapProps = yield select(
        (state: GlobalState) => state.location.routesMap[state.location.type],
      );
      yield put({
        type:
          currentRoute && currentRoute.type === 'modal' && currentRoute.parent,
      });
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([takeLatest(GO_PREVIOUS_PAGE, sagas.goPreviousRoute)]);
}

export default reducer;
