import React from 'react';
import { Form, Field } from 'formik';
import { FormSubTitle, InputWrapper } from 'components/Formik/utils';
import InputField from 'components/Formik/InputField';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import { readOnlyStyle } from 'styles/form';
import { useArtistRepEntries } from 'novaApi/hooks/user';

interface AllProps {
  isOnboardable: boolean;
}

const ArtistSubmissionForm: React.FunctionComponent<AllProps> = ({
  isOnboardable,
}) => {
  const artistRepUsers = useArtistRepEntries();
  return (
    <Form>
      {isOnboardable && (
        <>
          <FormSubTitle title='Onboarding Options' />
          <InputWrapper>
            <Field
              name='assigned_user'
              label='Assigned to'
              component={AutocompleteSelect}
              options={artistRepUsers}
            />
          </InputWrapper>
          <br />
          <br />
        </>
      )}
      <FormSubTitle title='Artist Info' />
      <InputWrapper>
        <Field
          name='first_name'
          label='First Name'
          component={InputField}
          InputProps={readOnlyStyle}
        />
        <Field
          name='last_name'
          label='Last Name'
          component={InputField}
          InputProps={readOnlyStyle}
        />
        <Field
          name='artist_band_name'
          label='Artist Band Name'
          component={InputField}
        />
        <Field
          name='source'
          label='Source'
          component={InputField}
          InputProps={readOnlyStyle}
        />
        <Field
          name='email'
          label='Email'
          component={InputField}
          InputProps={readOnlyStyle}
        />
        <Field
          name='country.name'
          label='Country'
          component={InputField}
          InputProps={readOnlyStyle}
        />
        <Field
          name='music_sounds_like'
          label='Music sounds like?'
          component={InputField}
          InputProps={readOnlyStyle}
          multiline
          rows={4}
          rowsMax={4}
        />
        <Field
          name='hear_about_us'
          label='How did you hear about us?'
          component={InputField}
          InputProps={readOnlyStyle}
          multiline
          rows={4}
          rowsMax={4}
        />
        <Field
          name='is_pro_free'
          label='PRO-Free?'
          component={InputField}
          InputProps={readOnlyStyle}
        />
      </InputWrapper>
    </Form>
  );
};

export default ArtistSubmissionForm;
