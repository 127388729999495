import React from 'react';
import FormikWrapper from 'components/Formik';
import TagCategoryForm, { getFormInitialValues } from './TagCategoryForm';
import { getValidationSchema } from './validation';
import { useTagCategoryCreate } from 'novaApi/hooks/tag_category';
import { replace } from 'redux-first-router';

export default function TagCategoriesCreatePage() {
  const tagCategoryToCreate = getFormInitialValues();
  const {
    createTagCategory,
    createdTagCategory,
    isSuccess,
  } = useTagCategoryCreate();

  const handleFormSubmit = (formData, formActions) => {
    createTagCategory({ formData, formActions });
  };

  if (isSuccess && createdTagCategory) {
    // go to edit page
    replace(`/tag-categories/edit/${createdTagCategory.tag_category.uuid}`);
  }

  return (
    <FormikWrapper
      initialValues={tagCategoryToCreate}
      validationSchema={getValidationSchema('CREATE')}
      submitForm={(formData, formActions) =>
        handleFormSubmit(formData, formActions)
      }
      title='Create tag category'
      customSize='sm'
    >
      <TagCategoryForm />
    </FormikWrapper>
  );
}
