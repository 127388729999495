import { Badge, withStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    badge: {
      top: 10,
      right: -2,
      backgroundColor: theme.customPalette.info,
      border: '2px solid white',
    },
  });

export default withStyles(styles)(Badge);
