import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  fork,
  delay,
  cancelled,
  PutEffect,
} from 'redux-saga/effects';
import {
  assign,
  createAction,
  createTypedAction,
  SimpleActionType,
} from 'utils/storeUtils';
import { apiFetch, FetchResponse, fetchWaveform } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { findIndex, forEach, isEmpty } from 'lodash';
import {
  presignedSourceFileFinalTrack,
  presignedSourceFileNewTrack,
  uploadSourceFile,
} from 'novaApi/NovaApi';
import checksumWorker from 'utils/checksum/checksumWorker';
import { goPreviousPage } from './router';
import { v4 as generateUuid } from 'uuid';
import analyseZipWorker, {
  ZipAnalyzerResult,
} from 'utils/zipAnalyzer/zipAnalyserWorker';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';
import { fetchWaveformsSuccess } from './songWaveform';
import { normalize } from 'utils/utils';

/**
 * Action Constants
 */
const INIT_SINGLE_TRACK_SUBMISSION = 'INIT_SINGLE_TRACK_SUBMISSION';

const FETCH_SINGLE_TRACK_SUBMISSION = 'FETCH_SINGLE_TRACK_SUBMISSION';
const FETCH_SINGLE_TRACK_SUBMISSION_SUCCESS =
  'FETCH_SINGLE_TRACK_SUBMISSION_SUCCESS';
const FETCH_SINGLE_TRACK_SUBMISSION_ERROR =
  'FETCH_SINGLE_TRACK_SUBMISSION_ERROR';

const EDIT_TRACK_SUBMISSION = 'EDIT_TRACK_SUBMISSION';
const EDIT_TRACK_SUBMISSION_ERROR = 'EDIT_TRACK_SUBMISSION_ERROR';
const UPLOAD_MY_TRACK_SUBMISSION = 'UPLOAD_MY_TRACK_SUBMISSION';
const EDIT_MY_TRACK_SUBMISSION = 'EDIT_MY_TRACK_SUBMISSION';
const UPLOAD_FINAL_SOURCE_FILE = 'UPLOAD_FINAL_SOURCE_FILE';
const CREATE_MY_TRACK_SUBMISSION = 'CREATE_MY_TRACK_SUBMISSION';
const CREATE_TRACK_SUBMISSION_ERROR = 'CREATE_TRACK_SUBMISSION_ERROR';
const SUBMIT_FINAL_SOURCE_FILES = 'SUBMIT_FINAL_SOURCE_FILES';

const FETCH_SINGLE_TRACK_SUBMISSION_WAVEFORM =
  'FETCH_SINGLE_TRACK_SUBMISSION_WAVEFORM';
const START_POLL_SINGLE_TRACK_SUBMISSION_WAVEFORM =
  'START_POLL_SINGLE_TRACK_SUBMISSION_WAVEFORM';

/**
 * Action Creators
 */
export const initSingleTrackSubmission = createAction(
  INIT_SINGLE_TRACK_SUBMISSION,
);
export const fetchSingleTrackSubmission = (payload: { uuid: string }) =>
  createTypedAction(FETCH_SINGLE_TRACK_SUBMISSION, payload);
export const fetchSingleTrackSubmissionSuccess = createAction(
  FETCH_SINGLE_TRACK_SUBMISSION_SUCCESS,
);
const fetchSingleTrackSubmissionError = createAction(
  FETCH_SINGLE_TRACK_SUBMISSION_ERROR,
);
export const editTrackSubmission = createAction(EDIT_TRACK_SUBMISSION);
export const uploadMyTrackSubmission = createAction(UPLOAD_MY_TRACK_SUBMISSION);
export const editMyTrackSubmission = createAction(EDIT_MY_TRACK_SUBMISSION);
export const uploadFinalSourceFiles = createAction(UPLOAD_FINAL_SOURCE_FILE);
export const createMyTrackSubmission = createAction(CREATE_MY_TRACK_SUBMISSION);
const createTrackSubmissionError = createAction(CREATE_TRACK_SUBMISSION_ERROR);
export const submitFinalSourceFiles = createAction(SUBMIT_FINAL_SOURCE_FILES);

export const fetchSingleTrackSubmissionWaveform = (payload: {
  waveform_json_url: URL;
  uuid: string;
}) => createTypedAction(FETCH_SINGLE_TRACK_SUBMISSION_WAVEFORM, payload);

export const pollWaveform = (payload: { uuid: string }) =>
  createTypedAction(START_POLL_SINGLE_TRACK_SUBMISSION_WAVEFORM, payload);

/**
 * Reducer
 */
const initialState = {
  data: {
    new_track_submission: {
      name: '' as string,
      source_file: {
        state: '' as Nl.SourceFileUploadState,
        original_filename: '' as string,
        url: '' as string,
        uuid: '' as string,
      } as any,
      description: '' as string,
      required_source_file_types: [],
    } as Partial<Nl.Api.SingleTrackSubmission>,
  },
  isLoading: false,
  isLoaded: false,
};

export type SingleTrackSubmissionState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case INIT_SINGLE_TRACK_SUBMISSION: {
      return initialState;
    }

    case FETCH_SINGLE_TRACK_SUBMISSION: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SINGLE_TRACK_SUBMISSION_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    case EDIT_TRACK_SUBMISSION_ERROR:
    case FETCH_SINGLE_TRACK_SUBMISSION_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }
    default:
      return state;
  }
};

/**
 * This function is called from the saga to start the waveform polling
 * When the waveform is not returned by the API, it usually means
 * the waveform is still being generated by the backend. This is usually
 * a quick task taking a few seconds to complete. We make a few attempts
 * to fetch the waveform at 1-second intervals (timing can be tuned as needed).
 *
 * After a few attempts are made and the waveform is still not available,
 * we abandon. It's not a critical element.
 *
 * If we habitually exhaust attempts to fetch the waveform, and it is
 * a situation to recurs often, get a backend engineer to look into it.
 *
 * Do not dispatch fetchSingleTrackSubmission to get waveform data inside this
 * polling loop. User's form entries will be reset if a state change occurs.
 */
function* pollWaveformLoop(uuid: string) {
  try {
    let attempts = 0;
    const maxAttempts = 16;
    const pollingInterval = 1000;
    while (attempts < maxAttempts) {
      const {
        data,
        success,
      }: FetchResponse<Nl.Api.TrackSubmissionResponse> = yield call(
        apiFetch,
        `/new_track_submission/${uuid}`,
      );
      if (success) {
        const { source_file } = data.new_track_submission;
        if (source_file && source_file.waveform_json_url) {
          const { waveform_json_url, uuid: source_file_uuid } = source_file;
          yield put(
            fetchSingleTrackSubmissionWaveform({
              waveform_json_url,
              uuid: source_file_uuid,
            }),
          );
          break;
        }
      }
      attempts += 1;
      yield delay(pollingInterval);
    }
  } finally {
    // Dispatch an action to log if the loop stop because it was cancelled
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put({ type: 'EVENT LOOP CANCELLED' });
    } else {
      yield put({ type: 'EVENT LOOP ENDED' });
    }
  }
}

/**
 * Sagas
 */
const sagas = {
  *fetchSingleTrackSubmission(
    action: ReturnType<typeof fetchSingleTrackSubmission>,
  ) {
    const { uuid } = action.payload;
    const { data, success, msg } = yield call(
      apiFetch,
      `/new_track_submission/${uuid}`,
    );

    if (success) {
      yield put(fetchSingleTrackSubmissionSuccess({ data }));
      yield put(pollWaveform({ uuid }));
    } else {
      yield put(fetchSingleTrackSubmissionError());
      yield put(addErrorNotification({ message: msg }));
    }
  },
  *editTrackSubmission(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const { success, data, errors } = yield call(
      apiFetch,
      `/new_track_submission/${formData.uuid}`,
      {
        method: 'PUT',
        body: formData,
      },
    );

    if (success) {
      yield put(fetchSingleTrackSubmissionSuccess({ data }));
      yield put(
        addSuccessNotification({ message: 'Track submission updated' }),
      );
    } else {
      formActions.setErrors(errors);
      yield put(
        addErrorNotification({ message: 'Failed to update track submission' }),
      );
    }
    formActions.setSubmitting(false);
  },
  *createMyTrackSubmission(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const {
      success,
      msg,
      errors,
    }: FetchResponse<Nl.Api.TrackSubmissionResponse> = yield call(
      apiFetch,
      '/new_track_submission',
      {
        method: 'POST',
        body: {
          name: formData.name,
          description: formData.description,
          url: formData.source_file.url,
          original_filename: formData.source_file.original_filename,
        },
      },
    );

    if (success) {
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been created successfully!`,
        }),
      );
      invalidateNovaQueries('/new_track_submission');
      yield put(goPreviousPage());
    } else {
      if (errors.url || errors.original_filename) {
        errors.source_file = ['An .mp3 is required'];
      } else {
        yield put(addErrorNotification({ message: msg }));
      }
      formActions.setErrors(errors);
      yield put(createTrackSubmissionError());
    }
    formActions.setSubmitting(false);
  },
  *editMyTrackSubmission(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const payload: Nl.TrackSubmissionEditPayload = { name: formData.name };
    const isFileUploadedState = 'uploaded';

    if (formData.source_file.state === isFileUploadedState) {
      payload.url = formData.source_file.url;
      payload.original_filename = formData.source_file.original_filename;
    }

    const {
      success,
      data,
      errors,
    }: FetchResponse<Nl.Api.TrackSubmissionResponse> = yield call(
      apiFetch,
      `/my_tracks/${formData.uuid}`,
      {
        method: 'PUT',
        body: payload,
      },
    );

    if (success) {
      const { uuid } = formData;
      invalidateNovaQueries('/new_track_submission');
      yield put(fetchSingleTrackSubmissionSuccess({ data }));
      yield put(pollWaveform({ uuid }));
      yield put(
        addSuccessNotification({ message: 'Track updated successfully!' }),
      );
    } else {
      formActions.setStatus(errors);
      yield put(
        addErrorNotification({ message: 'Failed to update track submission' }),
      );
    }
    formActions.setSubmitting(false);
  },
  *uploadMyTrackSubmission(action: SimpleActionType) {
    const { file, form } = action.payload;
    const trackUuid = action.payload?.trackUuid ?? generateUuid();
    form.setErrors({ source_file: [] });
    const fileChecksum: string = yield call(checksumWorker, file);
    form.setFieldValue('source_file.state', 'uploading');

    const res: FetchResponse<Nl.Api.UploadPresignedPostKeyResponse> = yield call(
      presignedSourceFileNewTrack,
      trackUuid,
      fileChecksum,
    );

    const { finalS3Url, eTag } = yield call(
      uploadSourceFile,
      res.data.url,
      res.data.fields,
      file,
    );

    if (eTag === fileChecksum) {
      form.setFieldValue('source_file.state', 'uploaded');
      form.setFieldValue('source_file.original_filename', file.name);
      form.setFieldValue('source_file.url', finalS3Url);
    } else {
      yield put(
        addErrorNotification({
          message: 'There was an issue uploading your MP3. Please try again.',
        }),
      );
      form.setFieldValue('source_file.state', 'init');
    }
  },
  *uploadFinalSourceFile(action: SimpleActionType) {
    const {
      file,
      form,
      fileType,
      humanReadableFiletype,
      channelLayout,
    } = action.payload;
    const fieldIndex = findIndex(form.values.files[channelLayout], [
      'file_type',
      fileType,
    ]);
    const setFormProp = (prop: string, value: string) =>
      form.setFieldValue(
        `files.['${channelLayout}'].${fieldIndex}.${prop}`,
        value,
      );

    setFormProp('error', '');

    /**
     * Sets form props when an error occurs
     * @param {Error} error - The error object with a useful description.
     * Should indicate the nature of the error in a way that the user or a dev can understand what failed.
     * @param {boolean} [retryable] - if true, prompts the user to try again.
     *
     * Because we are firing redux events within a Formik form in order to validate
     * user's inputs, calling form.setErrors clears existing errors that may still
     * be valid instructions for the user. This is why we must inject error messages
     * into the element's props (the same way we flip through the form's state)
     */
    function* onError(error: Error, retryable = true): Generator<PutEffect> {
      setFormProp('state', 'init');
      setFormProp(
        'error',
        `${error.toString()}. ${retryable ? 'Please try again.' : ''}`,
      );
      yield put(addErrorNotification({ message: 'Failed to upload ZIP file' }));
    }

    // Analysis stage: make sure ZIP and the files within ZIP are valid
    setFormProp('state', 'validating');
    let analysisResult: ZipAnalyzerResult;
    try {
      analysisResult = yield call(
        analyseZipWorker,
        file,
        humanReadableFiletype,
      );
    } catch (e) {
      yield onError(e);
      return;
    }
    if (!analysisResult.isValid) {
      yield onError(new Error(analysisResult.msg), false);
      return;
    }

    // Upload stage: transmit the ZIP file to S3
    setFormProp('state', 'uploading');
    let fileChecksum: string;
    try {
      fileChecksum = yield call(checksumWorker, file);
    } catch (e) {
      yield onError(e);
      return;
    }
    const trackUuid = action.payload?.trackUuid ?? generateUuid();
    const getPSKResponse: FetchResponse<Nl.Api.UploadPresignedPostKeyResponse> = yield call(
      presignedSourceFileFinalTrack,
      trackUuid,
      fileType,
      fileChecksum,
    );
    if (!isEmpty(getPSKResponse.errors)) {
      yield onError(new Error('Failed to generate a pre-signed key'));
      return;
    }
    let uploadFileResponse: Nl.Api.UploadSourceFileResponse;
    try {
      uploadFileResponse = yield call(
        uploadSourceFile,
        getPSKResponse.data.url,
        getPSKResponse.data.fields,
        file,
      );
    } catch (e) {
      yield onError(e);
      return;
    }
    if (uploadFileResponse.eTag !== fileChecksum) {
      yield onError(new Error('Checksums did not match'));
      return;
    }

    // Finalization stage: inject upload results into form state
    setFormProp('state', 'uploaded');
    setFormProp('original_filename', file.name);
    setFormProp('url', uploadFileResponse.finalS3Url);
  },
  *submitFinalSourceFiles(action: SimpleActionType) {
    const { formData, formActions } = action.payload;

    const files: { [fileType: string]: string } = {};
    forEach(formData.files, (channelFormat: any) => {
      forEach(channelFormat, (file) => {
        if (file.url) {
          // when user uploads a file but decides to delete it,
          // the url will be empty. Omit those files.
          files[file.file_type] = file.url;
        }
      });
    });
    const {
      success,
      data,
      errors,
    }: FetchResponse<Nl.Api.TrackSubmissionResponse> = yield call(
      apiFetch,
      `/new_track_submission/${formData.uuid}/final_source_files`,
      {
        method: 'POST',
        body: {
          ...formData,
          files,
        },
      },
    );

    if (success) {
      invalidateNovaQueries('/new_track_submission');
      yield put(fetchSingleTrackSubmissionSuccess({ data }));
      yield put(
        addSuccessNotification({
          message:
            'Success! Your files are being processed. Please check back shortly.',
        }),
      );
    } else {
      yield put(addErrorNotification({ message: 'Form Errors ❌' }));
      formActions.setErrors(errors);
    }
    formActions.setSubmitting(false);
  },
  *fetchSingleTrackSubmissionWaveform(
    action: ReturnType<typeof fetchSingleTrackSubmissionWaveform>,
  ) {
    const { uuid, waveform_json_url } = action.payload;
    const { success, errors, waveform } = yield call(
      fetchWaveform,
      waveform_json_url,
    );
    if (success) {
      const scalingFactor = 0.7;
      const normalized_data = {
        data: {
          [uuid]: {
            ...waveform,
            data: normalize(waveform.data, scalingFactor),
          },
        },
      };
      yield put(fetchWaveformsSuccess(normalized_data));
    } else {
      yield put(addErrorNotification({ message: errors }));
    }
  },
  *pollWaveform(action: ReturnType<typeof pollWaveform>) {
    const { uuid } = action.payload;
    // starts the task in the background
    yield fork(pollWaveformLoop, uuid);
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(FETCH_SINGLE_TRACK_SUBMISSION, sagas.fetchSingleTrackSubmission),
    takeLatest(EDIT_TRACK_SUBMISSION, sagas.editTrackSubmission),
    takeLatest(UPLOAD_MY_TRACK_SUBMISSION, sagas.uploadMyTrackSubmission),
    takeLatest(EDIT_MY_TRACK_SUBMISSION, sagas.editMyTrackSubmission),
    takeEvery(UPLOAD_FINAL_SOURCE_FILE, sagas.uploadFinalSourceFile),
    takeLatest(CREATE_MY_TRACK_SUBMISSION, sagas.createMyTrackSubmission),
    takeLatest(SUBMIT_FINAL_SOURCE_FILES, sagas.submitFinalSourceFiles),
    takeLatest(START_POLL_SINGLE_TRACK_SUBMISSION_WAVEFORM, sagas.pollWaveform),
    takeLatest(
      FETCH_SINGLE_TRACK_SUBMISSION_WAVEFORM,
      sagas.fetchSingleTrackSubmissionWaveform,
    ),
  ]);
}

export { sagas };
export default reducer;
