import React from 'react';
import { useNlSelector } from 'utils/redux';

import { getUrlPayloadParams } from 'novaApi/apiUtils';
import FormikWrapper from 'components/Formik';
import TagTermRelationTypeForm, {
  getFormInitialValues,
} from './TagTermRelationTypeForm';
import { getValidationSchema } from './validation';
import {
  useTagTermRelationType,
  useTagTermRelationTypeUpdate,
} from 'novaApi/hooks/tag_term_relation_type';
import { useDispatch } from 'react-redux';
import { goPreviousPage } from 'store/router';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';

export default function TagTermRelationTypesEditPage() {
  const dispatch = useDispatch();
  const { uuid } = useNlSelector(getUrlPayloadParams);
  const { data } = useTagTermRelationType(uuid!);
  const tagTermRelationTypeToEdit = getFormInitialValues(
    data?.tag_term_relation_type,
  );
  const {
    updateTagTermRelationType,
    isSuccess,
    updatedTagTermRelationType,
  } = useTagTermRelationTypeUpdate(uuid!);

  const handleFormSubmit = (formData, formActions) => {
    updateTagTermRelationType({ formData, formActions });
  };

  if (!data) return null;
  if (updatedTagTermRelationType && isSuccess) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={tagTermRelationTypeToEdit}
      validationSchema={getValidationSchema()}
      submitForm={(formData, formActions) =>
        handleFormSubmit(formData, formActions)
      }
      title='Edit tag-term relation type'
      customSize='sm'
    >
      <TagTermRelationTypeForm />

      {uuid && (
        <CollapsableUserLogs
          activityType='tag-term-relation-types'
          resourceUuid={uuid}
        />
      )}
    </FormikWrapper>
  );
}
