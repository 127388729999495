import { put, call, takeLatest } from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from '../utils/storeUtils';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';

const FETCH_TAXONOMY_TAG_ENTITIES = 'FETCH_TAXONOMY_TAG_ENTITIES';
const FETCH_TAXONOMY_TAG_ENTITIES_SUCCESS =
  'FETCH_TAXONOMY_TAG_ENTITIES_SUCCESS';
const FETCH_TAXONOMY_TAG_ENTITIES_ERROR = 'FETCH_TAXONOMY_TAG_ENTITIES_ERROR';

// Action creator
export const fetchTaxonomyTags = createAction(FETCH_TAXONOMY_TAG_ENTITIES);
const fetchTaxonomyTagsSuccess = createAction(
  FETCH_TAXONOMY_TAG_ENTITIES_SUCCESS,
);
const fetchTaxonomyTagsError = createAction(FETCH_TAXONOMY_TAG_ENTITIES_ERROR);

// Reducer
const initialState = {
  data: {
    taxonomy_tags: [] as Nl.Api.SelectorEntity[],
    total_size: 0,
    current_size: undefined as number | undefined,
    current_page: undefined as number | undefined,
    offset: undefined as number | undefined,
    total_pages: undefined as number | undefined,
  },
  isLoading: false,
  isLoaded: false,
};

export type TaxonomyTags = Readonly<typeof initialState>;

const reducer = (state = initialState, action: SimpleActionType) => {
  switch (action.type) {
    case FETCH_TAXONOMY_TAG_ENTITIES_SUCCESS:
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });

    default:
      return state;
  }
};

const sagas = {
  *fetchTaxonomyTagsEntitiesSaga() {
    const results: FetchResponse<Nl.Api.TaxonomyTagsResponse> = yield call(
      apiFetch,
      '/tag/select',
    );
    if (results.success) {
      yield put(
        fetchTaxonomyTagsSuccess({
          data: {
            ...results.data,
            taxonomy_tags: results.data.tags.map((tag) => ({
              name: tag.name,
              uuid: tag.uuid,
            })),
          },
        }),
      );
    } else {
      yield put(fetchTaxonomyTagsError({ error: results.msg }));
    }
  },
};

export function* rootSaga() {
  yield takeLatest(
    FETCH_TAXONOMY_TAG_ENTITIES,
    sagas.fetchTaxonomyTagsEntitiesSaga,
  );
}
export { sagas };
export default reducer;
