import React from 'react';
import { UpsertPage } from 'components/UpsertPage/UpsertPage';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import { getUrlPayloadParams } from 'novaApi/apiUtils';
import { useComparisonUpsert } from 'novaApi/hooks/comparison';
import { useNlSelector } from 'utils/redux';

import ComparisonForm from './ComparisonForm';
import { getValidationSchema } from './validations';

export const UpsertComparisonPage = () => {
  const handleFormSubmit = (action, formData, formActions) => {
    action({ formData, formActions });
  };
  const validations = getValidationSchema();
  const UpsertComparison = UpsertPage(useComparisonUpsert);
  const { uuid } = useNlSelector(getUrlPayloadParams);

  return (
    <UpsertComparison
      handleFormSubmit={handleFormSubmit}
      title={`${uuid ? 'Update' : 'Create'} Comparison`}
      validationSchema={validations}
    >
      <ComparisonForm />
      {uuid && (
        <CollapsableUserLogs activityType='comparisons' resourceUuid={uuid} />
      )}
    </UpsertComparison>
  );
};

export default UpsertComparisonPage;
