import { omitBy, isNil, forOwn } from 'lodash';
import config from '../config';
import { getAuthToken } from '../utils/storedToken';
import { getPageSizeCookie } from '../utils/cookies';
import { GlobalState } from 'store/rootReducer';

const apiUrl = config.clients.api.url;

/**
 * WARNING: this is not encoding special chars 🤦‍♂️
 * But apiFetch does apply encodeURI on the whole URL,
 * so we can't just encode here as it should be without risking double encodding...
 */
export const convertQueryObjToUrlString = (queryObj: {
  [filterKey: string]: string[] | string;
}): string => {
  const filterArray: string[] = [];
  Object.keys(queryObj).forEach((filterKey) => {
    const value = queryObj[filterKey];
    if (Array.isArray(value)) {
      value.forEach((f) => filterArray.push(`${filterKey}=${f}`));
    } else {
      filterArray.push(`${filterKey}=${value}`);
    }
  });
  return filterArray.length > 0 ? `?${filterArray.join('&')}` : '';
};

export const getSearchParamsFromCurrentUrl = (state: GlobalState) => {
  let storeQueries = getRawUrlQueryParams(state);

  // if pagesize cookie is present, default to that in the URL query params
  const pageSizeCookie = getPageSizeCookie();
  if (pageSizeCookie) {
    storeQueries.pagesize = pageSizeCookie.toString();
  }

  // If pagesize is default for the URL, we need to avoid side effect when opening MODAL
  // Need to combine queries
  const currentPage = state.location.routesMap?.[
    state.location.type
  ] as Nl.RouteMapProps;

  if (currentPage?.type === 'modal') {
    const parentPageQuery = (state.location.prev?.query || {}) as {
      [key: string]: string;
    };
    storeQueries = { ...storeQueries, ...parentPageQuery };
  }

  return storeQueries;
};

export const getUrlQueryParams = (state: GlobalState): string => {
  return convertQueryObjToUrlString(getSearchParamsFromCurrentUrl(state));
};

export const getRawUrlQueryParams = (state: GlobalState) => {
  return (omitBy(state.location.query, isNil) || {}) as {
    [key: string]: string;
  };
};

export const getUrlPayloadParams = (state: GlobalState): Nl.RoutePayloadType =>
  // @ts-ignore
  state.location.payload;

const buildOutboundBody = ({
  body,
  fileKeys,
}: {
  body: any;
  fileKeys: boolean;
}) => {
  // Body needs no transformation
  if (!body || body instanceof FormData || typeof body === 'string') {
    return body;
  }

  if (fileKeys) {
    const formData = new FormData();
    forOwn(body, (value, key) => {
      if (value) {
        formData.append(key, value);
      }
    });
    return formData;
  }
  return JSON.stringify(body);
};

export interface NovaApiResponse<Data> {
  data: Data;
  errors: Record<string, any>;
  message: string;
}

export interface FetchResponse<Data> {
  status?: number;
  data: Data;
  errors?: any;
  msg: string;
  success?: boolean;
}

export interface FetchWaveformResponse {
  status: number;
  errors: any;
  success: boolean;
  waveform: Nl.Api.Waveform | null;
}

export const getNovaApiHeaders = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: getAuthToken() || '',
  };
};

/**
 * TODO: Write unit tests for this method and make it more consistent in the way it manages error (throwing vs return { errors })
 */
export async function apiFetch<Data>(
  uri: string,
  options: any = {},
): Promise<FetchResponse<Data>> {
  const body = buildOutboundBody(options);
  const { fileKeys, ...passThroughOpts } = options;
  const fetchOpts = {
    ...passThroughOpts,
    body,
    headers: {
      ...getNovaApiHeaders(),
      ...(options.headers || {}),
    },
  };

  // FormData requires a special content header
  if (body && body instanceof FormData) {
    delete fetchOpts.headers['Content-Type'];
  }

  try {
    const encodedUri = encodeURI(`${apiUrl}${uri}`);
    const res = await fetch(encodedUri, fetchOpts);

    try {
      // try to parse the body
      const resBody: NovaApiResponse<Data> = await res.json();

      return {
        status: res.status,
        data: resBody.data,
        errors: resBody.errors,
        msg: resBody.message,
        success: res.ok,
      };
    } catch (e) {
      // If the body cannot be parsed, still returned the status code
      return {
        status: res.status,
        data: {} as any,
        msg: 'No body in the response',
        success: res.ok,
      };
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      data: {} as any,
      msg:
        'Impossible to complete the request. This may be due to a network failure.',
      success: false,
    };
  }
}

export async function fetchWaveform(uri: URL): Promise<FetchWaveformResponse> {
  try {
    const res = await fetch(uri.toString());

    try {
      const resBody: any = await res.json();

      return {
        status: res.status as number,
        errors: resBody.errors as any,
        success: res.ok as boolean,
        waveform: resBody as Nl.Api.Waveform,
      };
    } catch (e) {
      // If the body cannot be parsed, still returned the status code
      return {
        status: res.status,
        errors: 'Could not parse waveform JSON',
        success: false,
        waveform: null,
      };
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      status: -1,
      errors:
        'Impossible to complete the request. This may be due to a network failure.',
      success: false,
      waveform: null,
    };
  }
}
