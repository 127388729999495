import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 260;

export const useHeaderStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appLogo: {
    width: '235px',
    marginLeft: '13px',
    marginRight: '15px',
    marginTop: '15px',
    marginBottom: '5px',
  },
  appLogoLink: {
    alignSelf: 'center',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'floralwhite',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));
