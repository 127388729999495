import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { useDispatch } from 'react-redux';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import { TableConfigEntry } from 'utils/tableConfig';
import InternalLink from 'components/Link/InternalLink';
import { getReadableDate } from 'utils/utils';
import { useTagCategories } from 'novaApi/hooks/tag_category';
import ConnectedButton from 'components/Button/ConnectedButton';
import DeleteButton from 'components/Button/DeleteButton';
import { deleteTagCategory } from 'store/tagCategory';

const useStyles = makeStyles(styles);

const DeleteTagCategoryButton = ({
  uuid,
  is_deletable,
}: {
  uuid: string;
  is_deletable: boolean;
}) => {
  const dispatch = useDispatch();
  return (
    <DeleteButton
      aria-label={
        (is_deletable && 'Delete') || 'Can not delete category with tags'
      }
      onClick={() => {
        if (
          window.confirm('Are you sure you want to delete this tag category?')
        ) {
          dispatch(deleteTagCategory({ uuid }));
        }
      }}
      disabled={!is_deletable}
      data-e2e='delete_tag_category_button'
    />
  );
};

const tableConfig = (): TableConfigEntry<Nl.Api.TagCategory> => ({
  columnData: [
    {
      id: 'name',
      label: 'Name',
      cellProps: { width: '50%' },
      isSortable: true,
      render: ({ uuid, name }) => (
        <InternalLink
          color='inherit'
          to={{ type: 'route/TAG_CATEGORIES_EDIT', payload: { uuid } }}
        >
          {name}
        </InternalLink>
      ),
    },
    {
      id: 'created_at',
      label: 'Created',
      isSortable: true,
      render: ({ created_at }) => getReadableDate(created_at) || '-',
    },
    {
      id: 'updated_at',
      label: 'Last Modified',
      isSortable: true,
      render: ({ updated_at }) => getReadableDate(updated_at) || '-',
    },
    {
      id: 'delete',
      label: '',
      permission: 'CAN_DELETE_TAG_CATEGORY',
      render: ({ uuid, is_deletable }) => (
        <DeleteTagCategoryButton uuid={uuid} is_deletable={is_deletable} />
      ),
    },
  ],
});

export default function TagCategoriesPage() {
  const classes = useStyles({});
  const { data, isLoaded } = useTagCategories();

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Tag Categories'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'tagCategory-search-name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/TAG_CATEGORIES_CREATE'
            label='Create new Tag Category'
            permission='CAN_CREATE_TAG_CATEGORY'
          />
        }
      />
      <Table
        config={tableConfig()}
        data={data?.tag_categories}
        isLoaded={isLoaded}
        paginationData={data}
      />
    </Paper>
  );
}
