import { select, take, spawn, call, put } from 'redux-saga/effects';
import { Location, LocationState } from 'redux-first-router';
import { routesMap } from '../routes';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';
import { actions as authActions } from './auth';
import { startPollingUserMessages } from './userMessages';
import { GlobalState } from './rootReducer';
import { getAuthToken } from 'utils/storedToken';
import { SimpleActionType } from 'utils/storeUtils';

export function* routes() {
  const { location }: { location: LocationState } = yield select();
  const token = getAuthToken();

  if (token) {
    const response: FetchResponse<any> = yield call(
      apiFetch,
      '/auth/validate',
      {
        method: 'POST',
        body: { token },
      },
    );
    if (response.success) {
      yield put(
        authActions.onAuthenticationSuccess({
          user: response.data,
          token,
        }),
      );
      yield put(startPollingUserMessages());

      // Run saga in route map that matches initialRoute if exists
      const targetRoute: Nl.RouteMapProps =
        routesMap[location.type as Nl.RouteMapType];
      if (typeof targetRoute === 'object') {
        if (targetRoute.saga) {
          yield spawn(targetRoute.saga, {
            type: location.type,
            payload: location.payload,
          });
        }
      }
    } else {
      yield put(authActions.onAuthenticationError({ error: response.msg }));
    }
  } else {
    yield put(authActions.onAuthenticationError());
  }

  // Watch for future navigation events and run the correct leave and enter saga if needed
  while (true) {
    const action: SimpleActionType = yield take(Object.keys(routesMap));
    const previousLocation: Location = yield select(
      (state: GlobalState) => state.location.prev,
    );
    const previousRoute: Nl.RouteMapProps =
      routesMap[previousLocation.type as Nl.RouteMapType];

    if (typeof previousRoute === 'object' && previousRoute.onLeaveSaga) {
      yield spawn(previousRoute.onLeaveSaga, previousLocation);
    }
    const newRoute: Nl.RouteMapProps =
      routesMap[action.type as Nl.RouteMapType];

    if (typeof newRoute === 'object' && newRoute.saga) {
      yield spawn(newRoute.saga, action);
    }
  }
}
