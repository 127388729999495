import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { useNlSelector } from 'utils/redux';
import { useNovaApi } from '../useNovaApi';

export const usePROEarnings = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.PROEarningsResponse>('/pro_earning', {
    query,
  });
};
