import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import {
  getEditFormInitialValues,
  createSongCollection,
} from 'store/songCollections';
import { selectNotificationStatus } from 'store/selectors';
import SongCollectionForm from './SongCollectionForm';
import validationSchema from 'utils/basicYupValidation';
import { useNlSelector } from 'utils/redux';

export default function SongCollectionCreatePage() {
  const notificationStatus = useNlSelector(selectNotificationStatus);
  const dispatch = useDispatch();
  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues()}
      validationSchema={validationSchema(notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(createSongCollection({ formData, formActions }))
      }
      title='Create New Song Collection'
    >
      <SongCollectionForm />
    </FormikWrapper>
  );
}
