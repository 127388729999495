import React from 'react';
import { FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { get } from 'lodash';
import { FieldProps } from 'formik';

interface Props extends FieldProps {
  label: string;
  disabled?: boolean;
}

export default ({ label, form, field, disabled }: Props) => {
  const fieldError = get(form.errors, field.name);
  const statusError = get(form.status, field.name);
  const hasError = Boolean(fieldError) || Boolean(statusError);

  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            checked={!!field.value}
            data-e2e={`checkbox-${field.name}`}
            data-e2e-value={!!field.value}
            onChange={() => {
              form.setFieldValue(field.name, !field.value);
              if (hasError) {
                const { errors } = form;
                errors[field.name] = [];
                form.setErrors({ errors });
              }
            }}
            disabled={disabled}
          />
        }
      />
      <FormHelperText
        style={{ display: hasError ? 'block' : 'none', margin: 0 }}
        error={hasError}
      >
        {fieldError || statusError}
      </FormHelperText>
    </>
  );
};
