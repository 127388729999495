import { useNovaApi } from 'novaApi/useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';

export const useReports = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.ReportsResponse>('/report', {
    query,
  }).data;
};
