import * as React from 'react';
import { connect } from 'react-redux';
import { Tooltip, Button, IconButton, Icon } from '@material-ui/core';
import { selectors as authSelectors } from '../../store/auth';
import { GlobalState } from 'store/rootReducer';
import { isUserPermitted } from '../../utils/authUtils';
import { ButtonProps } from '@material-ui/core/Button';
import Link from 'redux-first-router-link';

interface OwnProps extends ButtonProps {
  route: Nl.RouteMapType;
  label: string;
  icon?: string;
  permission?: Nl.UserPermissionType;
  size?: 'small' | 'medium' | 'large';
  fab?: boolean;
}

interface StateProps {
  userPermissions: Nl.UserPermissionType[];
}

type AllProps = OwnProps & StateProps;

const ConnectedButton = ({
  icon,
  label,
  route,
  userPermissions,
  permission,
  fab,
}: AllProps) => {
  return fab ? (
    <Tooltip title={label} placement='bottom'>
      <IconButton
        to={{ type: route }}
        component={Link}
        data-e2e='create_entity'
        disabled={!isUserPermitted(userPermissions, permission)}
      >
        {icon && <Icon>{icon}</Icon>}
      </IconButton>
    </Tooltip>
  ) : (
    <Button
      to={{ type: route }}
      component={Link}
      data-e2e='create_entity'
      disabled={!isUserPermitted(userPermissions, permission)}
    >
      {icon && <Icon>{icon}</Icon>}
      {label}
    </Button>
  );
};

export default connect((state: GlobalState) => ({
  userPermissions: authSelectors.getUserPermissions(state),
}))(ConnectedButton);
