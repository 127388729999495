import React, { useState, useEffect } from 'react';
import MyTrackUploadDropzone from './MyTrackUploadDropzone';
import TrackSubmissionsTable from 'pages/Submissions/TrackSubmissionsTable';
import { Button } from '@material-ui/core';
import { Field } from 'formik';
import { get } from 'lodash';
import {
  selectTrackSubmissionSourceFile,
  selectNotificationStatus,
} from 'store/selectors';
import { useNlSelector } from 'utils/redux';
import { FieldAttributes } from 'formik/dist/Field';

interface AllProps {
  isReuploadAllowed: boolean;
}

const MyTrackMP3Submission: React.FunctionComponent<AllProps> = ({
  isReuploadAllowed,
}) => {
  const [isReuploadButtonClicked, setIsReuploadButtonClicked] = useState(false);
  const [
    isUploadInProgressOrCompleted,
    setIsUploadInProgressOrCompleted,
  ] = useState(false);
  const sourceFile = useNlSelector(
    (state) => selectTrackSubmissionSourceFile(state)!,
  );
  const notificationStatus = useNlSelector((state) =>
    get(selectNotificationStatus(state), 'status', ''),
  );

  // This checks if the form has been successfully submitted and
  // resets MP3 track submission player to default
  useEffect(() => {
    if (notificationStatus === 'success') {
      setIsReuploadButtonClicked(false);
      setIsUploadInProgressOrCompleted(false);
    }
  }, [notificationStatus]);

  const renderTrackSubmissionTable = () => (
    <>
      <TrackSubmissionsTable sourceFiles={[sourceFile]} />
      {isReuploadAllowed && (
        <Button
          style={{ marginTop: '.5rem', backgroundColor: '#E19832' }}
          variant='contained'
          color='primary'
          size='small'
          onClick={() => setIsReuploadButtonClicked(true)}
          data-e2e='btn-reupload'
        >
          Reupload
        </Button>
      )}
    </>
  );

  const renderDropzone = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Field
        name='source_file'
        component={({ form, field }: FieldAttributes<any>) => {
          return (
            <MyTrackUploadDropzone
              trackUuid={sourceFile.uuid}
              form={form}
              state={field.value.state}
              original_filename={field.value.original_filename}
              setIsUploadInProgressOrCompleted={
                setIsUploadInProgressOrCompleted
              }
              fileType='mp3'
              isEdit
            />
          );
        }}
      />
      {!isUploadInProgressOrCompleted && (
        <Button
          style={{ backgroundColor: '#CB5950', marginLeft: '1rem' }}
          variant='contained'
          color='primary'
          size='small'
          onClick={() => setIsReuploadButtonClicked(false)}
        >
          Cancel
        </Button>
      )}
    </div>
  );

  return isReuploadButtonClicked
    ? renderDropzone()
    : renderTrackSubmissionTable();
};

export default MyTrackMP3Submission;
