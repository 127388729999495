import React from 'react';
import { makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';

const useStyles = makeStyles(styles);

interface AllProps {
  isUpdateAllowed: boolean;
  assignedToEmail: string;
}

/**
 * A message box conditionally rendered above the Edit My Profile form
 */
const EditMyProfileMessage: React.FunctionComponent<AllProps> = ({
  isUpdateAllowed,
  assignedToEmail,
}) => {
  const classes = useStyles({});

  if (isUpdateAllowed) {
    return null;
  }

  return (
    <>
      <div className={classes.infoTextInForm}>
        To make any modifications to your contact information, please contact
        your artist rep at {assignedToEmail}
      </div>
      <br />
    </>
  );
};

export default EditMyProfileMessage;
