import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getUrlPayloadParams,
  getSearchParamsFromCurrentUrl,
} from 'novaApi/apiUtils';
import { addSuccessNotification } from 'store/notifications';
import { goPreviousPage } from 'store/router';
import { useNlSelector } from 'utils/redux';
import { useNovaApi, useNovaApiCreate, useNovaApiUpdate } from '../useNovaApi';
import { NovaApiUpserted } from './types';

export const useComparisonSelect = () => {
  return (
    useNovaApi<Nl.Api.ComparisonSelectResponse>('/comparison/select').data
      ?.comparisons || []
  );
};

export const useComparisons = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.ComparisonsResponse>(`/comparison`, {
    query,
  });
};

export const useComparison = (uuid?: string) => {
  const comparisonData = {
    comparison: {
      uuid: '',
      name: '',
      definition: '',
      procedural_definition: '',
      created_at: '',
      updated_at: '',
    },
  };
  const path = uuid ? `/comparison/${uuid}` : undefined;
  return useNovaApi<Nl.Api.ComparisonResponse>(path, {
    initialData: comparisonData,
  });
};

export const useComparisonUpsert = (): NovaApiUpserted<
  Nl.Api.ComparisonResponse,
  Nl.Api.Comparison
> => {
  const { uuid } = useNlSelector(getUrlPayloadParams);

  const dispatch = useDispatch();
  const {
    mutation: mutationCreate,
    data: dataCreate,
    isSuccess: isSuccessCreate,
  } = useNovaApiCreate<Nl.Api.ComparisonResponse, Nl.Api.Comparison>(
    '/comparison',
    (comparisonData) => {
      dispatch(
        addSuccessNotification({
          message: `Comparison ${comparisonData.comparison.name} has been created`,
        }),
      );
    },
  );

  const {
    mutation: mutationUpdate,
    data: dataUpdate,
    isSuccess: isSuccessUpdate,
  } = useNovaApiUpdate<Nl.Api.ComparisonResponse, Nl.Api.Comparison>(
    `/comparison/${uuid}`,
    (comparisonData) => {
      dispatch(
        addSuccessNotification({
          message: `Comparison ${comparisonData.comparison.name} has been updated`,
        }),
      );
    },
  );
  const initial = useComparison(uuid);
  const data = uuid ? dataUpdate : dataCreate;
  const mutation = uuid ? mutationUpdate : mutationCreate;
  const isSuccess = uuid ? isSuccessUpdate : isSuccessCreate;

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(goPreviousPage());
    }
  }, [isSuccess, data, dispatch]);

  return {
    action: mutation.mutate,
    data: initial.data!.comparison,
  };
};
