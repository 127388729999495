import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'formik';
import { FormSubTitle, InputWrapper } from 'components/Formik/utils';
import InputField from 'components/Formik/InputField';
import CheckboxField from 'components/Formik/CheckboxField';
import { GlobalState } from 'store/rootReducer';

interface AllProps {
  isNameEditable: boolean;
}

const TrackSubmissionForm: React.FunctionComponent<AllProps> = ({
  isNameEditable,
}) => {
  return (
    <Form>
      <br />
      <InputWrapper>
        <div>
          <FormSubTitle title='Basic Info' />
          <Field
            name='name'
            label='Track name'
            component={InputField}
            disabled={!isNameEditable}
          />
          <br />
          <Field
            name='description'
            label='Track Description'
            component={InputField}
            multiline
            rows={4}
            rowsMax={4}
            disabled
          />
        </div>
        <div>
          <FormSubTitle title='Audio Properties' />
          <Field
            name='is_logo'
            label='This track is an audio logo. File delivery will not require shorts or loops'
            component={CheckboxField}
          />
          <br />
          <Field
            name='has_lyrics'
            label='This track contains lyrics and requires a lyrics field'
            component={CheckboxField}
          />
          <br />
          <Field
            name='is_ambisonic'
            label='This track requires an ambisonic version'
            component={CheckboxField}
          />
          <br />
          <Field
            name='is_surround_5_1'
            label='This track requires a 5.1 surround version'
            component={CheckboxField}
          />
        </div>
      </InputWrapper>
      <br />
    </Form>
  );
};

const mapStateToProps = (state: GlobalState) => ({});

export default connect(mapStateToProps)(TrackSubmissionForm);
