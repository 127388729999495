import * as React from 'react';
import { Theme, Chip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    high: {
      backgroundColor: theme.customPalette.success,
      marginRight: theme.spacing(1),
    },
    medium: {
      backgroundColor: theme.customPalette.warning,
      marginRight: theme.spacing(1),
    },
    low: {
      // @ts-ignore
      backgroundColor: theme.palette.error[300],
      marginRight: theme.spacing(1),
    },
    blue: {
      backgroundColor: 'dodgerblue',
      marginRight: theme.spacing(1),
    },
    label: {
      ...theme.typography.body1,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: 'white',
    },
    red: {
      backgroundColor: theme.customPalette.error,
      marginRight: theme.spacing(1),
    },
  }),
);

interface Props {
  className?: string;
  label: string;
  indicator: 'high' | 'medium' | 'low' | 'blue' | 'red';
}

const ChipMeter: React.SFC<Props> = ({ className, label, indicator }) => {
  const classes = useStyles();

  return (
    <Chip
      className={className}
      data-e2e={label}
      classes={{
        root: classes[indicator],
        label: classes.label,
      }}
      label={label}
    />
  );
};

export default ChipMeter;
