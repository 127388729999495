import { select, put, call, takeEvery } from 'redux-saga/effects';
import {
  SimpleActionType,
  assign,
  createTypedAction,
} from '../utils/storeUtils';
import { fetchSourceFileWaveforms } from 'novaApi/NovaApi';
import keyBy from 'lodash/keyBy';
import difference from 'lodash/difference';
import { GlobalState } from './rootReducer';
import { normalize } from 'utils/utils';

export const selectWaveform = (state: GlobalState, uuid: string) => {
  return state.waveform[uuid];
};

export const selectWaveformUuids = (state: GlobalState) => {
  return Object.keys(state.waveform);
};

const FETCH_WAVEFORMS = 'FETCH_WAVEFORMS';
const FETCH_WAVEFORMS_SUCCESS = 'FETCH_WAVEFORMS_SUCCESS';
const FETCH_WAVEFORMS_ERROR = 'FETCH_WAVEFORMS_ERROR';

// Action creator
export const fetchWaveforms = (payload: { uuids: string[] }) =>
  createTypedAction(FETCH_WAVEFORMS, payload);
export const fetchWaveformsSuccess = (payload: { data: WaveformList }) =>
  createTypedAction(FETCH_WAVEFORMS_SUCCESS, payload);
const fetchWaveformsError = (payload: { error: string }) =>
  createTypedAction(FETCH_WAVEFORMS_ERROR, payload);

type WaveformList = { [uuid: string]: Nl.Api.Waveform };

// Reducer
const initialState = {} as WaveformList;

export type WaveformState = Readonly<typeof initialState>;

const reducer = (state = initialState, action: SimpleActionType) => {
  switch (action.type) {
    case FETCH_WAVEFORMS_SUCCESS:
      return assign(state, {
        ...action.payload.data,
      });

    default:
      return state;
  }
};

const sagas = {
  *fetchWaveformsSaga(action: ReturnType<typeof fetchWaveforms>) {
    const { uuids } = action.payload;
    // get the uuids of waveform not yet fetched
    const uuidsOfWaveforms: ReturnType<
      typeof selectWaveformUuids
    > = yield select(selectWaveformUuids);
    const uuidsNotFetched = difference(uuids, uuidsOfWaveforms);
    try {
      const waveforms: Nl.Waveform[] = yield call(
        fetchSourceFileWaveforms,
        uuidsNotFetched,
      );
      // Useless to have non-normalized waveforms anywhere in the UI.
      // Therefore, we perform the calculation once in the store.
      // 70% scale ensures the loop seam indicators are not obstructed by the waveform.
      // If scaling needs to one day be at the discretion of a component, simply move into
      // the action.payload as a prop
      const scalingFactor = 0.7;
      const normalizedWaveforms: Nl.Waveform[] = waveforms.map((waveform) => ({
        ...waveform,
        data: normalize(waveform.data, scalingFactor),
      }));
      const data: WaveformList = keyBy(
        normalizedWaveforms,
        (waveform) => waveform.uuid,
      );
      yield put(fetchWaveformsSuccess({ data }));
    } catch (err) {
      yield put(fetchWaveformsError({ error: err.message }));
    }
  },
};

export function* rootSaga() {
  yield takeEvery(FETCH_WAVEFORMS, sagas.fetchWaveformsSaga);
}
export { sagas };
export default reducer;
