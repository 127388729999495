import React from 'react';
import { useDispatch } from 'react-redux';
import { Paper, Checkbox, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import {
  totalSongsSelector,
  currentSizeSelector,
  areSongsFilteredSelector,
  selectSongs,
  areSongsLoaded,
} from 'store/songs';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import UpdateAllNotifcation from 'components/Table/UpdateAllNotification';
import { setBatchUpdateStage } from 'store/batchUpdate';
import useSelectableTable from 'hooks/useSelectableTable';
import { useNlSelector } from 'utils/redux';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import SongsFilters from './SongsFilters';
import SongsActions from './SongsActions';
import SongBulkActionBar from './SongBulkActionBar';
import { TableConfigEntry } from 'utils/tableConfig';
import InternalLink from 'components/Link/InternalLink';
import AudioControlIcon from 'components/AudioControlIcon';
import { getReadableDate } from 'utils/utils';
import PublicationMeter from 'components/Chip/PublicationMeter';

const useStyles = makeStyles(styles);

const tableConfig = (
  selected: ReturnType<typeof useSelectableTable>['selected'],
  handleSelection: ReturnType<typeof useSelectableTable>['handleSelection'],
): TableConfigEntry<Nl.Api.SongSummary> => ({
  storeKey: 'songs',
  columnData: [
    {
      id: 'selectable',
      checkbox: true,
      cellProps: { role: 'checkbox', padding: 'checkbox' },
      render: ({ uuid, work_title }) => (
        <Checkbox
          aria-label={`Select ${work_title}`}
          checked={selected.includes(uuid)}
          color='primary'
          onClick={() =>
            selected.includes(uuid)
              ? handleSelection(selected.filter((s) => s !== uuid))
              : handleSelection(selected.concat(uuid))
          }
        />
      ),
    },
    {
      id: 'play_track',
      label: '',
      render: ({ full_source_file, work_title }) => (
        <AudioControlIcon
          audioTrack={{
            uuid: full_source_file?.uuid ?? '',
            trackType: 'song',
            url: full_source_file?.original_wav_url,
            trackName: work_title,
            duration: full_source_file ? full_source_file.duration : 0,
          }}
        />
      ),
    },
    {
      id: 'work_title',
      label: 'Work Title',
      isSortable: true,
      render: ({ uuid, work_title }) => (
        <InternalLink
          color='inherit'
          to={{ type: 'route/SONG_EDIT', payload: { uuid } }}
        >
          {work_title}
        </InternalLink>
      ),
    },
    {
      id: 'artist',
      label: 'Artist',
      isSortable: true,
      render: ({ artist }) => artist?.name || '-',
    },
    { id: 'song_collection_count', label: 'Collections', isSortable: true },
    { id: 'source_files_count', label: 'Source files', isSortable: true },
    {
      id: 'curation_state',
      label: 'Curation State',
      render: ({ curation_state }) => curation_state?.name || '-',
    },
    {
      id: 'publish_state',
      label: 'Publish State',
      render: ({ publish_state }) => (
        <PublicationMeter publishState={publish_state} />
      ),
    },
    {
      id: 'created_at',
      label: 'Created',
      isSortable: true,
      render: ({ created_at }) => getReadableDate(created_at) || '-',
    },
    {
      id: 'updated_at',
      label: 'Last Modified',
      isSortable: true,
      render: ({ updated_at }) => getReadableDate(updated_at) || '-',
    },
  ],
});

export default function SongsPage() {
  const data = useNlSelector(selectSongs);
  const isLoaded = useNlSelector(areSongsLoaded);
  const totalSongs = useNlSelector(totalSongsSelector);
  const currentSize = useNlSelector(currentSizeSelector);
  const areSongsFiltered = useNlSelector(areSongsFilteredSelector);

  const dispatch = useDispatch();
  const onSelectAllSongs = (stage: Nl.BatchUpdateStages) =>
    dispatch(setBatchUpdateStage({ stage }));

  const classes = useStyles({});
  const {
    selectionStatus,
    selected,
    handleSelection,
    handleSelectAll,
  } = useSelectableTable(data, currentSize, onSelectAllSongs);

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Songs'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by title',
                queryFilterKey: 'work_title',
                e2e: 'name',
              },
            ]}
          />
        }
        filters={<SongsFilters />}
        actions={<SongsActions />}
        bulkActions={(props) => <SongBulkActionBar {...props} />}
        selected={selected}
        onReset={() => handleSelection([])}
      />
      {areSongsFiltered && (
        <UpdateAllNotifcation
          type='songs'
          totalSize={totalSongs}
          currentSize={currentSize}
          setStage={onSelectAllSongs}
          onReset={() => handleSelection([])}
        />
      )}
      <Table
        config={tableConfig(selected, handleSelection)}
        data={data}
        isLoaded={isLoaded}
        selectionStatus={selectionStatus}
        selectAll={handleSelectAll}
      />
    </Paper>
  );
}
