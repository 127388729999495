import * as React from 'react';
import classnames from 'classnames';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    '@keyframes highlight': {
      '0%': {
        backgroundColor: 'inherit',
        color: 'inherit',
      },
      '50%': {
        backgroundColor: theme.customPalette.warning,
        color: 'white',
      },
      '100%': {
        backgroundColor: 'inherit',
        color: 'inherit',
      },
    },
    root: {
      animation: 'highlight 1s linear 2s alternate',
    },
  });

interface OwnProps extends WithStyles<typeof styles> {
  disabled?: boolean;
}

const HighlightOnLoad: React.SFC<OwnProps> = ({
  children,
  classes,
  disabled,
}) => {
  const child = React.Children.only(children) as React.ReactElement<any>;
  const className = !disabled
    ? classnames(child.props.className, classes.root)
    : child.props.className;
  return React.cloneElement(child, { className });
};

export default withStyles(styles)(HighlightOnLoad);
