import { UserActivityType, useUserActivity } from 'novaApi/hooks/user_activity';
import * as React from 'react';
import UserLogList from '../UserLogList';
import ActivityLogItem from './ActivityLogItem';

interface OwnProps {
  className?: string;
  spaceAround?: boolean;
  alignRight?: boolean;
  activityType?: UserActivityType;
  resourceUuid?: string;
}

export default function ActivityLogList({
  className,
  alignRight,
  spaceAround,
  activityType,
  resourceUuid,
}: OwnProps) {
  const { userActivity, isLoaded } = useUserActivity(
    activityType,
    resourceUuid,
  );

  const getMessage = () => {
    if (isLoaded && !userActivity.length) {
      return 'No Log data found';
    }
    return 'Waiting for activity...';
  };

  return (
    <UserLogList
      className={className}
      title='User Activity'
      items={userActivity}
      componentBuilder={(activity: Nl.Api.UserActivity, idx) => (
        <ActivityLogItem
          activity={activity}
          key={idx}
          alignRight={alignRight}
        />
      )}
      emptyMessage={getMessage()}
      spaceAround={spaceAround}
      alignRight={alignRight}
    />
  );
}
