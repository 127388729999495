import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { GlobalState } from 'store/rootReducer';
import {
  selectIsLoaded,
  selectMyWriters,
  selectSingleTrackSubmission,
} from 'store/selectors';
import FormikWrapper from 'components/Formik';
import NotesWrapper from 'components/Notes/NotesWrapper';
import MyTrackEditForm from './MyTrackEditForm';
import MyTrackActions from '../MyTracksActions';
import {
  editMyTrackSubmission,
  submitFinalSourceFiles,
} from 'store/singleTrackSubmission';
import GlobalWarning from 'components/GlobalWarning';
import { getValidationSchema } from '../validation';
import { myTrackInitialValues } from '../MyTracksData';

interface AllProps {
  trackSubmission: Nl.Api.SingleTrackSubmission;
  myWriters: Nl.Api.Writer[];
  isLoaded: boolean;
}

const RevisionWarning = () => (
  <div style={{ marginBottom: '1rem' }}>
    <GlobalWarning>
      Your artist rep has requested that you revise your track submission.
      Please see notes in the communication section below.
    </GlobalWarning>
  </div>
);

const MyTrackEditPage: React.FC<AllProps> = ({
  trackSubmission,
  myWriters,
  isLoaded,
}) => {
  const initialValues = myTrackInitialValues(trackSubmission, myWriters)!;
  const {
    artist_can_edit_name,
    is_revisable,
    requires_revision,
    final_source_files_can_be_submitted,
    has_lyrics,
    required_source_file_types,
    channel_layout_types,
  } = initialValues;
  const dispatch = useDispatch();
  const isReuploadAllowed = is_revisable || requires_revision;
  const submitForm = final_source_files_can_be_submitted
    ? submitFinalSourceFiles
    : editMyTrackSubmission;
  if (!isLoaded) {
    return null;
  }
  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={getValidationSchema(
        final_source_files_can_be_submitted,
        has_lyrics,
      )}
      submitForm={(formData, formActions) =>
        dispatch(submitForm({ formActions, formData }))
      }
      title='My Track'
      customSize='lg'
      extraActions={<MyTrackActions track={trackSubmission} disablePlayBtn />}
    >
      <>
        {requires_revision && <RevisionWarning />}
        <MyTrackEditForm
          isNameEditable={!!artist_can_edit_name}
          isReuploadAllowed={!!isReuploadAllowed}
          isReadyForDelivery={final_source_files_can_be_submitted}
          hasLyrics={has_lyrics}
          requiredSourceFiles={required_source_file_types}
          channelLayoutTypes={channel_layout_types}
          trackUuid={trackSubmission.uuid}
        />
        <NotesWrapper
          config='my_track_submission'
          uuid={trackSubmission.uuid}
        />
      </>
    </FormikWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  trackSubmission: selectSingleTrackSubmission(
    state,
  ) as Nl.Api.SingleTrackSubmission,
  myWriters: selectMyWriters(state),
  isLoaded: selectIsLoaded(state, 'singleTrackSubmission'),
});

export default connect(mapStateToProps)(MyTrackEditPage);
