import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';

export const getValidationSchema = () => {
  const shape = {
    name: Yup.string().required(errorMessages.basicRequired),
  };

  return Yup.object().shape(shape);
};
