import cookie from 'js-cookie';

const PAGE_SIZE_COOKIE_NAME = 'pagesize';

const getCookie = (name: string): any => cookie.get(name);
const setCookie = (name: string, value: string) => cookie.set(name, value);

export const setPageSizeCookie = (value: number) =>
  setCookie(PAGE_SIZE_COOKIE_NAME, String(value));
export const getPageSizeCookie = (): number | undefined => {
  const cook = getCookie(PAGE_SIZE_COOKIE_NAME);
  return cook ? parseInt(cook, 10) : undefined;
};
