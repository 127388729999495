export const mapColumnIdToDateE2e = (id: string) => {
  switch (id) {
    case 'category':
      return 'col_categories';
    case 'collection':
      return 'col_collections';
    default:
      return `col_${id}`;
  }
};
