import * as React from 'react';
import UserActivityStream from 'components/UserLogs/ActivityLogs/ActivityLogList';

const UserActivityPage: React.FunctionComponent = () => {
  return (
    <div data-e2e='pageHome'>
      <UserActivityStream spaceAround />
    </div>
  );
};

export default UserActivityPage;
