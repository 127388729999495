import { all, call, put, takeEvery } from 'redux-saga/effects';
import analyseZipWorker from 'utils/zipAnalyzer/zipAnalyserWorker';
import { FetchResponse } from 'novaApi/apiUtils';
import { createTypedAction } from 'utils/storeUtils';
import checksumWorker from 'utils/checksum/checksumWorker';
import {
  presignedRevisionSourceFile,
  presignedSourceFile,
  uploadSourceFile,
} from 'novaApi/NovaApi';
import { addErrorNotification } from './notifications';
import analyseWavWorker from 'utils/wavAnalyzer/wavAnalyserWorker';
import { getWavRequirements } from 'utils/songSourceFile';

const UPLOAD_FILE = 'UPLOAD_FILE';

const UPLOAD_REVISION_FILE = 'UPLOAD_REVISION_FILE';

export type UploadPayload = {
  contentType: 'application/zip' | 'audio/wav';
  uuid: string;
  file: File;
  type: string;
  formValues: Nl.UploadFormValues;
};

export type UploadRevisionPayload = {
  contentType: 'application/zip' | 'audio/wav';
  revisionUuid: string;
  sourceFileUuid: string;
  file: File;
  type: Nl.SongSourceFileType;
  formValues: Nl.UploadFormValues;
};

export const uploadFile = (payload: UploadPayload) =>
  createTypedAction(UPLOAD_FILE, payload);

export const uploadRevisionFile = (payload: UploadRevisionPayload) =>
  createTypedAction(UPLOAD_REVISION_FILE, payload);

// Sagas
export const sagas = {
  *uploadSourceFileSaga(action: ReturnType<typeof uploadFile>) {
    const { contentType, uuid, file, type, formValues } = action.payload;

    const isReupload: boolean = contentType === 'audio/wav';
    const uploadMap = {
      'audio/wav': {
        worker: analyseWavWorker,
        urlKey: 'url_wav',
      },
      'application/zip': {
        worker: analyseZipWorker,
        urlKey: 'url_zip',
      },
    };

    // Update the source file's state storing directly in the form object
    formValues.set(`${formValues.field}.state`, 'validating');

    const { worker } = uploadMap[contentType];

    // Do the zip validation by calling the zip-analyzer
    const { isValid, msg } = yield call<typeof worker>(worker, file, type);

    if (!isValid) {
      // If the zip is not valid, reset the source file upload state for user feedback
      formValues.set(`${formValues.field}.state`, 'init');
      yield put(addErrorNotification({ message: msg }));
    } else {
      // If the zip is valid then continue the upload process
      // Compute the checksum (eTag)
      const fileChecksum: string = yield call(checksumWorker, file);
      formValues.set(`${formValues.field}.state`, 'uploading');

      // Get the pre-signed key for uploading the file to aws
      const res: FetchResponse<Nl.Api.UploadPresignedPostKeyResponse> = yield call(
        presignedSourceFile,
        uuid,
        fileChecksum,
        contentType,
        isReupload ? file.name : undefined,
      );

      // Upload the file to AWS
      const { finalS3Url, eTag } = yield call(
        uploadSourceFile,
        res.data.url,
        res.data.fields,
        file,
      );

      // If the file's checksum is verified
      if (eTag === fileChecksum) {
        // then update the formValues with the zip's url
        // The filename and upload state are updated only for user feedback
        formValues.set(`${formValues.field}.filename`, file.name);
        formValues.set(
          `${formValues.field}.${uploadMap[contentType].urlKey}`,
          finalS3Url,
        );
        formValues.set(`${formValues.field}.state`, 'uploaded');
      } else {
        yield put(
          addErrorNotification({
            message: 'The File checksum returned by AWS is not verified.',
          }),
        );
        formValues.set(`${formValues.field}.state`, 'init');
      }
    }
  },
  *uploadRevisionSourceFileSaga(action: ReturnType<typeof uploadRevisionFile>) {
    const {
      contentType,
      revisionUuid,
      sourceFileUuid,
      file,
      type,
      formValues,
    } = action.payload;

    const requirements = getWavRequirements(type);

    // Update the source file's state storing directly in the form object
    formValues.set(`${formValues.field}.state`, 'validating');

    // Perform wav file validation
    const { isValid, msg } = yield call(analyseWavWorker, file, requirements);

    if (!isValid) {
      // If the file is not valid, reset the source file upload state for user feedback
      formValues.set(`${formValues.field}.state`, 'init');
      yield put(addErrorNotification({ message: msg }));
    } else {
      // If the file is valid then continue the upload process
      // Compute the checksum (eTag)
      const fileChecksum: string = yield call(checksumWorker, file);
      formValues.set(`${formValues.field}.state`, 'uploading');

      // Get the pre-signed key for uploading the file to aws
      const res: FetchResponse<Nl.Api.UploadPresignedPostKeyResponse> = yield call(
        presignedRevisionSourceFile,
        revisionUuid,
        sourceFileUuid,
        fileChecksum,
        contentType,
        file.name,
      );

      // Upload the file to AWS
      const { finalS3Url, eTag } = yield call(
        uploadSourceFile,
        res.data.url,
        res.data.fields,
        file,
      );

      // If the file's checksum is verified
      if (eTag === fileChecksum) {
        // then update the formValues with the zip's url
        // The filename and upload state are updated only for user feedback
        formValues.set(`${formValues.field}.revision_filename`, file.name);
        formValues.set(`${formValues.field}.revision_storage_key`, finalS3Url);
        formValues.set(`${formValues.field}.state`, 'uploaded');
      } else {
        yield put(
          addErrorNotification({
            message: 'The File checksum returned by AWS is not verified.',
          }),
        );
        formValues.set(`${formValues.field}.state`, 'init');
      }
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeEvery(UPLOAD_FILE, sagas.uploadSourceFileSaga),
    takeEvery(UPLOAD_REVISION_FILE, sagas.uploadRevisionSourceFileSaga),
  ]);
}
