import * as React from 'react';
import { Tooltip, WithTheme, withTheme } from '@material-ui/core';
import { getColor } from 'utils/songSourceFile';

interface OwnProps {
  className?: string;
  type: Nl.SongSourceFileType | 'all';
}

const SourceFileTypeCircle: React.SFC<OwnProps & WithTheme> = ({
  className,
  type,
  theme,
}) => {
  const color = getColor(type);

  return (
    <Tooltip title={type}>
      <svg height='20' width='20' className={className}>
        <circle
          cx='10'
          cy='10'
          r='9'
          stroke={theme.palette.grey[400]}
          strokeWidth='1'
          fill={color}
        />
      </svg>
    </Tooltip>
  );
};

export default withTheme(SourceFileTypeCircle);
