import * as React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Card, Theme, createStyles } from '@material-ui/core';
import { Formik } from 'formik';
import SongFinderForm from './SongFinderForm';
import { findSongs } from 'store/songFinder';
import { selectIsLoaded } from 'store/selectors';
import SongFinderResults from './SongFinderResults';
import { songFinderValidation } from './validation';
import { useNlSelector } from 'utils/redux';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      marginTop: theme.spacing(8),
      maxWidth: theme.spacing(125),
    },
  });

const useStyles = makeStyles(styles);

const initialValues = { song_id: '' };

const SongFinderPage = () => {
  const dispatch = useDispatch();

  const songFinderItems = useNlSelector((state) => state.songFinder.data.items);
  const isLoaded = useNlSelector((state) =>
    selectIsLoaded(state, 'songFinder'),
  );

  const classes = useStyles({});
  return (
    <>
      <Card className={classes.root}>
        <Formik
          initialValues={initialValues}
          validationSchema={songFinderValidation}
          onSubmit={(formData, formActions) => {
            dispatch(findSongs({ formData, formActions }));
          }}
        >
          {SongFinderForm}
        </Formik>
      </Card>
      {SongFinderResults(songFinderItems, isLoaded)}
    </>
  );
};

export default SongFinderPage;
