import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { isEqual } from 'lodash';
import moment from 'moment';
import {
  TableCell,
  TableRow,
  makeStyles,
  Theme,
  IconButton,
  Tooltip,
  Collapse,
  Table,
  TableBody,
} from '@material-ui/core';
import Upload from '@material-ui/icons/CloudUploadOutlined';
import Close from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { GlobalState } from 'store/rootReducer';
import { makeSelectPlayUrl, makeSelectDownloadUrl } from 'store/singleSong';
import { required } from 'utils/validate';
import DeleteButton from 'components/Button/DeleteButton';
import AudioControlIcon from 'components/AudioControlIcon';
import InputField from 'components/Formik/InputField';
import SourceFileTypeCircle from 'components/SourceFileTypeCircle';
import SongWaveform from 'components/SongWaveform';
import SongEditSourceFileInfo from './SongEditSourceFileInfo';
import SongEditUploadDropzone from './SongEditUploadDropzone';
import StatusToggleButton from 'components/StatusToggleButton';
import DownloadButton from 'components/Button/DownloadButton';
import { FieldAttributes } from 'formik/dist/Field';
import { showRevisionNote } from 'utils/songSourceFile';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  type: {
    paddingRight: 0,
  },
  label: {
    minWidth: 150,
    width: '20%',
  },
  labelInput: {
    width: 150,
  },
  info: {
    position: 'absolute',
    top: 16,
    left: -8,
    // @ts-ignore
    color: theme.palette.error[400],
  },
  preview: {
    position: 'relative',
    width: '80%',
  },
  waveform: {
    minWidth: 100,
    width: '100%',
  },
  status: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  duration: {
    whiteSpace: 'nowrap',
  },
  actions: {
    whiteSpace: 'nowrap',
  },
  mainRow: {
    '& > td': {
      borderBottom: 'none',
    },
  },
  collapsableRow: {
    padding: 0,
    borderTop: 'none',
  },
}));

interface OwnProps {
  key: string;
  songUuid: string;
  isEditable: boolean;
  sourceFile: Nl.SourceFileExtended;
  sourceFileIndex: number;
  onRemove: () => void;
  formValues: Nl.UploadFormValues;
}

interface AllProps extends OwnProps {
  playUrl: string | undefined;
  downloadUrl: string | undefined;
}

const SongEditSourceFileRow: React.SFC<AllProps> = ({
  songUuid,
  isEditable,
  sourceFile,
  sourceFileIndex,
  playUrl,
  downloadUrl,
  formValues,
  onRemove,
}) => {
  const classes = useStyles({});
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleReUpload = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onRemoveRow = () => {
    setIsCollapsed(false);
    onRemove();
  };

  const isLoop = playUrl !== downloadUrl;

  return (
    <Table>
      <TableBody>
        <TableRow key={sourceFile.uuid} className={classes.mainRow}>
          <TableCell className={classes.type}>
            <SourceFileTypeCircle type={sourceFile.file_type} />
          </TableCell>
          <TableCell className={classes.label}>
            <Field
              name={`source_files.${sourceFileIndex}.label`}
              InputProps={{ readOnly: true }}
              component={InputField}
            />
          </TableCell>
          <TableCell>{sourceFile.duration}</TableCell>
          <TableCell padding='none'>
            <AudioControlIcon
              isLoop={isLoop}
              audioTrack={{
                uuid: sourceFile.uuid,
                trackType: sourceFile.file_type,
                url: playUrl,
                trackName: sourceFile.label,
                duration: sourceFile.duration,
              }}
            />
          </TableCell>
          <TableCell className={classes.preview}>
            <SongEditSourceFileInfo
              bitDepth={sourceFile.bit_depth}
              channels={sourceFile.channels}
              originalFileName={sourceFile.original_file_name}
              sampleRate={sourceFile.sample_rate}
              uuid={sourceFile.uuid}
            >
              {!sourceFile.meets_quality_requirements && (
                <WarningIcon className={classes.info} />
              )}
              <SongWaveform
                uuid={sourceFile.uuid}
                className={classes.waveform}
                type={sourceFile.file_type}
                displayLoopOnce
                isLoop={isLoop}
              />
            </SongEditSourceFileInfo>
          </TableCell>
          <TableCell className={classes.status}>
            <Field
              name={`source_files.${sourceFileIndex}.status`}
              component={({ field, form, ...rest }: FieldAttributes<any>) => (
                <StatusToggleButton
                  {...rest}
                  disabled={!isEditable}
                  onChange={(val) => form.setFieldValue(field.name, val)}
                  value={field.value}
                />
              )}
            />
          </TableCell>
          <TableCell>{moment(sourceFile.created_at).format('L')}</TableCell>
          <TableCell className={classes.actions}>
            {isEditable && (
              <>
                <DeleteButton aria-label='Remove' onClick={onRemoveRow} />
                <Tooltip title='Re-Upload'>
                  <IconButton
                    color={isCollapsed ? 'primary' : 'default'}
                    aria-label='Re-Upload'
                    onClick={toggleReUpload}
                  >
                    <Upload />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {downloadUrl && <DownloadButton href={downloadUrl} />}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={5} />
          {showRevisionNote(sourceFile) && (
            <TableCell align='right' colSpan={3}>
              <Field
                label='Revision note'
                name={`source_files.${sourceFileIndex}.revision_note`}
                data-e2e='source-file-revision-note'
                InputProps={{
                  readOnly: false,
                  value: sourceFile.revision_note || '',
                  multiline: true,
                }}
                component={InputField}
              />
            </TableCell>
          )}
        </TableRow>
        <TableRow style={{ height: 0, padding: 0 }}>
          <TableCell
            colSpan={2}
            className={!isCollapsed ? classes.collapsableRow : ''}
          >
            {null}
          </TableCell>
          <TableCell
            align='center'
            colSpan={5}
            className={!isCollapsed ? classes.collapsableRow : ''}
          >
            <Collapse in={isCollapsed} timeout='auto' unmountOnExit>
              <Field
                name={`source_files.${sourceFileIndex}.url_wav`}
                component={() => (
                  <>
                    {!sourceFile.filename ? (
                      <SongEditUploadDropzone
                        contentType='audio/wav'
                        songUuid={songUuid}
                        sourceFile={sourceFile}
                        type={sourceFile.file_type}
                        state={sourceFile.state}
                        formValues={formValues}
                      />
                    ) : (
                      <>
                        Will be replaced by {sourceFile.filename} on save
                        <IconButton onClick={toggleReUpload}>
                          <Close fontSize='small' />
                        </IconButton>
                      </>
                    )}
                  </>
                )}
                validate={[required]}
              />
            </Collapse>
          </TableCell>
          <TableCell
            colSpan={1}
            className={!isCollapsed ? classes.collapsableRow : ''}
          >
            {null}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const makeMapStateToProps = () => {
  const selectPlayUrl = makeSelectPlayUrl();
  const selectDownloadUrl = makeSelectDownloadUrl();
  return (state: GlobalState, { sourceFile }: OwnProps) => {
    const playUrl = selectPlayUrl(state, sourceFile.uuid);
    const downloadUrl = selectDownloadUrl(state, sourceFile.uuid);
    return {
      playUrl,
      downloadUrl,
    };
  };
};

function areEqual(prevProps: OwnProps, nextProps: OwnProps) {
  const sourceFileModified = !isEqual(
    prevProps.sourceFile,
    nextProps.sourceFile,
  );
  const indexModified: boolean =
    prevProps.formValues.field !== nextProps.formValues.field;

  const modified = sourceFileModified || indexModified;
  return !modified;
}

export default React.memo(
  connect(makeMapStateToProps)(SongEditSourceFileRow),
  areEqual,
);
