import { put, call, takeLatest, all } from 'redux-saga/effects';
import { createAction, SimpleActionType } from '../utils/storeUtils';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

const DELETE_TAG = 'DELETE_TAG';

export const deleteTag = createAction(DELETE_TAG);

export const sagas = {
  *deleteTagSaga(action: SimpleActionType) {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/tag/${action.payload.uuid}`,
      {
        method: 'DELETE',
      },
    );
    if (results.success) {
      yield put(addSuccessNotification({ message: 'Tag has been deleted' }));
      invalidateNovaQueries(`/tag`);
    } else {
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the tag',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([takeLatest(DELETE_TAG, sagas.deleteTagSaga)]);
}
