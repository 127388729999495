import { useNovaApi } from '../useNovaApi';
import moment from 'moment';

const convertSongIngestionLogs = (
  log: Nl.Api.SongIngestionLog,
): Nl.SongIngestionLog => {
  return {
    uuid: log.uuid,
    timestamp: moment.utc(log.updated_at).valueOf(), // Get timestamp from UTC date
    status: log.status,
    channel: log.channel.name,
    operation: log.operation,
    errorMessage: log.message,
    stackTrace: log.details,
  };
};

export const useSongIngestionLogs = (songUuid: string) => {
  return (
    useNovaApi<Nl.Api.SongIngestionLogResponse>('/song_ingestion_log', {
      query: { song: songUuid },
    }).data?.song_ingestion_logs.map(convertSongIngestionLogs) || []
  );
};
