import React from 'react';
import { Form, Field } from 'formik';
import InputField from 'components/Formik/InputField';
import MyTrackUploadDropzone from './MyTrackUploadDropzone';
import { Typography, Grid, FormHelperText } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { FieldAttributes } from 'formik/dist/Field';

const MyTrackCreateForm: React.FunctionComponent = () => {
  const renderDropzone = () => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Field
        name='source_file'
        component={({ form, field }: FieldAttributes<any>) => {
          return (
            <Grid item xs={12}>
              <Grid>
                <MyTrackUploadDropzone
                  form={form}
                  state={field.value.state}
                  original_filename={field.value.original_filename}
                  isRequiredError={!isEmpty(form.errors.source_file)}
                  fileType='mp3'
                />
              </Grid>
              <Grid>
                <FormHelperText error>{form.errors.source_file}</FormHelperText>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );

  return (
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant='h6'>Track Details</Typography>
          <Field name='name' label='Track Name' component={InputField} />
          <Field
            name='description'
            label='Track Description'
            component={InputField}
            multiline
            rows={4}
            rowsMax={4}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='h6'>New Track Upload</Typography>
          <div style={{ marginTop: '1rem' }}>{renderDropzone()}</div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default MyTrackCreateForm;
