import * as React from 'react';
import { styles } from 'components/Table/Table.styles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(styles);

interface AllProps {
  writerCount: number;
  artistRepEmail: string | null;
}

const MyWritersInfoBox: React.FunctionComponent<AllProps> = ({
  writerCount,
  artistRepEmail,
}) => {
  const classes = useStyles({});
  const showEditWriterHelpText = artistRepEmail && writerCount > 0;
  const showAddWriterHelpText = writerCount === 0;
  return (
    <>
      {showEditWriterHelpText && (
        <div className={classes.infoText}>
          To make any modifications to your existing writers,
          <br />
          please contact your artist rep at {artistRepEmail}
        </div>
      )}
      {showAddWriterHelpText && (
        <div className={classes.infoText} style={{ float: 'right' }}>
          You currently have no writers in your profile.
          <br />
          Click + above to add one.
        </div>
      )}
    </>
  );
};

export default MyWritersInfoBox;
