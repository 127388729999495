import { put, call, takeLatest, all } from 'redux-saga/effects';
import { createTypedAction, SimpleActionType } from '../utils/storeUtils';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';

const AWAIT_ASYNC_RESULT = 'AWAIT_ASYNC_RESULT';
const statusSuccess = 'SUCCESS';
const baseUrl = '/async_result';

type AsyncResultResponse = FetchResponse<{ async_result: Nl.Api.AsyncResult }>;
interface AwaitAsyncResultPayload {
  task_id: string;
  callback?: () => SimpleActionType;
}

export const awaitAsyncResult = (payload: AwaitAsyncResultPayload) =>
  createTypedAction(AWAIT_ASYNC_RESULT, payload);

const sagas = {
  *awaitAsyncResult(action: ReturnType<typeof awaitAsyncResult>) {
    const { task_id, callback } = action.payload;
    const { success, data }: AsyncResultResponse = yield call(
      apiFetch,
      `${baseUrl}/${task_id}`,
    );
    if (success) {
      const taskSuccessful = data.async_result.status === statusSuccess;
      if (taskSuccessful && callback) {
        yield put(callback());
      }
    }
  },
};

export function* rootSaga() {
  yield all([takeLatest(AWAIT_ASYNC_RESULT, sagas.awaitAsyncResult)]);
}

export { sagas };
