import { string, object } from 'yup';
import errorMessages from 'constants/errorMessages';

export default object().shape({
  name: string().required(errorMessages.basicRequired),
  assigned_to: string().required(errorMessages.basicRequired).nullable(),
  contact_information: object().shape({
    first_name: string().required(errorMessages.basicRequired),
    last_name: string().required(errorMessages.basicRequired),
    email: string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.basicRequired),
  }),
});

export const validateCurationState = (value: string) => {
  let error;
  if (!value) {
    error = errorMessages.basicRequired;
  }
  return error;
};
