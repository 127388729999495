import * as React from 'react';
import { connect } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';
import { GlobalState } from 'store/rootReducer';
import { setDuration } from 'store/audioPlayer';

interface OwnProps {
  deepRef: React.LegacyRef<ReactAudioPlayer>;
  url: string;
  onTrackPlayed(position: number): void;
  onTrackStopped(): void;
}

interface StateProps {
  volume: number;
}

interface DispatchProp {
  onSetDuration: (duration: number) => void;
}

type AllProps = OwnProps & StateProps & DispatchProp;

const AudioRenderer: React.SFC<AllProps> = ({
  deepRef,
  url,
  onTrackPlayed,
  onTrackStopped,
  volume,
  onSetDuration,
}) => (
  <ReactAudioPlayer
    ref={deepRef}
    listenInterval={200} // milliseconds
    src={url}
    onEnded={onTrackStopped}
    volume={volume}
    onLoadedMetadata={(e: any) => {
      onSetDuration(e.currentTarget.duration);
    }}
    onListen={(time) => {
      onTrackPlayed(time);
    }}
    autoPlay
  />
);

export default connect(
  (state: GlobalState) => ({
    volume: state.audioPlayer.volume,
  }),
  (dispatch) => ({
    onSetDuration: (duration: number) => dispatch(setDuration(duration)),
  }),
)(AudioRenderer);
