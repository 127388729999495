import React, { useState } from 'react';
import {
  IconButton,
  // Typography,
  Popover,
  Tooltip,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SelectField from '../Form/AutocompleteSelect';
import { multipleSelectFormat } from 'utils/utils';
import theme from 'assets/theme';

const useStyles = makeStyles({
  popover: {
    overflow: 'visible',
    padding: `${theme.spacing(2)}px`,
  },
  selectField: {
    minWidth: '200px',
    marginBottom: 0,
  },
  popoverTitle: {
    marginBottom: theme.spacing(1),
  },
  submit: {
    marginTop: `${theme.spacing(2)}px`,
    marginRight: `${theme.spacing(2)}px`,
  },
});

export type BatchAction = {
  name: string;
  icon: React.ReactNode;
  requiredPermission: Nl.UserPermissionType;
  e2e: string;
  interface: {
    label: string;
    type: 'multi-select' | 'single-select';
    data: Nl.Api.SelectorEntity[];
    buttons: {
      action: Nl.BatchUpdateActions;
      dispatch(action: Nl.BatchUpdateActions, values: string[]): unknown;
    }[];
    isCreatable: boolean;
  };
};

function BulkActionsBar({ actionsList }: { actionsList: BatchAction[] }) {
  const classes = useStyles({});

  type InterfaceState = {
    anchorEl?: HTMLElement;
    interface?: BatchAction['interface'];
  };

  // Action Interface State
  const [actionInterface, setActionInterface] = useState<InterfaceState>({});
  const activeInterface = Boolean(actionInterface.anchorEl);

  // Selector State
  const [selectorValues, setSelectorValues] = useState<string[]>([]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    action: BatchAction,
  ) => {
    if (action?.interface) {
      setActionInterface({
        anchorEl: event.currentTarget,
        interface: action.interface,
      });
    } else {
      // This is where future actions will go pending the bulk actions bar refactor MIT-3797
    }
  };

  const renderActionInterface = () => {
    if (!actionInterface.interface) {
      return null;
    }
    const {
      type,
      label,
      data,
      buttons,
      isCreatable,
    } = actionInterface.interface;

    switch (type) {
      case 'multi-select':
      case 'single-select': {
        return (
          <>
            <SelectField
              e2e='targetList'
              name='targetList'
              label={label}
              isCreatable={isCreatable}
              selected={selectorValues}
              options={multipleSelectFormat(data)}
              onChange={(value: string[]) => setSelectorValues(value)}
              classes={{
                root: classes.selectField,
              }}
              isMulti={type === 'multi-select'}
            />
            {buttons.map(({ action, dispatch }) => (
              <Button
                size='small'
                key={action}
                variant='contained'
                color='primary'
                onClick={() => dispatch(action, selectorValues)}
                className={classes.submit}
                data-e2e={action}
                disabled={!selectorValues.length}
              >
                {action}
              </Button>
            ))}
          </>
        );
      }

      default:
        return <div>Interface Type Not Supported {type}</div>;
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      {actionsList.map((action) => {
        if (!action.icon) return <div>I need an icon {action.name}</div>;

        return (
          <Tooltip title={action.name} key={action.name}>
            <IconButton
              data-e2e={action.name}
              onClick={(e) => handleClick(e, action)}
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        );
      })}
      <Popover
        data-e2e='popover'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        open={activeInterface}
        anchorEl={actionInterface.anchorEl}
        onClose={() => setActionInterface({})}
      >
        {activeInterface && renderActionInterface()}
      </Popover>
    </div>
  );
}

export default BulkActionsBar;
