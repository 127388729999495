import { useNovaApi } from 'novaApi/useNovaApi';
import config from 'config';

export const useLanguages = () => {
  const query = { pagesize: config.clients.api.maxPageSize };
  return (
    useNovaApi<Nl.Api.LanguagesResponse>('/language', {
      query,
      staleTime: Infinity,
    }).data?.languages ?? []
  );
};

export const useLanguageSelect = () => {
  return (
    useNovaApi<Nl.Api.LanguageSelectResponse>('/language/select').data
      ?.languages || []
  );
};
