import { put, call, takeLatest, all } from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from 'utils/storeUtils';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';

import { goPreviousPage } from 'store/router';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

// Action Constants
const CREATE_SONG_GENRE = 'CREATE_SONG_GENRE';
const CREATE_SONG_GENRE_ERROR = 'CREATE_SONG_GENRE_ERROR';
const DELETE_SONG_GENRE = 'DELETE_SONG_GENRE';
const DELETE_SONG_GENRE_SUCCESS = 'DELETE_SONG_GENRE_SUCCESS';
const DELETE_SONG_GENRE_ERROR = 'DELETE_SONG_GENRE_ERROR';
const FETCH_SONG_GENRE_ENTITIES = 'FETCH_SONG_GENRE_ENTITIES';
const FETCH_SONG_GENRE_ENTITIES_SUCCESS = 'FETCH_SONG_GENRE_ENTITIES_SUCCESS';
const FETCH_SONG_GENRE_ENTITIES_ERROR = 'FETCH_SONG_GENRE_ENTITIES_ERROR';

// Action Creators
export const createSongGenre = createAction(CREATE_SONG_GENRE);
const createSongGenreError = createAction(CREATE_SONG_GENRE_ERROR);
export const deleteSongGenre = createAction(DELETE_SONG_GENRE);
const deleteSongGenreSuccess = createAction(DELETE_SONG_GENRE_SUCCESS);
const deleteSongGenreError = createAction(DELETE_SONG_GENRE_ERROR);
export const fetchSongGenreEntities = createAction(FETCH_SONG_GENRE_ENTITIES);
const fetchSongGenreEntitiesSuccess = createAction(
  FETCH_SONG_GENRE_ENTITIES_SUCCESS,
);
const fetchSongGenreEntitiesError = createAction(
  FETCH_SONG_GENRE_ENTITIES_ERROR,
);

const songGenreInitialValues = {
  uuid: '' as string,
  name: '' as string,
  parent_genre_uuid: '' as string,
};

// Selector
export const getEditFormInitialValues = (): {
  uuid: string;
  name: string;
  parent_genre_uuid: string;
} => {
  return songGenreInitialValues;
};

export interface SongGenres {
  isLoading: boolean;
  isLoaded: boolean;
  entities: Nl.Api.SelectorEntity[];
}

const initialState: SongGenres = {
  isLoading: false,
  isLoaded: false,
  entities: [],
};

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_SONG_GENRE_ENTITIES: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SONG_GENRE_ENTITIES_SUCCESS: {
      return assign(state, {
        entities: action.payload.data.song_genres.map(
          (entity: Nl.Api.FullNameEntity) => ({
            name: entity.full_name,
            uuid: entity.uuid,
          }),
        ),
        isLoading: false,
      });
    }

    case FETCH_SONG_GENRE_ENTITIES_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSongGenreEntitiesSaga() {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/song_genre/select',
    );
    if (results.success) {
      yield put(fetchSongGenreEntitiesSuccess({ data: results.data }));
    } else {
      const message = results.errors?.name || results.msg;
      yield put(fetchSongGenreEntitiesError());
      yield put(addErrorNotification({ message }));
    }
  },
  *createSongGenreSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(apiFetch, '/song_genre', {
      method: 'POST',
      body: { ...formData },
    });
    if (results.success) {
      invalidateNovaQueries('/song_genre');
      yield put({ type: 'route/SONG_GENRES' });
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been created`,
        }),
      );
      yield put(goPreviousPage());
    } else {
      yield put(createSongGenreError(results.msg));
      if (results.errors.name) {
        yield put(addErrorNotification({ message: results.errors.name }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
    }
    formActions.setSubmitting(false);
  },
  *deleteSongGenreSaga(action: SimpleActionType) {
    try {
      yield call(apiFetch, `/song_genre/${action.payload.uuid}`, {
        method: 'DELETE',
      });
      yield put(deleteSongGenreSuccess());
      invalidateNovaQueries('/song_genre');
      yield put(
        addSuccessNotification({ message: 'Song Genre has been deleted' }),
      );
    } catch (error) {
      yield put(deleteSongGenreError());
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the songGenre',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(CREATE_SONG_GENRE, sagas.createSongGenreSaga),
    takeLatest(DELETE_SONG_GENRE, sagas.deleteSongGenreSaga),
    takeLatest(FETCH_SONG_GENRE_ENTITIES, sagas.fetchSongGenreEntitiesSaga),
  ]);
}

export { sagas };
export default reducer;
