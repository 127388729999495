import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import { Theme, createStyles } from '@material-ui/core/styles';
import SearchField from '../Form/SearchField';
import ConnectedSelectField from '../Form/ConnectedAutocompleteSelect';
import ConnectedTypeaheadFilter from '../Form/ConnectedTypeaheadFilter';
import { convertStringToArray } from '../../utils/routeUtils';
import DateSelect from 'components/Form/DateSelect';
import { GlobalState } from 'store/rootReducer';
import { filterBy } from 'store/queryParams';
import { useNlSelector } from 'utils/redux';

interface Props {
  filters: Nl.TableFilterTypes<GlobalState>[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'flex-end',
      width: '100%',
      margin: 0,
      marginBottom: '10px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    selectField: {
      width: '100%',
      marginBottom: 0,
    },
  }),
);

const TableFilters = ({ filters }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const query = useNlSelector((state) => state.location.query);

  const onDispatchFilters = (queryFilter: Nl.QueryFilter) => {
    dispatch(filterBy({ query: queryFilter, useSearchSaga: true }));
  };

  const onFreeTextFilterChange = (
    queryFilterKey: string,
    value: string | undefined,
  ) => {
    onDispatchFilters({ [queryFilterKey]: value });
  };

  const onSelectChange = (
    selected: string | string[],
    name: Nl.QueryFilterType,
  ) => {
    onDispatchFilters({ [name]: selected });
  };

  const renderSearchField = ({
    e2e,
    label,
    queryFilterKey,
  }: Nl.TableInputFilterType) => {
    return (
      <SearchField
        key={e2e}
        id={queryFilterKey}
        e2e={e2e}
        label={label}
        defaultValue={query?.[queryFilterKey]?.toString()}
        onChange={(value) => onFreeTextFilterChange(queryFilterKey, value)}
      />
    );
  };

  const renderDateField = ({
    e2e,
    label,
    queryFilterKey,
  }: Nl.TableInputFilterType) => {
    return (
      <DateSelect
        id={queryFilterKey}
        e2e={e2e}
        label={label}
        defaultValue={query?.[queryFilterKey]?.toString()}
        onChange={(value) => onFreeTextFilterChange(queryFilterKey, value)}
      />
    );
  };

  const renderSingleSelectField = ({
    e2e,
    label,
    queryFilterKey,
    optionsSelector,
    isSticky,
  }: Nl.TableSelectFilterType<GlobalState>) => {
    return (
      <ConnectedSelectField
        key={e2e}
        name={queryFilterKey}
        e2e={e2e}
        label={label}
        selector={optionsSelector}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.uuid || option.name}
        selected={query?.[queryFilterKey]?.toString()}
        onChange={onSelectChange}
        menuPortalTarget={document.body}
        classes={{
          root: classes.selectField,
        }}
        isMulti={false}
        isSticky={isSticky}
      />
    );
  };

  const renderMultipleSelectField = ({
    e2e,
    label,
    queryFilterKey,
    optionsSelector,
  }: Nl.TableSelectFilterType<GlobalState>) => {
    const selected = query?.[queryFilterKey];

    return (
      <ConnectedSelectField
        key={e2e}
        name={queryFilterKey}
        e2e={e2e}
        label={label}
        selector={optionsSelector}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.uuid || option.name}
        selected={
          selected === undefined ? undefined : convertStringToArray(selected)
        }
        onChange={onSelectChange}
        menuPortalTarget={document.body}
        classes={{
          root: classes.selectField,
        }}
        isMulti
      />
    );
  };

  const renderTypeaheadField = ({
    label,
    queryFilterKey,
    optionsSelector,
  }: Nl.TableSelectFilterType<GlobalState>) => {
    return (
      <ConnectedTypeaheadFilter
        label={label}
        optionsSelector={optionsSelector}
        name={queryFilterKey}
        value={query?.[queryFilterKey]}
        onChange={onSelectChange}
      />
    );
  };

  const renderFilter = (filter: any) => {
    switch (filter.type) {
      case 'search':
        return renderSearchField(filter);
      case 'date':
        return renderDateField(filter);
      case 'single_select':
        return renderSingleSelectField(filter);
      case 'multi_select':
        return renderMultipleSelectField(filter);
      case 'typeahead':
        return renderTypeaheadField(filter);
      default:
        return null;
    }
  };

  const searchFilters = filters.filter(
    (f) => f.type === 'search',
  ) as Nl.TableInputFilterType[];
  const regularFilters = filters.filter((f) => f.type !== 'search');
  // TODO this renderSearchField should be extracted into a TableSearch component
  return (
    <>
      {searchFilters.map(renderSearchField)}
      {regularFilters.length > 0 && (
        <Grid container spacing={1} className={classes.container}>
          {regularFilters.length > 0 &&
            regularFilters.map((filter, idx) => (
              <Grid key={idx} item xs={6} sm={4} md={3} lg={3} xl={3}>
                {renderFilter(filter)}
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
};

export default TableFilters;
