import * as React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'formik';
import { GlobalState } from 'store/rootReducer';
import InputField from 'components/Formik/InputField';
import {
  selectIdenticalValueLabelFormat,
  multipleSelectFormat,
} from 'utils/utils';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import Checkbox from 'components/Formik/CheckboxField';
import { InputWrapper } from 'components/Formik/utils';
import { useCountries } from 'novaApi/hooks/country';
import { usePROSelect } from 'novaApi/hooks/performing_rights_organization';

interface AllProps {
  publisherTypes: string[];
}

const PublisherForm: React.SFC<AllProps> = ({ publisherTypes }) => {
  const pros = usePROSelect();
  const countries = useCountries();

  return (
    <Form>
      <Field name='name' label='Name' component={InputField} />
      <InputWrapper>
        <Field
          name='type'
          label='Type'
          options={selectIdenticalValueLabelFormat(publisherTypes)}
          component={AutocompleteSelect}
        />
      </InputWrapper>
      <InputWrapper>
        <Field name='ipi_name' label='IPI Name' component={InputField} />
        <Field name='ipi_number' label='IPI Number' component={InputField} />
      </InputWrapper>
      <InputWrapper>
        <Field name='tax_id' label='Tax ID' component={InputField} />
      </InputWrapper>
      <InputWrapper>
        <Field
          name='performing_rights_organization_member_id'
          label='PRO Member ID'
          component={InputField}
        />
        <Field
          name='performing_rights_organization_uuid'
          label='PRO'
          component={AutocompleteSelect}
          options={multipleSelectFormat(pros)}
        />
      </InputWrapper>
      <Field
        name='country_uuids'
        label='Territories'
        component={AutocompleteSelect}
        options={multipleSelectFormat(countries)}
        isMulti
      />
      <Field name='is_visible' label='Is visible' component={Checkbox} />
    </Form>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  publisherTypes: state.publishers.constants.types,
});

export default connect(mapStateToProps)(PublisherForm);
