import * as React from 'react';
import { Button, Popover, MenuItem, MenuList } from '@material-ui/core';
import SourceFileTypeCircle from 'components/SourceFileTypeCircle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  circle: {
    marginRight: 8,
  },
});

interface OwnProps {
  icon: React.ReactNode;
  title: string;
  onClickAction: (key: Nl.SongSourceFileType | 'all') => void;
  types: Nl.SongSourceFileType[];
}

const ActionsSourceFiles: React.SFC<OwnProps> = React.memo(
  ({ title, icon, onClickAction, types }) => {
    const [anchorRef, setAnchorRef] = React.useState<HTMLButtonElement>();
    const classes = useStyles({});
    const onClickItem = (action: Nl.SongSourceFileType | 'all') => {
      onClickAction(action);
      setAnchorRef(undefined);
    };

    return (
      <div>
        <Button
          aria-controls={`menu-${title}`}
          aria-haspopup='true'
          onClick={(e) => setAnchorRef(e.currentTarget)}
        >
          {icon}
          {title}
        </Button>

        <Popover
          open={Boolean(anchorRef)}
          anchorEl={anchorRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => setAnchorRef(undefined)}
        >
          <MenuList>
            <MenuItem key='all' onClick={(e) => onClickItem('all')}>
              <SourceFileTypeCircle className={classes.circle} type='all' /> All
            </MenuItem>
            {types.map((type) => (
              <MenuItem key={type} onClick={(e) => onClickItem(type)}>
                <SourceFileTypeCircle className={classes.circle} type={type} />{' '}
                {type}
              </MenuItem>
            ))}
          </MenuList>
        </Popover>
      </div>
    );
  },
);

export default ActionsSourceFiles;
