import React from 'react';
import FormikWrapper from 'components/Formik';
import ExampleForm, { getFormInitialValues } from './ExampleForm';
import { getValidationSchema } from './validation';
import { useExampleCreate } from 'novaApi/hooks/example';
import { useDispatch } from 'react-redux';
import { goPreviousPage } from 'store/router';
import { duration } from 'moment';

export default function ExamplesCreatePage() {
  const dispatch = useDispatch();
  const exampleToCreate = getFormInitialValues();
  const { createExample, createdExample, isSuccess } = useExampleCreate();

  const handleFormSubmit = (formData, formActions) => {
    const start = formData.start_point;
    const end = formData.end_point;

    const transformedFormData = {
      ...formData,
      start_point: start ? duration(start).asSeconds() : null,
      end_point: end ? duration(end).asSeconds() : null,
    };
    createExample({ formData: transformedFormData, formActions });
  };

  if (isSuccess && createdExample) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={exampleToCreate}
      validationSchema={getValidationSchema()}
      submitForm={handleFormSubmit}
      title='Create example'
      customSize='sm'
    >
      <ExampleForm />
    </FormikWrapper>
  );
}
