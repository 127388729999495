import * as React from 'react';
import { Field, getIn } from 'formik';
import {
  Button,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { multipleSelectFormat } from 'utils/utils';
import DeleteButton from 'components/Button/DeleteButton';
import Typeahead from 'components/Typeahead/Typeahead';
import { useTagSelect } from 'novaApi/hooks/tag';
import { useTagRelationTypeSelect } from 'novaApi/hooks/tag_relation_type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    table: {
      width: '100%',
    },
    tableCell: {
      verticalAlign: 'top',
      padding: '0px 10px 20px 10px',
    },
    error: {
      // @ts-ignore
      color: theme.palette.error[400],
    },
    warning: {
      color: theme.customPalette.warning,
    },
  }),
);

interface AllProps {
  form: any;
  name: any;
  remove: any;
  push: any;
}

const RelatedTags = ({ form, name, remove, push }: AllProps) => {
  const classes = useStyles();

  const error = false;
  const warning = false;
  const fields = getIn(form.values, name, []);

  const tagRelations = useTagRelationTypeSelect();
  const availableTags = useTagSelect();

  return (
    <div className={classes.root}>
      {error && (
        <Typography variant='subtitle2' className={classes.error}>
          {error}
        </Typography>
      )}
      {warning && (
        <Typography variant='subtitle2' className={classes.error}>
          {warning}
        </Typography>
      )}

      <div className={classes.table}>
        {!!fields && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Relation Name</TableCell>
                <TableCell>Related Tags</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields?.map((field, index) => {
                const relationUuid = getIn(field, 'uuid');
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCell} width='35%'>
                      <Field
                        name={`related_tags.${index}.uuid`}
                        label='Relation'
                        noClearable
                        component={Typeahead}
                        options={multipleSelectFormat(
                          // Return the available relations list (except the ones already used)
                          tagRelations.filter(
                            ({ uuid }) =>
                              relationUuid === uuid ||
                              !fields.find((relType) => relType.uuid === uuid),
                          ),
                        )}
                      />
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      <Field
                        name={`related_tags.${index}.tags`}
                        label='Tags'
                        noClearable
                        isMulti
                        component={Typeahead}
                        options={multipleSelectFormat(availableTags)}
                      />
                    </TableCell>

                    <TableCell padding='none' align='center'>
                      <DeleteButton
                        aria-label='Remove'
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        <Button
          onClick={() => push({ uuid: undefined, tags: [] })}
          data-e2e='addRelatedTagsButton'
        >
          <AddIcon />
          Add
        </Button>
      </div>
    </div>
  );
};

export default RelatedTags;
