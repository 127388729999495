import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';
import { strongPassword } from 'utils/form/validators';
import { NotificationState } from 'store/notifications';

function validationSchema(
  passwordFlag = false,
  notificationState: NotificationState,
) {
  return Yup.object().shape({
    first_name: Yup.string().required(errorMessages.basicRequired),
    last_name: Yup.string().required(errorMessages.basicRequired),
    email: Yup.string()
      .email(errorMessages.invalidEmail)
      .required(errorMessages.basicRequired)
      .test(
        'Check if email duplicate error',
        errorMessages.uniqueEmailRequired,
        () => notificationState.status === 'default',
      ),

    is_password_edit: Yup.boolean(),
    password: Yup.string()
      .nullable()
      .test({
        name: 'Check password strength',
        test(value: string | null | undefined) {
          if (value) {
            const isStrongPassword = strongPassword(value);
            return isStrongPassword
              ? this.createError({
                  message: isStrongPassword,
                  path: 'password',
                })
              : true;
          }
          return passwordFlag
            ? this.createError({
                message: errorMessages.basicRequired,
                path: 'password',
              })
            : true;
        },
      }),

    user_role_uuid: Yup.string()
      .nullable()
      .required(errorMessages.basicRequired),
  });
}

export default validationSchema;
