import * as React from 'react';
import { connect } from 'react-redux';
import GlobalWarning from 'components/GlobalWarning';
import { GlobalState } from 'store/rootReducer';

interface StateProps {
  isDesynchronized: boolean;
}

type AllProps = StateProps;

const SongSyncWarning: React.SFC<AllProps> = ({ isDesynchronized }) => (
  <>
    {isDesynchronized ? (
      <GlobalWarning>
        Current song state is out of sync with downstream channels! Press
        Publish to sync up.
      </GlobalWarning>
    ) : null}
  </>
);

export default connect((state: GlobalState) => ({
  isDesynchronized: state.singleSong.data.song?.is_desynchronized ?? false,
}))(SongSyncWarning);
