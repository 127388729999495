import { createSelector, defaultMemoize } from 'reselect';
import { GlobalState } from './rootReducer';

export const selectPermission = defaultMemoize(
  (state: GlobalState, permission: Nl.UserPermissionType) =>
    (state.auth?.permissions ?? []).includes(permission),
);

// Any key of GlobalState whose value has a 'isLoaded' property
type StateKeyWithIsLoaded = PickKeys<GlobalState, { isLoaded: boolean }>;

export function selectIsLoaded(state: GlobalState, key: StateKeyWithIsLoaded) {
  return state[key].isLoaded;
}

export const selectSingleTrackSubmission = defaultMemoize(
  (state: GlobalState) =>
    state.singleTrackSubmission?.data?.new_track_submission ?? null,
);

export const selectTrackSubmissionSourceFile = createSelector(
  selectSingleTrackSubmission,
  (results) => results?.source_file ?? null,
);

export const selectSingleArtistSubmission = defaultMemoize(
  (state: GlobalState) =>
    state.singleArtistSubmission?.data?.new_artist_submission ?? null,
);

export const selectSongUUID = defaultMemoize(
  (state: GlobalState) => state.singleSong?.data?.song?.uuid ?? '',
);

export const selectInstruments = defaultMemoize(
  (state: GlobalState) => state.songInstruments.entities,
);

export const selectGenres = defaultMemoize(
  (state: GlobalState) => state.songGenres.entities,
);

export const selectThemes = defaultMemoize(
  (state: GlobalState) => state.songThemes.entities,
);

export const selectTags = defaultMemoize(
  (state: GlobalState) => state.songTags.data.song_tags,
);

export const selectHiddenTags = defaultMemoize(
  (state: GlobalState) => state.songHiddenTags.data.song_tags_hidden,
);

export const selectPublishers = createSelector(
  selectPermission,
  (state: GlobalState) => state.singleSong?.data?.song?.publishers ?? [],
  (permission: boolean, publishers: any) => permission && publishers,
);

export const selectPublisherRulesets = defaultMemoize(
  (state: GlobalState) => state.publisherRuleset.entities,
);

export const selectSongCollections = createSelector(
  selectPermission,
  (state: GlobalState) => state.songCollections.entities,
  (permission: boolean, songCollections: any) => permission && songCollections,
);

export const selectSongCollectionData = defaultMemoize(
  (state: GlobalState) => state.songCollections.data.song_collections,
);

export const selectSongCollectionsLoadingStatus = defaultMemoize(
  (state: GlobalState) => state.songCollections.isLoaded,
);

export const selectVersionTypes = defaultMemoize(
  (state: GlobalState) => state.songs.constants.versionTypes,
);

export const selectCurationStates = defaultMemoize(
  (state: GlobalState) => state.songs.curationStateOptions,
);

export const selectOriginalComposers = createSelector(
  selectPermission,
  (state: GlobalState) => state.originalComposer.entities,
  (permission: boolean, originalComposers: any) =>
    permission && originalComposers,
);

export const selectNotificationStatus = defaultMemoize(
  (state: GlobalState) => state.notifications,
);

export const selectSourceFileUploads = defaultMemoize(
  (state: GlobalState) =>
    state.singleSong?.data?.song?.source_file_uploads ?? [],
);

export const selectUserUuid = defaultMemoize(
  (state: GlobalState) => state.auth.uuid,
);

export const selectUsersLoadingStatus = defaultMemoize(
  (state: GlobalState) => state.users.isLoaded,
);

export const selectQuery = defaultMemoize(
  (state: GlobalState) => state.location.query,
);

export const selectSalesReports = defaultMemoize(
  (state: GlobalState) => state.salesBreakdown.data.earnings,
);

export const selectSalesReportsLoadingStatus = defaultMemoize(
  (state: GlobalState) => state.salesBreakdown.isLoaded,
);

export const selectSalesPerTrack = defaultMemoize(
  (state: GlobalState) => state.salesPerTrack.data.songs,
);

export const selectSalesPerTrackLoadingStatus = defaultMemoize(
  (state: GlobalState) => state.salesPerTrack.isLoaded,
);

export const selectMyWriters = defaultMemoize(
  (state: GlobalState) => state.myWriters?.data?.writers ?? [],
);

export const selectMyWritersIsLoaded = defaultMemoize(
  (state: GlobalState) => state.myWriters?.isLoaded,
);

export const selectMyWriterSuggested = defaultMemoize(
  (state: GlobalState) => state.myWriters?.suggestedWriter,
);

export const selectMyProfile = defaultMemoize(
  (state: GlobalState) => state.myProfile?.data?.artist,
);

export const selectPublishersPageData = defaultMemoize(
  (state: GlobalState) => state.publishers.data.publishers,
);

export const selectPublishersLoadingStatus = defaultMemoize(
  (state: GlobalState) => state.publishers.isLoaded,
);

export const selectRegistrationUser = defaultMemoize(
  (state: GlobalState) => state.register?.data?.user,
);

export const selectRegistrationTokenIsValid = defaultMemoize(
  (state: GlobalState) => !!state.register?.tokenIsValid,
);

export const selectRegistrationToken = defaultMemoize(
  (state: GlobalState) => state.location?.query?.token,
);

export const selectAudioPlayerIsOpen = defaultMemoize(
  (state: GlobalState) => !!state.audioPlayer?.isOpen,
);
