import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const inputWrapperStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1rem',
  },
});

export const InputWrapper = (props: { children: React.ReactNode }) => {
  const classes = inputWrapperStyles({});
  return <div className={classes.root}>{props.children}</div>;
};

export const FormSubTitle = ({ title }: { title: string }) => (
  <Typography variant='h6'>{title}</Typography>
);
