import * as React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { useDispatch } from 'react-redux';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { deleteSongCollection } from 'store/songCollections';
import {
  selectSongCollectionData,
  selectSongCollectionsLoadingStatus,
} from 'store/selectors';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import ConnectedButton from 'components/Button/ConnectedButton';
import { TableConfigEntry } from 'utils/tableConfig';
import { getReadableDate } from 'utils/utils';
import EditButton from 'components/Button/EditButton';
import { useNlSelector } from 'utils/redux';
import DeleteButton from 'components/Button/DeleteButton';
import InternalLink from 'components/Link/InternalLink';

const useStyles = makeStyles(styles);

interface ButtonProps {
  uuid: string;
  read_only: boolean;
  isLoaded: boolean;
}

const EditSongCollectionButton = ({
  uuid,
  read_only,
  isLoaded,
}: ButtonProps) => {
  const dispatch = useDispatch();
  return (
    <EditButton
      aria-label='Edit'
      onClick={() => {
        dispatch({
          type: 'route/SONG_COLLECTIONS_EDIT',
          payload: { uuid },
        });
      }}
      disabled={!isLoaded || read_only}
    />
  );
};

const DeleteSongCollectionButton = ({
  uuid,
  read_only,
  isLoaded,
}: ButtonProps) => {
  const dispatch = useDispatch();
  return (
    <DeleteButton
      aria-label='Delete'
      onClick={() => {
        if (
          window.confirm(
            'Are you sure you want to delete this song collection?',
          )
        )
          dispatch(deleteSongCollection({ uuid }));
      }}
      disabled={!isLoaded || read_only}
    />
  );
};

const tableConfig = (
  isLoaded: boolean,
): TableConfigEntry<Nl.Api.SongCollection> => ({
  storeKey: 'songCollections',
  columnData: [
    { id: 'name', label: 'Name', isSortable: true },
    {
      id: 'song_count',
      label: 'Songs',
      render: ({ song_count, uuid }) =>
        song_count < 1 ? (
          song_count
        ) : (
          <InternalLink
            color='inherit'
            to={{
              type: 'route/SONGS',
              payload: { query: { song_collection_uuid: uuid } },
            }}
          >
            {song_count}
          </InternalLink>
        ),
    },
    {
      id: 'channels',
      label: 'Channels',
      render: ({ channel_count }) => channel_count,
    },
    {
      id: 'modified',
      label: 'Last Modified',
      render: ({ updated_at }) => getReadableDate(updated_at),
    },
    {
      id: 'edit',
      label: '',
      permission: 'CAN_UPDATE_SONG_COLLECTIONS',
      render: ({ uuid, read_only }) => (
        <EditSongCollectionButton
          isLoaded={isLoaded}
          uuid={uuid}
          read_only={read_only}
        />
      ),
    },
    {
      id: 'delete',
      label: '',
      permission: 'CAN_DELETE_SONG_COLLECTIONS',
      render: ({ uuid, read_only }) => (
        <DeleteSongCollectionButton
          isLoaded={isLoaded}
          uuid={uuid}
          read_only={read_only}
        />
      ),
    },
  ],
});

export default function SongCollectionPage() {
  const data = useNlSelector(selectSongCollectionData);
  const isLoaded = useNlSelector(selectSongCollectionsLoadingStatus);
  const classes = useStyles({});

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Song Collections'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/SONG_COLLECTIONS_CREATE'
            label='Add a song collection'
            permission='CAN_CREATE_SONG_COLLECTIONS'
          />
        }
      />
      <Table config={tableConfig(isLoaded)} data={data} isLoaded={isLoaded} />
    </Paper>
  );
}
