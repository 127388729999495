import * as React from 'react';
import { Button } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { generateSongZips, downloadZip } from 'store/singleSong';
import ActionsSourceFiles from './ActionsSourceFiles';
import { GlobalState } from 'store/rootReducer';
import { connect } from 'react-redux';

interface OwnProps {
  songUuid: string;
  types: Nl.SongSourceFileType[];
}

interface StateProps {
  zipState: 'pending' | 'in_progress' | 'complete' | undefined;
  generateZipsAllowed: boolean;
}

interface DispatchProps {
  generateZips: () => void;
  downloadZipFile: (type: Nl.SongSourceFileType | 'all') => void;
}

type AllProps = OwnProps & StateProps & DispatchProps;

const ActionDownloadZips: React.SFC<AllProps> = ({
  types,
  zipState,
  generateZips,
  downloadZipFile,
  generateZipsAllowed,
}) => {
  switch (zipState) {
    case 'in_progress':
      return <Button disabled>Generate Zips In Progress</Button>;
    case 'complete':
      return (
        <ActionsSourceFiles
          title='Download'
          icon={<DownloadIcon />}
          onClickAction={(type) => downloadZipFile(type)}
          types={types}
        />
      );
    default:
      return (
        <Button onClick={() => generateZips()} disabled={!generateZipsAllowed}>
          Generate Zips
        </Button>
      );
  }
};

export default connect(
  (state: GlobalState) => ({
    zipState: state.singleSong.data.song?.zip_state,
    generateZipsAllowed:
      state.singleSong.data.song?.generate_zip_allowed ?? false,
  }),
  (dispatch, { songUuid }: OwnProps) => ({
    generateZips: () => dispatch(generateSongZips({ songUuid })),
    downloadZipFile: (type: Nl.SongSourceFileType | 'all') =>
      dispatch(downloadZip({ songUuid, type })),
  }),
)(ActionDownloadZips);
