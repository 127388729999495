import * as React from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Link from 'redux-first-router-link';

interface OwnProps {
  to?: object;
  onClick?: () => void;
  disabled?: boolean;
}

const EditButton: React.SFC<OwnProps> = ({ to, onClick, disabled }) => {
  const ButtonLink: React.SFC<any> = React.forwardRef((props, ref) => (
    <Link to={to} {...props} />
  ));
  const component = to && ButtonLink;
  return (
    <IconButton
      data-e2e='editButton'
      aria-label='Edit'
      color='default'
      {...{ component, onClick, disabled }}
    >
      <EditIcon />
    </IconButton>
  );
};

export default EditButton;
