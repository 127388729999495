import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from '../utils/storeUtils';
import { cleanUserFormPassword } from '../utils/formUtils';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { goPreviousPage } from 'store/router';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

// Action Constants
const FETCH_SINGLE_USER = 'FETCH_SINGLE_USER';
const FETCH_SINGLE_USER_SUCCESS = 'FETCH_SINGLE_USER_SUCCESS';
const FETCH_SINGLE_USER_ERROR = 'FETCH_SINGLE_USER_ERROR';
const CREATE_USER = 'CREATE_USER';
const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
const EDIT_USER = 'EDIT_USER';
const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
const TOGGLE_USER_IS_ACTIVE = 'TOGGLE_USER_IS_ACTIVE';

// Action Creators
export const fetchSingleUser = createAction(FETCH_SINGLE_USER);
const fetchSingleUserSuccess = createAction(FETCH_SINGLE_USER_SUCCESS);
const fetchSingleUserError = createAction(FETCH_SINGLE_USER_ERROR);
export const createUser = createAction(CREATE_USER);
const createUserError = createAction(CREATE_USER_ERROR);
export const editUser = createAction(EDIT_USER);
const editUserSuccess = createAction(EDIT_USER_SUCCESS);
const editUserError = createAction(EDIT_USER_ERROR);
export const toggleUserIsActive = createAction(TOGGLE_USER_IS_ACTIVE);

const userInitialValues = {
  uuid: '' as string,
  first_name: '' as string,
  last_name: '' as string,
  email: '' as string,
  user_role_uuid: '' as string,
  is_active: false as boolean,
  password: '' as string,
};

export type UserInitialValues = typeof userInitialValues;

// Selector
export const getEditFormInitialValues = (user?: Nl.Api.User) => {
  return user
    ? {
        uuid: user.uuid,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        user_role_uuid: user.user_role.uuid,
        is_active: user.is_active,
        password: '',
      }
    : userInitialValues;
};

// Reducer

export type UsersState = Readonly<{
  data: {
    user?: Nl.Api.User;
    total_size: number;
    current_size?: number;
    current_page?: number;
    offset?: number;
    total_pages?: number;
  };
  isLoading: boolean;
  isLoaded: boolean;
}>;

const initialState: UsersState = {
  data: {
    total_size: 0,
  },
  isLoading: false,
  isLoaded: false,
};

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_SINGLE_USER: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SINGLE_USER_SUCCESS:
    case EDIT_USER_SUCCESS: {
      return assign(state, {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        isLoading: false,
      });
    }

    case FETCH_SINGLE_USER_ERROR: {
      return assign(state, {
        isLoading: false,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSingleUserSaga(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<any> = yield call(apiFetch, `/user/${uuid}`);
    if (results.success) {
      yield put(fetchSingleUserSuccess({ data: results.data }));
    } else {
      yield put(fetchSingleUserError({ error: results.msg }));
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *createUserSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(apiFetch, '/user', {
      method: 'POST',
      body: { ...formData },
    });
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.first_name} ${formData.last_name} has been created`,
        }),
      );
      yield put(goPreviousPage());
    } else {
      yield put(createUserError({ error: results.msg }));
      if (results.errors) {
        yield put(addErrorNotification({ message: results.errors.email }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
    }
    formActions.setSubmitting(false);
  },
  *editUserSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const cleanedFormData = cleanUserFormPassword(formData);
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/user/${formData.uuid}`,
      {
        method: 'PATCH',
        body: { ...cleanedFormData },
      },
    );
    if (results.success) {
      yield put(editUserSuccess({ data: results.data }));
      yield put(
        addSuccessNotification({
          message: `${results.data.user.first_name} ${results.data.user.last_name} has been updated`,
        }),
      );
      formActions.resetForm();
    } else {
      yield put(editUserError({ error: results.msg }));
      if (results.errors) {
        yield put(addErrorNotification({ message: results.errors.email }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
    }
    formActions.setSubmitting(false);
  },
  *toggleUserIsActiveSaga({ payload }: SimpleActionType) {
    const { uuid, data } = payload;
    const results: FetchResponse<Nl.Api.UserResponse> = yield call(
      apiFetch,
      `/user/${uuid}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
    );
    if (results.success) {
      yield put(editUserSuccess({ data: results.data }));
      invalidateNovaQueries('/user');
      const message = `${results?.data?.user?.first_name ?? ''} ${
        results?.data?.user?.last_name ?? ''
      } has been updated`;
      yield put(addSuccessNotification({ message }));
    } else {
      yield put(editUserError({ error: results.msg }));
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(FETCH_SINGLE_USER, sagas.fetchSingleUserSaga),
    takeEvery(CREATE_USER, sagas.createUserSaga),
    takeLatest(EDIT_USER, sagas.editUserSaga),
    takeLatest(TOGGLE_USER_IS_ACTIVE, sagas.toggleUserIsActiveSaga),
  ]);
}

export { sagas };
export default reducer;
