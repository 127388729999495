import * as React from 'react';
import config from 'config';
import { debounce } from 'lodash';
import { InputAdornment, Input, WithStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { fade } from '@material-ui/core/styles/colorManipulator';

// TODO Refactor this component. Currently it is based on a copy/paste of the InputField and the css should be simpler.
const styles = createStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.03),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.06),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    maxWidth: '500px',
    height: '35px',
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: fade(theme.palette.common.black, 0.15),
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  clear: {
    marginTop: '2px',
    marginRight: '6px',
    color: fade(theme.palette.common.black, 0.15),
    cursor: 'pointer',
  },
}));

interface OwnProps {
  id: string;
  classes?: any;
  e2e?: string;
  label: string;
  defaultValue?: string;
  onChange(val: string | undefined): void;
}

type AllProps = OwnProps & WithStyles<typeof styles>;

class SearchField extends React.PureComponent<AllProps> {
  private inputRef = React.createRef<HTMLDivElement>();

  render() {
    const {
      id,
      classes,
      onChange,
      e2e,
      label,
      defaultValue,
      ...rest
    } = this.props;
    const debouncedSearchFunction = debounce(
      (value) => onChange!(value),
      config.app.asyncTypingTimeout,
    );

    const handleSearchOnChange = ({
      target,
    }: React.ChangeEvent<HTMLInputElement>): void => {
      debouncedSearchFunction(
        target.value.length > 1 ? target.value : undefined,
      );
    };

    return (
      <div ref={this.inputRef} className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <Input
          key={e2e}
          placeholder={label}
          disableUnderline
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          type='text'
          inputProps={{ 'data-e2e': e2e, 'aria-label': label }}
          onChange={handleSearchOnChange}
          defaultValue={defaultValue}
          endAdornment={
            defaultValue && (
              <InputAdornment position='end'>
                <ClearIcon
                  aria-label='clear'
                  className={classes.clear}
                  onClick={() => {
                    // Dirty hack to reset the input value
                    this.inputRef.current!.getElementsByTagName(
                      'input',
                    )[0].value = '';
                    onChange(undefined);
                  }}
                  style={{ fontSize: 20 }}
                />
              </InputAdornment>
            )
          }
          {...rest}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SearchField);
