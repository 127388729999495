import { useSongDownstreamStatus } from 'novaApi/hooks/song';
import * as React from 'react';
import UserLogList from '../UserLogList';
import DownstreamStatusItem from './DownstreamStatusItem';

interface OwnProps {
  className?: string;
  songUuid: string;
}

export default function DownstreamStatusList({
  className,
  songUuid,
}: OwnProps) {
  const items = useSongDownstreamStatus(songUuid);
  return (
    <UserLogList
      className={className}
      title='Current Status on all Channels'
      items={items}
      componentBuilder={(item: Nl.Api.SongFinderItem, idx: number) => (
        <DownstreamStatusItem item={item} key={idx} />
      )}
      emptyMessage='Not available'
    />
  );
}
