import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

const MusicNotes = (props: {}) => (
  <SvgIcon {...props} viewBox='0 0 124 104'>
    <path d='M25.1851852,21.146875 L25.1851852,72.59375 C22.5092593,70.5421875 19.3611111,69.4375 15.7407407,69.4375 C7.08333333,69.4375 0,76.5390625 0,85.21875 C0,93.8984375 7.08333333,101 15.7407407,101 C24.3981481,101 31.4814815,93.8984375 31.4814815,85.21875 L31.4814815,44.1875 L78.7037037,28.40625 L78.7037037,63.125 C76.0277778,61.0734375 72.8796296,59.96875 69.2592593,59.96875 C60.6018519,59.96875 53.5185185,67.0703125 53.5185185,75.75 C53.5185185,84.4296875 60.6018519,91.53125 69.2592593,91.53125 C77.9166667,91.53125 85,84.4296875 85,75.75 L85,0 L25.1851852,21.146875 Z' />
  </SvgIcon>
);

export default MusicNotes;
