import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from '../apiUtils';
import { useNovaApi } from '../useNovaApi';

export const useSearchUsers = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.UsersResponse>('/user', { query });
};

export const useArtistRepEntries = () => {
  return (
    useNovaApi('/user/select_artist_rep', {
      convert: ({ users }: Nl.Api.SelectArtistRepResponse) =>
        users.map(({ full_name, uuid }) => ({
          label: full_name,
          value: uuid,
        })),
    }).data || []
  );
};
