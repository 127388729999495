import * as React from 'react';
import ChipMeter from './ChipMeter';

const getIndicatorByPublishState = (
  publishState: Nl.PublishState,
): 'high' | 'medium' | 'low' | 'red' => {
  switch (publishState) {
    case 'Published':
      return 'high';
    case 'Taken Down':
      return 'red';
    case 'Error':
      return 'low';
    default:
      return 'medium';
  }
};

interface OwnProps {
  publishState: Nl.PublishState;
}

type AllProps = OwnProps;

const PublicationMeter: React.SFC<AllProps> = ({ publishState }) => {
  return (
    <ChipMeter
      label={publishState}
      indicator={getIndicatorByPublishState(publishState)}
    />
  );
};

export default PublicationMeter;
