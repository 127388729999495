import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';
import { NotificationState } from 'store/notifications';

export default function validationSchema(notificationState: NotificationState) {
  return Yup.object().shape({
    name: Yup.string()
      .test('Check if server error', notificationState.message!, () => {
        return notificationState.status === 'default';
      })
      .required(errorMessages.basicRequired),
    country_uuid: Yup.string().nullable().required(errorMessages.invalidUUID),
  });
}

export const earningsReportUploadValidation = Yup.object().shape({
  pro: Yup.string().required(errorMessages.basicRequired),
  date: Yup.string().required(errorMessages.basicRequired),
  source_file: Yup.object().shape({
    url: Yup.string().required(errorMessages.basicRequired),
  }),
});
