import { useDispatch } from 'react-redux';
import { addErrorNotification } from 'store/notifications';
import config from '../config';
import lodash from 'lodash';
import { getNovaApiHeaders, NovaApiResponse } from './apiUtils';
import { useLastDefinedValue } from '../utils/hooks/useLastDefinedValue';
import { stringify } from 'query-string';
import { NovaApiPath } from './NovaApiPath';
import { combineURLs } from 'utils/utils';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from 'queryClient';
import { FormikHelpers, FormikValues } from 'formik';
import { useNlSelector } from 'utils/redux';

export class ApiError extends Error {
  constructor(
    message: string,
    public status: number | undefined,
    public response: any,
  ) {
    super(message);
  }
}

export const formatBody = (body?: FormData | string | Object) => {
  // Body needs no transformation
  if (!body || body instanceof FormData || typeof body === 'string') {
    return body;
  }
  return JSON.stringify(body);
};

export const fetchNovaApi = <Data>(
  path: NovaApiPath | string,
  {
    query,
    body,
    ...options
  }: Omit<RequestInit, 'body' | 'method'> & {
    method?: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
    query?: Record<string, any>;
    body?: FormData | string | Object;
  } = {},
) => {
  return fetcher<Data>(getNovaApiUrl(path, query), {
    ...options,
    body: formatBody(body),
  });
};

const fetcher = async <Data>(
  url: string,
  options?: RequestInit | undefined,
): Promise<Data> => {
  const response = await fetch(url, {
    ...options,
    headers: { ...getNovaApiHeaders(), ...options?.headers },
  });

  if (response.status === 204) {
    // need this because DELETE API returns 'no content (204)'.
    return new Promise((resolve) => resolve({} as Data));
  }

  const responseBody: NovaApiResponse<Data> = await response.json();
  if (!response.ok) {
    throw new ApiError(response.statusText, response.status, responseBody);
  }
  return responseBody.data;
};

export function getNovaApiUrl(
  path?: NovaApiPath | string,
  query?: Record<string, any>,
) {
  const urlBuilder = new URL(combineURLs(config.clients.api.url, path || ''));
  if (query) {
    urlBuilder.search = stringify(query);
  }
  return urlBuilder.toString();
}

/** Useful to invalidate cache data when we know it became stale */
export const invalidateNovaQueries = (
  path: NovaApiPath | string,
  query?: Record<string, any>,
  pageType?: string,
) => {
  queryClient.invalidateQueries([path, query, pageType].filter(Boolean));
};

interface IMutationData<T> {
  formData: T;
  formActions?: FormikHelpers<unknown>;
}

type IMutationFunction<TResponseData, TFormData> = (
  mutationData: IMutationData<TFormData>,
) => Promise<TResponseData>;

export const useNovaApiCreate = <TResponseData, TFormData>(
  path: NovaApiPath | string,
  onSuccess?: (responseData: TResponseData) => void,
  options?: RequestInit,
) => {
  const mutationFunction: IMutationFunction<TResponseData, TFormData> = ({
    formData,
  }) => {
    return fetcher<TResponseData>(getNovaApiUrl(path), {
      body: formatBody(formData as Object),
      ...options,
      method: 'POST',
    });
  };
  return useNovaApiMutation(path, mutationFunction, onSuccess);
};

export const useNovaApiUpdate = <TResponseData, TFormData>(
  path: NovaApiPath | string,
  onSuccess?: (responseData: TResponseData) => void,
  options?: RequestInit,
) => {
  const mutationFunction: IMutationFunction<TResponseData, TFormData> = ({
    formData,
  }) => {
    return fetcher<TResponseData>(getNovaApiUrl(path), {
      body: formatBody(formData as Object),
      ...options,
      method: 'PUT',
    });
  };
  return useNovaApiMutation(path, mutationFunction, onSuccess);
};

export const useNovaApiDelete = (
  path: NovaApiPath | string,
  onSuccess?: () => void,
  options?: RequestInit,
) => {
  const mutationFunction: IMutationFunction<Response, unknown> = () => {
    return fetcher<Response>(getNovaApiUrl(path), {
      ...options,
      method: 'DELETE',
    });
  };
  return useNovaApiMutation(path, mutationFunction, onSuccess);
};

export const useNovaApiMutation = <TResponseData, TFormData>(
  path: NovaApiPath | string,
  mutationFn: IMutationFunction<TResponseData, TFormData>,
  onSuccess?: (responseData: TResponseData, formData: TFormData) => void,
) => {
  const dispatch = useDispatch();

  const mutation = useMutation(mutationFn, {
    // before mutation
    onMutate: async () => {
      await queryClient.cancelQueries(path);
    },
    // on success of mutation
    onSuccess: (
      responseData: TResponseData,
      mutationData: IMutationData<TFormData>,
    ) => {
      // optional callback that can be used in hooks
      if (onSuccess) onSuccess(responseData, mutationData.formData);
    },
    // on mutation errors show them on Form and Toast message
    onError: (
      errors: { response: NovaApiResponse<TResponseData> },
      mutationData,
    ) => {
      // eslint-disable-next-line no-console
      console.error(`react-query error:`, errors);
      mutationData.formActions?.setErrors(
        errors.response.errors as FormikValues,
      );
      mutationData.formActions?.setSubmitting(false);

      let errorsText = 'This may be due to a network failure.';
      if (!lodash.isEmpty(errors.response.errors)) {
        errorsText = '';
        Object.values(errors.response.errors).forEach((err) => {
          errorsText += `\n\u2022 ${err}`;
        });
      } else if (errors.response.message) {
        errorsText = errors.response.message;
      }

      dispatch(
        addErrorNotification({
          message: `Impossible to complete the request. ${errorsText}`,
        }),
      );
    },
  });

  const { data, isLoading, isSuccess, isError, error } = mutation;
  return { mutation, isLoading, data, isSuccess, isError, error };
};

export const useNovaApi = <Data, Converted = Data>(
  path?: NovaApiPath | string,
  {
    query,
    convert = (data: any) => data,
    initialData,
    staleTime = 0,
    enabled = true,
  }: {
    query?: Record<string, any>;
    convert?: (data: Data) => Converted;
    initialData?: Converted;
    staleTime?: number;
    enabled?: boolean;
  } = {},
) => {
  const dispatch = useDispatch();

  // 'currentPage.type' is used to invalidate stale queries when we navigate
  // between page/modal and refetch actual data
  const location = useNlSelector((state) => state.location);
  const currentPage = location.routesMap?.[location.type] as Nl.RouteMapProps;
  const { data, error, isFetching } = useQuery<Converted>(
    [path, query, currentPage?.type],
    () => {
      if (path) {
        return fetcher<Data>(getNovaApiUrl(path, query)).then(convert);
      }
      return new Promise((resolve) =>
        resolve((initialData || {}) as Converted),
      );
    },
    {
      // Regaining focus triggers a refetch, potentially causing the next invalidate to fail
      // https://github.com/tannerlinsley/react-query/issues/2154
      refetchOnWindowFocus: false,
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.error(`react-query error:`, err);
        dispatch(
          addErrorNotification({
            message:
              'Impossible to complete the request. This may be due to a network failure.',
          }),
        );
      },
      // do not retry if we got a response from the server
      retry: (failureCount, e: any) => {
        return e.response ? false : failureCount < 3;
      },
      initialData,
      staleTime,
      enabled,
    },
  );
  const returnedData = useLastDefinedValue(data);
  const isLoaded = returnedData !== undefined;
  return { isLoaded, data: returnedData, error, isFetching };
};
