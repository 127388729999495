import React from 'react';
import DateSelect from 'components/Form/DateSelect';
import { FormHelperText } from '@material-ui/core';

interface DateSelectFieldProps {
  id: string;
  label: string;
  field: any;
  form: any;
}

export default function DateSelectField({
  id,
  label,
  field,
  form,
}: DateSelectFieldProps) {
  const formError = form?.errors?.[field.name];

  return (
    <div>
      <DateSelect
        {...{ id, label, defaultValue: field.value, ...field }}
        onChange={(val) => form.setFieldValue(field.name, val)}
        isError={!!formError}
      />
      {formError && (
        <FormHelperText style={{ marginLeft: '14px' }} error>
          {formError}
        </FormHelperText>
      )}
    </div>
  );
}
