import { SimpleActionType, createAction, assign } from 'utils/storeUtils';
import {
  convertQueryObjToUrlString,
  getUrlQueryParams,
  apiFetch,
  FetchResponse,
} from 'novaApi/apiUtils';
import { select, call, put, all, takeLatest } from 'redux-saga/effects';
import { head } from 'lodash';
import { addErrorNotification, addSuccessNotification } from './notifications';

// Action Constants
const FETCH_MY_WRITERS = 'FETCH_MY_WRITERS';
const FETCH_MY_WRITERS_SUCCESS = 'FETCH_MY_WRITERS_SUCCESS';
const CREATE_MY_WRITER = 'CREATE_MY_WRITER';
const SEARCH_WRITERS = 'SEARCH_WRITERS';
const SEARCH_WRITERS_SUCCESS = 'SEARCH_WRITERS_SUCCESS';
const ADD_SUGGESTED_WRITER = 'ADD_SUGGESTED_WRITER';
const CLEAR_SINGLE_MY_WRITER_DATA = 'CLEAR_SINGLE_MY_WRITER_DATA';

// Action Creators
export const fetchMyWriters = createAction(FETCH_MY_WRITERS);
const fetchMyWritersSuccess = createAction(FETCH_MY_WRITERS_SUCCESS);
export const createMyWriter = createAction(CREATE_MY_WRITER);
export const searchWriters = createAction(SEARCH_WRITERS);
const searchMyWritersSuccess = createAction(SEARCH_WRITERS_SUCCESS);
export const addSuggestedWriter = createAction(ADD_SUGGESTED_WRITER);
export const clearSingleMyWriterData = createAction(
  CLEAR_SINGLE_MY_WRITER_DATA,
);

// Initial Values
export const editFormInitialValues = {
  first_name: '' as string,
  last_name: '' as string,
  is_default: false as boolean,
  performing_rights_organization_uuid: '' as string,
  ipi_number: '' as string,
  performing_rights_organization_member_id: '' as string,
};
export type MyWriterFormInitialValues = typeof editFormInitialValues;
const initialState = {
  data: {
    writers: [] as Nl.Api.Writer[],
    total_size: 0,
    offset: 0,
    current_page: 1,
    current_size: 0,
    total_pages: 1,
  },
  suggestedWriter: null as Nl.Api.Writer | null,
  isLoading: false,
  isLoaded: false,
};

export type MyWritersState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_MY_WRITERS: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_MY_WRITERS_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    case SEARCH_WRITERS_SUCCESS: {
      const suggestedWriters = action.payload?.data?.writers ?? [];
      return assign(state, {
        suggestedWriter: head(suggestedWriters),
      });
    }

    case CLEAR_SINGLE_MY_WRITER_DATA: {
      return assign(state, {
        suggestedWriter: initialState.suggestedWriter,
      });
    }

    default:
      return state;
  }
};

const sagas = {
  *fetchMyWritersSaga(action: SimpleActionType) {
    const queryParams: string = action?.payload
      ? convertQueryObjToUrlString(action.payload)
      : yield select(getUrlQueryParams);
    try {
      const results: FetchResponse<Nl.Api.MyWritersResponse> = yield call(
        apiFetch,
        `/my_writer${queryParams}`,
      );
      yield put(fetchMyWritersSuccess({ data: results.data }));
    } catch ({ message }) {
      yield put(addErrorNotification({ message }));
    }
  },
  *searchWritersSaga(action: SimpleActionType) {
    const { ipi_number } = action.payload;
    const { success, msg, data } = yield call(
      apiFetch,
      `/writer?ipi_number=${ipi_number}`,
    );
    if (success) {
      yield put(searchMyWritersSuccess({ data }));
    } else {
      yield put(addErrorNotification({ message: msg }));
    }
  },
  *createMyWriterSaga(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const { ipi_number } = formData;
    if (ipi_number) {
      yield put(searchWriters({ ipi_number }));
    }
    const { success, msg, errors } = yield call(apiFetch, '/my_writer', {
      method: 'POST',
      body: formData,
    });
    if (success) {
      yield put(
        addSuccessNotification({ message: 'Your writer has been created' }),
      );
      yield put({ type: 'route/MY_WRITERS' });
    } else {
      yield put(addErrorNotification({ message: msg }));
      formActions.setErrors(errors);
    }
    formActions.setSubmitting(false);
  },
  *addSuggestedWriterSaga(action: SimpleActionType) {
    const { success, msg } = yield call(apiFetch, '/my_writer/existing', {
      method: 'POST',
      body: {
        writer_uuid: action.payload.writer.uuid,
      },
    });
    if (success) {
      yield put(addSuccessNotification({ message: 'Writer added' }));
      yield put({ type: 'route/MY_WRITERS' });
    } else {
      yield put(addErrorNotification({ message: msg }));
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(FETCH_MY_WRITERS, sagas.fetchMyWritersSaga),
    takeLatest(CREATE_MY_WRITER, sagas.createMyWriterSaga),
    takeLatest(SEARCH_WRITERS, sagas.searchWritersSaga),
    takeLatest(ADD_SUGGESTED_WRITER, sagas.addSuggestedWriterSaga),
  ]);
}

export { sagas };
export default reducer;
