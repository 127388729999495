import * as React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { useDispatch } from 'react-redux';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { TableConfigEntry } from 'utils/tableConfig';
import { toggleUserIsActive } from 'store/users';
import { useSearchUsers } from 'novaApi/hooks/user';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import UserFilters from 'components/UserFilters/UserFilters';
import ConnectedButton from 'components/Button/ConnectedButton';
import { getReadableDate } from 'utils/utils';
import BasicIconButton from 'components/Button/BasicIconButton';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import EditButton from 'components/Button/EditButton';

const useStyles = makeStyles(styles);

const ToggleActiveButton = ({
  is_active,
  uuid,
  isLoaded,
}: {
  uuid: string;
  is_active: boolean;
  isLoaded: boolean;
}) => {
  const dispatch = useDispatch();
  return (
    <BasicIconButton
      aria-label={is_active ? 'Deactivate' : 'Activate'}
      onClick={() =>
        dispatch(
          toggleUserIsActive({
            uuid,
            data: { is_active: !is_active },
          }),
        )
      }
      color='secondary'
      disabled={!isLoaded}
    >
      {is_active ? <Lock /> : <LockOpen />}
    </BasicIconButton>
  );
};

const EditUserButton = ({
  uuid,
  isLoaded,
}: {
  uuid: string;
  isLoaded: boolean;
}) => {
  const dispatch = useDispatch();
  return (
    <EditButton
      aria-label='Edit'
      onClick={() => {
        dispatch({
          type: 'route/USERS_EDIT',
          payload: { uuid },
        });
      }}
      disabled={!isLoaded}
    />
  );
};

const tableConfig = (isLoaded: boolean): TableConfigEntry<Nl.Api.User> => ({
  columnData: [
    { id: 'full_name', label: 'Name', isSortable: true },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Role', render: ({ user_role }) => user_role.name },
    {
      id: 'active',
      label: 'Is Active?',
      render: ({ is_active }) => (is_active ? 'Yes' : 'No'),
    },
    {
      id: 'last_active_at',
      label: 'Last Active At',
      render: ({ last_active_at }) =>
        last_active_at ? getReadableDate(last_active_at) : 'Never',
    },
    {
      id: 'toggle_active',
      label: '',
      render: ({ is_active, uuid }) => (
        <ToggleActiveButton {...{ is_active, uuid, isLoaded }} />
      ),
    },
    {
      id: 'edit',
      label: '',
      permission: 'CAN_UPDATE_USERS',
      render: ({ uuid }) => <EditUserButton {...{ uuid, isLoaded }} />,
    },
  ],
});

export default function UsersPage() {
  const { data } = useSearchUsers();
  const isLoaded = !!data;
  const classes = useStyles({});

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Users'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'name',
              },
            ]}
          />
        }
        filters={<UserFilters />}
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/USERS_CREATE'
            label='Create new User'
            permission='CAN_CREATE_USERS'
          />
        }
      />
      <Table
        config={tableConfig(isLoaded)}
        data={data?.users || []}
        isLoaded={isLoaded}
        paginationData={data}
      />
    </Paper>
  );
}
