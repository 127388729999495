import React from 'react';
import { useNlSelector } from 'utils/redux';

import { getUrlPayloadParams } from 'novaApi/apiUtils';
import FormikWrapper from 'components/Formik';
import TermForm, { getFormInitialValues } from './TermsForm';
import { useTerm, useTermUpdate } from 'novaApi/hooks/term';
import { goPreviousPage } from 'store/router';
import { useDispatch } from 'react-redux';
import { getValidationSchema } from './validation';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';

const TermEditPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useNlSelector(getUrlPayloadParams);

  const { data, isLoaded } = useTerm(uuid!);
  const { updateTerm, updatedTerm, isSuccess } = useTermUpdate(uuid!);

  const handleFormSubmit = (formData: Nl.Api.Term, formActions) => {
    updateTerm({ formData, formActions });
  };

  if (!data && isLoaded) return null;
  if (updatedTerm && isSuccess) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={getFormInitialValues(data?.term)}
      validationSchema={getValidationSchema()}
      submitForm={(formData, formActions) => {
        handleFormSubmit(formData as Nl.Api.Term, formActions);
      }}
      title='Term details'
      customSize='md'
    >
      <TermForm />

      {uuid && <CollapsableUserLogs activityType='terms' resourceUuid={uuid} />}
    </FormikWrapper>
  );
};

export default TermEditPage;
