import * as React from 'react';
import InputField from 'components/Formik/InputField';
import { FormSubTitle } from 'components/Formik/utils';
import { Field, Form } from 'formik';

const ComparisonForm = () => {
  return (
    <Form>
      <FormSubTitle title='Comparison Information' />
      <Field name='name' label='Name' component={InputField} />
      <Field
        name='definition'
        label='Definition'
        component={InputField}
        multiline
        rows={1}
        rowsMax={4}
      />
      <Field
        name='procedural_definition'
        label='Procedural Definition'
        component={InputField}
        multiline
        rows={1}
        rowsMax={4}
      />
    </Form>
  );
};

export default ComparisonForm;
