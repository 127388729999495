import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import {
  NAS_CAME_FROM_VALUES,
  NAS_STATUS_VALUES,
  PRO_TYPE_OPTIONS,
} from 'constants/staticData';
import React, { useMemo } from 'react';
import { useCountries } from 'novaApi/hooks/country';

export function ArtistSubmissionsFilters() {
  const countries = useCountries();
  const countriesISO = useMemo(
    () => countries.map(({ iso_code, name }) => ({ uuid: iso_code, name })),
    [countries],
  );

  return (
    <ConnectedTableFilters
      filters={[
        {
          type: 'single_select',
          label: 'Status',
          queryFilterKey: 'status',
          optionsSelector: () => NAS_STATUS_VALUES,
          e2e: 'select_status',
        },
        {
          type: 'single_select',
          label: 'Country',
          queryFilterKey: 'country',
          optionsSelector: () => countriesISO,
          e2e: 'select_country',
        },
        {
          type: 'single_select',
          label: 'From',
          queryFilterKey: 'came_from',
          optionsSelector: () => NAS_CAME_FROM_VALUES,
          e2e: 'select_came_from',
        },
        {
          type: 'single_select',
          label: 'PRO Membership',
          queryFilterKey: 'is_pro_member',
          optionsSelector: () => PRO_TYPE_OPTIONS,
          e2e: 'select_is_pro_member',
        },
      ]}
    />
  );
}
