import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import {
  getEditFormInitialValues,
  editSongCollection,
} from 'store/songCollections';
import { selectNotificationStatus } from 'store/selectors';
import SongCollectionForm from './SongCollectionForm';
import validationSchema from 'utils/basicYupValidation';
import { useNlSelector } from 'utils/redux';

export default function SongCollectionEditPage() {
  const songCollection = useNlSelector(
    (state) => state.songCollections.data.song_collection,
  );
  const notificationStatus = useNlSelector(selectNotificationStatus);
  const dispatch = useDispatch();
  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues(songCollection)}
      validationSchema={validationSchema(notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(editSongCollection({ formData, formActions }))
      }
      title='Edit Song Collection'
    >
      <SongCollectionForm />
      {songCollection && (
        <CollapsableUserLogs
          activityType='song-collections'
          resourceUuid={songCollection.uuid}
        />
      )}
    </FormikWrapper>
  );
}
