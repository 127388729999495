import { useNovaApi } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { selectors as authSelectors } from 'store/auth';

export const useWriter = (uuid: string) => {
  return useNovaApi<Nl.Api.WriterResponse>(`/writer/${uuid}`).data?.writer;
};

export const useWriters = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.WritersResponse>('/writer', {
    query,
  }).data;
};

export const useWriterSelect = () => {
  const enabled = useNlSelector((state) =>
    authSelectors.isUserAllowed(state, 'CAN_GET_WRITERS'),
  );

  return (
    useNovaApi<Nl.Api.WriterSelectResponse>('/writer/select', { enabled }).data
      ?.writers ?? []
  );
};

export const useDesignationCodes = () => {
  return (
    useNovaApi<Nl.Api.WriterConstantsResponse>('/writer/constants', {
      staleTime: Infinity,
    }).data?.writer_constants?.designation_codes ?? []
  );
};
