import * as React from 'react';
import InputField from 'components/Formik/InputField';
import { FormSubTitle } from 'components/Formik/utils';
import { Field, Form } from 'formik';

const tagTermRelationTypeInitialValues = {
  uuid: '',
  name: '',
  description: '',
};

export const getFormInitialValues = (
  tagTermRelationType?: Nl.Api.TagTermRelationType,
) => {
  if (!tagTermRelationType) return { ...tagTermRelationTypeInitialValues };

  return {
    uuid: tagTermRelationType.uuid,
    name: tagTermRelationType.name,
    description: tagTermRelationType.description,
  };
};

const TagTermRelationTypeForm = () => {
  return (
    <Form>
      <FormSubTitle title='Tag-Term Relation Type Information' />
      <Field
        name='name'
        label='Tag-Term Relation Type name'
        component={InputField}
      />
      <Field
        name='description'
        label='Description'
        component={InputField}
        multiline
        rows={1}
        rowsMax={4}
      />
    </Form>
  );
};

export default TagTermRelationTypeForm;
