import * as React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';

const BasicIconButton: React.SFC<
  { children: React.ReactNode; 'aria-label': string } & IconButtonProps
> = ({ children, ...props }) => (
  <Tooltip title={props['aria-label']} placement='left'>
    <IconButton color='secondary' {...props}>
      {children}
    </IconButton>
  </Tooltip>
);

export default BasicIconButton;
