import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { selectIsLoaded } from 'store/selectors';
import Table from 'components/Table/Table';
import TableToolbar from 'components/TableToolbar';
import { TableConfigEntry } from 'utils/tableConfig';
import { ArtistSubmissionsFilters } from './ArtistSubmissionsFilters';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import InternalLink from 'components/Link/InternalLink';
import ArtistIndications from 'pages/Artists/ArtistIndications';
import { getReadableDate } from 'utils/utils';
import AudioControlIcon from 'components/AudioControlIcon';
import ArtistSubmissionsActions from 'pages/Submissions/ArtistSubmissionsActions';
import { useNlSelector } from 'utils/redux';

const useStyles = makeStyles(styles);

const previewIcon = ({
  uuid,
  url,
  original_filename,
  duration,
}: Nl.Api.SubmissionSourceFile) => (
  <AudioControlIcon
    key={uuid}
    audioTrack={{
      uuid,
      url,
      duration,
      trackName: original_filename,
      trackType: 'song',
    }}
    style={{ padding: 5 }}
  />
);

const config: TableConfigEntry<Nl.Api.ArtistSubmission> = {
  storeKey: 'artistSubmissions',
  columnData: [
    {
      id: 'full_name',
      label: 'Name',
      isSortable: true,
      cellProps: { style: { paddingRight: '5px' } },
      render: (obj) => (
        <InternalLink
          color='inherit'
          to={{
            type: 'route/ARTIST_SUBMISSION_EDIT_PAGE',
            payload: { uuid: obj.uuid },
          }}
        >
          {obj.full_name}
        </InternalLink>
      ),
    },
    {
      id: 'is_pro_free',
      label: '',
      cellProps: { style: { paddingLeft: 0 } },
      render: (obj) => (
        <ArtistIndications isPROFree={!obj.is_pro_member} isRoyalty={false} />
      ),
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      isSortable: true,
      cellProps: { style: { width: '80px' } },
      render: (obj) => getReadableDate(obj.updated_at),
    },
    {
      id: 'country',
      label: 'Country',
      isSortable: true,
      render: (obj) => obj.country.name,
    },
    { id: 'source', label: 'Source', cellProps: { style: { width: '85px' } } },
    { id: 'status', label: 'Status', cellProps: { style: { width: '165px' } } },
    {
      id: 'play_track',
      label: 'Previews',
      cellProps: { style: { minWidth: '204px', padding: 0 } },
      render: (obj) => (obj.source_files || []).map(previewIcon),
    },
    {
      id: 'actions',
      label: 'Actions',
      cellProps: { style: { width: '185px' } },
      render: (obj) => <ArtistSubmissionsActions artistSubmission={obj} />,
    },
  ],
};

const ArtistSubmissionsPage = () => {
  const classes = useStyles({});

  const data = useNlSelector(
    (state) => state.artistSubmissions.data.new_artist_submissions,
  );
  const isLoaded = useNlSelector((state) =>
    selectIsLoaded(state, 'artistSubmissions'),
  );

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Artist Submissions'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'full_name',
                e2e: 'name',
              },
            ]}
          />
        }
        filters={<ArtistSubmissionsFilters />}
      />
      <Table {...{ config, isLoaded, data }} />
    </Paper>
  );
};

export default ArtistSubmissionsPage;
