import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '2px',
    },
    colorPrimary: {
      backgroundColor: theme.palette.background.default,
    },
    barColorPrimary: {
      backgroundColor: '#f37569',
    },
  });

type Props = { isLoading: boolean } & WithStyles<typeof styles>;

const completed = 100; // %
const startDelay = 500; // ms

class ProgressBar extends React.PureComponent<Props> {
  timer!: NodeJS.Timeout;

  state = {
    progress: 0,
    show: false,
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate() {
    if (this.props.isLoading && !this.state.show) {
      clearInterval(this.timer);
      this.timer = setInterval(this.progress, startDelay);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ show: true, progress: 10 });
    } else if (!this.props.isLoading && !this.state.show) {
      clearInterval(this.timer);
    }
  }

  progress = () => {
    const { isLoading } = this.props;
    const { show, progress } = this.state;

    if (progress === completed && show) {
      this.setState({ show: false, progress: 0 });
    } else if (progress !== completed) {
      const diff = isLoading ? Math.random() * 30 : 99;
      this.setState({
        show: true,
        progress: Math.min(progress + diff, completed),
      });
    }
  };

  render() {
    const { classes, isLoading } = this.props;
    const { progress, show } = this.state;
    return (
      <div
        data-e2e={`ProgressBar-${isLoading ? 'on' : 'off'}`}
        style={{
          position: 'fixed',
          opacity: show ? 1 : 0,
          width: '100%',
          zIndex: 2000,
        }}
      >
        <LinearProgress
          classes={{
            root: classes.root,
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
          variant='determinate'
          value={progress}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ProgressBar);
