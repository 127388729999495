import { filter, snakeCase } from 'lodash';
import { fileTypes } from 'utils/songSourceFile';

export const myTrackInitialValues = (
  trackSubmission: Nl.Api.SingleTrackSubmission | undefined,
  myWriters: Nl.Api.Writer[] = [],
) => {
  const { acceptable_source_file_types } = trackSubmission || {};

  return {
    ...trackSubmission,
    artist_name: trackSubmission?.artist?.name || '',
    is_royalty: trackSubmission?.artist?.is_royalty || '',
    is_pro_free: trackSubmission?.artist?.is_pro_free || '',
    files: acceptable_source_file_types
      ? formatFiles(acceptable_source_file_types)
      : {},
    share_data: filter(myWriters, 'is_default').map(
      (writer, index, defaultWriters) => ({
        writer_uuid: writer.uuid,
        name: writer.full_name,
        pro: writer?.performing_rights_organization?.name ?? '-',
        ipi: writer?.ipi_number ?? '-',
        share:
          index === 0
            ? Math.floor(100 / defaultWriters.length) +
              (100 % defaultWriters.length)
            : Math.floor(100 / defaultWriters.length),
        editable: true,
      }),
    ),
    channel_layout_types: acceptable_source_file_types
      ? getChannelLayoutTypes(acceptable_source_file_types)
      : [],
    taxonomy: trackSubmission?.taxonomy || {},
  };
};

interface Mapper {
  [humanReadableType: string]: Nl.FinalTrackSourceFileType | undefined;
}

const types = Object.keys(fileTypes) as Nl.FinalTrackSourceFileType[];
const mapper = types.reduce<Mapper>((res, type) => {
  res[fileTypes[type]] = type;
  return res;
}, {});

const formatFiles = (
  acceptableFileTypes: Nl.FinalTrackAcceptableSourceFileType[],
) => {
  // Normally, english file types can be mapped to file_type prop by just snake-casing
  // When a straight snake-case doesn't work, add it to this mapper.
  // Example: 5.1 Surround starts with a number, variables can usually not be created with
  // a leading number. The mapper is used in this case.
  return acceptableFileTypes.reduce((files, fileType) => {
    files[fileType.channel_layout] = fileType.types.map((type) => ({
      file_type:
        mapper[type] || (snakeCase(type) as Nl.FinalTrackSourceFileType),
      state: 'default',
      original_url: '',
      url: '',
      original_filename: '',
      human_readable_filetype: type,
    }));
    return files;
  }, {} as { [channelLayout: string]: Nl.FinalTrackFile[] });
};

export const getChannelLayoutTypes = (
  acceptableFilesTypes: Nl.FinalTrackAcceptableSourceFileType[],
) => {
  const channelLayoutTypes: Nl.ChannelLayoutType[] = [];
  acceptableFilesTypes.forEach(
    (fileType: Nl.FinalTrackAcceptableSourceFileType) => {
      channelLayoutTypes.push(fileType.channel_layout);
    },
  );
  return channelLayoutTypes;
};
