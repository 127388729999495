import React from 'react';
import FormikWrapper from 'components/Formik';
import TagTermRelationTypeForm, {
  getFormInitialValues,
} from './TagTermRelationTypeForm';
import { getValidationSchema } from './validation';
import { useTagTermRelationTypeCreate } from 'novaApi/hooks/tag_term_relation_type';
import { useDispatch } from 'react-redux';
import { goPreviousPage } from 'store/router';

export default function TagTermRelationTypesCreatePage() {
  const dispatch = useDispatch();
  const tagTermRelationTypeToCreate = getFormInitialValues();
  const {
    createTagTermRelationType,
    createdTagTermRelationType,
    isSuccess,
  } = useTagTermRelationTypeCreate();

  const handleFormSubmit = (formData, formActions) => {
    createTagTermRelationType({ formData, formActions });
  };

  if (isSuccess && createdTagTermRelationType) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={tagTermRelationTypeToCreate}
      validationSchema={getValidationSchema()}
      submitForm={(formData, formActions) =>
        handleFormSubmit(formData, formActions)
      }
      title='Create tag-term relation type'
      customSize='sm'
    >
      <TagTermRelationTypeForm />
    </FormikWrapper>
  );
}
