import { useNovaApi } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';

export const useArtist = (uuid: string) => {
  return useNovaApi<Nl.Api.ArtistResponse>(`/artist/${uuid}`).data?.artist;
};

export const useArtists = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.ArtistsResponse>(`/artist`, { query }).data;
};

export const useArtistEntities = () => {
  return (
    useNovaApi<Nl.Api.ArtistSelectResponse>('/artist/select').data?.artists ||
    []
  );
};
