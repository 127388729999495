import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { createNlStore } from './store';
import App from './pages/App/App';
import ScrollToTop from './utils/router/ScrollToTop';
import theme from './assets/theme';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'queryClient';

const store = createNlStore();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale='en'>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root'),
);

// Accessible for easier debugging
Object.assign(window, { queryClient, store });
