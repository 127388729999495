import { styled } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const SecondaryActionButton = styled(Button)({
  backgroundColor: '#58c55d',
  color: 'white',
  '&:hover': {
    backgroundColor: '#38bb3e',
  },
});

export default SecondaryActionButton;
