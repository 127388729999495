import { useNovaApi, useNovaApiCreate, useNovaApiUpdate } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { useDispatch } from 'react-redux';
import { addSuccessNotification } from 'store/notifications';

export const useTagRelationTypes = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.TagRelationTypesResponse>(`/tag_relation_type`, {
    query,
  });
};

export const useTagRelationTypeSelect = () => {
  return (
    useNovaApi<Nl.Api.TagRelationTypeSelectResponse>(
      '/tag_relation_type/select',
    ).data?.tag_relation_types || []
  );
};

export const useTagRelationTypeCreate = () => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiCreate<
    Nl.Api.TagRelationTypeResponse,
    Nl.Api.TagRelationType
  >('/tag_relation_type', (createdTagRelationType) => {
    dispatch(
      addSuccessNotification({
        message: `Tag Relation Type ${createdTagRelationType.tag_relation_type.name} has been created`,
      }),
    );
  });

  return {
    createTagRelationType: mutation.mutate,
    isSuccess,
    createdTagRelationType: data,
  };
};

export const useTagRelationType = (uuid: string) => {
  return useNovaApi<Nl.Api.TagRelationTypeResponse>(
    `/tag_relation_type/${uuid}`,
  );
};

export const useTagRelationTypeUpdate = (uuid: string) => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiUpdate<
    Nl.Api.TagRelationTypeResponse,
    Nl.Api.TagRelationType
  >(`/tag_relation_type/${uuid}`, (updatedTagRelationType) => {
    dispatch(
      addSuccessNotification({
        message: `Tag Relation Type ${updatedTagRelationType.tag_relation_type.name} has been updated`,
      }),
    );
  });

  return {
    updateTagRelationType: mutation.mutate,
    updatedTagRelationType: data,
    isSuccess,
  };
};
