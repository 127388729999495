import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from 'utils/storeUtils';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';

import { goPreviousPage } from 'store/router';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

// Action Constants
const CREATE_SONG_THEME = 'CREATE_SONG_THEME';
const CREATE_SONG_THEME_ERROR = 'CREATE_SONG_THEME_ERROR';
const DELETE_SONG_THEME = 'DELETE_SONG_THEME';
const DELETE_SONG_THEME_SUCCESS = 'DELETE_SONG_THEME_SUCCESS';
const DELETE_SONG_THEME_ERROR = 'DELETE_SONG_THEME_ERROR';
const FETCH_SONG_THEME_ENTITIES = 'FETCH_SONG_THEME_ENTITIES';
const FETCH_SONG_THEME_ENTITIES_SUCCESS = 'FETCH_SONG_THEME_ENTITIES_SUCCESS';
const FETCH_SONG_THEME_ENTITIES_ERROR = 'FETCH_SONG_THEME_ENTITIES_ERROR';

// Action Creators
export const createSongTheme = createAction(CREATE_SONG_THEME);
const createSongThemeError = createAction(CREATE_SONG_THEME_ERROR);
export const deleteSongTheme = createAction(DELETE_SONG_THEME);
const deleteSongThemeSuccess = createAction(DELETE_SONG_THEME_SUCCESS);
const deleteSongThemeError = createAction(DELETE_SONG_THEME_ERROR);
export const fetchSongThemeEntities = createAction(FETCH_SONG_THEME_ENTITIES);
const fetchSongThemeEntitiesSuccess = createAction(
  FETCH_SONG_THEME_ENTITIES_SUCCESS,
);
const fetchSongThemeEntitiesError = createAction(
  FETCH_SONG_THEME_ENTITIES_ERROR,
);

const songThemeInitialValues = {
  uuid: '' as string,
  name: '' as string,
  parent_theme_uuid: '' as string,
};

// Selector
export const getEditFormInitialValues = (): {
  uuid: string;
  name: string;
  parent_theme_uuid;
} => songThemeInitialValues;

export interface SongThemes {
  isLoading: boolean;
  isLoaded: boolean;
  entities: Nl.Api.SelectorEntity[];
}

const initialState: SongThemes = {
  isLoading: false,
  isLoaded: false,
  entities: [],
};

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_SONG_THEME_ENTITIES: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SONG_THEME_ENTITIES_SUCCESS: {
      return assign(state, {
        entities: action.payload.data.song_themes.map(
          (entity: Nl.Api.FullNameEntity) => ({
            name: entity.full_name,
            uuid: entity.uuid,
          }),
        ),
        isLoading: false,
      });
    }

    case FETCH_SONG_THEME_ENTITIES_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSongThemeEntitiesSaga() {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/song_theme/select',
    );
    if (results.success) {
      yield put(fetchSongThemeEntitiesSuccess({ data: results.data }));
    } else {
      yield put(fetchSongThemeEntitiesError());
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *createSongThemeSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(apiFetch, '/song_theme', {
      method: 'POST',
      body: { ...formData },
    });
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been created`,
        }),
      );
      invalidateNovaQueries('/song_theme');
      yield put({ type: 'route/SONG_THEMES' });
      yield put(goPreviousPage());
    } else {
      yield put(createSongThemeError({ error: results.msg }));
      if (results.errors.name) {
        yield put(addErrorNotification({ message: results.errors.name }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
    }
    formActions.setSubmitting(false);
  },
  *deleteSongThemeSaga(action: SimpleActionType) {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/song_theme/${action.payload.uuid}`,
      {
        method: 'DELETE',
      },
    );
    if (results.success) {
      yield put(deleteSongThemeSuccess());
      invalidateNovaQueries('/song_theme');
      yield put(
        addSuccessNotification({ message: 'Song Theme has been deleted' }),
      );
    } else {
      yield put(deleteSongThemeError());
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the songGenre',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(FETCH_SONG_THEME_ENTITIES, sagas.fetchSongThemeEntitiesSaga),
    takeEvery(CREATE_SONG_THEME, sagas.createSongThemeSaga),
    takeLatest(DELETE_SONG_THEME, sagas.deleteSongThemeSaga),
  ]);
}

export { sagas };
export default reducer;
