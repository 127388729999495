import { put, call, takeLatest, all } from 'redux-saga/effects';
import { createAction, SimpleActionType } from '../utils/storeUtils';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

const DELETE_TAG_CATEGORY = 'DELETE_TAG_CATEGORY';

export const deleteTagCategory = createAction(DELETE_TAG_CATEGORY);

export const sagas = {
  *deleteTagCategorySaga(action: SimpleActionType) {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/tag_category/${action.payload.uuid}`,
      {
        method: 'DELETE',
      },
    );
    if (results.success) {
      yield put(
        addSuccessNotification({ message: 'Tag category has been deleted' }),
      );
      invalidateNovaQueries(`/tag_category`);
    } else {
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the tag category',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([takeLatest(DELETE_TAG_CATEGORY, sagas.deleteTagCategorySaga)]);
}
