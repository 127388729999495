/**
 * This file should ideally contains all the methods allowing
 * to call the nova api backend
 */
import { forOwn, compact } from 'lodash';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';

export async function approveTrack(uuid: string) {
  return apiFetch(`/new_track_submission/${uuid}/approve`, {
    method: 'POST',
  });
}

export async function rejectTrack(uuid: string) {
  return apiFetch(`/new_track_submission/${uuid}/reject`, {
    method: 'POST',
  });
}

export async function destroyTrack(uuid: string) {
  return apiFetch(`/new_track_submission/${uuid}`, {
    method: 'DELETE',
  });
}

export async function fetchProcessingSourceFiles(songUuid: string) {
  return apiFetch<Nl.Api.SongResponse>(`/song/${songUuid}/polling`, {
    method: 'GET',
  });
}

export async function fetchUserMessage() {
  return apiFetch<Nl.Api.UserMessagesResponse>('/user_message', {
    method: 'GET',
  });
}

export async function presignedSourceFile(
  songUuid: string,
  eTag: string,
  contentType: Nl.UploadContentType,
  originalFilename?: string,
) {
  return apiFetch<Nl.Api.UploadPresignedPostKeyResponse>(
    '/upload/presigned_post_key/song_source_file',
    {
      method: 'POST',
      body: {
        song_uuid: songUuid,
        content_type: contentType,
        checksum: eTag,
        original_filename: originalFilename,
      },
    },
  );
}

export async function presignedRevisionSourceFile(
  revisionUuid: string,
  sourceFileUuid: string,
  eTag: string,
  contentType: Nl.UploadContentType,
  originalFilename: string,
) {
  return apiFetch<Nl.Api.UploadPresignedPostKeyResponse>(
    `/my_song_revision/${revisionUuid}/upload`,
    {
      method: 'POST',
      body: {
        source_file: sourceFileUuid,
        content_type: contentType,
        checksum: eTag,
        original_filename: originalFilename,
      },
    },
  );
}

export async function presignedSourceFileNewTrack(
  songUuid: string,
  eTag: string,
) {
  return apiFetch<Nl.Api.UploadPresignedPostKeyResponse>(
    '/upload/presigned_post_key/new_track_submission',
    {
      method: 'POST',
      body: {
        uuid: songUuid,
        checksum: eTag,
      },
    },
  );
}

export async function presignedSourceFileFinalTrack(
  trackUuid: string,
  fileType: Nl.FinalTrackSourceFileType,
  fileChecksum: string,
) {
  return apiFetch(
    '/upload/presigned_post_key/new_track_submission_final_files',
    {
      method: 'POST',
      body: {
        uuid: trackUuid,
        file_type: fileType,
        checksum: fileChecksum,
      },
    },
  );
}

export async function uploadSourceFile(
  s3url: string,
  fields: Nl.Api.AwsUploadBody,
  file: File,
): Promise<Nl.Api.UploadSourceFileResponse> {
  // combine formData and file into a request body
  const s3body = new FormData();
  const finalFields = { ...fields, file };
  forOwn(finalFields, (value, key) => s3body.append(key, value));

  let response: Response;
  try {
    response = await fetch(s3url, { method: 'POST', body: s3body });
  } catch {
    // Network error - could not get a response from the server
    throw new Error('Internet connection dropped while transferring');
  }
  let xmlResults: string;
  const parser = new DOMParser();
  let xml: Document;
  try {
    xmlResults = await response.text();
    xml = parser.parseFromString(xmlResults, 'text/xml');
  } catch {
    // AWS sent a response we can't parse
    // Error message is pretty generic. Not meant to inform the user, more for
    // us to look in the right place if it is ever reported.
    throw new Error('Server response was corrupted');
  }
  if (!response.ok) {
    const message = xml?.getElementsByTagName('Message')[0].textContent;
    throw new Error(message || response.statusText);
  }
  const finalS3Url = xml.getElementsByTagName('Location')[0].textContent;
  if (!finalS3Url) {
    throw new Error('Did not receive an file location from server');
  }
  const eTag = xml
    .getElementsByTagName('ETag')[0]
    .textContent!.replaceAll('"', ''); // eTag is wrapped in quotes for some reason
  if (!eTag) {
    throw new Error('Did not receive an ETag from server');
  }
  return { finalS3Url, eTag };
}

export async function fetchSourceFileWaveform(
  sourceFileUuid: string,
): Promise<Nl.Waveform | null> {
  const res = await apiFetch<{ song_source_file: Nl.Api.Waveform }>(
    `/song_source_file/${sourceFileUuid}/waveform`,
    {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
      },
    },
  );
  if (res.status === 412) {
    // means the waveform is not ready yet.
    return null; // don't want to throw any error in this case.
  }
  if (!res.data || !res.data.song_source_file) {
    throw new Error(
      `Cannot fetch the song waveform with uuid=${sourceFileUuid}`,
    );
  }

  return {
    uuid: sourceFileUuid,
    ...res.data.song_source_file,
  };
}

export async function fetchSourceFileWaveforms(
  sourceFileUuids: string[],
): Promise<Nl.Waveform[]> {
  const waveforms = await Promise.all(
    sourceFileUuids.map(fetchSourceFileWaveform),
  );
  return compact(waveforms); // return the waveforms excluding null results
}

export async function postPublishSong(songUuids: string[]) {
  const body = songUuids.map((uuid) => ({
    song: uuid,
    is_visible: true,
  }));
  return apiFetch<{ songs: Nl.Api.Song[] }>('/song/push', {
    method: 'PATCH',
    body,
  });
}

export async function postTakeDownSong(songUuids: string[]) {
  const body = songUuids.map((uuid) => ({
    song: uuid,
    is_visible: false,
  }));
  return apiFetch<{ song: Nl.Api.Song[] }>('/song/push', {
    method: 'PATCH',
    body,
  });
}

export async function postSongBatchUpdate(
  body: Nl.BatchUpdateData,
  query: any,
) {
  const { action, attribute, values } = body;
  return apiFetch<{ batch_operation: Nl.Api.SongBatchUpdateResponse }>(
    `/song/batch${query}`,
    {
      method: 'PATCH',
      body: {
        action,
        attribute,
        values,
      },
    },
  );
}

export async function pollBatchUpdateTask(uuid: string) {
  return apiFetch<{ async_task: Nl.BatchUpdateAsyncTask }>(
    `/async_task/${uuid}`,
    {
      method: 'GET',
    },
  );
}

export async function postReadyForPublication(songUuids: string[]) {
  return apiFetch<{ song: Nl.Api.Song[] }>('/song/ready_for_publication', {
    method: 'PATCH',
    body: {
      songs: songUuids,
    },
  });
}

export async function getSalesPerTrack(
  queryParams: string,
): Promise<FetchResponse<Nl.Api.SalesPerTrackResponse>> {
  return apiFetch<Nl.Api.SalesPerTrackResponse>(
    `/artist/performance_per_song${queryParams}`,
    {
      method: 'GET',
    },
  );
}

export async function getSalesTotal(
  queryParams: string,
): Promise<FetchResponse<Nl.Api.SalesTotalResponse>> {
  return apiFetch<Nl.Api.SalesTotalResponse>(
    `/artist/earnings_summary/totals${queryParams}`,
    {
      method: 'GET',
    },
  );
}

export async function getSalesBreakdown(
  queryParams: string,
): Promise<FetchResponse<Nl.Api.SalesBreakdownResponse>> {
  return apiFetch<Nl.Api.SalesBreakdownResponse>(
    `/artist/earnings_summary${queryParams}`,
    {
      method: 'GET',
    },
  );
}

export async function getSalesBreakdownReport(
  queryParams: string,
): Promise<FetchResponse<{}>> {
  return apiFetch<Nl.Api.SalesTotalResponse>(
    `/artist/earnings_summary/report${queryParams}`,
    {
      method: 'POST',
    },
  );
}

export async function getSongZips(
  songUuid: string,
): Promise<FetchResponse<Nl.Api.SongZipsResponse>> {
  return apiFetch<Nl.Api.SongZipsResponse>(`/song/${songUuid}/zips`, {
    method: 'GET',
  });
}

export async function postGenerateSongZips(
  songUuid: string,
): Promise<FetchResponse<Nl.Api.SongResponse>> {
  return apiFetch<Nl.Api.SongResponse>(`/song/${songUuid}/zips`, {
    method: 'POST',
  });
}

export async function getCurationStateOptions(): Promise<FetchResponse<any>> {
  return apiFetch('/song_curation_state/select', {
    method: 'GET',
  });
}

export async function patchSongs(
  body: {
    song: string;
    curation_states?: string[];
    content_tier?: string;
  }[],
): Promise<FetchResponse<{ songs: Nl.Api.Song[] }>> {
  return apiFetch('/song', {
    method: 'PATCH',
    body,
  });
}
