// TODO rename this file, it's too generic and confusing with the parent filder name
// TODO All the function of this file should be tested (or removed if not unsed anywhere)
import moment from 'moment';
import 'moment-duration-format';

export const selectIdenticalValueLabelFormat = (
  resultArr: string[],
): Nl.SelectFieldOptionsType[] =>
  resultArr.map((result) => ({ label: result, value: result }));

export const multipleSelectFormat = (
  resultArr: (
    | { name: string; uuid?: string }
    | { full_name: string; uuid?: string }
  )[] = [],
): Nl.SelectFieldOptionsType[] => {
  return resultArr.map((option) => {
    const name = 'full_name' in option ? option.full_name : option.name;
    return {
      label: name,
      value: option.uuid ?? name,
    };
  });
};

export const multipleSelectValueOnly = (resultArr: any = []): string[] =>
  resultArr.map((result: any) => result.uuid);

export const addIdsToArray = (resultArr: any) =>
  resultArr.map((result: any, index: number) => ({ ...result, id: index }));

export const getCategoryPlural = (count: number) =>
  count > 1 ? 'categories' : 'category';
export const getCollectionPlural = (count: number) =>
  count > 1 ? 'collections' : 'collection';
export const getItemPlural = (count: number) => (count > 1 ? 'items' : 'item');

/**
 * Generate a readable date for table views
 *
 * @param date the date string
 */
export const getReadableDate = (date: string | Date) => {
  const parsedDate = new Date(date);
  return `${
    parsedDate.getMonth() + 1
  } - ${parsedDate.getDate()} - ${parsedDate.getFullYear()}`;
};

/*
  Returns a string representation of seconds in mins and seconds
  Note: if value is below 1, moment will display (890 milliseconds)
*/
export const convertSecondsToMinutes = (seconds: number): string => {
  if (seconds < 1) {
    return '0:00';
  }
  const duration = moment.duration(seconds, 'seconds');
  return duration.format();
};

/*
  Returns a truncated list of country ISO codes. Examples:
  5 or less countries -> CZ, GU, ZA, TK, EH
  More -> AQ, IS, MO and 7 more
 */
export const truncateCountryIsos = (countries: Nl.Api.Country[]): string => {
  const MAX_COUNTRIES = 5;
  const MAX_TRUNCATED = 3;
  const isoCodes = countries.map((country) => country.iso_code);
  if (countries.length <= MAX_COUNTRIES) {
    return isoCodes.join(', ') || '-';
  }
  return `${isoCodes.slice(0, MAX_TRUNCATED).join(', ')} and ${
    countries.length - MAX_TRUNCATED
  } more`;
};

export const formatNumber = (num: number | string): string =>
  num
    ? (typeof num === 'number' ? num.toString() : num).replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        '$1,',
      )
    : '0';

/*
  Normalizes an array of numbers to between [1, -1], optionally applies scaling
  Notes:
  - scalingFactor scales all the data points by the same amount
  - avoid using .map with Math.abs, Math.min to calculate largest sample for faster performance (subjectively observed)
*/
export const normalize = (data: number[], scale = 1) => {
  const absMax = Math.max(...data.map(Math.abs));
  const normalizeFactor = scale / absMax;
  return data.map((i) => i * normalizeFactor);
};

export const combineURLs = (...parts: string[]) => {
  return parts
    .map((part, i) => {
      if (i > 0) {
        // eslint-disable-next-line no-param-reassign
        part = part.replace(/^\/+/, '');
      }
      if (i < parts.length - 1) {
        // eslint-disable-next-line no-param-reassign
        part = part.replace(/\/+$/, '');
      }
      return part;
    })
    .join('/');
};
