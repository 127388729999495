import { put, call, select, takeLatest, all } from 'redux-saga/effects';
import { ActionsUnion, createTypedAction, assign } from 'utils/storeUtils';
import { getUrlQueryParams, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';

import { getSalesBreakdown, getSalesBreakdownReport } from 'novaApi/NovaApi';

/**
 * Action Constants
 */
const FETCH_SALES_BREAKDOWN = 'FETCH_SALES_BREAKDOWN';
const FETCH_SALES_BREAKDOWN_SUCCESS = 'FETCH_SALES_BREAKDOWN_SUCCESS';
const FETCH_SALES_BREAKDOWN_ERROR = 'FETCH_SALES_BREAKDOWN_ERROR';

const GENERATE_SALES_BREAKDOWN_REPORT = 'GENERATE_SALES_BREAKDOWN_REPORT';
const GENERATE_SALES_BREAKDOWN_REPORT_SUCCESS =
  'GENERATE_SALES_BREAKDOWN_REPORT_SUCCESS';
const GENERATE_SALES_BREAKDOWN_REPORT_ERROR =
  'GENERATE_SALES_BREAKDOWN_REPORT_ERROR';

/**
 * Action Creators
 */
export const actions = {
  fetchSalesBreakdown: () => createTypedAction(FETCH_SALES_BREAKDOWN),
  fetchSalesBreakdownSuccess: (payload: {
    data: Nl.Api.SalesBreakdownResponse;
  }) => createTypedAction(FETCH_SALES_BREAKDOWN_SUCCESS, payload),
  fetchSalesBreakdownError: (payload: { error: string }) =>
    createTypedAction(FETCH_SALES_BREAKDOWN_ERROR, payload),

  generateSalesBreakdownReport: () =>
    createTypedAction(GENERATE_SALES_BREAKDOWN_REPORT),
  generateSalesBreakdownReportSuccess: () =>
    createTypedAction(GENERATE_SALES_BREAKDOWN_REPORT_SUCCESS),
  generateSalesBreakdownReportError: (payload: { error: string }) =>
    createTypedAction(GENERATE_SALES_BREAKDOWN_REPORT_ERROR, payload),
};

export const { fetchSalesBreakdown } = actions;
export const { fetchSalesBreakdownSuccess } = actions;

export const { generateSalesBreakdownReport } = actions;

/**
 * Reducer
 */
const initialState = {
  data: {
    earnings: [],
    total_size: 0,
    current_size: 0,
    current_page: 0,
    offset: 0,
    total_pages: 0,
  } as Nl.Api.SalesBreakdownResponse,
  isLoading: false,
  isLoaded: false,
};

export type SalesBreakdownState = Readonly<typeof initialState>;

type Actions = ActionsUnion<typeof actions>;

const reducer = (
  state: SalesBreakdownState = initialState,
  action: Actions,
) => {
  switch (action.type) {
    case FETCH_SALES_BREAKDOWN: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SALES_BREAKDOWN_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSalesBreakdownSaga() {
    const queryParams: string = yield select(getUrlQueryParams);
    const results: FetchResponse<Nl.Api.SalesBreakdownResponse> = yield call(
      getSalesBreakdown,
      queryParams,
    );
    if (results.success) {
      yield put(actions.fetchSalesBreakdownSuccess({ data: results.data }));
    } else {
      yield put(
        addErrorNotification({
          message: 'Impossible to fetch the Sales Reports data.',
        }),
      );
      yield put(actions.fetchSalesBreakdownError({ error: results.msg }));
    }
  },
  *generateSalesBreakdownReport() {
    const queryParams: string = yield select(getUrlQueryParams);
    const results: FetchResponse<{}> = yield call(
      getSalesBreakdownReport,
      queryParams,
    );
    if (results.success) {
      yield put(actions.generateSalesBreakdownReportSuccess());
      yield put(
        addSuccessNotification({ message: 'Your CSV is being generated.' }),
      );
    } else {
      yield put(
        actions.generateSalesBreakdownReportError({ error: results.msg }),
      );
      yield put(
        addErrorNotification({
          message: 'An error occurred. Impossible to generate the CSV.',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(FETCH_SALES_BREAKDOWN, sagas.fetchSalesBreakdownSaga),
    takeLatest(
      GENERATE_SALES_BREAKDOWN_REPORT,
      sagas.generateSalesBreakdownReport,
    ),
  ]);
}

export { sagas };
export default reducer;
