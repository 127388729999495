import React from 'react';
import {
  makeStyles,
  Typography,
  Link,
  List,
  ListItem,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    margin: '50px',
    maxWidth: '700px',
  },
});

// If the token is expired or invalid, lead the user to a possible solution
// Contacting the rep should be last item in the list
const LinkExpiredPage: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant='h3' gutterBottom>
        This link is expired or invalid
      </Typography>
      <List dense>
        <ListItem>
          <Typography variant='subtitle1' gutterBottom>
            If you are already registered, please login{' '}
            <Link href='/login'>here</Link>.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant='subtitle1' gutterBottom>
            If you forgot your password, please click{' '}
            <Link href='/forgot-password'>here</Link>.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant='subtitle1' gutterBottom>
            If you need a new registration link, please contact your rep.
          </Typography>
        </ListItem>
      </List>
    </div>
  );
};

export default LinkExpiredPage;
