import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { uploadRevisionFile } from 'store/upload';
import UploadDropzone from 'components/Dropzone/UploadDropzone';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

interface OwnProps {
  revisionUuid: string;
  contentType: 'application/zip' | 'audio/wav';
  sourceFile: any;
  type: Nl.SongSourceFileType;
  state?: Nl.SourceFileUploadState;
  formValues: Nl.UploadFormValues;
}

interface DispatchProps {
  onUpload: (file: File) => void;
}

type AllProps = OwnProps & DispatchProps;

const RevisionUploadDropzone: React.FC<AllProps> = ({
  onUpload,
  type,
  state,
  contentType,
  sourceFile,
  formValues,
}) => {
  const { errors } = useFormikContext<Nl.Api.SongRevision>();
  return (
    <UploadDropzone
      accept={contentType}
      displayFileType='wav'
      onDrop={(accepted) => {
        if (accepted.length > 0) {
          onUpload(accepted[0]);
        }
      }}
      onClear={() => {
        formValues.set(`${formValues.field}.state`, 'init');
      }}
      state={state}
      disabled={!type}
      doneMessage={sourceFile.filename}
      isRequiredError={!isEmpty(errors.source_files)}
    />
  );
};

export default compose(
  connect(
    null,
    (
      dispatch,
      { revisionUuid, sourceFile, type, contentType, formValues }: OwnProps,
    ) => ({
      onUpload: (file: File) => {
        dispatch(
          uploadRevisionFile({
            contentType,
            revisionUuid,
            sourceFileUuid: sourceFile.uuid,
            file,
            type,
            formValues,
          }),
        );
      },
    }),
  ),
)(RevisionUploadDropzone);
