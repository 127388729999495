import React from 'react';
import FormikWrapper from 'components/Formik';
import TermForm, { getFormInitialValues } from './TermsForm';
import { useTermCreate } from 'novaApi/hooks/term';
import { goPreviousPage } from 'store/router';
import { useDispatch } from 'react-redux';
import { getValidationSchema } from './validation';

const TermCreatePage = () => {
  const dispatch = useDispatch();

  const { createTerm, createdTerm, isSuccess } = useTermCreate();

  const handleFormSubmit = (formData, formActions) => {
    createTerm({ formData, formActions });
  };

  if (createdTerm && isSuccess) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={getFormInitialValues()}
      validationSchema={getValidationSchema()}
      submitForm={(formData, formActions) => {
        handleFormSubmit(formData, formActions);
      }}
      title='Create term'
      customSize='md'
    >
      <TermForm />
    </FormikWrapper>
  );
};

export default TermCreatePage;
