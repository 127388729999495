import { put, take, call, select, fork } from 'redux-saga/effects';
import { includes, some } from 'lodash';
import config from 'config';
import { userRoles } from 'constants/userRoles';
import {
  createAction,
  SimpleActionType,
  createTypedAction,
} from 'utils/storeUtils';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { setAuthToken, deleteAuthToken } from 'utils/storedToken';
import { addSuccessNotification, addErrorNotification } from './notifications';
import {
  stopPollingUserMessages,
  startPollingUserMessages,
} from './userMessages';
import { GlobalState } from './rootReducer';
import { redirect, Location } from 'redux-first-router';

export interface AuthState extends Partial<Nl.Api.AuthUser> {
  postLoginRedirect: Location | false;
  isLoaded: boolean;
  isLoading: boolean;
  isLoggedIn: boolean;
}

export const initialState = {
  postLoginRedirect: false,
  isLoaded: false,
  isLoading: false,
  isLoggedIn: false,
} as AuthState;

// Action Constants
export const ON_LOGIN = 'ON_LOGIN';
export const ON_LOGOUT = 'ON_LOGOUT';
const ON_AUTHENTICATION_SUCCESS = 'ON_AUTHENTICATION_SUCCESS';
const ON_AUTHENTICATION_ERROR = 'ON_AUTHENTICATION_ERROR';
const CLEAR_USER_TOKEN = 'CLEAR_USER_TOKEN';
const POST_LOGIN_REDIRECT = 'POST_LOGIN_REDIRECT';

// Action Creators
const actions = {
  onLogin: (payload: { formData: { password: string; email: string } }) =>
    createTypedAction(ON_LOGIN, payload),
  onLogout: createAction(ON_LOGOUT),
  onAuthenticationSuccess: createAction(ON_AUTHENTICATION_SUCCESS),
  onAuthenticationError: createAction(ON_AUTHENTICATION_ERROR),
  clearUserToken: createAction(CLEAR_USER_TOKEN),
  setPostLoginRedirect: createAction(POST_LOGIN_REDIRECT),
};

// Selectors
const selectors = {
  getUserPermissions: (state: GlobalState) => state.auth?.permissions ?? [],
  getUserRole: (state: GlobalState) => state.auth?.user_role?.name,
  isLoading: (state: GlobalState) => state.auth?.isLoading,
  isLoaded: (state: GlobalState) => state.auth?.isLoaded,
  isLoggedIn: (state: GlobalState) => state.auth?.isLoggedIn,
  isUserAllowed: (state: GlobalState, permission: Nl.UserPermissionType) =>
    selectors.getUserPermissions(state).includes(permission),
  isUserArtist: (state: GlobalState) =>
    selectors.getUserRole(state) === userRoles.artist,
};

// Reducer
const reducer = (state = initialState, action: SimpleActionType) => {
  switch (action.type) {
    case ON_AUTHENTICATION_SUCCESS: {
      const { user = {}, token } = action.payload;
      if (token) {
        setAuthToken(token);
      } else {
        deleteAuthToken();
      }
      return {
        ...user,
        isLoading: false,
        isLoaded: true,
        isLoggedIn: true,
        postLoginRedirect: state.postLoginRedirect,
      };
    }

    case ON_AUTHENTICATION_ERROR:
    case CLEAR_USER_TOKEN:
      return {
        ...initialState,
        postLoginRedirect: state.postLoginRedirect,
      };

    case POST_LOGIN_REDIRECT:
      return {
        ...state,
        postLoginRedirect: action.payload,
      };
    default:
      return state;
  }
};

// Sagas
const sagas = {
  *onLoginSaga({ payload }: ReturnType<typeof actions.onLogin>) {
    const { formData } = payload;
    const response: FetchResponse<any> = yield call(apiFetch, '/auth/login', {
      method: 'POST',
      body: { ...formData },
    });

    if (response.success) {
      const { permissions } = response.data.user;
      // Make sure user is allowed to login to Nova UI even if they authed successfully
      const isAllowed = some(config.app.loginPermissionKey, (permission) =>
        includes(permissions, permission),
      );
      if (isAllowed) {
        yield put(actions.onAuthenticationSuccess(response.data));
        yield put(startPollingUserMessages());
        yield put(redirect({ type: 'route/HOME' }));
        const message: string = yield select((state: GlobalState) =>
          state.auth.first_name
            ? `Welcome ${state.auth.first_name}!`
            : 'Welcome!',
        );
        yield put(addSuccessNotification({ message }));
      } else {
        yield put(actions.onAuthenticationError());
        yield put(addErrorNotification({ message: 'Unauthorized' }));
      }
    } else {
      yield put(actions.onAuthenticationError());
      yield put(addErrorNotification({ message: response.msg }));
    }
  },
  *onLogoutSaga() {
    yield put(stopPollingUserMessages());
    yield call(deleteAuthToken);
    yield put(actions.clearUserToken());
    // Fire the logout action. This will let the loginFlowSaga move on
    // This will also trigger the rootReducer to flush the state
    yield put(actions.onLogout());
    // Logout route can also trigger this saga, while the loginFlow is still waiting at the take(ON_LOGIN)
    yield put({ type: 'route/LOGIN' });
  },

  // see https://redux-saga.js.org/docs/advanced/FutureActions.html
  // and https://redux-saga.js.org/docs/advanced/NonBlockingCalls.html for why we doing while
  *loginFlowSaga() {
    while (true) {
      const loginAction: ReturnType<typeof actions.onLogin> = yield take(
        ON_LOGIN,
      );
      yield fork(sagas.onLoginSaga, loginAction);
      yield take([ON_LOGOUT, ON_AUTHENTICATION_ERROR]);
      yield put({ type: 'route/LOGIN' });
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield call(sagas.loginFlowSaga);
}

export { actions, sagas, selectors };
export default reducer;
