import React from 'react';
import { Form, Field, FastField, FieldArray } from 'formik';
import InputField from 'components/Formik/InputField';
import { FormSubTitle, InputWrapper } from 'components/Formik/utils';
import { multipleSelectFormat } from 'utils/utils';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import ArtistWritersTable from './ArtistWritersTable';
import { validateCurationState } from './validation';
import curationStates from 'constants/curationStates';
import { useArtistRepEntries } from 'novaApi/hooks/user';
import { useCountries } from 'novaApi/hooks/country';
import { useWriterSelect } from 'novaApi/hooks/writer';

interface Props {
  artist?: Nl.Api.Artist;
}

export const artistFormInitialValues = {
  uuid: '',
  name: '',
  legal_name: '',
  slug: '',
  curation_state: '',
  bio: '',
  is_royalty: false,
  is_pro_free: false,
  is_dormant: false,
  is_onboardable: false,
  assigned_to: '',
  contact_information: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    email_alternate: '',
    phone_alternate: '',
    address_1: '',
    apartment: '',
    city: '',
    state_province_region: '',
    postal_zip_code: '',
    country: '',
  },
  writers: [] as Nl.Api.Artist['writers'],
  past_writers: [] as Nl.Api.Artist['past_writers'],
  is_published: false,
  contributor_id: null as string | null,
};

export type ArtistFormInitialValues = typeof artistFormInitialValues;

export const getEditFormInitialValues = (
  artist?: Nl.Api.Artist,
): ArtistFormInitialValues => {
  if (!artist) {
    return artistFormInitialValues;
  }
  return {
    uuid: artist.uuid,
    name: artist.name,
    slug: artist.slug,
    curation_state: artist.curation_state || '',
    bio: artist.bio,
    legal_name: artist.legal_name,
    is_royalty: artist.is_royalty,
    is_pro_free: artist.is_pro_free,
    is_dormant: artist.is_dormant,
    is_onboardable: artist.is_onboardable,
    assigned_to: artist.assigned_to?.uuid,
    is_published: artist.is_published,
    contact_information: artist.contact_information
      ? {
          ...artist.contact_information,
          country: artist.contact_information?.country?.uuid ?? '',
          email_alternate: artist.contact_information.email_alternate || '',
          phone_alternate: artist.contact_information.phone_alternate || '',
          apartment: artist.contact_information.apartment || '',
          state_province_region:
            artist.contact_information.state_province_region || '',
          postal_zip_code: artist.contact_information.postal_zip_code || '',
        }
      : artistFormInitialValues.contact_information,
    writers: artist.writers,
    past_writers: artist.past_writers,
    contributor_id: artist.contributor_id,
  };
};

const ArtistForm = ({ artist }: Props) => {
  const isDisabledName = artist?.is_published;
  const isEdit = !!artist;
  const writers = useWriterSelect();
  const reps = useArtistRepEntries();
  const countries = useCountries();

  return (
    <Form>
      <FormSubTitle title='Basic Information' />
      <InputWrapper>
        <div>
          <Field
            name='name'
            label='Name'
            component={InputField}
            disabled={isDisabledName}
          />
        </div>
        <Field
          name='slug'
          label='Slug (Read Only)'
          component={InputField}
          disabled
        />
      </InputWrapper>
      {isEdit && (
        <InputWrapper>
          <Field
            name='curation_state'
            validate={validateCurationState}
            label='Curation State'
            component={AutocompleteSelect}
            options={multipleSelectFormat([
              { name: curationStates.readyForReview },
              { name: curationStates.readyForPublication },
            ])}
          />
          <Field
            name='contributor_id'
            label='Contributor ID (Read Only)'
            component={InputField}
            disabled
          />
        </InputWrapper>
      )}
      <FastField
        name='bio'
        label='Bio'
        component={InputField}
        multiline
        rows={4}
        rowsMax={4}
      />
      <br />
      <br />
      <FormSubTitle title='Contact Information' />
      <InputWrapper>
        <Field
          name='contact_information.first_name'
          label='First Name'
          component={InputField}
        />
        <Field
          name='contact_information.last_name'
          label='Last Name'
          component={InputField}
        />
      </InputWrapper>
      <Field
        name='legal_name'
        label='Legal Business Name'
        component={InputField}
      />
      <InputWrapper>
        <Field
          name='contact_information.email'
          label='Email'
          component={InputField}
        />
        <Field
          name='contact_information.email_alternate'
          label='Email (alternate)'
          component={InputField}
        />
      </InputWrapper>
      <InputWrapper>
        <Field
          name='contact_information.phone'
          label='Phone Number'
          component={InputField}
        />
        <Field
          name='contact_information.phone_alternate'
          label='Phone Number (alternate)'
          component={InputField}
        />
      </InputWrapper>
      <InputWrapper>
        <Field
          name='contact_information.address_1'
          label='Address'
          component={InputField}
        />
        <Field
          name='contact_information.apartment'
          label='Apartment'
          component={InputField}
        />
      </InputWrapper>
      <InputWrapper>
        <Field
          name='contact_information.city'
          label='City'
          component={InputField}
        />
        <Field
          name='contact_information.state_province_region'
          label='State/Province/Region'
          component={InputField}
        />
      </InputWrapper>
      <InputWrapper>
        <Field
          name='contact_information.country'
          label='Country'
          component={AutocompleteSelect}
          options={multipleSelectFormat(countries)}
        />
        <Field
          name='contact_information.postal_zip_code'
          label='Postal/Zip Code'
          component={InputField}
        />
      </InputWrapper>
      <br />
      <FormSubTitle title='Assigned Rep' />
      <InputWrapper>
        <Field
          name='assigned_to'
          label='Rep'
          component={AutocompleteSelect}
          options={reps}
        />
      </InputWrapper>
      <br />
      <FormSubTitle title='Writers' />
      <FieldArray name='writers'>
        {(props) => <ArtistWritersTable {...props} writers={writers} />}
      </FieldArray>
      <br />
    </Form>
  );
};

export default ArtistForm;
