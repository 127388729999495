import React from 'react';
import { Form, Field } from 'formik';
import { FormSubTitle } from 'components/Formik/utils';
import InputField from 'components/Formik/InputField';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import { multipleSelectFormat } from 'utils/utils';
import { Grid } from '@material-ui/core';
import { useCountries } from 'novaApi/hooks/country';

export const EditMyProfile = () => {
  const countries = useCountries();

  return (
    <>
      <FormSubTitle title='Basic Information' />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.first_name'
            label='First Name'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.last_name'
            label='Last Name'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='legal_name'
            label='Legal/Business Name'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='name'
            label='Artist Name'
            component={InputField}
            disabled
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <FormSubTitle title='Contact Information' />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.email'
            label='Email'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.email_alternate'
            label='Alternate Email (optional)'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.phone'
            label='Phone Number'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.phone_alternate'
            label='Alternate Phone Number (optional)'
            component={InputField}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <FormSubTitle title='Mailing Address' />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.address_1'
            label='Address Line 1'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.apartment'
            label='Apartment (if applicable)'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.address_2'
            label='Address Line 2 (if applicable)'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.city'
            label='City'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.state_province_region'
            label='State/Province/Region (if applicable)'
            component={InputField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.country_uuid'
            label='Country'
            component={AutocompleteSelect}
            options={multipleSelectFormat(countries)}
            noClearable
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name='contact_information.postal_zip_code'
            label='Postal/ZIP Code (if applicable)'
            component={InputField}
          />
        </Grid>
      </Grid>
    </>
  );
};

const EditMyProfileForm = () => {
  return (
    <Form>
      <EditMyProfile />
    </Form>
  );
};

export default EditMyProfileForm;
