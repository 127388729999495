import { all, call, put, takeLatest } from 'redux-saga/effects';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { assign, createAction, SimpleActionType } from 'utils/storeUtils';
import { addErrorNotification } from './notifications';

// Action Constants
const FETCH_ORIGINAL_COMPOSER_ENTITIES = 'FETCH_ORIGINAL_COMPOSER_ENTITIES';
const FETCH_ORIGINAL_COMPOSER_ENTITIES_SUCCESS =
  'FETCH_ORIGINAL_COMPOSER_ENTITIES_SUCCESS';
const FETCH_ORIGINAL_COMPOSER_ENTITIES_ERROR =
  'FETCH_ORIGINAL_COMPOSER_ENTITIES_ERROR';

// Action Creators
export const fetchOriginalComposerEntities = createAction(
  FETCH_ORIGINAL_COMPOSER_ENTITIES,
);
const fetchOriginalComposerEntitiesSuccess = createAction(
  FETCH_ORIGINAL_COMPOSER_ENTITIES_SUCCESS,
);
const fetchOriginalComposerEntitiesError = createAction(
  FETCH_ORIGINAL_COMPOSER_ENTITIES_ERROR,
);

// Reducer
const initialState = {
  entities: [] as Nl.Api.OriginalComposerEntity[],
};

export type OriginalComposerState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_ORIGINAL_COMPOSER_ENTITIES_SUCCESS: {
      return assign(state, {
        entities: action.payload.data.original_composers,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchOriginalComposerEntitiesSaga() {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/original_composer/select',
    );
    if (results.success) {
      yield put(fetchOriginalComposerEntitiesSuccess({ data: results.data }));
    } else {
      yield put(fetchOriginalComposerEntitiesError());
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(
      FETCH_ORIGINAL_COMPOSER_ENTITIES,
      sagas.fetchOriginalComposerEntitiesSaga,
    ),
  ]);
}

export { sagas };
export default reducer;
