import React, { useState, useMemo } from 'react';
// import config from 'config';
// import { debounce } from 'lodash';
import { get, isEqual } from 'lodash';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { SelectionChip } from './SelectionChip';

const calculateCurrentValue = (
  safeAssignment: boolean,
  isCreatable: boolean | undefined,
  getNewOptionData: ((value: string) => any) | undefined,
  isMulti: boolean | undefined,
  field: any,
  options: Nl.SelectFieldOptionsType[],
) => {
  if (!safeAssignment) {
    return isMulti ? [] : ('' as any);
  }

  const getMatchingOption = (
    value: string,
  ): Nl.SelectFieldOptionsType | undefined => {
    let selectedOption = options.find((option) => option.value === value);

    // No match was found. But if the field has been declared as creatable,
    // then we create the new value on the fly
    // Caller can specify an option builder function, but we provide a
    // sane default that adheres to the app's conventions
    if (!selectedOption && isCreatable) {
      selectedOption = getNewOptionData
        ? getNewOptionData(value)
        : { label: value, value };
    }

    return selectedOption;
  };

  return isMulti
    ? field.value.map(getMatchingOption).filter(Boolean)
    : getMatchingOption(field.value);
};

// the ones with !important are because mui has a more
// specific selector they style than they provide in the API lol
const useStyles = makeStyles(() => ({
  root: {
    marginTop: '1rem',
    height: 'auto',
  },
  // keep it same style as the other inputs we have in edit pages
  inputRoot: {
    height: 'auto',
    padding: '10.5px 14px !important',
  },
  input: {
    'padding-top': '0 !important',
    'padding-bottom': '0! important',
  },
}));

const useOtherStyles = makeStyles(() => ({
  error: {
    marginBottom: '1rem',
  },
  subField: {
    marginTop: 0,
  },
}));

export type TypeaheadOption = {
  label: string;
  value: string;
  [otherKey: string]: any;
};

type OwnProps = {
  options: TypeaheadOption[];
  label: string;
  form: any;
  field: any;
  isMulti?: boolean;
  getOptionLabel?: (option: any) => string;
  placeholder?: string;
  noMatchingText?: string;
  isCreatable?: boolean;
  noClearable?: boolean;
  isSubSection?: boolean;
  getNewOptionData?: (value: string) => any;
  disabled?: boolean;
  helperText?: string;
  onChange?: (option: any) => any;
};

const Typeahead = ({
  options,
  isMulti,
  label,
  form,
  field,
  getOptionLabel,
  placeholder,
  noMatchingText,
  isCreatable,
  noClearable,
  isSubSection,
  helperText,
  getNewOptionData,
  onChange,
}: OwnProps) => {
  const MAX_OPTIONS_TO_SHOW = 1000;
  const classes = useStyles();
  const otherClasses = useOtherStyles();

  const [searchText, setSearchText] = useState('');
  const [inputVal, setInputVal] = useState('');
  const safeAssignment: boolean = options && field && field.value;

  const currentValue = useMemo(
    () =>
      calculateCurrentValue(
        safeAssignment,
        isCreatable,
        getNewOptionData,
        isMulti,
        field,
        options,
      ),
    [safeAssignment, isCreatable, getNewOptionData, isMulti, field, options],
  );
  // this is solely because to migrate from old AutocompleteSearch to this, it will provide a lot of convenience
  // so we don't have to pass in the same prop everywhere
  const defaultGetOptionLabel = (option) => {
    return option.label || '';
  };

  // Having in case we want this, the UX feels a bit off
  // with the delay but could help performance
  // maybe YAGNI???

  // const debounceSetSearchText = debounce(
  //   (e) => setSearchText(e.target.value),
  //   config.app.asyncTypingTimeout,
  // );

  const onInputChanged = (e) => {
    setSearchText(e.target.value);
    // debounceSetSearchText(e);
  };

  // in the case of a created option, properly return the label
  // since mui uses label for both display on the input while typing
  // and also for display after selection
  const formatNewlyAddedOption = (option) => {
    if (Array.isArray(option)) {
      return option.map((o) => formatNewlyAddedOption(o));
    }
    if (option?.inputValue) {
      // in this case we also created the entry but via
      // mui selection rather than the enter hack we have below
      // so also add it to all options
      // all these workarounds can go away once mui is upgraded to 5
      const addedOption = {
        label: option.inputValue,
        value: option.value,
      };
      return addedOption;
    }
    return option;
  };

  // this is a hack to get dynamic creation allowed with when on change fires via enter key
  // mui should do this on its own with the autoHighlight option
  // but there's a bug using that flag with dynamic creation + filterSelectedOptions
  // ref: https://github.com/mui/material-ui/issues/22942
  // fixed in mui 5 -.- so if/when we upgrade to that, then we can just use autoHighlight prop
  const createTypeaheadOption = (option) => {
    if (!isCreatable) return option;
    let formattedOption = option;
    if (Array.isArray(option)) {
      formattedOption = [];
      for (const o of option) {
        const newlyCreated = createTypeaheadOption(o);
        if (newlyCreated) {
          formattedOption.push(createTypeaheadOption(o));
        }
      }
      return formattedOption;
    }
    if (typeof option === 'object' && option !== null) {
      return option;
    }
    // down here is when user has typed in a new value to create
    if (field.value?.includes(option)) {
      return;
    }
    const newOption = {
      label: option,
      value: option,
    };

    return newOption;
  };

  const setFieldValue = (option) => {
    if (Array.isArray(option)) {
      form.setFieldValue(
        field.name,
        option.map((o) => o.value),
      );
    } else {
      form.setFieldValue(field.name, option?.value || '');
    }
  };

  const onSelection = (_, option) => {
    onChange?.(option);
    if (!option) {
      setFieldValue(option);
      return;
    }
    let formattedOption = createTypeaheadOption(option);
    formattedOption = formatNewlyAddedOption(formattedOption);
    setFieldValue(formattedOption);
  };

  const evaluateOptionValue = (option, newVal) => {
    return option.value === newVal.value;
  };

  const filterOptions = createFilterOptions({
    ignoreAccents: false,
    ignoreCase: true,
    limit: MAX_OPTIONS_TO_SHOW,
  });

  const getFilteredOptions = (selections, state) => {
    // To prevent huge list of selections rendering, only return selections when users are searching
    if (!state.inputValue && selections.length > MAX_OPTIONS_TO_SHOW) return [];
    const filteredOptions = filterOptions(selections, state);
    const filteredOptionValues = filteredOptions.map(
      (fo) => (fo as TypeaheadOption).value,
    );
    if (
      isCreatable &&
      state.inputValue &&
      !field.value?.includes(state.inputValue) &&
      !filteredOptionValues.includes(state.inputValue)
    ) {
      filteredOptions.push({
        label: `Create ${state.inputValue}`,
        value: state.inputValue,
        inputValue: state.inputValue,
      });
    }
    return filteredOptions;
  };
  const labelGetter = getOptionLabel || defaultGetOptionLabel;

  const creatableProps = isCreatable
    ? {
        selectOnFocus: true,
        clearOnBlur: true,
        handleHomeEndKeys: true,
        freeSolo: true,
      }
    : {};

  const getAdditionalClassNames = () => {
    const classNames: string[] = [];
    if (form.errors[field.name]) {
      classNames.push(otherClasses.error);
    }
    if (isSubSection) {
      classNames.push(otherClasses.subField);
    }
    return classNames.join(' ');
  };

  return (
    <Autocomplete
      {...creatableProps}
      autoHighlight
      openOnFocus
      filterSelectedOptions
      data-e2e='typeahead'
      multiple={isMulti}
      options={options}
      value={currentValue || null}
      classes={classes}
      className={getAdditionalClassNames()}
      getOptionLabel={labelGetter}
      filterOptions={getFilteredOptions}
      getOptionSelected={evaluateOptionValue}
      onChange={onSelection}
      disableClearable={isMulti || noClearable}
      inputValue={noClearable ? undefined : inputVal}
      onInputChange={(e, val, reason) => {
        // in this case we have a background polling mechanism
        // that causes a re-render of the form and the typeahead
        // the re-render will empty out the input, so make it a no-op
        // if the user is currently typing
        // and also handle various scenarios of typing / clearing / pressing enter
        // hence it's super ugly, sorry lol
        const eventType = e?.type;
        /* istanbul ignore else */
        if (
          eventType &&
          !['click', 'keydown', 'blur'].includes(eventType) &&
          reason === 'reset' &&
          inputVal
        ) {
          setInputVal(inputVal);
        } else if (reason === 'reset' && !eventType && !val && inputVal) {
          setInputVal('');
        } else if (!eventType && val && reason === 'reset') {
          setInputVal(val);
        } else if (eventType === 'blur') {
          setInputVal('');
        } else {
          setInputVal(val);
        }
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          name={field.name}
          variant='outlined'
          placeholder={field.value ? '' : placeholder || ''}
          onChange={onInputChanged}
          error={!!get(form.errors, field.name)}
          helperText={helperText ?? get(form.errors, field.name)}
          data-e2e={`typeaheadInput-${field.name}`}
        />
      )}
      noOptionsText={
        searchText
          ? noMatchingText || 'No matching entries..'
          : 'Start typing...'
      }
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <SelectionChip
            {...getTagProps({ index })}
            label={labelGetter(option)}
          />
        ));
      }}
    />
  );
};

function areEqual(prevProps: OwnProps, nextProps: OwnProps) {
  /*
    There are too many cases in which this component re-renders due to the
    way the props are drilled in here, simply too many things cause a re-render
    so only re-render when the following props are modified which allows us to
    maintain changes users have made
  */

  const valueHasChanged = () => prevProps.field.value !== nextProps.field.value;
  const labelHasChanged = () => prevProps.label !== nextProps.label;
  const optionsLengthHasChanged = () =>
    prevProps.options.length !== nextProps.options.length;
  const optionsHaveChanged = () =>
    !isEqual(
      prevProps.options.map((option) => option.value),
      nextProps.options.map((option) => option.value),
    );
  const hasBeenDisabled = () => prevProps.disabled !== nextProps.disabled;
  const hasNewErrors = () =>
    get(prevProps.form.errors, prevProps.field.name) !==
    get(nextProps.form.errors, nextProps.field.name);

  /*
  Implemented this way because we want to make the absolute minimum number of
  computations to decide on re-rendering.

  Computing everything everytime causes major slow-downs in e2e tests.

  This can be further improved by ordering by decreasing order of probability
   */
  const modified =
    valueHasChanged() ||
    labelHasChanged() ||
    optionsLengthHasChanged() ||
    optionsHaveChanged() ||
    hasBeenDisabled() ||
    hasNewErrors();

  return !modified;
}

export default React.memo(Typeahead, areEqual);
