/*
 * This Yup Validation Schema provides validation for basic
 * forms that have 1 required name field.
 */

import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';
import { NotificationState } from 'store/notifications';

export default function validationSchema(notificationState: NotificationState) {
  return Yup.object().shape({
    name: Yup.string()
      .required(errorMessages.basicRequired)
      .test(
        'Check if server error',
        notificationState.message!,
        () => notificationState.status === 'default',
      ),
  });
}
