import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Link,
  Grid,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import InputField from 'components/Formik/InputField';
import { resetPasswordValidationSchema } from './validation';
import { Wrapper, useStyles } from './styles';
import PasswordHelper from 'components/PasswordHelper';
import { selectRegistrationToken } from 'store/selectors';
import { addErrorNotification } from 'store/notifications';
import { apiFetch } from 'novaApi/apiUtils';
import { useNlSelector } from 'utils/redux';

type tokenValidationStatus = 'default' | 'success' | 'error';

const ResetPasswordPage: React.FunctionComponent = () => {
  const classes = useStyles({});
  const token = useNlSelector(selectRegistrationToken);
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [tokenValidated, setTokenValidated] = useState<tokenValidationStatus>(
    'default',
  );

  const validatePasswordToken = async () => {
    const results = await apiFetch('/auth/validate_reset_password_token', {
      method: 'POST',
      body: { token },
    });
    return results.success
      ? setTokenValidated('success')
      : setTokenValidated('error');
  };

  const resetPassword = async (new_password: string) => {
    const results = await apiFetch('/user/reset_password', {
      method: 'POST',
      body: {
        new_password,
      },
      headers: {
        Authorization: token,
      },
    });
    return results.success
      ? setIsSubmitted(true)
      : dispatch(addErrorNotification({ message: results.msg }));
  };

  useEffect(() => {
    validatePasswordToken();
  });

  switch (tokenValidated) {
    case 'error':
      return (
        <Wrapper>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant='h5' gutterBottom>
                Your link has expired.
              </Typography>
              <Typography variant='subtitle1' gutterBottom>
                Please send a new request for password reset{' '}
                <Link href='/forgot-password'>here</Link>.
              </Typography>
            </CardContent>
          </Card>
        </Wrapper>
      );

    case 'success':
      return (
        <Wrapper>
          <Card className={classes.root}>
            {isSubmitted ? (
              <CardContent>
                <Typography variant='h5' gutterBottom>
                  Password change successful{' '}
                  <CheckCircleIcon className={classes.icon} />
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                  Your password has been successfully reset! You may now{' '}
                  <Link href='/login'>login</Link> with your new password.
                </Typography>
              </CardContent>
            ) : (
              <Formik
                initialValues={{
                  new_password: '',
                  confirmPassword: '',
                }}
                validationSchema={resetPasswordValidationSchema}
                onSubmit={({ new_password }) => resetPassword(new_password)}
                validateOnBlur
                validateOnChange={false}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <CardContent>
                      <Typography variant='h5' gutterBottom>
                        Reset Password
                      </Typography>
                      <Typography variant='subtitle1' gutterBottom>
                        <Grid container>
                          <Grid item xs={11}>
                            <Typography>
                              Please enter a secure password
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <PasswordHelper />
                          </Grid>
                        </Grid>
                      </Typography>
                      <Field
                        name='new_password'
                        label='New Password'
                        type='password'
                        component={InputField}
                      />
                      <Field
                        name='confirmPassword'
                        label='Confirm New Password'
                        type='password'
                        component={InputField}
                      />
                    </CardContent>
                    <CardActions>
                      <Button
                        color='primary'
                        type='submit'
                        data-e2e='submit'
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </CardActions>
                  </Form>
                )}
              </Formik>
            )}
          </Card>
        </Wrapper>
      );

    case 'default':
    default:
      return null;
  }
};

export default ResetPasswordPage;
