import * as React from 'react';
import RouterLink, {
  LinkProps as RouterLinkProps,
} from 'redux-first-router-link';
import Link, { LinkProps } from '@material-ui/core/Link';

const MyLink = React.forwardRef<any, RouterLinkProps>((props, ref) => (
  <RouterLink ref={ref} {...props} />
));

type AllProps = LinkProps &
  Omit<RouterLinkProps, 'to'> & {
    to: string | { type: Nl.RouteMapType; payload?: Record<string, any> };
  };

const InternalLink: React.FunctionComponent<AllProps> = React.forwardRef<
  any,
  AllProps
>((props, ref) => <Link component={MyLink} ref={ref} {...props} />);

export default InternalLink;
