import { useSongIngestionLogs } from 'novaApi/hooks/song_ingestion_log';
import * as React from 'react';
import UserLogList from '../UserLogList';
import IngestionLogItem from './IngestionLogItem';

interface OwnProps {
  className?: string;
  spaceAround?: boolean;
  alignRight?: boolean;
  songUuid: string;
}

export default function IngestionLogListView({
  className,
  alignRight,
  spaceAround,
  songUuid,
}: OwnProps) {
  const ingestionLogs = useSongIngestionLogs(songUuid);

  return (
    <UserLogList
      className={className}
      title='Ingestion Logs'
      items={ingestionLogs}
      componentBuilder={(logs: Nl.SongIngestionLog, idx) => (
        <IngestionLogItem logs={logs} key={idx} alignRight={alignRight} />
      )}
      emptyMessage='Waiting for ingestion...'
      spaceAround={spaceAround}
      alignRight={alignRight}
    />
  );
}
