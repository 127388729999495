import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { GlobalState } from '../../store/rootReducer';
import { Fab, Paper, Theme } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import Mousetrap from 'mousetrap';
import {
  closePlayer,
  pauseAudioTrack,
  playAudioTrack,
  seekAudioTrack,
  trackPlayed,
  trackStopped,
} from 'store/audioPlayer';
import SongWaveform from 'components/SongWaveform';
import AudioRenderer from './AudioRenderer';
import PlayerInfo from './PlayerInfo';
import PlayerVolume from './PlayerVolume';
import ReactAudioPlayer from 'react-audio-player';
import { compose } from 'redux';

const styles = (theme: Theme) =>
  createStyles({
    audioPlayer: {
      display: 'none',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1400,
    },
    isOpen: {
      display: 'block',
    },
    container: {
      maxWidth: 1920,
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      margin: '0px auto',
      position: 'relative',
    },
    volumeButton: {
      position: 'absolute',
      top: 0,
      right: theme.spacing(22),
      float: 'right',
    },
    playPauseButton: {
      position: 'absolute',
      top: -theme.spacing(2),
      right: theme.spacing(12),
      float: 'right',
      boxShadow: 'none',
    },
    stopButton: {
      position: 'absolute',
      top: -theme.spacing(2),
      right: theme.spacing(4),
      float: 'right',
      boxShadow: 'none',
    },
    infoContainer: {
      display: 'inline-flex',
    },
    scrubber: {
      width: '100%',
    },
  });

interface StateProps {
  isOpen: boolean;
  isPlaying: boolean;
  uuid: string;
  trackType: 'song' | Nl.SongSourceFileType;
  url: string;
  isLoop: boolean;
  trackName: string;
  artistName: string;
}

interface DispatchProps {
  onClosePlayer(): void;
  onPlayAudioTrack(): void;
  onPauseAudioTrack(): void;
  onTrackPlayed(position: number): void;
  onSeekAudioTrack(position: number): void;
  onTrackStopped(): void;
}

type AllProps = StateProps & DispatchProps & WithStyles<typeof styles>;

class AudioPlayer extends React.PureComponent<AllProps> {
  audioPlayerElement: ReactAudioPlayer | null = null;

  SKIP_DURATION = 5; // seconds

  // eslint-disable-next-line react/sort-comp
  UNSAFE_componentWillReceiveProps(newProps: any) {
    const { isOpen } = newProps;
    const wasPlaying = this.props.isPlaying;
    const isNowPlaying = newProps.isPlaying;
    if (wasPlaying && !isNowPlaying) {
      this.pauseTrack();
    } else if (isOpen && !wasPlaying && isNowPlaying) {
      this.playTrack();
    }
  }

  componentDidMount() {
    const modifierKey = 'alt';
    Mousetrap.bind(`${modifierKey}+space`, () =>
      this._handlePlayKeyboardShortcut(),
    );
  }

  render() {
    const {
      classes,
      uuid,
      trackType,
      isLoop,
      url,
      isOpen,
      isPlaying,
      onTrackPlayed,
      onTrackStopped,
      onPlayAudioTrack,
      onPauseAudioTrack,
      onClosePlayer,
    } = this.props;

    let IconComponent;
    let onClickPlay;
    let playPauseAudioDataE2e;

    if (isPlaying) {
      IconComponent = Pause;
      onClickPlay = () => onPauseAudioTrack();
      playPauseAudioDataE2e = 'playPauseAudioStatePaused';
    } else {
      IconComponent = PlayArrow;
      onClickPlay = () => onPlayAudioTrack();
      playPauseAudioDataE2e = 'playPauseAudioStatePlay';
    }
    return (
      <Paper
        elevation={2}
        className={classnames(classes.audioPlayer, isOpen && classes.isOpen)}
        data-e2e='audioPlayer'
      >
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <PlayerInfo />
          </div>
          <div className={classes.scrubber}>
            <SongWaveform
              uuid={uuid}
              type={trackType}
              onSeek={(position) => this.seek(position)}
              isLoop={isLoop}
            />
          </div>
          <PlayerVolume className={classes.volumeButton} />
          <Fab
            className={classes.playPauseButton}
            color='primary'
            onClick={onClickPlay}
            size='medium'
            data-e2e='playPauseAudio'
          >
            <IconComponent data-e2e={playPauseAudioDataE2e} />
          </Fab>
          <Fab
            className={classes.stopButton}
            color='primary'
            onClick={() => onClosePlayer()}
            size='medium'
            data-e2e='stopAudio'
          >
            <StopIcon />
          </Fab>
          <AudioRenderer
            deepRef={(elem) => {
              this.audioPlayerElement = elem;
            }}
            url={url}
            onTrackPlayed={onTrackPlayed}
            onTrackStopped={onTrackStopped}
          />
        </div>
      </Paper>
    );
  }

  pauseTrack(): void {
    this.audioElement?.pause();
  }

  playTrack(): void {
    const { audioIsPlaying, audioElement } = this;
    if (!audioIsPlaying && audioElement) {
      audioElement.play().catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(error.message);
      });
    }
  }

  updateTrackPosition(newPosition: number): void {
    if (this.audioElement) {
      this.audioElement.currentTime = newPosition;
    }
  }

  _handlePlayKeyboardShortcut(): void {
    const {
      onPlayAudioTrack,
      onPauseAudioTrack,
      isPlaying,
      isOpen,
    } = this.props;

    if (isOpen) {
      if (isPlaying) {
        onPauseAudioTrack();
      } else {
        onPlayAudioTrack();
      }
    }
  }

  seek(newPosition: number): void {
    const { onSeekAudioTrack } = this.props;

    onSeekAudioTrack(newPosition);
    this.updateTrackPosition(newPosition);
  }

  get audioElement() {
    return this.audioPlayerElement?.audioEl?.current;
  }

  get audioIsPlaying(): boolean {
    /*
      https://stackoverflow.com/a/36898221/3902555
    */
    const { audioElement } = this;
    return !!(
      audioElement &&
      audioElement.currentTime > 0 &&
      !audioElement.paused &&
      !audioElement.ended &&
      audioElement.readyState > 2
    );
  }
}

export default compose(
  connect(
    ({ audioPlayer }: GlobalState) => ({
      isOpen: audioPlayer.isOpen,
      isPlaying: audioPlayer.isPlaying,
      isLoop: audioPlayer.isLoop,
      ...audioPlayer.track,
    }),
    (dispatch) => ({
      onClosePlayer: () => dispatch(closePlayer()),
      onPlayAudioTrack: () => dispatch(playAudioTrack()),
      onPauseAudioTrack: () => dispatch(pauseAudioTrack()),
      onTrackPlayed: (position: number) => dispatch(trackPlayed({ position })),
      onSeekAudioTrack: (position: number) =>
        dispatch(seekAudioTrack({ position })),
      onTrackStopped: () => dispatch(trackStopped()),
    }),
  ),
  withStyles(styles),
)(AudioPlayer) as React.FC;
