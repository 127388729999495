import cookie from 'js-cookie';

const TOKEN_STORAGE_KEY = 'auth';
// set the auth cookie with the root domain to share it bertween the old and new nova
const rootDomain = window.location.hostname.replace('v2.', '');

export const getAuthToken = (): string | null => {
  return cookie.get(TOKEN_STORAGE_KEY) || null;
};

export const setAuthToken = (token: string) => {
  cookie.set(TOKEN_STORAGE_KEY, token, { domain: rootDomain });
};

export const deleteAuthToken = () =>
  cookie.remove(TOKEN_STORAGE_KEY, { domain: rootDomain });
