import * as React from 'react';
import { Tooltip } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Approve from 'components/icons/Approve';
import Decline from 'components/icons/Decline';

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export default function StatusToggleButton({
  value,
  onChange,
  disabled,
}: OwnProps) {
  return (
    <ToggleButtonGroup
      value={value}
      size='small'
      exclusive
      onChange={(event, nextValue: string) => {
        if (!nextValue) {
          onChange('pending');
        } else {
          onChange(nextValue);
        }
      }}
    >
      <ToggleButton value='approved' disabled={disabled}>
        <Tooltip title='Approve'>
          <Approve />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value='declined' disabled={disabled}>
        <Tooltip title='Declined'>
          <Decline />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
