import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';

export default Yup.object().shape({
  work_title: Yup.string().required(errorMessages.basicRequired),
  artist_uuid: Yup.string().required(errorMessages.basicRequired).nullable(),
});

export const songFinderValidation = Yup.object().shape({
  song_id: Yup.string().required(errorMessages.basicRequired),
});
