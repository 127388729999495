export const mimeTypes = {
  mp3: [
    'audio/mp3',
    'audio/mpeg',
    'audio/mpeg3',
    'audio/x-mpeg-3',
    'video/x-mpeg',
  ],
  zip: [
    'application/zip',
    'multipart/x-zip',
    'application/x-zip-compressed',
    'application/x-compressed',
  ],
};
