import * as React from 'react';
import { Typography, Popover, Tooltip, Button } from '@material-ui/core';
import {
  Theme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core/styles';
import { multipleSelectFormat } from 'utils/utils';
import SelectField from '../Form/AutocompleteSelect';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    actionButton: {
      whiteSpace: 'nowrap',
    },
    selectField: {
      minWidth: '200px',
      marginBottom: 0,
    },
    popover: {
      overflow: 'visible',
      padding: `${theme.spacing(2)}px`,
    },
    popoverTitle: {
      marginBottom: theme.spacing(1),
    },
    submit: {
      marginTop: `${theme.spacing(2)}px`,
    },
  });

interface OwnProps {
  selected: string[];
  actionsList: Nl.BulkActionsListItem[];
}

interface OwnState {
  actionSelected?: Nl.BulkActionsListItem;
  targetSelected?: string;
  confirmation?: string;
  anchorEl: HTMLElement | null;
}

export type AllProps = OwnProps & WithStyles<typeof styles>;

class BulkActionsBar extends React.PureComponent<AllProps, OwnState> {
  state: OwnState = {
    actionSelected: undefined,
    targetSelected: undefined,
    anchorEl: null,
    confirmation: undefined,
  };

  handleClose = () => {
    this.onReset();
  };

  handleClick = (
    event: React.MouseEvent<HTMLElement>,
    current: Nl.BulkActionsListItem,
  ) => {
    if (current && current.target) {
      this.setState({
        anchorEl: event.currentTarget,
        actionSelected: current,
        // targetList: current.target && current.target.data,
        // targetLabel: current.target && current.target.label,
        confirmation: current.confirmation,
      });
    } else {
      this.onReset();
    }
    if (!current.target) {
      const { selected } = this.props;
      if (!current.confirmation || window.confirm(current.confirmation)) {
        current.dispatch({ selected, actionSelected: current.uuid });
      }
    }
  };

  onTargetChange = (targetValue: string) => {
    this.setState({
      targetSelected: targetValue,
    });
  };

  onSubmit = () => {
    const { selected } = this.props;
    const { actionSelected, targetSelected, confirmation } = this.state;
    if (!confirmation || window.confirm(confirmation)) {
      actionSelected?.dispatch({
        selected,
        actionSelected: actionSelected.uuid,
        targetSelected,
      });
    }
  };

  onReset = () => {
    this.setState({
      anchorEl: null,
      actionSelected: undefined,
      targetSelected: undefined,
      confirmation: undefined,
    });
  };

  UNSAFE_componentWillReceiveProps({ selected }: OwnProps) {
    if (selected.length === 0) {
      this.onReset();
    }
  }

  render() {
    /*
      This component receives 'actions bar configs' which provide all dispatchers and props needed to complete an action.
      It is responsible for merely the user interaction with these potential outcomes.
    */
    const { classes, selected, actionsList } = this.props;
    const { actionSelected, targetSelected, anchorEl } = this.state;
    const targetList = actionSelected?.target?.data;
    const targetLabel = actionSelected?.target?.label;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        {actionsList.map((action) => (
          <Tooltip key={action.uuid} title={action.icon ? action.name : ''}>
            <Button
              data-e2e={action.e2e}
              disabled={
                action.disabled ||
                (actionSelected && actionSelected.uuid === action.uuid)
              }
              className={classes.actionButton}
              onClick={(e) => {
                this.handleClick(e, action);
              }}
              color={action.color}
            >
              {action.icon}
              {action.buttonText}
            </Button>
          </Tooltip>
        ))}
        <Popover
          data-e2e='Popover'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          classes={{ paper: classes.popover }}
        >
          {targetList && (
            <>
              <Typography variant='subtitle1' className={classes.popoverTitle}>
                {actionSelected?.name} ({selected.length} items):
              </Typography>
              <SelectField
                e2e='targetList'
                name='targetList'
                label={targetLabel}
                selected={targetSelected}
                options={multipleSelectFormat(targetList)}
                onChange={this.onTargetChange}
                classes={{
                  root: classes.selectField,
                }}
              />
            </>
          )}
          <Button
            disabled={!targetSelected}
            size='small'
            variant='contained'
            color='primary'
            onClick={this.onSubmit}
            className={classes.submit}
            data-e2e='apply'
          >
            Apply
          </Button>
        </Popover>
      </div>
    );
  }
}

export default withStyles(styles)(BulkActionsBar);
