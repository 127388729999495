import { useNovaApi } from '../useNovaApi';

export const useTagFieldEntities = (tagFieldName: string) => {
  return useNovaApi<Nl.Api.TagSelectResponse>(
    `/tag/select?tag_field=${tagFieldName}`,
  ).data?.tags;
};

export const useTagFieldSelect = () => {
  return (
    useNovaApi<Nl.Api.TagFieldSelectResponse>('/tag_field/select').data
      ?.tag_fields || []
  );
};

// Shared functions between components to get
// an information for taxonomy tags dropdowns
export const useTagFieldEntitiesInstruments = () => ({
  ensemble: useTagFieldEntities('ensemble'),
  nonstandardInstrument: useTagFieldEntities('nonstandard_instrument'),
  guitarBassInstrument: useTagFieldEntities('guitar_bass_instrument'),
  guitarBassBehavior: useTagFieldEntities('guitar_bass_behavior'),
  brassInstrument: useTagFieldEntities('brass_instrument'),
  brassBehavior: useTagFieldEntities('brass_behavior'),
  keyboardInstrument: useTagFieldEntities('keyboard_instrument'),
  keyboardBehavior: useTagFieldEntities('keyboard_behavior'),
  percussionInstrument: useTagFieldEntities('percussion_instrument'),
  percussionBehavior: useTagFieldEntities('percussion_behavior'),
  stringInstrument: useTagFieldEntities('string_instrument'),
  stringBehavior: useTagFieldEntities('string_behavior'),
  synthInstrument: useTagFieldEntities('synth_instrument'),
  synthBehavior: useTagFieldEntities('synth_behavior'),
  voiceInstrument: useTagFieldEntities('voice_instrument'),
  voiceBehavior: useTagFieldEntities('voice_behavior'),
  woodwindInstrument: useTagFieldEntities('woodwind_instrument'),
  woodwindBehavior: useTagFieldEntities('woodwind_behavior'),
});

export const useTagFieldEntitiesEmotional = () => ({
  moods: useTagFieldEntities('mood'),
  styleSubstyle: useTagFieldEntities('style_substyle'),
  vibes: useTagFieldEntities('vibes'),
  presence: useTagFieldEntities('presence'),
  thickness: useTagFieldEntities('thickness'),
  expectancy: useTagFieldEntities('expectancy'),
  tones: useTagFieldEntities('tones'),
  acousticElectric: useTagFieldEntities('acoustic_electric'),
  eras: useTagFieldEntities('eras'),
});

export const useTagFieldEntitiesDescriptive = () => ({
  types: useTagFieldEntities('type'),
  locations: useTagFieldEntities('location'),
  culturesReligions: useTagFieldEntities('culture_religion'),
  swing: useTagFieldEntities('swing'),
  sfxs: useTagFieldEntities('sfx'),
  effectsProcessings: useTagFieldEntities('effects_processing'),
  pitches: useTagFieldEntities('pitch'),
  articulations: useTagFieldEntities('articulation'),
  rhythms: useTagFieldEntities('rhythm'),
  backgroundTextures: useTagFieldEntities('background_texture'),
  events: useTagFieldEntities('event'),
  melodyContours: useTagFieldEntities('melody_contour'),
});

export const useTagFieldEntitiesExtramusic = () => ({
  creativeInspiration: useTagFieldEntities('creative_inspiration'),
  productionUseCase: useTagFieldEntities('production_use_case'),
  useCase: useTagFieldEntities('use_case'),
});

export const useTagFieldEntitiesLyrics = () => ({
  lyricsLanguage: useTagFieldEntities('lyrics_language'),
  lyricsSubject: useTagFieldEntities('lyrics_subject'),
});
