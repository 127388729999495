import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import { createAction, SimpleActionType } from '../utils/storeUtils';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { goPreviousPage } from 'store/router';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

const CREATE_WRITER = 'CREATE_WRITER';
const EDIT_WRITER = 'EDIT_WRITER';
const DELETE_WRITER = 'DELETE_WRITER';

export const createWriter = createAction(CREATE_WRITER);
export const editWriter = createAction(EDIT_WRITER);
export const deleteWriter = createAction(DELETE_WRITER);

const sagas = {
  *createWriterSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(apiFetch, '/writer', {
      method: 'POST',
      body: { ...formData },
    });

    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.first_name} ${formData.last_name} has been created`,
        }),
      );
      invalidateNovaQueries(`/writer`);
      yield put(goPreviousPage());
    } else {
      yield put(addErrorNotification({ message: results.msg }));
      formActions.setStatus(results.errors);
    }
  },
  *editWriterSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/writer/${formData.uuid}`,
      {
        method: 'PUT',
        body: { ...formData },
      },
    );
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${results.data.writer.full_name} has been updated`,
        }),
      );
      invalidateNovaQueries(`/writer/${formData.uuid}`);
    } else {
      yield put(addErrorNotification({ message: results.msg }));
      formActions.setStatus(results.errors);
      formActions.setSubmitting(false);
    }
  },
  *deleteWriterSaga(action: SimpleActionType) {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/writer/${action.payload.uuid}`,
      {
        method: 'DELETE',
      },
    );
    if (results.success) {
      yield put(addSuccessNotification({ message: 'Writer has been deleted' }));
      invalidateNovaQueries(`/writer`);
    } else {
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the writer',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeEvery(CREATE_WRITER, sagas.createWriterSaga),
    takeLatest(EDIT_WRITER, sagas.editWriterSaga),
    takeLatest(DELETE_WRITER, sagas.deleteWriterSaga),
  ]);
}

export { sagas };
