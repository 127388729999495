import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';
import { strongPassword } from 'utils/form/validators';
import { NotificationState } from 'store/notifications';

export const passwordSchema = Yup.string()
  .nullable()
  .required(errorMessages.basicRequired)
  .test({
    name: 'Check password strength',
    test(value: string | null | undefined) {
      // prevents checking passwordStrength if field is empty
      if (value) {
        const isStrongPassword = strongPassword(value);
        return isStrongPassword
          ? this.createError({
              message: isStrongPassword,
              path: 'new_password',
            })
          : true;
      }
      return true;
    },
  });

const validationSchema = (notificationState: NotificationState) => {
  return Yup.object().shape({
    current_password: Yup.string()
      .nullable()
      .required(errorMessages.basicRequired)
      .test(
        'Check if server error',
        'Invalid current password',
        () => notificationState.status === 'default',
      ),

    new_password: passwordSchema.notOneOf(
      [Yup.ref('current_password')],
      'New password cannot be the same as your current one',
    ),

    new_password_confirmation: Yup.string()
      .nullable()
      .required(errorMessages.basicRequired)
      .oneOf([Yup.ref('new_password')], 'Should match the new password'),
  });
};

export const editMyProfileValidationSchema = Yup.object().shape({
  legal_name: Yup.string().required(errorMessages.basicRequired).nullable(),
  contact_information: Yup.object().shape({
    first_name: Yup.string().required(errorMessages.basicRequired).nullable(),
    last_name: Yup.string().required(errorMessages.basicRequired).nullable(),
    email: Yup.string()
      .email('Invalid email')
      .required(errorMessages.basicRequired)
      .nullable(),
    email_alternate: Yup.string().email('Invalid email').nullable(),
    phone: Yup.string().required(errorMessages.basicRequired).nullable(),
    phone_alternate: Yup.string().nullable(),
    address_1: Yup.string().required(errorMessages.basicRequired).nullable(),
    address_2: Yup.string().nullable(),
    apartment: Yup.string().nullable(),
    city: Yup.string().required(errorMessages.basicRequired).nullable(),
    state_province_region: Yup.string().nullable(),
    country_uuid: Yup.string().nullable(),
    postal_zip_code: Yup.string().nullable(),
  }),
});

export default validationSchema;
