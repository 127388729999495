import { fork, all } from 'redux-saga/effects';
import { rootSaga as publishersRootSaga } from './publishers';
import { rootSaga as publisherRulesetRootSaga } from './publisherRuleset';
import { rootSaga as prosRootSaga } from './pros';
import { rootSaga as proSongRegistrationsSaga } from './proSongRegistrations';
import { rootSaga as usersRootSaga } from './users';
import { rootSaga as myProfileRootSaga } from './myProfile';
import { rootSaga as writersRootSaga } from './writers';
import { rootSaga as authRootSaga } from './auth';
import { rootSaga as queryParamsSaga } from './queryParams';
import { rootSaga as songsSaga } from './songs';
import { rootSaga as singleSongSaga } from './singleSong';
import { rootSaga as artistsSaga } from './artists';
import { rootSaga as songTagsSaga } from './songTags';
import { rootSaga as songHiddenTagsSaga } from './songTagsHidden';
import { rootSaga as taxonomyTagsSaga } from './taxonomyTags';
import { rootSaga as songCollectionsSaga } from './songCollections';
import { rootSaga as songGenreSaga } from './songGenre';
import { rootSaga as songThemeSaga } from './songTheme';
import { rootSaga as songInstrumentSaga } from './songInstrument';
import { rootSaga as originalComposer } from './originalComposer';
import { rootSaga as userMessages } from './userMessages';
import { rootSaga as uploadSaga } from './upload';
import { rootSaga as waveformSaga } from './songWaveform';
import { rootSaga as audioPlayerSaga } from './audioPlayer';
import { rootSaga as salesPerTrackSaga } from './salesPerTrack';
import { rootSaga as salesBreakdown } from './salesBreakdown';
import { rootSaga as salesTotal } from './salesTotal';
import { rootSaga as trackSubmissions } from './trackSubmissions';
import { rootSaga as artistSubmissions } from './artistSubmissions';
import { rootSaga as singleTrackSubmission } from './singleTrackSubmission';
import { rootSaga as singleArtistSubmission } from './singleArtistSubmission';
import { rootSaga as routerSaga } from './router';
import { rootSaga as batchUpdateSaga } from './batchUpdate';
import { rootSaga as myWritersSaga } from './myWriters';
import { rootSaga as registrationSaga } from './register';
import { rootSaga as SongFinderSaga } from './songFinder';
import { rootSaga as AsyncResultSaga } from './asyncResult';
import { routes } from './routes';
import { rootSaga as termsRootSaga } from './terms';
import { rootSaga as tagsRootSaga } from './tags';
import { rootSaga as tagCategoriesRootSaga } from './tagCategory';

export default function* rootSaga(dispatch: any) {
  yield all([
    fork(routes),
    fork(routerSaga),
    fork(artistsSaga),
    fork(publishersRootSaga),
    fork(publisherRulesetRootSaga),
    fork(prosRootSaga),
    fork(proSongRegistrationsSaga),
    fork(usersRootSaga),
    fork(myProfileRootSaga),
    fork(writersRootSaga),
    fork(authRootSaga),
    fork(queryParamsSaga),
    fork(songsSaga),
    fork(singleSongSaga),
    fork(SongFinderSaga),
    fork(songCollectionsSaga),
    fork(songGenreSaga),
    fork(songThemeSaga),
    fork(songInstrumentSaga),
    fork(songTagsSaga),
    fork(songHiddenTagsSaga),
    fork(taxonomyTagsSaga),
    fork(originalComposer),
    fork(userMessages),
    fork(uploadSaga),
    fork(waveformSaga),
    fork(audioPlayerSaga),
    fork(salesPerTrackSaga),
    fork(salesBreakdown),
    fork(salesTotal),
    fork(trackSubmissions),
    fork(artistSubmissions),
    fork(singleTrackSubmission),
    fork(singleArtistSubmission),
    fork(batchUpdateSaga),
    fork(myWritersSaga),
    fork(registrationSaga),
    fork(AsyncResultSaga),
    fork(tagsRootSaga),
    fork(tagCategoriesRootSaga),
    fork(termsRootSaga),
  ]);
}
