type StageNames =
  | 'initial'
  | 'check'
  | 'selected'
  | 'confirmation'
  | 'execution'
  | 'results';

export default {
  initial: 0,
  check: 1,
  selected: 2,
  confirmation: 3,
  execution: 4,
  results: 5,
} as Record<StageNames, Nl.BatchUpdateStages>;
