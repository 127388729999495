import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import ArtistForm, { getEditFormInitialValues } from './ArtistForm';
import { editArtist } from 'store/artists';
import validationSchema from './validation';
import ArtistActions from './ArtistActions';
import { useArtist } from 'novaApi/hooks/artist';
import { useNlSelector } from 'utils/redux';
import { getUrlPayloadParams } from 'novaApi/apiUtils';

export default function ArtistEditPage() {
  const { uuid } = useNlSelector(getUrlPayloadParams);
  const artist = useArtist(uuid!);
  const dispatch = useDispatch();

  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues(artist)}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) =>
        dispatch(editArtist({ formData, formActions }))
      }
      title='Edit Artist'
      customSize='xl'
      extraActions={artist && <ArtistActions artist={artist} />}
    >
      <ArtistForm artist={artist} />
      {artist && (
        <CollapsableUserLogs
          activityType='artists'
          resourceUuid={artist.uuid}
        />
      )}
    </FormikWrapper>
  );
}
