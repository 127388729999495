import React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import { SongRevisionForm } from './SongRevisionForm';
import {
  useMySongRevision,
  useMySongRevisionUpdate,
} from 'novaApi/hooks/my_song_revisions';
import { useNlSelector } from 'utils/redux';
import { getUrlPayloadParams } from 'novaApi/apiUtils';
import { goPreviousPage } from 'store/router';
import { fetchWaveforms } from 'store/songWaveform';
import { validate } from './validation';

const SongRevisionEditPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useNlSelector(getUrlPayloadParams);
  const { data, isLoaded } = useMySongRevision(uuid!);
  const {
    updateSongRevision,
    updatedSongRevision,
    isSuccess,
  } = useMySongRevisionUpdate(uuid!);

  const handleFormSubmit = (formData: Nl.Api.SongRevision, formActions) => {
    updateSongRevision({ formData, formActions });
  };

  if (!data || !isLoaded) return null;
  if (updatedSongRevision && isSuccess) dispatch(goPreviousPage());

  dispatch(
    fetchWaveforms({
      uuids: data!.song_revision.source_files.map((sf) => sf.uuid),
    }),
  );

  return (
    <FormikWrapper
      initialValues={data.song_revision}
      submitForm={handleFormSubmit}
      validate={validate}
      title={data.song_revision.work_title}
      customSize='xl'
    >
      <SongRevisionForm uuid={uuid!} />
    </FormikWrapper>
  );
};

export default SongRevisionEditPage;
