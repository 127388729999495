import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { assign, SimpleActionType, createAction } from 'utils/storeUtils';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { actions as authActions } from 'store/auth';
import { selectRegistrationToken } from 'store/selectors';
import { isEmpty } from 'lodash';
import { startPollingUserMessages } from './userMessages';
import { addErrorNotification } from './notifications';

const VALIDATE_REGISTRATION_TOKEN = 'VALIDATE_REGISTRATION_TOKEN';
const VALIDATE_REGISTRATION_TOKEN_SUCCESS =
  'VALIDATE_REGISTRATION_TOKEN_SUCCESS';
const VALIDATE_REGISTRATION_TOKEN_ERROR = 'VALIDATE_REGISTRATION_TOKEN_ERROR';
const SET_FIRST_PASSWORD = 'SET_FIRST_PASSWORD';
const SET_FIRST_PROFILE = 'SET_FIRST_PROFILE';

export const validateRegistrationToken = createAction(
  VALIDATE_REGISTRATION_TOKEN,
);
const validateRegistrationTokenSuccess = createAction(
  VALIDATE_REGISTRATION_TOKEN_SUCCESS,
);
const validateRegistrationTokenError = createAction(
  VALIDATE_REGISTRATION_TOKEN_ERROR,
);
export const setFirstPassword = createAction(SET_FIRST_PASSWORD);
export const setFirstProfile = createAction(SET_FIRST_PROFILE);

export type RegisterState = Readonly<{
  data: any;
  tokenIsValid: boolean;
  isLoading: boolean;
  isLoaded: boolean;
}>;

const initialState: RegisterState = {
  data: null,
  tokenIsValid: false,
  isLoading: false,
  isLoaded: false,
};

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case VALIDATE_REGISTRATION_TOKEN:
      return assign(state, {
        isLoading: true,
      });

    case VALIDATE_REGISTRATION_TOKEN_SUCCESS:
      return assign(state, {
        data: action.payload.data,
        tokenIsValid: true,
        isLoaded: true,
        isLoading: false,
      });

    case VALIDATE_REGISTRATION_TOKEN_ERROR:
      return assign(state, {
        tokenIsValid: false,
        isLoading: false,
        isLoaded: true,
      });

    default:
      return state;
  }
};

const sagas = {
  *validateTokenSaga({ payload }: SimpleActionType) {
    const { token } = payload;
    const { success, data } = yield call(
      apiFetch,
      '/auth/validate_reset_password_token',
      {
        method: 'POST',
        body: { token },
      },
    );
    if (success) {
      yield put(validateRegistrationTokenSuccess({ data }));
    } else {
      yield put(validateRegistrationTokenError());
    }
  },
  *setFirstPasswordSaga({ payload }: SimpleActionType) {
    const token: string = yield select(selectRegistrationToken);
    const { formData, formActions } = payload;
    const { new_password } = formData;

    // Set and reset password work the same way
    const { success, errors } = yield call(apiFetch, '/user/reset_password', {
      method: 'POST',
      body: {
        new_password,
      },
      headers: {
        Authorization: token,
      },
    });

    if (success) {
      // Automatically log the user in if setting the password is successful
      const email: string = yield select(
        (state) => state.register?.data?.user?.email,
      );
      const response: FetchResponse<Nl.Api.Auth> = yield call(
        apiFetch,
        '/auth/login',
        {
          method: 'POST',
          body: {
            email,
            password: new_password,
          },
        },
      );

      // Execute the usual post-login actions
      yield put(authActions.onAuthenticationSuccess(response.data));
      yield put(startPollingUserMessages());

      // advance the user to the next step of registration: setting up profile
      yield put({ type: 'route/MY_PROFILE_EDIT_REGISTRATION' });
    } else {
      formActions.setErrors(errors);
    }
    formActions.setSubmitting(false);
  },
  *setFirstProfileSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;

    // Remember to send onboarding: true in body -> sends email to rep to review profile
    const { success, msg, errors } = yield call(
      apiFetch,
      '/artist/my_profile',
      {
        method: 'PUT',
        body: { onboarding: true, ...formData },
      },
    );

    if (success) {
      // Advance to next step of registration: submit a new track
      yield put({ type: 'route/MY_TRACKS' });
    } else {
      if (isEmpty(errors)) {
        yield put(addErrorNotification({ message: msg }));
      }
      formActions.setErrors(errors);
    }
    formActions.setSubmitting(false);
  },
};

export function* rootSaga() {
  yield all([
    takeLatest(VALIDATE_REGISTRATION_TOKEN, sagas.validateTokenSaga),
    takeLatest(SET_FIRST_PASSWORD, sagas.setFirstPasswordSaga),
    takeLatest(SET_FIRST_PROFILE, sagas.setFirstProfileSaga),
  ]);
}

export { sagas };
export default reducer;
