import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';
import { passwordSchema } from 'pages/MyProfile/validation';

export const passwordValidationSchema = Yup.object().shape({
  new_password: passwordSchema,
  confirm_password: Yup.string()
    .required(errorMessages.basicRequired)
    .oneOf([Yup.ref('new_password')], 'Passwords do not match'),
});
