import classnames from 'classnames';
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import React from 'react';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { CSSObject } from '@emotion/serialize';

const NoOptionsMessage = (props: any) => {
  return (
    <MenuItem disabled component='div'>
      <Typography noWrap>{props.children}</Typography>
    </MenuItem>
  );
};

const createdOption = (value: string) => {
  return (
    <span
      style={{
        fontWeight: 'bold',
        backgroundColor: 'yellow',
        padding: '.25rem',
      }}
    >
      Create &quot;{value}&quot;
    </span>
  );
};

const Option = (props: any) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = {
    ...props,
    innerProps: rest,
  };
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      data-e2e={props.children}
      data-e2e-alt='menuOption'
      {...newProps.innerProps}
    >
      <Typography noWrap>{props.children}</Typography>
    </MenuItem>
  );
};

const ValueContainer = (props: any) => {
  const { classes } = props.selectProps;
  return <div className={classes.valueContainer}>{props.children}</div>;
};

const Menu = (props: any) => {
  const { classes } = props.selectProps;
  return (
    <Paper square className={classes.menu} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

const SingleValue = (props: any) => {
  const { classes } = props.selectProps;
  return (
    <Typography
      {...props.innerProps}
      className={
        props.selectProps.isDisabled
          ? classnames(classes.disable, classes.singleValue)
          : classes.singleValue
      }
    >
      {props.children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, children, ...props }: any) => {
  return (
    <div ref={inputRef} {...props}>
      {children}
    </div>
  );
};

// Override the autocomplete="off" prop for the input which is added by react-select
// https://github.com/JedWatson/react-select/issues/3500
const Input = (props: any) => (
  <components.Input {...props} autoComplete='new-password' />
);

const Control = (props: any) => {
  const { classes } = props.selectProps;
  return (
    <TextField
      fullWidth
      name={props.selectProps.name}
      label={props.selectProps.label}
      InputLabelProps={{
        shrink: props.getValue().length > 0 || props.isFocused,
      }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.textFieldInput,
          inputRef: props.innerRef,
          'data-e2e': props.selectProps.e2e,
          children: props.children,
          ...props.innerProps,
        },
      }}
      disabled={props.selectProps.isDisabled}
      error={props.selectProps.error}
      helperText={props.selectProps.helperText}
      {...props.selectProps.textFieldProps}
      variant='outlined'
    />
  );
};

const MultiValue = (props: any) => {
  const { classes } = props.selectProps;
  return (
    <Chip
      tabIndex={-1}
      data-e2e='chip'
      label={props.children}
      classes={{
        label: classes.multiValueLabel,
        root: classes.multiValue,
      }}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      variant='outlined'
    />
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    menu: {
      position: 'absolute',
      zIndex: theme.zIndex.modal,
      width: '100%',
    },
    multiValue: {
      maxWidth: '95%',
    },
    multiValueLabel: {
      width: '100%',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    textFieldInput: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      margin: 0,
      fontWeight: 400,
      font: 'inherit',
      alignItems: 'center',
      ...theme.overrides?.MuiInputBase?.input,
    },
    singleValue: {
      position: 'absolute',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    valueContainer: {
      width: 'calc(100% - 24px)',
      position: 'relative',
      paddingRight: '8px',
    },
    disable: {
      color: theme.palette.action.disabled,
    },
  });

export interface OwnProps {
  name: string;
  e2e?: string;
  label?: string;
  placeholder?: string;
  isCreatable?: boolean;
  options: Nl.SelectFieldOptionsType[];
  selected?: string | string[];
  isForm?: boolean;
  isMulti?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  noClearable?: boolean;
  isSticky?: boolean;
  menuPortalTarget?: HTMLElement;
  /* Resolves option data to a string to be displayed as the label by components */
  getOptionLabel?: (option: any) => string;
  /* Resolves option data to a string to compare options and specify value attributes */
  getOptionValue?: (option: any) => string;
  getNewOptionData?: (value: string) => any;
  format?: (entities: any[]) => Nl.SelectFieldOptionsType[];
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?(selected: string | string[] | undefined, name: string): void;
}

class SelectField extends React.PureComponent<
  OwnProps & WithStyles<typeof styles, true>
> {
  static defaultProps = {
    isMulti: false,
  };

  storageKey = `${window.location.pathname}_${this.props.label}`;

  getStorageItem() {
    const value = window.localStorage.getItem(this.storageKey);
    return value && JSON.parse(value);
  }

  componentDidMount() {
    const { onChange, name } = this.props;
    const item = this.getStorageItem();
    if (item && onChange) onChange(item.uuid, name);
  }

  render() {
    const {
      classes,
      isCreatable,
      options,
      selected,
      onChange,
      name,
      label,
      placeholder,
      isMulti,
      onBlur,
      error,
      helperText,
      e2e,
      disabled,
      noClearable,
      menuPortalTarget,
      format,
      isSticky,
    } = this.props;

    const formattedOptions = format ? format(options) : options;

    const getOptionLabel = this.props.getOptionLabel
      ? this.props.getOptionLabel
      : (option: any) => option.label;
    const getOptionValue = this.props.getOptionValue
      ? this.props.getOptionValue
      : (option: any) => option.value;

    const getNewOptionData = this.props.getNewOptionData
      ? this.props.getNewOptionData
      : (value: any) => ({ label: value, value });

    const ReactSelect: any = isCreatable ? Creatable : Select;

    let currentValue;
    let selectedItem = selected;

    if (isSticky) {
      const item = this.getStorageItem();
      if (item) selectedItem = item.uuid;
    }

    if (isCreatable && selectedItem) {
      currentValue = Array.isArray(selectedItem)
        ? selectedItem.map((v) => getNewOptionData(v))
        : getNewOptionData(selectedItem);
    } else {
      currentValue = Array.isArray(selectedItem)
        ? formattedOptions.filter((v) =>
            selectedItem!.includes(getOptionValue(v)),
          )
        : [formattedOptions.find((v) => getOptionValue(v) === selectedItem)];
    }

    return (
      <div className={classes.root}>
        <ReactSelect
          name={name}
          options={formattedOptions}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          formatCreateLabel={(value: string) => createdOption(value)}
          styles={{
            menuPortal: (base: CSSObject) => ({
              ...base,
              zIndex: 2000,
            }),
            clearIndicator: (base: CSSObject) => ({
              ...base,
              padding: '6px',
              cursor: 'pointer',
            }),
            dropdownIndicator: (base: CSSObject) => ({
              ...base,
              padding: '6px',
              cursor: 'pointer',
            }),
            indicatorsContainer: (base: CSSObject) => ({
              ...base,
              margin: '-6px',
            }),
            input: (base: object) => ({
              ...base,
              display: 'inline-block',
              margin: 0,
              padding: 0,
            }),
          }}
          components={{
            Control,
            Menu,
            MultiValue,
            NoOptionsMessage,
            Option,
            SingleValue,
            ValueContainer,
            Input,
          }}
          placeholder={placeholder || ''}
          autoFocus={false}
          backspaceRemovesValue
          isClearable={!isMulti && !noClearable}
          value={currentValue}
          menuPortalTarget={menuPortalTarget}
          onChange={(value: any) => {
            if (isSticky)
              window.localStorage.setItem(
                this.storageKey,
                JSON.stringify(value),
              );
            if (onChange) {
              if (Array.isArray(value)) {
                onChange(
                  value.map((v) => getOptionValue(v)),
                  name,
                );
              } else if (value == null) {
                onChange(undefined, name);
              } else if (value) {
                onChange(getOptionValue(value), name);
              }
            }
          }}
          isSearchable
          onBlur={onBlur}
          isMulti={isMulti}
          isDisabled={disabled}
          // getNewOptionData={getNewOptionData}
          error={error}
          helperText={helperText}
          classes={classes}
          e2e={e2e}
          label={label}
          aria-label={label}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SelectField);
