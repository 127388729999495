import { useNovaApi } from 'novaApi/useNovaApi';

export const useNotes = (
  parent_type: Nl.ParentNoteType,
  parent_uuid: string,
) => {
  return useNovaApi<Nl.Api.NotesResponse>(`/note`, {
    query: { parent_type, parent_uuid },
  }).data?.notes;
};
