import { useNovaApi, useNovaApiCreate, useNovaApiUpdate } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { useDispatch } from 'react-redux';
import { addSuccessNotification } from 'store/notifications';

export const useExamples = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.ExamplesResponse>(`/example`, {
    query,
  });
};

export const useExample = (uuid: string) => {
  return useNovaApi<Nl.Api.ExampleResponse>(`/example/${uuid}`);
};

export const useExampleSelect = () => {
  return (
    useNovaApi<Nl.Api.ExampleSelectResponse>('/example/select').data
      ?.examples || []
  );
};

export const useExampleCreate = () => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiCreate<
    Nl.Api.ExampleResponse,
    Nl.Api.Example
  >('/example', (createdExample) => {
    dispatch(
      addSuccessNotification({
        message: `Example ${createdExample.example.name} has been created`,
      }),
    );
  });

  return {
    createExample: mutation.mutate,
    isSuccess,
    createdExample: data,
  };
};

export const useExampleUpdate = (uuid: string) => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiUpdate<
    Nl.Api.ExampleResponse,
    Nl.Api.Example
  >(`/example/${uuid}`, (updatedExample) => {
    dispatch(
      addSuccessNotification({
        message: `Example ${updatedExample.example.name} has been updated`,
      }),
    );
  });

  return {
    updateExample: mutation.mutate,
    updatedExample: data,
    isSuccess,
  };
};
