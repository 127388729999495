import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, Grid } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    marginTop: theme.spacing(8),
  },
  icon: {
    verticalAlign: '-3px',
  },
}));

export const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <Grid container justify='center'>
    <Grid item xs={12} md={6} lg={4}>
      {children}
    </Grid>
  </Grid>
);
