import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';
import moment from 'moment';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { GlobalState } from 'store/rootReducer';
import { required } from 'utils/validate';
import AudioControlIcon from 'components/AudioControlIcon';
import SongWaveform from 'components/SongWaveform';
import RevisionUploadDropzone from './RevisionUploadDropzone';
import Delete from '@material-ui/icons/Delete';
import { isLoop } from 'utils/songSourceFile';
import { FormSubTitle } from 'components/Formik/utils';
import TextField from '@material-ui/core/TextField';
import { isEqual } from 'lodash';

interface OwnProps {
  revisionUuid: string;
  isEditable: boolean;
  sourceFile: Nl.Api.SourceFileRevision;
  sourceFileIndex: number;
  formValues: Nl.UploadFormValues;
  init: Nl.Api.SongRevision;
}

interface StateProps {
  waveformIsLoaded: boolean;
}

type AllProps = OwnProps & StateProps;

const SourceFileRevisionRow: React.FC<AllProps> = ({
  revisionUuid,
  sourceFile,
  sourceFileIndex,
  formValues,
  init,
  waveformIsLoaded,
}) => {
  const deleteUpload = () => {
    if (init.source_files) {
      formValues.set(`${formValues.field}`, init.source_files[sourceFileIndex]);
    }
  };
  const _isLoop = isLoop(sourceFile.file_type);
  const sourceFileInfo = [
    {
      label: 'File type',
      value: sourceFile.file_type,
    },
    {
      label: 'Duration',
      value: _isLoop
        ? Math.round(sourceFile.duration / 3)
        : sourceFile.duration,
    },
    {
      label: '# of channels',
      value: sourceFile.channels,
    },
    {
      label: 'Sample rate',
      value: sourceFile.sample_rate,
    },
    {
      label: 'Delivered on',
      value: moment(sourceFile.created_at).format('L'),
    },
  ].map(({ label, value }) => {
    return (
      <Typography variant='caption' display='block' key={label}>
        <b>{label}: </b>
        {value}
      </Typography>
    );
  });

  return (
    <Grid container>
      <Grid item xs={12} data-e2e={`filename-${sourceFile.uuid}`}>
        <FormSubTitle
          title={sourceFile.original_file_name || sourceFile.file_type}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} direction='row' alignItems='center'>
          <Grid item xs={2}>
            {sourceFileInfo}
          </Grid>
          <Grid item xs={6}>
            <Grid container data-e2e={`player-waveform-${sourceFile.uuid}`}>
              <Grid item xs={1}>
                <AudioControlIcon
                  isLoop={_isLoop}
                  audioTrack={{
                    uuid: sourceFile.uuid,
                    trackType: sourceFile.file_type,
                    url: sourceFile.original_wav_url,
                    trackName: sourceFile.original_file_name || '',
                    duration: sourceFile.duration,
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                {waveformIsLoaded && (
                  <SongWaveform
                    uuid={sourceFile.uuid}
                    type={sourceFile.file_type}
                    displayLoopOnce
                    isLoop={_isLoop}
                  />
                )}
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Field
                  name={`source_files.${sourceFileIndex}.original_wav_url`}
                  data-e2e={`dropzone-${sourceFile.uuid}`}
                  component={() => (
                    <>
                      {sourceFile.revision_filename ? (
                        <Grid container direction='row' alignItems='center'>
                          <Grid item xs={10}>
                            <Typography align='center'>
                              Will be replaced by {sourceFile.revision_filename}{' '}
                              on submit
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={deleteUpload}
                              data-e2e='deleteUpload'
                            >
                              <Delete fontSize='small' />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ) : (
                        <RevisionUploadDropzone
                          contentType='audio/wav'
                          revisionUuid={revisionUuid}
                          sourceFile={sourceFile}
                          type={sourceFile.file_type}
                          state={sourceFile.state}
                          formValues={formValues}
                        />
                      )}
                    </>
                  )}
                  validate={[required]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label='Requested changes'
              variant='outlined'
              fullWidth
              InputProps={{
                readOnly: true,
                value: sourceFile.revision_note || 'See general note.',
                multiline: true,
                rows: 4,
              }}
              data-e2e='source-file-revision-note'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const makeMapStateToProps = () => {
  return (state: GlobalState, { sourceFile }: OwnProps) => {
    const waveformIsLoaded = !!(
      state.waveform[sourceFile.uuid] && state.waveform[sourceFile.uuid].data
    );
    return {
      waveformIsLoaded,
    };
  };
};

/*
 * Need to prevent re-render when another row is updated with a file selection.
 * If re-render occurs while user is selecting a file, the file selection is
 * lost and the use has to start over.
 * */

const arePropsEqual = (prevProps: AllProps, nextProps: AllProps): boolean =>
  isEqual(prevProps.sourceFile, nextProps.sourceFile) &&
  isEqual(prevProps.waveformIsLoaded, nextProps.waveformIsLoaded);

export default connect(makeMapStateToProps)(
  React.memo(SourceFileRevisionRow, arePropsEqual),
);
