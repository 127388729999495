import * as React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Moment } from 'moment';

const moment = require('moment');

interface OwnProps {
  id: string;
  e2e?: string;
  label: string;
  defaultValue?: string;
  onChange(val: string): void;
  isError?: boolean;
}

export default function DateSelect({
  id,
  label,
  e2e,
  onChange,
  defaultValue,
  isError,
}: OwnProps) {
  const value = defaultValue ? moment.utc(Number(defaultValue)) : null;

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <DatePicker
        id={id}
        // use the following workaround instead of fullwith to solve an issue with the input
        style={{ width: '100%' }}
        data-e2e={e2e}
        label={label}
        inputProps={{ 'aria-label': label }}
        autoOk
        clearable
        disableFuture
        placeholder='mm/dd/yyyy'
        maxDateMessage='Date must be less than today'
        maxDate={new Date()}
        value={value}
        onChange={(date: Moment | null) => {
          const newDate = date
            ? moment
                .utc(date)
                .startOf('day') // Ignore local clock's current time, use beginning of day (midnight)
                .toDate()
                .getTime()
                .toString()
            : null;
          onChange(newDate);
        }}
        format='D MMM YYYY'
        inputVariant='outlined'
        error={isError}
      />
    </MuiPickersUtilsProvider>
  );
}
