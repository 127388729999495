import * as React from 'react';
import { connect } from 'react-redux';
import asyncComponent from './AsyncComponent';
import { routesMap } from '../routes';
import { GlobalState } from 'store/rootReducer';

const mapStateToProps = (state: GlobalState) => ({
  route: state.location.type as Nl.RouteMapType,
});

interface StateProps {
  route: Nl.RouteMapType;
}

type AllProps = StateProps;

class ModalSwitcher extends React.Component<AllProps> {
  shouldComponentUpdate(nextProps: AllProps) {
    const isSameModal =
      routesMap[nextProps.route].type === 'modal' &&
      routesMap[this.props.route].type === 'modal'
        ? routesMap[this.props.route].component ===
          routesMap[nextProps.route].component
        : false;
    // Re-render the modal, only if the modal is already opened
    return !isSameModal;
  }

  render() {
    const { route } = this.props;
    const targetRoute: Nl.RouteMapProps = routesMap[route];
    // If the component is not a modal then return null and so unmount the previous modal if it exists.
    const modalComponent =
      targetRoute.type === 'modal' ? targetRoute.component : null;
    const Modal = modalComponent ? asyncComponent(modalComponent) : null;
    return Modal ? <Modal /> : null;
  }
}

export default connect(mapStateToProps)(ModalSwitcher);
