import { chain } from 'lodash';

export const getPopulatedFields = (fields: any[]) =>
  fields?.filter((field) => field.uuid);

export const getSourceFileSegmentTypes = (fields?: any[]) => {
  return chain(getPopulatedFields(fields || []))
    .map((field) => field.file_type)
    .filter((type) => type && !type.includes('Full'))
    .uniq()
    .value();
};
