import * as React from 'react';
import ActivityLogList from './ActivityLogs/ActivityLogList';
import IngestionLogList from './IngestionLogs/IngestionLogList';
import {
  FormControlLabel,
  Switch,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import DownstreamStatusList from './DownstreamStatus/DownstreamStatusList';
import { UserActivityType } from 'novaApi/hooks/user_activity';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },
    switch: {
      margin: '0 auto',
    },
    logContainer: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    logs: {
      width: '100%',
      '&:not(:first-child)': {
        // if we have more than one column
        marginLeft: '20px',
      },
    },
  }),
);

interface OwnProps {
  withSongIngestionLogs?: boolean;
  activityType: UserActivityType;
  resourceUuid: string;
}

export default function CollapsableUserLogs({
  withSongIngestionLogs,
  activityType,
  resourceUuid,
}: OwnProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            data-e2e='toggle-log'
            color='primary'
            checked={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          />
        }
        label='Logs'
      />
      <div className={classes.logContainer}>
        {isOpen && (
          <ActivityLogList
            className={classes.logs}
            {...{ activityType, resourceUuid }}
          />
        )}
        {isOpen && withSongIngestionLogs && (
          <>
            <IngestionLogList
              className={classes.logs}
              songUuid={resourceUuid}
            />
            <DownstreamStatusList
              className={classes.logs}
              songUuid={resourceUuid}
            />
          </>
        )}
      </div>
    </div>
  );
}
