import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';

type SchemaOptions = 'EDIT' | 'CREATE';

export const getValidationSchema = (option: SchemaOptions = 'CREATE') => {
  const baseShape = {
    name: Yup.string().required(errorMessages.basicRequired),
    tag_category: Yup.string().required(errorMessages.basicRequired),
    tagging_label: Yup.string().required(errorMessages.basicRequired),
    numerical_value: Yup.number()
      .typeError(errorMessages.notValidNumber)
      .nullable(),
    average_tempo: Yup.number()
      .typeError(errorMessages.notValidNumber)
      .nullable(),
    related_tags: Yup.array().of(
      Yup.object().shape({
        uuid: Yup.string().required(errorMessages.basicRequired),
        tags: Yup.array().when('uuid' || [], {
          is: (val) => val && val.length > 0,
          then: Yup.array().min(1, errorMessages.basicRequired).required(),
        }),
      }),
    ),
    tag_terms: Yup.array().of(
      Yup.object().shape({
        uuid: Yup.string().required(errorMessages.basicRequired),
        terms: Yup.array().when('uuid' || [], {
          is: (val) => val && val.length > 0,
          then: Yup.array().min(1, errorMessages.basicRequired).required(),
        }),
      }),
    ),
  };

  const editShape = {
    // Edit mode conditions here (if needed)
  };

  return Yup.object().shape({
    ...baseShape,
    ...(option === 'EDIT' ? editShape : {}), // add edit shape in EDIT mode
  });
};
