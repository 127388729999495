import * as React from 'react';
import { Form, Field } from 'formik';
import InputField from 'components/Formik/InputField';

const ChangeMyPasswordForm: React.SFC = () => (
  <Form>
    <Field
      name='current_password'
      label='Current password'
      component={InputField}
      type='password'
    />
    <Field
      name='new_password'
      label='New Password'
      component={InputField}
      type='password'
    />
    <Field
      name='new_password_confirmation'
      label='Confirm New Password'
      component={InputField}
      type='password'
    />
  </Form>
);

export default ChangeMyPasswordForm;
