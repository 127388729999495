import * as React from 'react';
import UserLogItem from '../UserLogItem';
import { Typography, Link } from '@material-ui/core';

interface OwnProps {
  item: Nl.Api.SongFinderItem;
}

const DownstreamStatusItem: React.FunctionComponent<OwnProps> = ({ item }) => {
  const infoText =
    item.status === 'live' && item.url_downstream ? (
      <>
        This song is{' '}
        <Link href={item.url_downstream}>live on {item.channel}</Link>
      </>
    ) : (
      `This song is ${item.status} on ${item.channel}`
    );
  return (
    <UserLogItem>
      <Typography>
        {infoText}
        <br />
        {item.id_downstream ? `ID: ${item.id_downstream}` : null}
      </Typography>
    </UserLogItem>
  );
};

export default DownstreamStatusItem;
