import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import { TableConfigEntry } from 'utils/tableConfig';
import { getReadableDate } from 'utils/utils';
import { useComparisons } from 'novaApi/hooks/comparison';
import InternalLink from 'components/Link/InternalLink';
import ConnectedButton from 'components/Button/ConnectedButton';

const useStyles = makeStyles(styles);

const tableConfig: TableConfigEntry<Nl.Api.ComparisonBase> = {
  columnData: [
    {
      id: 'name',
      label: 'Name',
      isSortable: true,
      render: ({ uuid, name }) => (
        <InternalLink
          color='inherit'
          to={{ type: 'route/COMPARISONS_EDIT', payload: { uuid } }}
        >
          {name}
        </InternalLink>
      ),
    },
    {
      id: 'created_at',
      label: 'Created',
      isSortable: true,
      render: ({ created_at }) => getReadableDate(created_at) || '-',
    },
    {
      id: 'updated_at',
      label: 'Last Modified',
      isSortable: true,
      render: ({ updated_at }) => getReadableDate(updated_at) || '-',
    },
  ],
};

export default function ComparisonsPage() {
  const classes = useStyles({});
  const { data, isLoaded } = useComparisons();

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Comparisons'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'comparison-search-name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/COMPARISONS_CREATE'
            label='Create new Comparison'
            permission='CAN_CREATE_COMPARISON'
          />
        }
      />
      <Table
        config={tableConfig}
        data={data?.comparisons}
        isLoaded={isLoaded}
        paginationData={data}
      />
    </Paper>
  );
}
