import * as React from 'react';
import { Form, Field } from 'formik';
import Checkbox from 'components/Formik/CheckboxField';
import { multipleSelectFormat } from 'utils/utils';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import { Switch, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputField from 'components/Formik/InputField';
import { useUserRoles } from 'novaApi/hooks/user_role';

interface AllProps {
  isEdit?: boolean;
  isPasswordEdit?: boolean;
  setIsPasswordEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserForm: React.SFC<AllProps> = ({
  isEdit,
  isPasswordEdit,
  setIsPasswordEdit,
}) => {
  const userRoles = useUserRoles();

  return (
    <Form>
      <Field name='first_name' label='First Name' component={InputField} />
      <Field name='last_name' label='Last Name' component={InputField} />
      <Field name='email' label='Email' component={InputField} />
      {!isEdit && (
        <Field
          name='password'
          label='Password'
          type='password'
          component={InputField}
        />
      )}
      <Field
        name='user_role_uuid'
        label='User Role'
        component={AutocompleteSelect}
        options={multipleSelectFormat(userRoles)}
      />
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={6} style={{ display: 'flex' }}>
          {isEdit && (
            <FormControlLabel
              control={
                <Switch
                  name='password_switch'
                  color='primary'
                  checked={isPasswordEdit}
                  onChange={(event, value) => {
                    setIsPasswordEdit?.(value);
                  }}
                />
              }
              label={!isPasswordEdit && 'Edit Password'}
            />
          )}
          {isPasswordEdit && (
            <Field
              name='password'
              label='Password'
              type='password'
              component={InputField}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Field name='is_active' label='Active?' component={Checkbox} />
        </Grid>
      </Grid>
    </Form>
  );
};

export default UserForm;
