import { useNovaApi, useNovaApiCreate, useNovaApiUpdate } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { useDispatch } from 'react-redux';
import { addSuccessNotification } from 'store/notifications';

export const useTagCategories = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.TagCategoriesResponse>(`/tag_category`, {
    query,
  });
};

export const useTagCategory = (uuid: string) => {
  return useNovaApi<Nl.Api.TagCategoryResponse>(`/tag_category/${uuid}`);
};

export const useTagCategorySelect = (query: Nl.QueryFilter = {}) => {
  return (
    useNovaApi<Nl.Api.TagCategorySelectResponse>('/tag_category/select', {
      query,
    }).data?.tag_categories || []
  );
};

export const useTagCategoryCreate = () => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiCreate<
    Nl.Api.TagCategoryResponse,
    Nl.Api.TagCategory
  >('/tag_category', (createdTagCategory) => {
    dispatch(
      addSuccessNotification({
        message: `Tag Category ${createdTagCategory.tag_category.name} has been created`,
      }),
    );
  });

  return {
    createTagCategory: mutation.mutate,
    isSuccess,
    createdTagCategory: data,
  };
};

export const useTagCategoryUpdate = (uuid: string) => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiUpdate<
    Nl.Api.TagCategoryResponse,
    Nl.Api.TagCategory
  >(`/tag_category/${uuid}`, (updatedTagCategory) => {
    dispatch(
      addSuccessNotification({
        message: `Tag Category ${updatedTagCategory.tag_category.name} has been updated`,
      }),
    );
  });

  return {
    updateTagCategory: mutation.mutate,
    updatedTagCategory: data,
    isSuccess,
  };
};
