// eslint-disable-next-line import/extensions
const ZipValidationWorker = require('./zipAnalyzer.worker.ts');

export interface ZipAnalyzerResult {
  isValid: boolean;
  msg: string;
}

/**
 * The zipAnalyzer worker do the zip validation in a web worker and return the result as a promise
 */
export default async function analyseZipWorker(
  file: File,
  type: string,
): Promise<ZipAnalyzerResult> {
  // use webWorker to do the zip/wav validation.
  const zipWorker = new ZipValidationWorker();

  zipWorker.postMessage({ fileContent: file, fileType: type });

  return new Promise<ZipAnalyzerResult>((resolve, reject) => {
    zipWorker.onmessage = (event: { data: ZipAnalyzerResult }) => {
      const { isValid, msg } = event.data;
      resolve({ isValid, msg });
    };
    zipWorker.onerror = () => {
      reject(new Error('Failed to spawn analyzer worker'));
    };
  });
}
