import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import { getEditFormInitialValues, createSongGenre } from 'store/songGenre';
import { selectNotificationStatus } from 'store/selectors';
import SongGenreForm from './SongGenreForm';
import validationSchema from 'utils/basicYupValidation';
import { useNlSelector } from 'utils/redux';

const CreateSongGenrePage = () => {
  const dispatch = useDispatch();

  const initialValues = useNlSelector(getEditFormInitialValues);
  const notificationStatus = useNlSelector(selectNotificationStatus);

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={validationSchema(notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(createSongGenre({ formData, formActions }))
      }
      title='Create Song Genre'
    >
      <SongGenreForm />
    </FormikWrapper>
  );
};

export default CreateSongGenrePage;
