import React from 'react';
import { useNlSelector } from 'utils/redux';

import { getUrlPayloadParams } from 'novaApi/apiUtils';
import FormikWrapper from 'components/Formik';
import TagCategoryForm, { getFormInitialValues } from './TagCategoryForm';
import { useDispatch } from 'react-redux';
import { goPreviousPage } from 'store/router';
import { getValidationSchema } from './validation';
import {
  useTagCategory,
  useTagCategorySelect,
  useTagCategoryUpdate,
} from 'novaApi/hooks/tag_category';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';

export default function TagCategoriesEditPage() {
  const dispatch = useDispatch();
  const { uuid } = useNlSelector(getUrlPayloadParams);
  const { data } = useTagCategory(uuid!);
  const parentCategories = useTagCategorySelect({
    parents_for: uuid,
  });
  const tagCategoryToEdit = getFormInitialValues(data?.tag_category);
  const {
    updateTagCategory,
    isSuccess,
    updatedTagCategory,
  } = useTagCategoryUpdate(uuid!);

  const handleFormSubmit = (formData, formActions) => {
    updateTagCategory({ formData, formActions });
  };

  if (!data) return null;
  if (updatedTagCategory && isSuccess) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={tagCategoryToEdit}
      validationSchema={getValidationSchema('EDIT')}
      submitForm={(formData, formActions) =>
        handleFormSubmit(formData, formActions)
      }
      title='Edit tag category'
      customSize='sm'
    >
      <TagCategoryForm parentTagCategories={parentCategories} />

      {uuid && (
        <CollapsableUserLogs
          activityType='tag-categories'
          resourceUuid={uuid}
        />
      )}
    </FormikWrapper>
  );
}
