import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { formatNumber } from 'utils/utils';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { useNlSelector } from 'utils/redux';
import {
  selectSalesReports,
  selectSalesReportsLoadingStatus,
} from 'store/selectors';
import infoMessages from 'constants/infoMessages';
import EarningsSummarySalesTotal from './EarningsSummarySalesTotal';
import { ResponsivePaper } from 'components/ResponsiveLayout';
import { TableConfigEntry } from 'utils/tableConfig';
import SalesReportsActions from 'pages/Dashboard/SalesReportsActions';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';

const useStyles = makeStyles(styles);

const tableConfig: TableConfigEntry<Nl.Api.SalesBreakdown> = {
  storeKey: 'salesBreakdown',
  columnData: [
    {
      id: 'title',
      label: 'Track Name',
      cellProps: { width: '60%' },
      render: ({ work_title }) => work_title,
    },
    {
      id: 'dateOfPurchase',
      label: 'Date of Purchase',
      render: ({ purchase_date }) => moment(purchase_date).format('D MMM YYYY'),
    },
    {
      id: 'total',
      label: 'Total',
      render: ({ total }) => `$${formatNumber(total.toFixed(2).toString())}`,
    },
    {
      id: 'refunds',
      label: 'Refunds',
      render: ({ refunds }) =>
        refunds ? `$${formatNumber(refunds.toFixed(2))}` : '-',
    },
  ],
  link: {
    title: 'Sales Performance By Track',
    route: 'route/DASHBOARD_SALES_PER_TRACK',
    permission: 'CAN_GET_MY_EARNINGS_SUMMARY',
  },
};

export default function SalesReportsPage() {
  const data = useNlSelector(selectSalesReports);
  const isLoaded = useNlSelector(selectSalesReportsLoadingStatus);
  const classes = useStyles({});

  return (
    <ResponsivePaper elevation={1} className={classes.root}>
      <TableToolbar
        filtersOpen
        hideFiltersButton
        title='Sales Reports'
        actions={<SalesReportsActions />}
        filters={
          <ConnectedTableFilters
            filters={[
              {
                type: 'date',
                label: 'Start date',
                queryFilterKey: 'start_date',
                e2e: 'selectStartDate',
              },
              {
                type: 'date',
                label: 'End date',
                queryFilterKey: 'end_date',
                e2e: 'selectEndDate',
              },
            ]}
          />
        }
      />

      <div className={classes.infoWrapper}>
        <EarningsSummarySalesTotal className={classes.salesTotal} />
        <div className={classes.infoText}>
          {infoMessages.premiumBeatDataOnly}
        </div>
      </div>

      <Table config={tableConfig} data={data} isLoaded={isLoaded} />
    </ResponsivePaper>
  );
}
