import * as React from 'react';
import TableHeader from './TableHeader';
import TableBody, { BaseRowData } from './TableBody';
import CircularLoading from '../CircularLoading/CircularLoading';
import { Table as MaterialTable, makeStyles } from '@material-ui/core';
import { styles } from './Table.styles';
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied';
import { TableConfigEntry } from 'utils/tableConfig';

const useStyles = makeStyles(styles);

interface AllProps<RowData> {
  config: TableConfigEntry<RowData>;
  isLoaded: boolean;
  data?: (RowData & BaseRowData)[];
  selectionStatus?: 0 | 1 | 2;
  selectAll?: () => void;
  paginationData?: Nl.Api.PaginationData;
}

const NoResultMsg: React.SFC = () => {
  const classes = useStyles({});
  const emptyListMessage = 'Sorry, no results.';

  return (
    <div data-e2e='noResult' className={classes.noResult}>
      <div>
        <SentimentDissatisfied />
      </div>
      {emptyListMessage}
    </div>
  );
};

export function Table<RowData>({
  config,
  isLoaded,
  data,
  selectionStatus,
  selectAll,
  paginationData,
}: AllProps<RowData>) {
  const noResult = isLoaded && (!data || data.length === 0);
  return (
    <>
      {noResult ? (
        <NoResultMsg />
      ) : (
        <>
          {isLoaded && data ? (
            <MaterialTable aria-labelledby='tableTitle'>
              <TableHeader
                {...{ config, paginationData }}
                selectionStatus={selectionStatus}
                selectAll={selectAll}
              />
              <TableBody data={data} {...{ config, paginationData }} />
            </MaterialTable>
          ) : (
            <CircularLoading />
          )}
        </>
      )}
    </>
  );
}

export default Table;
