import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { selectUserUuid } from 'store/selectors';
import { TrackSubmissionsFilters } from './TrackSubmissionsFilters';
import { TableConfigEntry } from 'utils/tableConfig';
import InternalLink from 'components/Link/InternalLink';
import { get } from 'lodash';
import ArtistIndications from 'pages/Artists/ArtistIndications';
import { getReadableDate } from 'utils/utils';
import TrackSubmissionsActions from 'pages/Submissions/TrackSubmissionsActions';
import { useNlSelector } from 'utils/redux';
import { useTrackSubmissions } from 'novaApi/hooks/new_track_submission';

const useStyles = makeStyles(styles);

const removeRevisionFlag = (track: Nl.Api.TrackSubmission) => {
  delete track.is_revisable;
  return track;
};

export const config: TableConfigEntry<Nl.Api.TrackSubmission> = {
  columnData: [
    {
      id: 'name',
      label: 'Track Name',
      isSortable: true,
      render: (obj) => (
        <InternalLink
          color='inherit'
          to={{
            type: 'route/TRACK_SUBMISSION_EDIT_PAGE',
            payload: { uuid: obj.uuid },
          }}
        >
          {obj.name}
        </InternalLink>
      ),
    },
    {
      id: 'artist',
      label: 'Artist',
      cellProps: { style: { minWidth: '250px' } },
      render: (obj) => (
        <InternalLink
          color='inherit'
          to={{
            type: 'route/ARTIST_EDIT',
            payload: { uuid: get(obj, 'artist.uuid') },
          }}
        >
          {get(obj, 'artist.name', '')}
        </InternalLink>
      ),
    },
    {
      id: 'indications',
      label: 'Indications',
      cellProps: { style: { minWidth: '250px' } },
      render: (obj) => (
        <ArtistIndications
          isRoyalty={get(obj, 'artist.is_royalty', false)}
          isPROFree={get(obj, 'artist.is_pro_free', false)}
        />
      ),
    },
    { id: 'status', label: 'Status', cellProps: { style: { width: '250px' } } },
    {
      id: 'updated_at',
      label: 'Updated At',
      isSortable: true,
      render: (obj) => getReadableDate(obj.updated_at),
    },
    {
      id: 'actions',
      label: 'Actions',
      cellProps: { style: { width: '250px' } },
      render: (obj) => (
        <TrackSubmissionsActions track={removeRevisionFlag(obj)} />
      ),
    },
  ],
};

const TrackSubmissionsPage = () => {
  const classes = useStyles({});

  const data = useTrackSubmissions();
  const userUuid = useNlSelector(selectUserUuid)!;

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Track Submissions'
        filters={<TrackSubmissionsFilters {...{ userUuid }} />}
      />
      <Table
        config={config}
        data={data?.new_track_submissions ?? []}
        isLoaded={!!data}
        paginationData={data}
      />
    </Paper>
  );
};

export default TrackSubmissionsPage;
