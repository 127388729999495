import React from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TrackSubmissionPlayer from './TrackSubmissionPlayer';
import theme from 'assets/theme';
import { DesktopWrapper, useGetScreenSize } from 'components/ResponsiveLayout';
import { ClassNameMap } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  header: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    padding: '.75rem',
    color: theme.palette.secondary.dark,
  },
  body: {
    padding: '.75rem',
  },
  tableCell: {
    padding: ({ isMobile }: any) => isMobile && '0px',
  },
}));

interface AllProps {
  sourceFiles?: Nl.Api.SubmissionSourceFile[];
}

const playerForFile = (
  sourceFile: Nl.Api.SubmissionSourceFile,
  classes: ClassNameMap,
) => (
  <TableRow key={sourceFile.uuid}>
    <TableCell className={classes.tableCell}>
      <TrackSubmissionPlayer key={sourceFile.uuid} sourceFile={sourceFile} />
    </TableCell>
  </TableRow>
);

const TrackSubmissionsTable: React.SFC<AllProps> = ({ sourceFiles = [] }) => {
  const { isMobile } = useGetScreenSize();
  const classes = useStyles({ isMobile });

  return (
    <>
      <DesktopWrapper>
        <div className={classes.header}>
          <Grid container alignItems='center' justify='space-around'>
            <Grid item xs={2}>
              <Typography variant='subtitle2'>Info</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle2'>Preview</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='subtitle2'>Created At</Typography>
            </Grid>
          </Grid>
        </div>
      </DesktopWrapper>
      <TableContainer>
        <Table>
          <TableBody>
            {sourceFiles.map((sourceFile) =>
              playerForFile(sourceFile, classes),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TrackSubmissionsTable;
