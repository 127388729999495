import * as React from 'react';
import classnames from 'classnames';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignRight: {
      textAlign: 'right',
    },
    container: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    timeline: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      borderLeft: '1px solid lightgrey',
    },
    timelineAlignRight: {
      borderLeft: 'none',
      borderRight: '1px solid lightgrey',
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  }),
);

interface Props<Item> {
  className?: string;
  spaceAround?: boolean;
  alignRight?: boolean;
  title: string;
  emptyMessage: string;
  items: Item[];
  componentBuilder: (item: Item, idx: number) => React.ReactNode;
}

export default function UserLogList<Item>({
  className,
  alignRight,
  spaceAround,
  items,
  title,
  emptyMessage,
  componentBuilder,
}: Props<Item>) {
  const classes = useStyles();

  return (
    <Paper
      className={classnames(
        className,
        alignRight && classes.alignRight,
        spaceAround && classes.container,
      )}
      elevation={spaceAround ? 1 : 0}
    >
      <Typography variant='h6' className={classes.title}>
        {title}
      </Typography>
      {items.length > 0 ? (
        <div
          className={classnames(
            classes.timeline,
            alignRight && classes.timelineAlignRight,
          )}
        >
          {items.map(componentBuilder)}
        </div>
      ) : (
        <Typography variant='caption'>{emptyMessage}</Typography>
      )}
    </Paper>
  );
}
