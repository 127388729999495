import {
  put,
  call,
  select,
  takeLatest,
  takeEvery,
  all,
} from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from 'utils/storeUtils';
import {
  apiFetch,
  getUrlPayloadParams,
  getUrlQueryParams,
  convertQueryObjToUrlString,
  FetchResponse,
} from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import onRouteSagas from './onRouteSagas';

import { goPreviousPage } from 'store/router';

// Action Constants
const FETCH_SINGLE_SONG_COLLECTION = 'FETCH_SINGLE_SONG_COLLECTION';
const FETCH_SINGLE_SONG_COLLECTION_SUCCESS =
  'FETCH_SINGLE_SONG_COLLECTION_SUCCESS';
const FETCH_SINGLE_SONG_COLLECTION_ERROR = 'FETCH_SINGLE_SONG_COLLECTION_ERROR';
const FETCH_SONG_COLLECTIONS = 'FETCH_SONG_COLLECTIONS';
const FETCH_SONG_COLLECTIONS_SUCCESS = 'FETCH_SONG_COLLECTIONS_SUCCESS';
const FETCH_SONG_COLLECTIONS_ERROR = 'FETCH_SONG_COLLECTIONS_ERROR';
const CREATE_SONG_COLLECTION = 'CREATE_SONG_COLLECTION';
const CREATE_SONG_COLLECTION_ERROR = 'CREATE_SONG_COLLECTION_ERROR';
const EDIT_SONG_COLLECTION = 'EDIT_SONG_COLLECTION';
const EDIT_SONG_COLLECTION_SUCCESS = 'EDIT_SONG_COLLECTION_SUCCESS';
const EDIT_SONG_COLLECTION_ERROR = 'EDIT_SONG_COLLECTION_ERROR';
const DELETE_SONG_COLLECTION = 'DELETE_SONG_COLLECTION';
const DELETE_SONG_COLLECTION_SUCCESS = 'DELETE_SONG_COLLECTION_SUCCESS';
const DELETE_SONG_COLLECTION_ERROR = 'DELETE_SONG_COLLECTION_ERROR';
const FETCH_SONG_COLLECTION_CONSTANTS = 'FETCH_SONG_COLLECTION_CONSTANTS';
const FETCH_SONG_COLLECTION_CONSTANTS_SUCCESS =
  'FETCH_SONG_COLLECTION_CONSTANTS_SUCCESS';
const FETCH_SONG_COLLECTION_ENTITIES = 'FETCH_SONG_COLLECTION_ENTITIES';
const FETCH_SONG_COLLECTION_ENTITIES_SUCCESS =
  'FETCH_SONG_COLLECTION_ENTITIES_SUCCESS';
const FETCH_SONG_COLLECTION_ENTITIES_ERROR =
  'FETCH_SONG_COLLECTION_ENTITIES_ERROR';

// Action Creators
export const fetchSingleSongCollection = createAction(
  FETCH_SINGLE_SONG_COLLECTION,
);
const fetchSingleSongCollectionSuccess = createAction(
  FETCH_SINGLE_SONG_COLLECTION_SUCCESS,
);
const fetchSingleSongCollectionError = createAction(
  FETCH_SINGLE_SONG_COLLECTION_ERROR,
);
export const fetchSongCollections = createAction(FETCH_SONG_COLLECTIONS);
const fetchSongCollectionsSuccess = createAction(
  FETCH_SONG_COLLECTIONS_SUCCESS,
);
const fetchSongCollectionsError = createAction(FETCH_SONG_COLLECTIONS_ERROR);
export const createSongCollection = createAction(CREATE_SONG_COLLECTION);
const createSongCollectionError = createAction(CREATE_SONG_COLLECTION_ERROR);
export const editSongCollection = createAction(EDIT_SONG_COLLECTION);
const editSongCollectionSuccess = createAction(EDIT_SONG_COLLECTION_SUCCESS);
const editSongCollectionError = createAction(EDIT_SONG_COLLECTION_ERROR);
export const deleteSongCollection = createAction(DELETE_SONG_COLLECTION);
const deleteSongCollectionSuccess = createAction(
  DELETE_SONG_COLLECTION_SUCCESS,
);
const deleteSongCollectionError = createAction(DELETE_SONG_COLLECTION_ERROR);
export const fetchSongCollectionConstants = createAction(
  FETCH_SONG_COLLECTION_CONSTANTS,
);
export const fetchSongCollectionConstantsSuccess = createAction(
  FETCH_SONG_COLLECTION_CONSTANTS_SUCCESS,
);
export const fetchSongCollectionEntities = createAction(
  FETCH_SONG_COLLECTION_ENTITIES,
);
const fetchSongCollectionEntitiesSuccess = createAction(
  FETCH_SONG_COLLECTION_ENTITIES_SUCCESS,
);
const fetchSongCollectionEntitiesError = createAction(
  FETCH_SONG_COLLECTION_ENTITIES_ERROR,
);

// Selector
export const getEditFormInitialValues = (
  songCollection?: Nl.Api.SongCollection,
): { uuid: string; name: string } => ({
  uuid: songCollection?.uuid ?? '',
  name: songCollection?.name ?? '',
});

// Reducer
const initialState = {
  data: {
    song_collection: undefined as Nl.Api.SongCollection | undefined,
    song_collections: [] as Nl.Api.SongCollection[],
    total_size: 0,
    current_size: undefined as number | undefined,
    current_page: undefined as number | undefined,
    offset: undefined as number | undefined,
    total_pages: undefined as number | undefined,
  } as Nl.Api.SongCollectionsResponse & Partial<Nl.Api.SongCollectionResponse>,
  isLoading: false,
  isLoaded: false,
  entities: [] as Nl.Api.SelectorEntity[],
};

export type SongCollectionsState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_SONG_COLLECTIONS:
    case FETCH_SINGLE_SONG_COLLECTION:
    case FETCH_SONG_COLLECTION_ENTITIES: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SONG_COLLECTIONS_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    case FETCH_SINGLE_SONG_COLLECTION_SUCCESS:
    case EDIT_SONG_COLLECTION_SUCCESS: {
      return assign(state, {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        isLoading: false,
      });
    }

    case FETCH_SONG_COLLECTION_ENTITIES_SUCCESS: {
      return assign(state, {
        entities: action.payload.data.song_collections,
        isLoading: false,
      });
    }

    case FETCH_SONG_COLLECTIONS_ERROR:
    case FETCH_SINGLE_SONG_COLLECTION_ERROR:
    case FETCH_SONG_COLLECTION_ENTITIES_ERROR: {
      return assign(state, {
        isLoading: false,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSingleSongCollectionSaga() {
    const { uuid } = yield select(getUrlPayloadParams);
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/song_collection/${uuid}`,
    );
    if (results.success) {
      yield put(fetchSingleSongCollectionSuccess({ data: results.data }));
    } else {
      const message = results.errors?.name || results.msg;
      yield put(fetchSingleSongCollectionError());
      yield put(addErrorNotification({ message }));
    }
  },
  *fetchSongCollectionsSaga(action: SimpleActionType) {
    const queryParams: string =
      action && action.payload
        ? convertQueryObjToUrlString(action.payload)
        : yield select(getUrlQueryParams);
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/song_collection${queryParams}`,
    );
    if (results.success) {
      yield put(fetchSongCollectionsSuccess({ data: results.data }));
    } else {
      const message = results.errors?.name || results.msg;
      yield put(fetchSongCollectionsError({ error: message }));
      yield put(addErrorNotification({ message }));
    }
  },
  *fetchSongCollectionEntitiesSaga() {
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/song_collection/select',
    );
    if (results.success) {
      yield put(fetchSongCollectionEntitiesSuccess({ data: results.data }));
    } else {
      const message = results.errors?.name || results.msg;
      yield put(fetchSongCollectionEntitiesError({ error: message }));
      yield put(addErrorNotification({ message }));
    }
  },
  *createSongCollectionSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/song_collection',
      {
        method: 'POST',
        body: { ...formData },
      },
    );
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been created`,
        }),
      );
      yield put(goPreviousPage());
    } else {
      yield put(createSongCollectionError(results.msg));
      if (results.errors.name) {
        yield put(addErrorNotification({ message: results.errors.name }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
    }
    formActions.setSubmitting(false);
  },
  *editSongCollectionSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/song_collection/${formData.uuid}`,
      {
        method: 'PUT',
        body: { ...formData },
      },
    );
    if (results.success) {
      yield put(editSongCollectionSuccess({ data: results.data }));
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been updated`,
        }),
      );
    } else {
      yield put(editSongCollectionError(results.msg));
      if (results.errors.name) {
        yield put(addErrorNotification({ message: results.errors.name }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
    }
    formActions.setSubmitting(false);
  },
  *deleteSongCollectionSaga(action: SimpleActionType) {
    try {
      yield call(apiFetch, `/song_collection/${action.payload.uuid}`, {
        method: 'DELETE',
      });
      yield put(deleteSongCollectionSuccess());
      yield call(onRouteSagas.onSongCollectionRoute);
      yield put(
        addSuccessNotification({
          message: 'The Song Collection has been deleted',
        }),
      );
    } catch (error) {
      yield put(deleteSongCollectionError());
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the Song Collection',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(
      FETCH_SINGLE_SONG_COLLECTION,
      sagas.fetchSingleSongCollectionSaga,
    ),
    takeLatest(FETCH_SONG_COLLECTIONS, sagas.fetchSongCollectionsSaga),
    takeLatest(
      FETCH_SONG_COLLECTION_ENTITIES,
      sagas.fetchSongCollectionEntitiesSaga,
    ),
    takeEvery(CREATE_SONG_COLLECTION, sagas.createSongCollectionSaga),
    takeLatest(EDIT_SONG_COLLECTION, sagas.editSongCollectionSaga),
    takeLatest(DELETE_SONG_COLLECTION, sagas.deleteSongCollectionSaga),
  ]);
}

export { sagas };
export default reducer;
