// eslint-disable-next-line import/extensions
const WavValidationWorker = require('./wavAnalyzer.worker.ts');

export interface WavAnalyzerResult {
  isValid: boolean;
  msg: string;
}

/**
 * The wavAnalyzer worker do the wav validation in a web worker and return the result as a promise
 */
export default async function analyseWavWorker(
  file: File,
  requirements: Nl.WavRequirements,
): Promise<WavAnalyzerResult> {
  // use webWorker to do the wav/wav validation.
  const wavWorker = new WavValidationWorker();

  wavWorker.postMessage({
    fileContent: file,
    fileName: file.name,
    validationParams: requirements,
  });

  return new Promise<WavAnalyzerResult>((resolve, reject) => {
    wavWorker.onmessage = (event: { data: WavAnalyzerResult }) => {
      const { isValid, msg } = event.data;
      resolve({ isValid, msg });
    };
    wavWorker.onerror = (err: Error) => {
      reject(err);
    };
  });
}
