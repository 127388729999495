import React from 'react';
import { useDispatch } from 'react-redux';
import { selectIsLoaded, selectSingleArtistSubmission } from 'store/selectors';
import FormikWrapper from 'components/Formik';
import NotesWrapper from 'components/Notes/NotesWrapper';
import ArtistIndications from 'pages/Artists/ArtistIndications';
import TrackSubmissonsTable from './TrackSubmissionsTable';
import ArtistSubmissionForm from './ArtistSubmissionForm';
import ArtistSubmissionsActions from './ArtistSubmissionsActions';
import { editArtistSubmission } from 'store/singleArtistSubmission';
import { buildFormTitle, formatAsForm } from './ArtistSubmissionsData';
import { editArtistSubmissionSchema } from './validation';
import { useNlSelector } from 'utils/redux';

const ArtistSubmissionEditPage = () => {
  const dispatch = useDispatch();

  const artistSubmission = useNlSelector(selectSingleArtistSubmission);
  const isLoaded = useNlSelector((state) =>
    selectIsLoaded(state, 'singleArtistSubmission'),
  );

  const parentType: Nl.ParentNoteType = 'new_artist_submission';
  if (!isLoaded) {
    return null;
  }

  return (
    <FormikWrapper
      initialValues={formatAsForm(artistSubmission)}
      validationSchema={editArtistSubmissionSchema}
      submitForm={(formData, formActions) =>
        dispatch(editArtistSubmission({ formData, formActions }))
      }
      title={buildFormTitle(artistSubmission)}
      customSize='lg'
      extraActions={
        <>
          <ArtistIndications isPROFree={!artistSubmission.is_pro_member} />
          <ArtistSubmissionsActions
            artistSubmission={artistSubmission}
            isForm
          />
        </>
      }
    >
      <TrackSubmissonsTable sourceFiles={artistSubmission.source_files} />
      <br />
      <ArtistSubmissionForm isOnboardable={artistSubmission.is_onboardable} />
      <br />
      <NotesWrapper config={parentType} uuid={artistSubmission.uuid} />
    </FormikWrapper>
  );
};

export default ArtistSubmissionEditPage;
