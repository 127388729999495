import { useNovaApi, useNovaApiCreate, useNovaApiUpdate } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { useDispatch } from 'react-redux';
import { addSuccessNotification } from 'store/notifications';

export const useTagTermRelationTypes = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.TagTermRelationTypesResponse>(
    `/tag_term_relation_type`,
    {
      query,
    },
  );
};

export const useTagTermRelationTypeSelect = () => {
  return (
    useNovaApi<Nl.Api.TagTermRelationTypeSelectResponse>(
      '/tag_term_relation_type/select',
    ).data?.tag_term_relation_types || []
  );
};

export const useTagTermRelationTypeCreate = () => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiCreate<
    Nl.Api.TagTermRelationTypeResponse,
    Nl.Api.TagTermRelationType
  >('/tag_term_relation_type', (createdTagTermRelationType) => {
    dispatch(
      addSuccessNotification({
        message: `Tag-Term Relation Type ${createdTagTermRelationType.tag_term_relation_type.name} has been created`,
      }),
    );
  });

  return {
    createTagTermRelationType: mutation.mutate,
    isSuccess,
    createdTagTermRelationType: data,
  };
};

export const useTagTermRelationTypeUpdate = (uuid: string) => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiUpdate<
    Nl.Api.TagTermRelationTypeResponse,
    Nl.Api.TagTermRelationType
  >(`/tag_term_relation_type/${uuid}`, (updatedTagTermRelationType) => {
    dispatch(
      addSuccessNotification({
        message: `Tag-Term Relation Type ${updatedTagTermRelationType.tag_term_relation_type.name} has been updated`,
      }),
    );
  });

  return {
    updateTagTermRelationType: mutation.mutate,
    updatedTagTermRelationType: data,
    isSuccess,
  };
};

export const useTagTermRelationType = (uuid: string) => {
  return useNovaApi<Nl.Api.TagTermRelationTypeResponse>(
    `/tag_term_relation_type/${uuid}`,
  );
};
