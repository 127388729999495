import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Card, Theme, createStyles, makeStyles } from '@material-ui/core';
import { selectMyProfile, selectIsLoaded } from 'store/selectors';
import { setFirstProfile } from 'store/register';
import { formatAsForm } from 'pages/MyProfile/EditMyProfileData';
import { editMyProfileValidationSchema } from 'pages/MyProfile/validation';
import RegistrationMyProfileForm from './RegistrationMyProfileForm';
import { useNlSelector } from 'utils/redux';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      marginTop: theme.spacing(8),
      maxWidth: theme.spacing(125),
    },
  });
const useStyles = makeStyles(styles);

const RegistrationMyProfilePage = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const myProfile = useNlSelector(selectMyProfile);
  const isLoaded = useNlSelector((state) => selectIsLoaded(state, 'myProfile'));

  if (!isLoaded || !myProfile) {
    return null;
  }
  return (
    <Card className={classes.root}>
      <Formik
        initialValues={formatAsForm(myProfile)}
        validationSchema={editMyProfileValidationSchema}
        onSubmit={(formData, formActions) => {
          dispatch(setFirstProfile({ formData, formActions }));
        }}
      >
        {(formikProps) => <RegistrationMyProfileForm {...formikProps} />}
      </Formik>
    </Card>
  );
};

export default RegistrationMyProfilePage;
