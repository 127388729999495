import * as React from 'react';
import { connect } from 'react-redux';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { seekAudioTrack } from 'store/audioPlayer';
import { GlobalState } from 'store/rootReducer';
import { getColor } from 'utils/songSourceFile';

const Waveform = require('@shutterstock/waveform').default;

const defaultTheme = (color: string) => ({
  dividerHeight: 5,
  borderHeight: 1,
  ghostOpacity: 0.75,

  bars: {
    width: 1,
    gap: 0,
    maxAmplitude: 1,
  },
  progressBar: {
    width: 2,
    color: '#6c6c6c',
    ghostOpacity: 1,
  },
  activeColors: {
    topBars: [color, color],
    bottomBars: [lighten(color, 0.5), lighten(color, 0.5)],
    background: 'white',
    borders: 'white',
    divider: '#866f3b',
  },
  offColors: {
    topBars: '#6c6c6c',
    bottomBars: '#828282',
    background: 'white',
    borders: 'white',
    divider: '#666',
  },
});

interface OwnProps {
  uuid: string;
  type: 'song' | Nl.SongSourceFileType;
  className?: string;
  isLoop?: boolean;
  displayLoopOnce?: boolean;
  onSeek?(position: number): void;
}

interface StateProps {
  dataPoints: number[];
  isPlaying: boolean;
  position: number;
  duration: number;
}

interface DispatchProps {
  playAtPosition: (position: number) => void;
}

type AllProps = OwnProps & DispatchProps & StateProps;

type OwnState = {
  ghostProgress?: number;
};

const getMouseProgress = ({
  pageX,
  currentTarget,
}: React.MouseEvent<HTMLElement>) => {
  const offset = currentTarget.getBoundingClientRect().left;
  return (pageX - offset) / currentTarget.offsetWidth;
};

const getTouchProgress = ({
  touches,
  currentTarget,
}: React.TouchEvent<HTMLElement>) => {
  const offset = currentTarget.getBoundingClientRect().left;
  return (touches[0].pageX - offset) / currentTarget.offsetWidth;
};

class SongWaveform extends React.PureComponent<AllProps, OwnState> {
  state = {
    ghostProgress: undefined,
  };

  setProgress = (progress: number) => {
    const { duration } = this.props;
    if (this.props.onSeek && duration) {
      this.props.onSeek(duration * progress);
    }
  };

  setGhostProgress = (pageX: number, targetElement: HTMLElement) => {
    const offset = targetElement.getBoundingClientRect().left;
    this.setState({
      ghostProgress: (pageX - offset) / targetElement.offsetWidth,
    });
  };

  handleMouse: React.MouseEventHandler<HTMLElement> = (event) => {
    this.setGhostProgress(event.clientX, event.currentTarget);
  };

  handleMouseLeave: React.MouseEventHandler<HTMLElement> = () => {
    this.setState({ ghostProgress: undefined });
  };

  handleMouseUp: React.MouseEventHandler<HTMLElement> = (e) => {
    this.setProgress(getMouseProgress(e));
    this.setState({ ghostProgress: undefined });
  };

  handleTouch: React.TouchEventHandler<HTMLElement> = (e) => {
    this.setProgress(this.state.ghostProgress!);
    this.setState({
      ghostProgress: getTouchProgress(e),
    });
  };

  handleTouchEnd: React.TouchEventHandler<HTMLElement> = (e) => {
    if (this.state.ghostProgress !== undefined) {
      this.setState({ ghostProgress: undefined });
    }
  };

  render() {
    const {
      className,
      type,
      dataPoints,
      isPlaying,
      position,
      duration,
      isLoop,
      onSeek,
      displayLoopOnce,
    } = this.props;
    const { ghostProgress } = this.state;

    // Get the waveform color based on the file type (full/loop/short...)
    const color = getColor(type);

    // The duration here is the wav file duration
    // If it's a loop, it is the concatenation of 3 times the original loop file
    // So in this case, if we want to display the loop once we have to divide its duration by 3
    const processedDuration =
      isLoop && displayLoopOnce ? duration / 3 : duration;

    const loopIteration = Math.floor(position / processedDuration);
    const progress =
      (position - processedDuration * loopIteration) / processedDuration;

    // If no data points, fall back to a constant number which renders as a straight line
    // This acts as the background of the scrub bar. Changing the fallback value adjusts the
    // vertical width of the line proportionally
    const fallbackDatapoint = 0.2;
    let waveformData = [fallbackDatapoint];
    let isFallback = true;
    if (dataPoints) {
      waveformData = dataPoints;
      isFallback = false;
    }

    return (
      <div className={className}>
        <Waveform
          data={waveformData}
          ghostProgress={onSeek || isPlaying ? ghostProgress : 0}
          height={44}
          width='100%'
          isLoop={isLoop && !displayLoopOnce}
          getProgress={() => (isPlaying ? progress : 100)}
          theme={defaultTheme(color)}
          onMouseMove={onSeek && this.handleMouse}
          onMouseEnter={onSeek && this.handleMouse}
          onMouseLeave={onSeek && this.handleMouseLeave}
          onMouseUp={onSeek && this.handleMouseUp}
          onTouchStart={onSeek && this.handleTouch}
          onTouchMove={onSeek && this.handleTouch}
          onTouchEnd={onSeek && this.handleTouchEnd}
          data-e2e={isFallback ? 'waveform-fallback' : 'waveform'}
        />
      </div>
    );
  }
}

export default connect(
  (state: GlobalState, ownProps: OwnProps) => {
    const isCurrentPlayingSong =
      state.audioPlayer.track && state.audioPlayer.track.uuid === ownProps.uuid;
    return {
      dataPoints:
        state.waveform[ownProps.uuid] && state.waveform[ownProps.uuid].data,
      isPlaying: isCurrentPlayingSong,
      duration: isCurrentPlayingSong && state.audioPlayer.duration,
      position: isCurrentPlayingSong && state.audioPlayer.position,
    } as StateProps;
  },
  (dispatch) => ({
    playAtPosition: (position: number) => {
      dispatch(seekAudioTrack({ position }));
    },
  }),
)(SongWaveform);
