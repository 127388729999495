const PROTOCOL = process.env.REACT_APP_PROTOCOL || 'http';
const NOVA_HOSTNAME = process.env.REACT_APP_API_SERVICE_HOST || 'localhost';
const NOVA_PORT = process.env.REACT_APP_API_SERVICE_PORT || '5000';

const NOVA_API_URL = `${PROTOCOL}://${NOVA_HOSTNAME}:${NOVA_PORT}`;
const NOVA_API_VERSION = 'v1';

const disableGa: boolean =
  process.env.REACT_APP_GOOGLE_ANALYTICS_DISABLE === 'true';

export default {
  clients: {
    api: {
      url: `${NOVA_API_URL}/${NOVA_API_VERSION}`,
      defaultPageSize: 25,
      maxPageSize: 1000,
      pageSizeOptions: [25, 50, 100, 200],
    },
  },
  websocket: {
    enabled: false,
    url: 'http://127.0.0.1/ws-no-exist',
    userActivityEventPrefix: 'user_activity',
  },
  app: {
    loginPermissionKey: [
      'CAN_LOGIN_TO_NOVALIBRARY',
      'CAN_LOGIN_TO_ARTIST_PORTAL',
    ],
    asyncTypingTimeout: 400, // milliseconds
  },
  ga: {
    domains: (window as any)['ga-linker-domains'],
    id: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '',
    disable: disableGa,
  },
};
