import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getEditFormInitialValues, createSong } from 'store/singleSong';
import FormikWrapper from 'components/Formik';
import SongForm from './SongForm';
import SongEditActionRequired from './SongEditActionRequired';
import SongSyncWarning from './SongSyncWarning';
import validationSchema from './validation';

export default function SongCreatePage() {
  const dispatch = useDispatch();

  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues()}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) =>
        dispatch(createSong({ formData, formActions }))
      }
      title='Create Song'
      customSize='xl'
    >
      <SongSyncWarning />
      <SongEditActionRequired />
      <SongForm />
    </FormikWrapper>
  );
}
