import * as React from 'react';
import { connect } from 'react-redux';
import { FastField, Field, FieldArray, Form, useFormikContext } from 'formik';
import { Button, Grid, InputAdornment, Typography } from '@material-ui/core';
import { GlobalState } from 'store/rootReducer';
import {
  selectCurationStates,
  selectGenres,
  selectHiddenTags,
  selectInstruments,
  selectOriginalComposers,
  selectPermission,
  selectPublisherRulesets,
  selectPublishers,
  selectSongCollections,
  selectSongUUID,
  selectSourceFileUploads,
  selectTags,
  selectThemes,
  selectVersionTypes,
} from 'store/selectors';
import SongEditPublisher from './SongEditPublisher';
import SongEditWriter from './SongEditWriter';
import SourceFilesTable from './EditSourceFile/SourceFilesTable';
import InputField from 'components/Formik/InputField';
import DuplicatedWorkTitleWarning from './DuplicatedWorkTitleWarning';
import {
  multipleSelectFormat,
  selectIdenticalValueLabelFormat,
} from 'utils/utils';
import { FormSubTitle, InputWrapper } from 'components/Formik/utils';
import { isEmpty } from 'lodash';
import { readOnlyStyle } from 'styles/form';
import { useArtistEntities } from 'novaApi/hooks/artist';
import { FieldAttributes } from 'formik/dist/Field';
import { useContentTiersSelect } from 'novaApi/hooks/content_tier';
import Typeahead from 'components/Typeahead/Typeahead';
import {
  useTagFieldEntitiesDescriptive,
  useTagFieldEntitiesEmotional,
  useTagFieldEntitiesExtramusic,
  useTagFieldEntitiesInstruments,
  useTagFieldEntitiesLyrics,
} from 'novaApi/hooks/tag_field';
import Checkbox from 'components/Formik/CheckboxField';
import { showRevisionNote } from 'utils/songSourceFile';
import moment from 'moment';
import MailOutlined from '@material-ui/icons/MailOutlined';

interface AllProps {
  isEdit?: boolean;
  uuid: string;
  genres: Nl.Api.SelectorEntity[];
  themes: Nl.Api.SelectorEntity[];
  tags: Nl.Api.SelectorEntity[];
  hiddenTags: Nl.Api.SelectorEntity[];
  instruments: Nl.Api.SelectorEntity[];
  originalComposers: Nl.Api.SelectorEntity[];
  versionTypes: string[];
  curationStates: Nl.Api.SelectorEntity[];
  songCollections: Nl.Api.SelectorEntity[];
  updateWriterSharesPermitted: boolean;
  updatePublisherRulesetPermitted: boolean;
  viewWriterSharesPermitted: boolean;
  viewPublisherRulesetPermitted: boolean;
  publishers: Nl.Api.Publisher[];
  publisherRulesets: Nl.Api.SelectorEntity[];
  sourceFileUploads: Nl.Api.SourceFileUpload[];
  canOnlySetReadyForPublication: boolean;
  canUpdateSourceFiles: boolean;
}

// eslint-disable-next-line complexity
const SongForm: React.SFC<AllProps> = ({
  isEdit,
  uuid,
  genres,
  themes,
  tags,
  hiddenTags,
  instruments,
  originalComposers,
  versionTypes,
  curationStates,
  songCollections,
  publishers,
  publisherRulesets,
  sourceFileUploads,
  canOnlySetReadyForPublication,
  canUpdateSourceFiles,
  updatePublisherRulesetPermitted,
  updateWriterSharesPermitted,
  viewPublisherRulesetPermitted,
  viewWriterSharesPermitted,
}) => {
  const artists = useArtistEntities();
  const contentTiers = useContentTiersSelect(uuid);

  const emotionalTags = useTagFieldEntitiesEmotional();
  const descriptiveTags = useTagFieldEntitiesDescriptive();
  const instrumentTags = useTagFieldEntitiesInstruments();
  const extramusicTags = useTagFieldEntitiesExtramusic();
  const lyricsTags = useTagFieldEntitiesLyrics();

  const { values, submitForm } = useFormikContext<Nl.Api.Song>();
  const showGeneralRevisionNote = values.source_files?.some(showRevisionNote);
  const requestRevisionAllowed = values.source_files?.some(
    ({ status }) => status === 'declined',
  );

  const contentTierField = (
    <Field
      name='content_tier'
      label='Content Tier'
      component={Typeahead}
      options={multipleSelectFormat(contentTiers)}
    />
  );
  return (
    <Form>
      <FormSubTitle title='Basic Information' />
      <InputWrapper>
        <Field
          name='work_title'
          label='Work Title'
          component={InputField}
          data-e2e='workTitleInput'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <DuplicatedWorkTitleWarning />
              </InputAdornment>
            ),
          }}
        />
        {isEdit && (
          <FastField
            name='work_id'
            label='Work ID'
            component={InputField}
            disabled
          />
        )}
      </InputWrapper>
      <InputWrapper>
        <InputWrapper>
          <Field
            name='artist_uuid'
            label='Artist'
            options={multipleSelectFormat(artists)}
            component={Typeahead}
          />
          <FastField
            name='assigned_to_name'
            label='Producer'
            component={InputField}
            disabled
          />
        </InputWrapper>
        {isEdit && (
          <FastField name='isrc' label='ISRC' component={InputField} disabled />
        )}
      </InputWrapper>
      <InputWrapper>
        <Field
          name='version_type'
          label='Version Type'
          options={selectIdenticalValueLabelFormat(versionTypes)}
          component={Typeahead}
        />
        {originalComposers && (
          <Field name='version_type'>
            {({ field }: FieldAttributes<any>) => (
              <Field
                name='original_composer'
                label='Original composer'
                options={multipleSelectFormat(originalComposers)}
                isCreatable
                component={Typeahead}
                getNewOptionData={(value: string) => ({
                  label: value,
                  value,
                })}
                disabled={field.value !== 'Public Domain'}
              />
            )}
          </Field>
        )}
      </InputWrapper>
      <InputWrapper>
        <Field name='bpm' label='BPM' component={InputField} />
        {isEdit && (
          <FastField
            name='duration'
            label='Duration'
            component={InputField}
            disabled
          />
        )}
      </InputWrapper>
      <InputWrapper>
        {isEdit ? (
          <InputWrapper>
            <Field
              name='curation_state'
              label='Curation state'
              component={Typeahead}
              options={multipleSelectFormat(curationStates)}
              disabled={canOnlySetReadyForPublication}
            />
            {contentTierField}
          </InputWrapper>
        ) : (
          contentTierField
        )}
        {songCollections && (
          <Field
            name='song_collection_uuids'
            label='Song Collections'
            component={Typeahead}
            options={multipleSelectFormat(songCollections)}
            isMulti
          />
        )}
      </InputWrapper>
      <br />
      {(updatePublisherRulesetPermitted || viewPublisherRulesetPermitted) && (
        <>
          <FormSubTitle title='Publishers' />
          <InputWrapper>
            <Field
              name='publisher_ruleset_uuid'
              label='Publisher Ruleset'
              component={Typeahead}
              options={multipleSelectFormat(publisherRulesets)}
            />
            {!isEmpty(publishers) && (
              <SongEditPublisher publishers={publishers} />
            )}
          </InputWrapper>
          <br />
        </>
      )}
      {(updateWriterSharesPermitted || viewWriterSharesPermitted) && (
        <>
          <FormSubTitle title='Writers' />
          <FieldArray
            name='writer_shares'
            // @ts-ignore
            component={SongEditWriter}
          />
          <br />
        </>
      )}
      {isEdit && (
        <>
          <FormSubTitle title='Notes' />
          <InputWrapper>
            <Field
              name='artist_notes'
              label="Artist's Submission Notes"
              component={InputField}
              InputProps={readOnlyStyle}
              multiline
              rows={4}
              rowsMax={4}
            />
            <FastField
              name='description_hidden'
              label='Description (Hidden)'
              component={InputField}
              multiline
              rows={4}
              rowsMax={4}
            />
          </InputWrapper>
          <br />
          <br />
        </>
      )}
      <FormSubTitle title='Metadata' />
      {isEdit && (
        <>
          <Typography>Submitted by the artist</Typography>
          <InputWrapper>
            <Field
              name='artist_suggested_description'
              label='Artist-Suggested Description'
              component={InputField}
              multiline
              rows={4}
              rowsMax={4}
              InputProps={readOnlyStyle}
            />
            <Field
              name='artist_suggested_lyrics'
              label='Artist-Suggested Lyrics'
              component={InputField}
              multiline
              rows={4}
              rowsMax={4}
              InputProps={readOnlyStyle}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='artist_suggested_usage'
              label='Artist-Suggested Usage'
              component={InputField}
              multiline
              rows={4}
              rowsMax={4}
              InputProps={readOnlyStyle}
            />
            <Field
              name='artist_suggested_instruments'
              label='Artist-Suggested Instruments'
              component={InputField}
              multiline
              rows={4}
              rowsMax={4}
              InputProps={readOnlyStyle}
            />
          </InputWrapper>
          <br />
          <br />
          <Typography>Completed by the tagging team</Typography>
        </>
      )}
      {isEdit && (
        <Field
          name='auto_tagging_enabled'
          label='Enable auto-tagging'
          component={Checkbox}
        />
      )}
      {isEdit && (
        <FastField
          name='description'
          label='Description'
          component={InputField}
          multiline
          rows={4}
          rowsMax={4}
        />
      )}
      <Field
        name='genre_uuids'
        label='Genres'
        component={Typeahead}
        options={multipleSelectFormat(genres)}
        isMulti
      />
      <Field
        name='theme_uuids'
        label='Themes'
        component={Typeahead}
        options={multipleSelectFormat(themes)}
        isMulti
      />
      <Field
        name='instrument_uuids'
        label='Instruments'
        component={Typeahead}
        options={multipleSelectFormat(instruments)}
        isMulti
      />
      <Field
        name='tags'
        label='Tags'
        component={Typeahead}
        options={multipleSelectFormat(tags)}
        isMulti
        isCreatable
        getNewOptionData={(value: string) => ({
          label: value,
          value,
        })}
      />
      <Field
        name='tags_hidden'
        label='Tags (Hidden)'
        component={Typeahead}
        options={multipleSelectFormat(hiddenTags)}
        isMulti
        isCreatable
        getNewOptionData={(value: string) => ({
          label: value,
          value,
        })}
      />
      <div>
        Hidden tags will have an effect on search results for certain channels
        but will never be displayed in the UI.
      </div>
      <br />
      {isEdit && (
        <div data-e2e='taxonomyTags'>
          <FormSubTitle title='Emotional Tags' />
          <InputWrapper>
            <Field
              name='taxonomy.mood'
              label='Mood'
              component={Typeahead}
              options={multipleSelectFormat(emotionalTags.moods)}
            />
            <Field
              name='taxonomy.style_substyle'
              label='Style/substyle'
              component={Typeahead}
              options={multipleSelectFormat(emotionalTags.styleSubstyle)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.vibes'
              label='Vibes'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(emotionalTags.vibes)}
            />
            <Field
              name='taxonomy.presence'
              label='Presence'
              component={Typeahead}
              options={multipleSelectFormat(emotionalTags.presence)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.thickness'
              label='Thickness'
              component={Typeahead}
              options={multipleSelectFormat(emotionalTags.thickness)}
            />
            <Field
              name='taxonomy.expectancy'
              label='Expectancy'
              component={Typeahead}
              options={multipleSelectFormat(emotionalTags.expectancy)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.tones'
              label='Tones'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(emotionalTags.tones)}
            />
            <Field
              name='taxonomy.acoustic_electric'
              label='Acoustic/Electric'
              component={Typeahead}
              options={multipleSelectFormat(emotionalTags.acousticElectric)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.eras'
              label='Eras'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(emotionalTags.eras)}
            />
          </InputWrapper>
          <br />
          <FormSubTitle title='Descriptive Tags' />
          <InputWrapper>
            <Field
              name='taxonomy.type'
              label='Type(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.types)}
            />
            <Field
              name='taxonomy.location'
              label='Location(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.locations)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.culture_religion'
              label='Culture(s) & religion(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.culturesReligions)}
            />
            <Field
              name='taxonomy.swing'
              label='Swing'
              component={Typeahead}
              options={multipleSelectFormat(descriptiveTags.swing)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.sfx'
              label='SFX(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.sfxs)}
            />
            <Field
              name='taxonomy.effects_processing'
              label='Effects & processing'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.effectsProcessings)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.pitch'
              label='Pitch(es)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.pitches)}
            />
            <Field
              name='taxonomy.articulation'
              label='Articulation(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.articulations)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.rhythm'
              label='Rhythm(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.rhythms)}
            />
            <Field
              name='taxonomy.background_texture'
              label='Background texture(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.backgroundTextures)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.event'
              label='Event(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.events)}
            />
            <Field
              name='taxonomy.melody_contour'
              label='Melody & contour(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(descriptiveTags.melodyContours)}
            />
          </InputWrapper>
          <br />
          <FormSubTitle title='Lyrics' />
          <InputWrapper>
            <Field
              name='lyrics'
              label='Lyrics'
              component={InputField}
              multiline
              rows={6}
              rowsMax={6}
            />
            <div>
              <InputWrapper>
                <Field
                  name='taxonomy.lyrics_language'
                  label='Lyrics language(s)'
                  component={Typeahead}
                  isMulti
                  options={multipleSelectFormat(lyricsTags.lyricsLanguage)}
                />
                <Field
                  name='taxonomy.lyrics_subject'
                  label='Lyrics subject(s)'
                  component={Typeahead}
                  isMulti
                  options={multipleSelectFormat(lyricsTags.lyricsSubject)}
                />
              </InputWrapper>
              <Field
                name='additional_lyrics_subjects'
                label='Additional lyrics subjects'
                component={InputField}
                multiline
                rows={2}
                rowsMax={2}
              />
            </div>
          </InputWrapper>
          <br />
          <FormSubTitle title='Instrument Tags' />
          <InputWrapper>
            <Field
              name='taxonomy.ensemble'
              label='Ensemble(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.ensemble)}
            />
            <Field
              name='taxonomy.nonstandard_instrument'
              label='Nonstandard instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(
                instrumentTags.nonstandardInstrument,
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.guitar_bass_instrument'
              label='Guitar/bass instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(
                instrumentTags.guitarBassInstrument,
              )}
            />
            <Field
              name='taxonomy.guitar_bass_behavior'
              label='Guitar/bass behavior(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.guitarBassBehavior)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.brass_instrument'
              label='Brass instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.brassInstrument)}
            />
            <Field
              name='taxonomy.brass_behavior'
              label='Brass behavior(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.brassBehavior)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.keyboard_instrument'
              label='Keyboard instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.keyboardInstrument)}
            />
            <Field
              name='taxonomy.keyboard_behavior'
              label='Keyboard behavior(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.keyboardBehavior)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.percussion_instrument'
              label='Percussion instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(
                instrumentTags.percussionInstrument,
              )}
            />
            <Field
              name='taxonomy.percussion_behavior'
              label='Percussion behavior(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.percussionBehavior)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.string_instrument'
              label='String instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.stringInstrument)}
            />
            <Field
              name='taxonomy.string_behavior'
              label='String behavior(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.stringBehavior)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.synth_instrument'
              label='Synth instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.synthInstrument)}
            />
            <Field
              name='taxonomy.synth_behavior'
              label='Synth behavior(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.synthBehavior)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.voice_instrument'
              label='Voice instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.voiceInstrument)}
            />
            <Field
              name='taxonomy.voice_behavior'
              label='Voice behavior(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.voiceBehavior)}
            />
          </InputWrapper>
          <InputWrapper>
            <Field
              name='taxonomy.woodwind_instrument'
              label='Woodwind instrument(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.woodwindInstrument)}
            />
            <Field
              name='taxonomy.woodwind_behavior'
              label='Woodwind behavior(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(instrumentTags.woodwindBehavior)}
            />
          </InputWrapper>
          <br />
          <FormSubTitle title='Extramusic Tags' />
          <Field
            name='taxonomy.creative_inspiration'
            label='Creative inspiration(s) '
            component={Typeahead}
            isMulti
            isCreatable
            options={multipleSelectFormat(extramusicTags.creativeInspiration)}
          />
          <InputWrapper>
            <Field
              name='taxonomy.production_use_case'
              label='Film/TV/Game Usage'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(extramusicTags.productionUseCase)}
            />
            <Field
              name='taxonomy.use_case'
              label='Use case(s)'
              component={Typeahead}
              isMulti
              options={multipleSelectFormat(extramusicTags.useCase)}
            />
          </InputWrapper>
        </div>
      )}
      {isEdit && (
        <>
          <br />
          <FieldArray name='source_files'>
            {(props) => (
              // @ts-ignore
              <SourceFilesTable
                {...props}
                songUuid={uuid}
                isEditable={canUpdateSourceFiles}
                sourceFileUploads={sourceFileUploads}
              />
            )}
          </FieldArray>
        </>
      )}
      {showGeneralRevisionNote && (
        <Grid
          container
          alignItems='center'
          spacing={2}
          style={{ marginTop: '1rem' }}
        >
          <Grid item xs={10}>
            <Field
              name='revision_note'
              label='Revision Note'
              data-e2e='song-revision-note'
              component={InputField}
              InputProps={{
                readOnly: false,
                multiline: true,
                rows: 4,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container alignItems='center' direction='column'>
              <Grid item xs={12}>
                <Button
                  data-e2e='revision-button'
                  onClick={async () => {
                    values.request_revision = true;
                    await submitForm();
                  }}
                  disabled={!requestRevisionAllowed}
                >
                  <MailOutlined style={{ marginRight: '0.5rem' }} />
                  Request artist revision
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography data-e2e='inspection-state'>
                  Status: {values.inspection_state}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {values.revision_requested_at && (
                  <Typography data-e2e='revision-requested-at'>
                    {`Last requested: ${moment
                      .utc(values.revision_requested_at)
                      .local()
                      .format('D MMM YYYY LT')}`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  uuid: selectSongUUID(state),
  genres: selectGenres(state),
  themes: selectThemes(state),
  tags: selectTags(state),
  hiddenTags: selectHiddenTags(state),
  instruments: selectInstruments(state),
  originalComposers: selectOriginalComposers(
    state,
    'CAN_GET_ORIGINAL_COMPOSERS',
  ),
  versionTypes: selectVersionTypes(state),
  curationStates: selectCurationStates(state),
  updateWriterSharesPermitted: selectPermission(
    state,
    'CAN_UPDATE_SONG_WRITER_SHARES',
  ),
  updatePublisherRulesetPermitted: selectPermission(
    state,
    'CAN_UPDATE_SONG_PUBLISHER_RULESET',
  ),
  viewWriterSharesPermitted: selectPermission(
    state,
    'CAN_GET_SONG_WRITER_SHARES',
  ),
  viewPublisherRulesetPermitted: selectPermission(
    state,
    'CAN_GET_SONG_PUBLISHER_RULESET',
  ),
  publishers: selectPublishers(state, 'CAN_UPDATE_SONG_PUBLISHER_RULESET'),
  publisherRulesets: selectPublisherRulesets(state),
  songCollections: selectSongCollections(state, 'CAN_UPDATE_SONG_COLLECTIONS'),
  canOnlySetReadyForPublication: selectPermission(
    state,
    'CAN_ONLY_SET_READY_FOR_PUBLICATION',
  ),
  canUpdateSourceFiles: selectPermission(state, 'CAN_UPDATE_SONG_SOURCE_FILES'),
  sourceFileUploads: selectSourceFileUploads(state),
});

export default connect(mapStateToProps)(SongForm);
