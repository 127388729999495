import React from 'react';
import { FieldArray } from 'formik';
import TabPanel from 'components/Tab/TabPanel';
import { Tabs, Tab } from '@material-ui/core';
import MyTrackFinalDeliveryTable from './MyTrackFinalDeliveryTable';
import { useTabStyles } from 'styles/tab';

interface AllProps {
  requiredSourceFiles?: Nl.FinalTrackRequiredSourceFileType[];
  channelLayoutTypes: Nl.ChannelLayoutType[];
}

const MyTrackImmersiveTabs: React.FunctionComponent<AllProps> = ({
  requiredSourceFiles,
  channelLayoutTypes,
}) => {
  const classes = useTabStyles();
  const [currentTabValue, setCurrentTabValue] = React.useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, newTabValue: number) => {
    setCurrentTabValue(newTabValue);
  };

  return (
    <>
      <Tabs
        className={classes.root}
        value={currentTabValue}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
      >
        {channelLayoutTypes.map(
          (channelLayout: Nl.ChannelLayoutType, index: number) => (
            <Tab label={channelLayout} key={index} />
          ),
        )}
      </Tabs>
      {channelLayoutTypes.map(
        (channelLayout: Nl.ChannelLayoutType, index: number) => (
          <TabPanel value={currentTabValue} index={index} key={index}>
            <FieldArray name='files'>
              {(props) => (
                // @ts-ignore
                <MyTrackFinalDeliveryTable
                  {...props}
                  requiredSourceFiles={requiredSourceFiles}
                  channelLayout={channelLayout}
                />
              )}
            </FieldArray>
          </TabPanel>
        ),
      )}
    </>
  );
};

export default MyTrackImmersiveTabs;
