import { createAction, SimpleActionType } from 'utils/storeUtils';
import { GlobalState } from './rootReducer';

const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
const actions = {
  toggleDrawer: createAction(TOGGLE_DRAWER),
};

export interface HeaderState {
  drawerOpen: boolean;
}

const initialState = {
  drawerOpen: false,
} as HeaderState;

const selectors = {
  isDrawerOpen: (state: GlobalState) => !!state.header?.drawerOpen,
};

const reducer = (state = initialState, action: SimpleActionType) => {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return { drawerOpen: !state.drawerOpen };
    default:
      return state;
  }
};

export { actions, selectors };
export default reducer;
