import * as React from 'react';
import InputField from 'components/Formik/InputField';
import { FormSubTitle } from 'components/Formik/utils';
import { Field, Form } from 'formik';
import Checkbox from 'components/Formik/CheckboxField';
import { secondsToTimePoint } from './helpers';

const exampleInitialValues = {
  uuid: '',
  name: '',
  internal_url: '',
  external_url: '',
  description: '',
  start_point: '',
  end_point: '',
  ready_for_wiki: false,
};

export const getFormInitialValues = (example?: Nl.Api.Example) => {
  if (!example) return { ...exampleInitialValues };
  const start = example.start_point;
  const end = example.end_point;
  return {
    uuid: example.uuid,
    name: example.name,
    internal_url: example.internal_url,
    external_url: example.external_url,
    description: example.description,
    start_point: start == null ? '' : secondsToTimePoint(start),
    end_point: end == null ? '' : secondsToTimePoint(end),
    ready_for_wiki: example.ready_for_wiki,
  };
};

const ExampleForm = () => {
  return (
    <Form>
      <FormSubTitle title='Example Information' />
      <Field name='name' label='Name' component={InputField} />
      <Field name='internal_url' label='Internal URL' component={InputField} />
      <Field name='external_url' label='External URL' component={InputField} />
      <Field
        name='description'
        label='Description'
        component={InputField}
        multiline
        rows={1}
        rowsMax={4}
      />
      <Field
        name='start_point'
        label='Start point (hours:minutes:seconds)'
        component={InputField}
      />
      <Field
        name='end_point'
        label='End point (hours:minutes:seconds)'
        component={InputField}
      />
      <Field
        name='ready_for_wiki'
        label='Ready for Wiki'
        component={Checkbox}
      />
    </Form>
  );
};

export default ExampleForm;
