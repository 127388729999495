import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  assign,
  createAction,
  createTypedAction,
  SimpleActionType,
} from 'utils/storeUtils';
import {
  apiFetch,
  convertQueryObjToUrlString,
  FetchResponse,
  getUrlQueryParams,
} from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { fetchSingleArtistSubmission } from 'store/singleArtistSubmission';

/**
 * Route Designators
 */
const artistSubmissionEdit: keyof Nl.RouteMapList =
  'route/ARTIST_SUBMISSION_EDIT_PAGE';
const artistSubmissionsPage: keyof Nl.RouteMapList = 'route/ARTIST_SUBMISSIONS';

/**
 * Action Constants
 */
const FETCH_ARTIST_SUBMISSIONS = 'FETCH_ARTIST_SUBMISSIONS';
const FETCH_ARTIST_SUBMISSIONS_SUCCESS = 'FETCH_ARTIST_SUBMISSIONS_SUCCESS';
const FETCH_ARTIST_SUBMISSIONS_ERROR = 'FETCH_ARTIST_SUBMISSIONS_ERROR';
const APPROVE_ARTIST_SUBMISSION = 'APPROVE_ARTIST_SUBMISSION';
const ONBOARD_ARTIST_SUBMISSION = 'ONBOARD_ARTIST_SUBMISSION';
const DECLINE_ARTIST_SUBMISSION = 'DECLINE_ARTIST_SUBMISSION';
const DESTROY_ARTIST_SUBMISSION = 'DESTROY_ARTIST_SUBMISSION';
const ARTIST_SUBMISSION_ACTION_SUCCESS = 'ARTIST_SUBMISSION_ACTION_SUCCESS';

/**
 * Action Creators
 */
export const fetchArtistSubmissions = createAction(FETCH_ARTIST_SUBMISSIONS);
export const fetchArtistSubmissionsSuccess = createAction(
  FETCH_ARTIST_SUBMISSIONS_SUCCESS,
); // exported for testing only
const fetchArtistSubmissionsError = createAction(
  FETCH_ARTIST_SUBMISSIONS_ERROR,
);
export const approveArtistSubmission = createAction(APPROVE_ARTIST_SUBMISSION);
export const onboardArtistSubmission = createAction(ONBOARD_ARTIST_SUBMISSION);
export const declineArtistSubmission = createAction(DECLINE_ARTIST_SUBMISSION);
export const destroyArtistSubmission = createAction(DESTROY_ARTIST_SUBMISSION);
const artistActionSuccess = (payload: {
  type: keyof Nl.RouteMapList;
  message: string;
}) => createTypedAction(ARTIST_SUBMISSION_ACTION_SUCCESS, payload);

/**
 * Reducer
 */
const initialState = {
  data: {
    new_artist_submissions: [],
    total_size: 0,
    current_size: 0,
    current_page: 0,
    offset: 0,
    total_pages: 0,
  } as Nl.Api.ArtistSubmissionsResponse,
  isLoading: false,
  isLoaded: false,
};

export type ArtistSubmissionsState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_ARTIST_SUBMISSIONS: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_ARTIST_SUBMISSIONS_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    case FETCH_ARTIST_SUBMISSIONS_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }

    default:
      return state;
  }
};

const sagas = {
  *fetchArtistSubmissions(action: SimpleActionType) {
    try {
      const queryParams: string = action?.payload
        ? convertQueryObjToUrlString(action.payload)
        : yield select(getUrlQueryParams);
      const results: FetchResponse<Nl.Api.ArtistSubmissionsResponse> = yield call(
        apiFetch,
        `/new_artist_submission${queryParams}`,
      );
      yield put(fetchArtistSubmissionsSuccess({ data: results.data }));
    } catch (err) {
      yield put(fetchArtistSubmissionsError({ error: err.message }));
    }
  },
  *approveArtistSubmission(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<Nl.Api.ArtistSubmissionResponse> = yield apiFetch(
      `/new_artist_submission/${uuid}/approve`,
      { method: 'PUT' },
    );
    const { type } = yield select((state) => state.location);
    if (results.success) {
      yield put(artistActionSuccess({ type, message: 'Submission approved' }));
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *onboardArtistSubmission(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<Nl.Api.ArtistSubmissionResponse> = yield apiFetch(
      `/new_artist_submission/${uuid}/onboard`,
      { method: 'PUT' },
    );
    const { type } = yield select((state) => state.location);
    if (results.success) {
      yield put(artistActionSuccess({ type, message: 'Artist onboarded' }));
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *declineArtistSubmission(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<Nl.Api.ArtistSubmissionResponse> = yield apiFetch(
      `/new_artist_submission/${uuid}/reject`,
      { method: 'PUT' },
    );
    const { type } = yield select((state) => state.location);

    if (results.success) {
      yield put(artistActionSuccess({ type, message: 'Submission declined' }));
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *destroyArtistSubmission(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/new_artist_submission/${uuid}`,
      {
        method: 'DELETE',
      },
    );
    const { type } = yield select((state) => state.location);

    if (results.success) {
      yield put(artistActionSuccess({ type, message: 'Submission deleted' }));
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *artistActionSuccess(action: ReturnType<typeof artistActionSuccess>) {
    const { type, message } = action.payload;
    try {
      if (type === artistSubmissionEdit) {
        const {
          payload: { uuid },
        } = yield select((state) => state.location);
        yield put(fetchSingleArtistSubmission({ uuid }));
      } else if (type === artistSubmissionsPage) {
        yield put(fetchArtistSubmissions());
      }
      yield put(addSuccessNotification({ message }));
    } catch (err) {
      yield put(addErrorNotification({ message: err }));
    }
  },
};

// @bookmark @todo
// Test flow logic: 1st approver, 2nd approver, business

export function* rootSaga() {
  yield all([
    takeLatest(FETCH_ARTIST_SUBMISSIONS, sagas.fetchArtistSubmissions),
    takeLatest(APPROVE_ARTIST_SUBMISSION, sagas.approveArtistSubmission),
    takeLatest(ONBOARD_ARTIST_SUBMISSION, sagas.onboardArtistSubmission),
    takeLatest(DECLINE_ARTIST_SUBMISSION, sagas.declineArtistSubmission),
    takeLatest(DESTROY_ARTIST_SUBMISSION, sagas.destroyArtistSubmission),
    takeLatest(ARTIST_SUBMISSION_ACTION_SUCCESS, sagas.artistActionSuccess),
  ]);
}

export default reducer;
