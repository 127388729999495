import { put, call, takeEvery, all } from 'redux-saga/effects';
import { createAction, SimpleActionType } from '../utils/storeUtils';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';

import { goPreviousPage } from 'store/router';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

// Action Constants
const CREATE_PRO = 'CREATE_PRO';
const EDIT_PRO = 'EDIT_PRO';

// Action Creators
export const createPro = createAction(CREATE_PRO);
export const editPro = createAction(EDIT_PRO);

// Sagas
const sagas = {
  *createProSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/performing_rights_organization',
      {
        method: 'POST',
        body: { ...formData },
      },
    );
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been created`,
        }),
      );
      yield put(goPreviousPage());
      invalidateNovaQueries('/performing_rights_organization');
    } else {
      if (results.errors.name) {
        yield put(addErrorNotification({ message: results.errors.name }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
      formActions.setSubmitting(false);
    }
  },
  *editProSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<Nl.Api.PROResponse> = yield call(
      apiFetch,
      `/performing_rights_organization/${formData.uuid}`,
      {
        method: 'PUT',
        body: { ...formData },
      },
    );
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${results.data.performing_rights_organization.name} has been updated`,
        }),
      );
      invalidateNovaQueries(`/performing_rights_organization`);
      invalidateNovaQueries(`/performing_rights_organization/${formData.uuid}`);
    } else {
      if (results.errors.name) {
        yield put(addErrorNotification({ message: results.errors.name }));
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
      formActions.validateForm();
      formActions.setSubmitting(false);
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeEvery(CREATE_PRO, sagas.createProSaga),
    takeEvery(EDIT_PRO, sagas.editProSaga),
  ]);
}

export { sagas };
