import React from 'react';
import { FormHelperText, Typography } from '@material-ui/core';
import { map } from 'lodash';
import MyTrackFinalDeliveryField from './MyTrackFinalDeliveryField';
import { isFull, isFullInstrumental } from 'utils/songSourceFile';

interface AllProps {
  form: any;
  requiredSourceFiles?: Nl.FinalTrackRequiredSourceFileType[];
  channelLayout: Nl.ChannelLayoutType;
  trackUuid?: string;
}

const MyTrackFinalDeliveryTable: React.FunctionComponent<AllProps> = ({
  form,
  requiredSourceFiles,
  channelLayout,
  trackUuid,
}) => {
  const requiredTypes = requiredSourceFiles?.find(
    (required: Nl.FinalTrackRequiredSourceFileType) =>
      required.channel_layout === channelLayout,
  )?.types;

  return (
    <>
      <FormHelperText error>{form.errors.files?._field}</FormHelperText>
      {map(form.values.files[channelLayout], (value: Nl.FinalTrackFile) => (
        <div key={value.file_type}>
          <div data-e2e='text-area' style={{ margin: '8px 0' }}>
            <Typography variant='h6' display='inline'>
              {value.human_readable_filetype}
            </Typography>
            <Typography variant='caption' style={{ opacity: '.8' }}>
              {requiredTypes?.includes(value.human_readable_filetype) &&
                ' (Required)'}
            </Typography>
            {isFull(value.human_readable_filetype) && (
              <Typography variant='caption' display='block'>
                Please upload full track here (even if instrumental only)
              </Typography>
            )}
            {isFullInstrumental(value.human_readable_filetype) && (
              <Typography variant='caption' display='block'>
                Please upload ONLY if full track contains vocals
              </Typography>
            )}
          </div>
          <MyTrackFinalDeliveryField
            fileType={value.file_type}
            value={value}
            form={form}
            channelLayout={channelLayout}
            trackUuid={trackUuid}
          />
        </div>
      ))}
    </>
  );
};

export default MyTrackFinalDeliveryTable;
