import * as React from 'react';
import {
  Card,
  Theme,
  createStyles,
  makeStyles,
  CardContent,
  Typography,
  Grid,
  Link,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      marginTop: theme.spacing(8),
      maxWidth: theme.spacing(125),
    },
  });

const useStyles = makeStyles(styles);

const SongFinderResults = (
  songFinderItems: Nl.Api.SongFinderItem[],
  isLoaded: boolean,
) => {
  const classes = useStyles(styles);

  if (!isLoaded) {
    return null;
  }

  if (!songFinderItems.length) {
    return (
      <Card className={classes.root}>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            No Results
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return songFinderItems.map((item, idx) => (
    <Card className={classes.root} key={idx}>
      <CardContent>
        <Typography variant='h6' gutterBottom>
          Channel: {item.channel}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <b>Title: </b>
            {item.work_title}
            <br />
            <b>Artist: </b>
            {item.artist}
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>Status: </b>
            {item.status}
            <br />
            <b>Source: </b>
            {item.source}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            {item.url_downstream && (
              <>
                <Link href={item.url_downstream}>Go to sale page</Link>
                <br />
              </>
            )}
            {item.url_nova && (
              <Link href={item.url_nova}>View song in Nova</Link>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ));
};

export default SongFinderResults;
