import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Icon, Tooltip } from '@material-ui/core';
import ThumbDownAltTwoToneIcon from '@material-ui/icons/ThumbDownAltTwoTone';
import ThumbUpAltTwoToneIcon from '@material-ui/icons/ThumbUpAltTwoTone';
import RedoIcon from '@material-ui/icons/Redo';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import AudioControlIcon from 'components/AudioControlIcon';
import {
  approveTrackSubmission,
  declineTrackSubmission,
  destroyTrackSubmission,
  requestTrackSubmissionRevision,
} from 'store/trackSubmissions';
import { selectUserUuid } from 'store/selectors';
import { useNlSelector } from 'utils/redux';

interface AllProps {
  track: Nl.Api.TrackSubmission | Nl.Api.SingleTrackSubmission;
  disablePlayBtn?: boolean;
}

export const TrackSubmissionsActionsView: React.SFC<AllProps> = ({
  track,
  disablePlayBtn,
}) => {
  const dispatch = useDispatch();

  const userUuid = useNlSelector(selectUserUuid);

  const renderApproveButton: boolean = !track.last_approver
    ? track.is_approvable
    : track.is_approvable && track.last_approver.uuid !== userUuid;

  const renderEmptyIcon = () => (
    <IconButton disabled>
      <Icon />
    </IconButton>
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {track.source_file && !disablePlayBtn ? (
        <AudioControlIcon
          audioTrack={{
            uuid: track.source_file.uuid,
            trackType: 'song',
            url: track.source_file.url,
            trackName: track.name,
            duration: track.source_file.duration ?? 0,
          }}
        />
      ) : (
        renderEmptyIcon()
      )}
      {renderApproveButton ? (
        <Tooltip title='Approve Submission'>
          <IconButton
            data-e2e='approveSubmission'
            onClick={() =>
              dispatch(approveTrackSubmission({ uuid: track.uuid }))
            }
            className='approve'
            disabled={!renderApproveButton}
          >
            <ThumbUpAltTwoToneIcon />
          </IconButton>
        </Tooltip>
      ) : (
        renderEmptyIcon()
      )}
      {
        // This icon requires no empty icon as it only appears in single track view
        track.is_revisable && (
          <Tooltip title='Request a Revision'>
            <IconButton
              data-e2e='requestSubmissionRevision'
              onClick={() =>
                dispatch(requestTrackSubmissionRevision({ uuid: track.uuid }))
              }
              className='revise'
              disabled={!track.is_revisable}
            >
              <RedoIcon />
            </IconButton>
          </Tooltip>
        )
      }
      {track.is_rejectable ? (
        <Tooltip title='Decline Submission'>
          <IconButton
            data-e2e='declineSubmission'
            onClick={() => {
              if (
                window.confirm('Are you sure you want to DECLINE this track?')
              ) {
                dispatch(declineTrackSubmission({ uuid: track.uuid }));
              }
            }}
            className='reject'
            disabled={!track.is_rejectable}
          >
            <ThumbDownAltTwoToneIcon />
          </IconButton>
        </Tooltip>
      ) : (
        renderEmptyIcon()
      )}
      {track.is_deletable ? (
        <Tooltip title='Delete Submission Permanently'>
          <IconButton
            data-e2e='deleteSubmission'
            onClick={() => {
              if (
                window.confirm('Are you sure you want to DELETE this track?')
              ) {
                dispatch(destroyTrackSubmission({ uuid: track.uuid }));
              }
            }}
            className='destroy'
            disabled={!track.is_deletable}
          >
            <DeleteForeverTwoToneIcon color='action' />
          </IconButton>
        </Tooltip>
      ) : (
        renderEmptyIcon()
      )}
    </div>
  );
};

export default TrackSubmissionsActionsView;
