import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import {
  assignedTo,
  OLD_PRO_OPTIONS,
  ROYALTY_FILTER_OPTIONS,
  STATUS_VALUES,
} from 'constants/staticData';
import { useArtistEntities } from 'novaApi/hooks/artist';
import React from 'react';
import { GlobalState } from 'store/rootReducer';

export function TrackSubmissionsFilters({
  userUuid,
}: {
  userUuid: string | undefined;
}) {
  const artists = useArtistEntities();

  const filters: Nl.TableFilterTypes<GlobalState>[] = [
    {
      type: 'single_select',
      label: 'Artist',
      queryFilterKey: 'artist_uuid',
      e2e: 'artist',
      optionsSelector: () => artists,
    },
    {
      type: 'single_select',
      label: 'Status',
      queryFilterKey: 'status',
      e2e: 'status',
      optionsSelector: () => STATUS_VALUES,
    },
  ];

  if (userUuid) {
    filters.push({
      type: 'single_select',
      label: 'Assigned To',
      queryFilterKey: 'assigned_user_uuid',
      e2e: 'assigned_to',
      optionsSelector: () => assignedTo(userUuid),
      isSticky: true,
    });
  }

  filters.push({
    type: 'single_select',
    label: 'Performing Rights Organization',
    queryFilterKey: 'pro_affiliated',
    e2e: 'pro_affiliated',
    optionsSelector: () => OLD_PRO_OPTIONS,
  });

  filters.push({
    type: 'single_select',
    label: 'Royalty',
    queryFilterKey: 'is_royalty',
    e2e: 'is_royalty',
    optionsSelector: () => ROYALTY_FILTER_OPTIONS,
  });

  return <ConnectedTableFilters filters={filters} />;
}
