import { put, call, takeLatest } from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from '../utils/storeUtils';
import { apiFetch, FetchResponse } from '../novaApi/apiUtils';

const FETCH_SONG_HIDDEN_TAGS = 'FETCH_SONG_HIDDEN_TAGS';
const FETCH_SONG_HIDDEN_TAGS_SUCCESS = 'FETCH_SONG_HIDDEN_TAGS_SUCCESS';
const FETCH_SONG_HIDDEN_TAGS_ERROR = 'FETCH_SONG_HIDDEN_TAGS_ERROR';

// Action creator
export const fetchSongHiddenTags = createAction(FETCH_SONG_HIDDEN_TAGS);
const fetchSongHiddenTagsSuccess = createAction(FETCH_SONG_HIDDEN_TAGS_SUCCESS);
const fetchSongHiddenTagsError = createAction(FETCH_SONG_HIDDEN_TAGS_ERROR);

// Reducer
const initialState = {
  data: {
    song_tags_hidden: [] as Nl.SongTag[],
    total_size: 0,
    current_size: undefined as number | undefined,
    current_page: undefined as number | undefined,
    offset: undefined as number | undefined,
    total_pages: undefined as number | undefined,
  },
  isLoading: false,
  isLoaded: false,
};

export type SongHiddenTags = Readonly<typeof initialState>;

const reducer = (state = initialState, action: SimpleActionType) => {
  switch (action.type) {
    case FETCH_SONG_HIDDEN_TAGS_SUCCESS:
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });

    default:
      return state;
  }
};

const sagas = {
  *fetchSongHiddenTagsSaga() {
    const results: FetchResponse<Nl.Api.SongTagsHiddenResponse> = yield call(
      apiFetch,
      '/song_tag_hidden/select',
    );
    if (results.success) {
      yield put(
        fetchSongHiddenTagsSuccess({
          data: {
            ...results.data,
            song_tags_hidden: results.data.song_tags_hidden.map((tag) => ({
              name: tag.name,
              uuid: tag.name,
            })),
          },
        }),
      );
    } else {
      yield put(fetchSongHiddenTagsError({ error: results.msg }));
    }
  },
};

export function* rootSaga() {
  yield takeLatest(FETCH_SONG_HIDDEN_TAGS, sagas.fetchSongHiddenTagsSaga);
}
export { sagas };
export default reducer;
