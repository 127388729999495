import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Button, Icon, Snackbar } from '@material-ui/core';
import { Theme, createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { dismissNotification } from 'store/notifications';
import { GlobalState } from 'store/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      background: theme.palette.secondary.light,
      color: theme.customPalette.text,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: '5px',
      '.error &': {
        color: theme.palette.error.main,
      },
      '.success &': {
        color: theme.customPalette.success,
      },
    },
    text: {
      maxWidth: '500px',
      width: '100%',
      whiteSpace: 'pre-line',
    },
  }),
);

interface StateProps {
  isOpen: boolean;
  message: string | undefined;
  url: string | undefined;
  status: 'default' | 'error' | 'success';
  persist: boolean | undefined;
}

interface DispatchProps {
  onDismissNotification(): void;
}

type AllProps = StateProps & DispatchProps;

const Notification = ({
  isOpen,
  message,
  status,
  onDismissNotification,
  persist,
  url,
}: AllProps) => {
  const classes = useStyles();

  const icon = {
    success: 'done',
    error: 'error_outline',
    default: 'info',
  }[status || 'default'];

  return (
    <Snackbar
      data-e2e='notification'
      aria-label='notification'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isOpen}
      onClick={onDismissNotification}
      onClose={onDismissNotification}
      autoHideDuration={persist ? null : 3500}
      message={
        <div className={classes.container}>
          <Icon className={classes.icon} data-e2e={icon}>
            {icon}
          </Icon>
          <span className={classes.text}>{message}</span>
        </div>
      }
      action={url && <Button href={url}>Click here</Button>}
      ContentProps={{
        classes: {
          root: classnames(classes.content, status),
        },
      }}
    />
  );
};

export default connect(
  (state: GlobalState) => ({
    isOpen: state.notifications.isOpen,
    message: state.notifications.message,
    url: state.notifications.url,
    status: state.notifications.status,
    persist: state.notifications.persist,
  }),
  {
    onDismissNotification: dismissNotification,
  },
)(Notification);
