import { put, call, takeEvery, all, takeLatest } from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from 'utils/storeUtils';
import { isEmpty } from 'lodash';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { goPreviousPage } from './router';

// Action Constants
const CHANGE_MY_PASSWORD = 'CHANGE_MY_PASSWORD';
const CHANGE_MY_PASSWORD_SUCCESS = 'CHANGE_MY_PASSWORD_SUCCESS';
const CHANGE_MY_PASSWORD_ERROR = 'CHANGE_MY_PASSWORD_ERROR';
const FETCH_MY_PROFILE = 'FETCH_MY_PROFILE';
const FETCH_MY_PROFILE_SUCCESS = 'FETCH_MY_PROFILE_SUCCESS';
const UPDATE_MY_PROFILE = 'UPDATE_MY_PROFILE';
const UPDATE_MY_PROFILE_SUCCESS = 'UPDATE_MY_PROFILE_SUCCESS';

// Action Creators
export const changeMyPassword = createAction(CHANGE_MY_PASSWORD);
const changeMyPasswordSuccess = createAction(CHANGE_MY_PASSWORD_SUCCESS);
const changeMyPasswordError = createAction(CHANGE_MY_PASSWORD_ERROR);
export const fetchMyProfile = createAction(FETCH_MY_PROFILE);
const fetchMyProfileSuccess = createAction(FETCH_MY_PROFILE_SUCCESS);
export const updateMyProfile = createAction(UPDATE_MY_PROFILE);
const updateMyProfileSuccess = createAction(UPDATE_MY_PROFILE_SUCCESS);

// Initial values
const initialState = {
  data: {
    artist: undefined as Nl.Api.MyProfile | undefined,
  },
  isLoading: false,
  isLoaded: false,
};

export type MyProfileState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_MY_PROFILE: {
      return assign(state, {
        isLoading: true,
      });
    }
    case FETCH_MY_PROFILE_SUCCESS:
    case UPDATE_MY_PROFILE_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }
    default:
      return state;
  }
};

// Sagas
const sagas = {
  *changeMyPasswordSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(
      apiFetch,
      '/user/update_password',
      {
        method: 'POST',
        body: { ...formData },
      },
    );
    if (results.success) {
      yield put(changeMyPasswordSuccess({ data: results.data }));
      yield put(
        addSuccessNotification({ message: 'Your password has been updated' }),
      );
      yield put(goPreviousPage());
    } else {
      yield put(changeMyPasswordError({ error: results.msg }));
      yield put(
        addErrorNotification({
          message:
            results.msg === 'Bad request syntax'
              ? 'An error occurred. Please check your submission.'
              : results.msg,
        }),
      );
      formActions.validateForm();
    }
    formActions.setSubmitting(false);
  },
  *fetchMyProfileSaga() {
    const { success, msg, data } = yield call(apiFetch, '/artist/my_profile');
    if (success) {
      yield put(fetchMyProfileSuccess({ data }));
    } else {
      yield put(addErrorNotification({ message: msg }));
    }
  },
  *updateMyProfileSaga(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const { success, msg, errors, data } = yield call(
      apiFetch,
      '/artist/my_profile',
      {
        method: 'PUT',
        body: formData,
      },
    );
    if (success) {
      yield put(
        addSuccessNotification({ message: 'Your profile has been updated' }),
      );
      yield put(updateMyProfileSuccess({ data }));
    } else {
      if (isEmpty(errors)) {
        yield put(addErrorNotification({ message: msg }));
      }
      formActions.setErrors(errors);
    }
    formActions.setSubmitting(false);
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeEvery(CHANGE_MY_PASSWORD, sagas.changeMyPasswordSaga),
    takeLatest(FETCH_MY_PROFILE, sagas.fetchMyProfileSaga),
    takeLatest(UPDATE_MY_PROFILE, sagas.updateMyProfileSaga),
  ]);
}

export { sagas };
export default reducer;
