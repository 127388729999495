import * as React from 'react';
import { Form, Field } from 'formik';
import {
  CardContent,
  Typography,
  Grid,
  CardActions,
  Button,
} from '@material-ui/core';
import InputField from 'components/Formik/InputField';

const SongFinderForm = ({ isSubmitting }: { isSubmitting: boolean }) => {
  return (
    <Form>
      <CardContent>
        <Typography variant='h5' gutterBottom>
          Song Finder
        </Typography>
        <Typography variant='subtitle1'>
          Enter a song ID from any sales channel:
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Field name='song_id' label='Song ID' component={InputField} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button color='primary' type='submit' disabled={isSubmitting}>
          Submit
        </Button>
      </CardActions>
    </Form>
  );
};

export default SongFinderForm;
