import React from 'react';
import MyTrackUploadDropzone from '../MyTrackUploadDropzone';
import { Field } from 'formik';
import { FormHelperText, Grid } from '@material-ui/core';
import { isEmpty, isEqual } from 'lodash';

interface AllProps {
  fileType: Nl.FinalTrackSourceFileType;
  value: Nl.FinalTrackFile;
  form: any;
  channelLayout: Nl.ChannelLayoutType;
  trackUuid?: string;
}

const MyTrackFinalDeliveryField: React.FunctionComponent<AllProps> = ({
  fileType,
  value,
  form,
  channelLayout,
  trackUuid,
}) => (
  <>
    <Field
      name={fileType}
      // eslint-disable-next-line no-shadow
      component={() => (
        <Grid item xs={12}>
          <Grid>
            <MyTrackUploadDropzone
              form={form}
              state={value.state}
              original_filename={value.original_filename}
              isRequiredError={!isEmpty(form.errors.source_file)}
              fileType={fileType}
              humanReadableFiletype={value.human_readable_filetype}
              isFinalSourceFile
              channelLayout={channelLayout}
              trackUuid={trackUuid}
            />
          </Grid>
        </Grid>
      )}
    />
    <Grid>
      <FormHelperText error>{value.error}</FormHelperText>
    </Grid>
  </>
);

const areEqual = (prevProps: AllProps, nextProps: AllProps) => {
  const areValuesEqual = isEqual(prevProps.value, nextProps.value);
  const areErrorsEqual = isEqual(
    prevProps.form.errors[prevProps.fileType],
    nextProps.form.errors[prevProps.fileType],
  );
  return areValuesEqual && areErrorsEqual;
};

export default React.memo(MyTrackFinalDeliveryField, areEqual);
