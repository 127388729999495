import _ from 'lodash';

/*
Permission validation is often part of an array of value
where most of them don't require one. For that reason,
if we don't explicitly define a requiredPermission,
this method will return true.
*/

export const isUserPermitted = (
  userPermissions: Nl.UserPermissionType[],
  requiredPermission:
    | Nl.UserPermissionType
    | Nl.UserPermissionType[]
    | undefined,
) => {
  if (Array.isArray(requiredPermission)) {
    return _.every(requiredPermission, (permission) =>
      _.includes(userPermissions, permission),
    );
  }
  if (typeof requiredPermission === 'string') {
    return _.includes(userPermissions, requiredPermission);
  }
  return true;
};
