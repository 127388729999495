import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { goPreviousPage } from 'store/router';
import { Formik, FormikHelpers, isPromise, FormikValues } from 'formik';
import FormHeader from './FormHeader';
import { selectAudioPlayerIsOpen } from 'store/selectors';
import { addErrorNotification } from 'store/notifications';
import { toFormErrors } from 'utils/formUtils';

// Styles
const useStyles = makeStyles({
  dialogContent: {
    paddingBottom: '2rem',
  },
  dialogMarginBottom: {
    marginBottom: '100px',
  },
});

// Types
interface Props<Values> {
  initialValues: Values;
  validationSchema?: object;
  validate?: (values: Values) => object;
  title: string | React.ReactElement;
  /**
   * Hanldles form submissions.
   * If the function throws or rejects, the error will be handled by FormikWrapper
   * and NovaApi errors will automatically get converted to form errors.
   */
  submitForm: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => void | Promise<any> | any;
  children: React.ReactNode;
  extraActions?: React.ReactElement;
  customSize?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl';
}

export default function FormikWrapper<Values extends FormikValues>({
  // TODO:
  // default value is a quick fix to avoid crashes since we updated to Formik 2
  // But initalValues should be set by parent component:
  // "Even if your form is empty by default, you must initialize all fields with initial values otherwise React will throw an error saying that you have changed an input from uncontrolled to controlled"
  // Source: https://formik.org/docs/api/formik#initialvalues-values
  initialValues,
  validate,
  validationSchema,
  title,
  submitForm,
  children,
  extraActions,
  customSize = 'md',
}: Props<Values>) {
  const classes = useStyles({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const audioPlayerIsOpen = useSelector(selectAudioPlayerIsOpen);

  const handleClose = (isDirty: boolean) => {
    if (isDirty) {
      return (
        window.confirm(
          'Are you sure you want to discard your unsaved changes?',
        ) && dispatch(goPreviousPage())
      );
    }
    return dispatch(goPreviousPage());
  };

  function onSubmit(values: Values, formikHelpers: FormikHelpers<Values>) {
    const handleError = (error: any) => {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(addErrorNotification({ message: 'Form Errors ❌' }));
      formikHelpers.setErrors(toFormErrors(error));
    };
    try {
      const result = submitForm(values, formikHelpers);
      if (isPromise(result)) {
        return Promise.resolve(result).catch(handleError);
      }
    } catch (error) {
      handleError(error);
    }
  }

  return (
    <Formik
      {...{ initialValues, validate, validationSchema, onSubmit }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, handleReset, dirty, isValid, isSubmitting }) => {
        return (
          <Dialog
            open
            fullWidth
            fullScreen={fullScreen}
            maxWidth={customSize}
            onClose={() => handleClose(dirty)}
            className={audioPlayerIsOpen ? classes.dialogMarginBottom : ''}
            data-e2e='formikDialog'
          >
            <FormHeader
              title={title}
              extraActions={extraActions}
              save={handleSubmit}
              reset={() =>
                window.confirm(
                  'Are you sure you want to reset your unsaved changes?',
                ) && handleReset()
              }
              closeModal={() => handleClose(dirty)}
              isValid={isValid}
              isSubmitting={isSubmitting}
              isDirty={dirty}
            />
            <DialogContent
              className={classes.dialogContent}
              data-e2e='formikDialogContent'
            >
              {children}
            </DialogContent>
          </Dialog>
        );
      }}
    </Formik>
  );
}
