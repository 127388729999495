import * as React from 'react';
import EmojiPicker from 'components/Formik/EmojiPicker';
import InputField from 'components/Formik/InputField';
import { FormSubTitle } from 'components/Formik/utils';
import { Field, Form } from 'formik';
import Typeahead from 'components/Typeahead/Typeahead';
import { multipleSelectFormat } from 'utils/utils';
import Checkbox from 'components/Formik/CheckboxField';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  checkboxGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.25rem',
  },
}));

const tagCategoryInitialValues = {
  uuid: '',
  name: '',
  icon: '',
  definition: '',
  internal_definition: '',
  is_derived: false,
  is_organizational: false,
  is_internal: false,
  parent_categories: [],
};

export const getFormInitialValues = (tagCategory?: Nl.Api.TagCategory) => {
  if (!tagCategory) return tagCategoryInitialValues;

  return {
    uuid: tagCategory.uuid,
    name: tagCategory.name,
    icon: tagCategory.icon,
    definition: tagCategory.definition,
    internal_definition: tagCategory.internal_definition,
    is_derived: tagCategory.is_derived,
    is_organizational: tagCategory.is_organizational,
    is_internal: tagCategory.is_internal,
    parent_categories: tagCategory.parent_categories.map((pc) => pc.uuid),
  };
};

const TagCategoryForm = ({
  parentTagCategories,
}: {
  parentTagCategories?: Nl.Api.SelectorEntity[];
}) => {
  const classes = useStyles();

  // if 'parentTagCategories' props present, that means form in EDIT mode
  return (
    <Form>
      <FormSubTitle title='Tag Category Information' />
      <Field name='name' label='Tag category name' component={InputField} />
      <Field
        name='icon'
        label='Emoji'
        placeholder='Add emoji here'
        component={EmojiPicker}
      />
      {parentTagCategories && (
        <>
          <Field
            name='parent_categories'
            label='Parent categories'
            isMulti
            component={Typeahead}
            options={multipleSelectFormat(parentTagCategories)}
          />
          <Field
            name='definition'
            label='Definition'
            component={InputField}
            multiline
            rows={1}
            rowsMax={4}
          />
          <Field
            name='internal_definition'
            label='Internal Definition'
            component={InputField}
            multiline
            rows={1}
            rowsMax={4}
          />
          <Box className={classes.checkboxGroup}>
            <Field name='is_derived' label='Is derived' component={Checkbox} />
            <Field
              name='is_organizational'
              label='Is organizational'
              component={Checkbox}
            />
            <Field
              name='is_internal'
              label='Is internal'
              component={Checkbox}
            />
          </Box>
        </>
      )}
    </Form>
  );
};

export default TagCategoryForm;
