import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import { getEditFormInitialValues, createPublisher } from 'store/publishers';
import PublisherForm from './PublisherForm';
import validationSchema from './validation';

export default function PublisherCreatePage() {
  const dispatch = useDispatch();
  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues()}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) =>
        dispatch(createPublisher({ formData, formActions }))
      }
      title='Create New Publisher'
    >
      <PublisherForm />
    </FormikWrapper>
  );
}
