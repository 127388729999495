import * as React from 'react';
import {
  Tooltip,
  IconButton,
  Typography,
  Toolbar,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Link from 'redux-first-router-link';
import ExpansionPanel from 'components/ExpansionPanel';
import FilterIndicator from './FilterIndicator';
import FilterReset from './FilterReset';
import { selectors as authSelectors } from 'store/auth';
import { useNlSelector } from 'utils/redux';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    filtersButtonContainer: {
      flex: '1 1 100%',
    },
    actions: {
      display: 'inline-flex',
      marginLeft: 'auto',
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
      marginRight: '40px',
      whiteSpace: 'nowrap',
    },
    linkWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    linkText: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    bulkActions:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.text.secondary,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    hide: {
      opacity: 0,
    },
    filtersPanel: {
      margin: 0,
      boxShadow: 'none',
    },
  }),
);

export type RenderBulkActions = (props: {
  selected: string[];
  userPermissions: Nl.UserPermissionType[];
  onReset: () => void;
}) => React.ReactNode;

interface OwnProps {
  title: string;
  search?: React.ReactNode;
  filters?: React.ReactNode;
  actions?: React.ReactNode;
  bulkActions?: RenderBulkActions;
  selected?: string[];
  onReset?: () => void;
  filtersOpen?: boolean;
  hideFiltersButton?: boolean;
  alwaysExpanded?: boolean;
  link?: Nl.HeaderLink;
}

export default function TableToolbar({
  selected = [],
  onReset = () => {
    // eslint-disable-next-line no-console
    console.log('Reset');
  },
  filtersOpen,
  search,
  filters,
  actions,
  bulkActions,
  hideFiltersButton,
  alwaysExpanded,
  link,
  title,
}: OwnProps) {
  const [filtersOpened, setFiltersOpened] = useState(filtersOpen);
  const userPermissions = useNlSelector(authSelectors.getUserPermissions);
  const classes = useStyles();

  const renderTitle = () => {
    const permissibleLink: boolean =
      link && link.permission
        ? userPermissions.includes(link.permission)
        : true;

    if (link && permissibleLink) {
      return (
        <div className={classes.linkWrapper}>
          <Typography className={classes.title} variant='h6' id='tableTitle'>
            {title}
          </Typography>
          <Link className={classes.linkText} to={{ type: link.route }}>
            <Typography variant='h6' id='tableTitle'>
              {link.title}
            </Typography>
          </Link>
        </div>
      );
    }
    return (
      <Typography className={classes.title} variant='h6' id='tableTitle'>
        {title}
      </Typography>
    );
  };

  return (
    <>
      <Toolbar className={classes.root}>
        {renderTitle()}
        {search}
        {filters && !hideFiltersButton && (
          <div className={classes.filtersButtonContainer}>
            <Tooltip
              title={!filtersOpened ? 'Show the filters' : 'Hide the filters'}
              placement='right'
            >
              <span>
                <IconButton
                  aria-label='Filters'
                  aria-haspopup='true'
                  aria-owns={filtersOpened ? 'simple-popper' : undefined}
                  onClick={() => setFiltersOpened((value) => !value)}
                  data-e2e='toggleFilters'
                  disabled={alwaysExpanded}
                >
                  <FilterIndicator />
                </IconButton>
              </span>
            </Tooltip>
            <FilterReset />
          </div>
        )}
        {selected && selected.length > 0 ? (
          <>{bulkActions?.({ selected, userPermissions, onReset })}</>
        ) : (
          <div className={classes.actions}>{actions}</div>
        )}
      </Toolbar>
      <ExpansionPanel
        classes={{ root: classes.filtersPanel }}
        expanded={alwaysExpanded || filtersOpened}
      >
        {filters}
      </ExpansionPanel>
    </>
  );
}
