import * as React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButtonProps } from '@material-ui/core/IconButton';

interface Props extends IconButtonProps {
  'aria-label': string;
}

const DeleteButton = (props: Props) => (
  <Tooltip title={props['aria-label']} placement='bottom'>
    <>
      <IconButton color='default' {...props}>
        <DeleteIcon />
      </IconButton>
    </>
  </Tooltip>
);

export default DeleteButton;
