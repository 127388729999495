import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';

export const getValidationSchema = () => {
  const baseShape = {
    name: Yup.string().required(errorMessages.basicRequired),
    language: Yup.object()
      .shape({
        uuid: Yup.string().required(errorMessages.basicRequired),
        name: Yup.string().required(errorMessages.basicRequired),
      })
      .nullable()
      .notRequired()
      .default(null),
    universal_categories: Yup.array().of(
      Yup.object()
        .shape({
          uuid: Yup.string().required(errorMessages.basicRequired),
          name: Yup.string().required(errorMessages.basicRequired),
        })
        .nullable(),
    ),
  };

  return Yup.object().shape(baseShape);
};
