import * as React from 'react';
import {
  Typography,
  WithStyles,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Warning';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '12px 16px 12px 16px',
      width: '100%',
      border: `1px solid ${theme.palette.grey[300]}`,
      marginTop: `${theme.spacing(2)}px !important`,
    },
    icon: {
      color: theme.customPalette.warning,
      marginRight: theme.spacing(1),
    },
  });

type AllProps = WithStyles<typeof styles>;

export const SongSyncWarning: React.SFC<AllProps> = ({ classes, children }) => (
  <div className={classes.root}>
    <InfoIcon className={classes.icon} />
    <Typography>{children}</Typography>
  </div>
);

export default withStyles(styles)(SongSyncWarning);
