import * as React from 'react';
import { useDispatch } from 'react-redux';
import { createWriter } from 'store/writers';
import FormikWrapper from 'components/Formik';
import WriterForm, { getEditFormInitialValues } from './WriterForm';
import validationSchema from './validation';

const WriterCreatePage = () => {
  const dispatch = useDispatch();

  const initialValues = getEditFormInitialValues(undefined, false);

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) =>
        dispatch(createWriter({ formData, formActions }))
      }
      title='Create Writer'
    >
      <WriterForm />
    </FormikWrapper>
  );
};

export default WriterCreatePage;
