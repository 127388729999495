import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import {
  fetchProcessingSourceFiles,
  getSongZips,
  postGenerateSongZips,
  postPublishSong,
  postReadyForPublication,
  postTakeDownSong,
} from 'novaApi/NovaApi';
import { downloadFile } from 'utils/download';
import {
  apiFetch,
  FetchResponse,
  getRawUrlQueryParams,
} from 'novaApi/apiUtils';
import {
  ActionsUnion,
  assign,
  createTypedAction,
  SimpleActionType,
} from 'utils/storeUtils';
import { loadPlayerTrack } from './audioPlayer';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { GlobalState } from './rootReducer';
import { fetchWaveforms } from './songWaveform';
import { buildSourceFilesTable } from 'pages/Songs/EditSourceFile/SourceFilesTable';
import { Task } from 'redux-saga';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

// Selectors
export const selectCanSetReadyForPublication = (state: GlobalState) =>
  Boolean(
    state.auth?.permissions?.includes('CAN_ONLY_SET_READY_FOR_PUBLICATION') &&
      state.singleSong.data.song?.curation_state?.name === 'Needs Metadata',
  );

export const selectRequireAction = (state: GlobalState) =>
  state.singleSong.data.song?.is_visible_comments || [];

/**
 * This is a very special memoize selector that solve a very specific problem.
 * To avoid unnecessary rendering everytime a new original_wav_url is generated,
 * we check if original_wav_url was previously defined then the same is returned,
 * if not then the last stored one is returned
 */
const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  (currentVal: any, previousVal: any) => {
    return previousVal && typeof previousVal.original_wav_url === 'string';
  },
);

export const makeSelectPlayUrl = () =>
  createDeepEqualSelector(
    (state: GlobalState, songUuid: string) =>
      state.singleSong.data.song &&
      state.singleSong.data.song.source_files.find((s) => s.uuid === songUuid),
    (values) => {
      return (
        values && (values.original_repeated_wav_url || values.original_wav_url)
      );
    },
  );
export const makeSelectDownloadUrl = () =>
  createDeepEqualSelector(
    (state: GlobalState, songUuid: string) =>
      state.singleSong.data.song &&
      state.singleSong.data.song.source_files.find((s) => s.uuid === songUuid),
    (values) => {
      return values && values.original_wav_url;
    },
  );

/**
 * Action Constants
 */

const INIT_SINGLE_SONG = 'INIT_SINGLE_SONG';

const FETCH_SINGLE_SONG = 'FETCH_SINGLE_SONG';
const FETCH_SINGLE_SONG_SUCCESS = 'FETCH_SINGLE_SONG_SUCCESS';
const FETCH_SINGLE_SONG_ERROR = 'FETCH_SINGLE_SONG_ERROR';

const CLEAR_SONG_DATA = 'CLEAR_SONG_DATA';

const CREATE_SONG = 'CREATE_SONG';
const CREATE_SONG_ERROR = 'CREATE_SONG_ERROR';
const EDIT_SONG = 'EDIT_SONG';
const EDIT_SONG_SUCCESS = 'EDIT_SONG_SUCCESS';

const GENERATE_SONG_ZIPS = 'GENERATE_SONG_ZIPS';
const GENERATE_SONG_ZIPS_SUCCESS = 'GENERATE_SONG_ZIPS_SUCCESS';
const GENERATE_SONG_ZIPS_ERROR = 'GENERATE_SONG_ZIPS_ERROR';

const DOWNLOAD_SONG_ZIP = 'DOWNLOAD_SONG_ZIP';

const START_POLL_SOURCE_FILES = 'START_POLL_SOURCE_FILES';

const FETCH_SOURCE_FILES = 'FETCH_SOURCE_FILES';
const FETCH_SOURCE_FILES_SUCCESS = 'FETCH_SOURCE_FILES_SUCCESS';
const FETCH_SOURCE_FILES_ERROR = 'FETCH_SOURCE_FILES_ERROR';
const STOP_SOURCE_FILE_POLLING = 'STOP_SOURCE_FILE_POLLING';

const PUBLISH_SONG = 'PUBLISH_SONG';
const PUBLISH_SONG_SUCCESS = 'PUBLISH_SONG_SUCCESS';
const PUBLISH_SONG_ERROR = 'PUBLISH_SONG_ERROR';

const TAKE_DOWN_SONG = 'TAKE_DOWN_SONG';
const TAKE_DOWN_SONG_SUCCESS = 'TAKE_DOWN_SONG_SUCCESS';
const TAKE_DOWN_SONG_ERROR = 'TAKE_DOWN_SONG_ERROR';

const PLAY_SOURCE_FILE_ON_LOAD = 'PLAY_SOURCE_FILE_ON_LOAD';

const READY_FOR_PUBLICATION_SONG = 'READY_FOR_PUBLICATION_SONG';
const READY_FOR_PUBLICATION_SONG_SUCCESS = 'READY_FOR_PUBLICATION_SONG_SUCCESS';
const READY_FOR_PUBLICATION_SONG_ERROR = 'READY_FOR_PUBLICATION_SONG_ERROR';

/**
 * Action Creators
 */
export const actions = {
  initSingleSong: () => createTypedAction(INIT_SINGLE_SONG),

  fetchSingleSong: (payload: { uuid: string }) =>
    createTypedAction(FETCH_SINGLE_SONG, payload),
  fetchSingleSongSuccess: (payload: { data: Nl.Api.SongResponse }) =>
    createTypedAction(FETCH_SINGLE_SONG_SUCCESS, payload),
  fetchSingleSongError: (payload: { error: string }) =>
    createTypedAction(FETCH_SINGLE_SONG_ERROR, payload),

  clearSongData: () => createTypedAction(CLEAR_SONG_DATA),

  createSong: (payload: any) => createTypedAction(CREATE_SONG, payload),
  createSongError: (payload: { error: string }) =>
    createTypedAction(CREATE_SONG_ERROR, payload),

  editSong: (payload: any) => createTypedAction(EDIT_SONG, payload),
  editSongSuccess: (payload: { data: Nl.Api.SongResponse }) =>
    createTypedAction(EDIT_SONG_SUCCESS, payload),

  generateSongZips: (payload: { songUuid: string }) =>
    createTypedAction(GENERATE_SONG_ZIPS, payload),
  generateSongZipsSuccess: (payload: { data: Nl.Api.SongResponse }) =>
    createTypedAction(GENERATE_SONG_ZIPS_SUCCESS, payload),
  generateSongZipsError: (payload: { error: string }) =>
    createTypedAction(GENERATE_SONG_ZIPS_ERROR, payload),

  downloadZip: (payload: {
    songUuid: string;
    type: Nl.SongSourceFileType | 'all';
  }) => createTypedAction(DOWNLOAD_SONG_ZIP, payload),

  pollSourceFiles: (payload: { songUuid: string }) =>
    createTypedAction(START_POLL_SOURCE_FILES, payload),
  stopSourceFilesPolling: (payload: { songUuid: string }) =>
    createTypedAction(STOP_SOURCE_FILE_POLLING, payload),

  fetchSourceFiles: (payload: { songUuid: string }) =>
    createTypedAction(FETCH_SOURCE_FILES, payload),
  fetchSourceFileSuccess: (payload: { data: Nl.Api.SongResponse }) =>
    createTypedAction(FETCH_SOURCE_FILES_SUCCESS, payload),
  fetchSourceFileError: (payload: { songUuid: string; error: string }) =>
    createTypedAction(FETCH_SOURCE_FILES_ERROR, payload),

  publishSong: (payload: { songUuid: string }) =>
    createTypedAction(PUBLISH_SONG, payload),
  publishSongSuccess: (payload: { data: Nl.Api.Song }) =>
    createTypedAction(PUBLISH_SONG_SUCCESS, payload),
  publishSongError: (payload: { error: string }) =>
    createTypedAction(PUBLISH_SONG_ERROR, payload),

  takeDownSong: (payload: { songUuid: string }) =>
    createTypedAction(TAKE_DOWN_SONG, payload),
  takeDownSongSuccess: (payload: { data: Nl.Api.Song }) =>
    createTypedAction(TAKE_DOWN_SONG_SUCCESS, payload),
  takeDownSongError: (payload: { error: string }) =>
    createTypedAction(TAKE_DOWN_SONG_ERROR, payload),

  playSourceFileOnLoad: () => createTypedAction(PLAY_SOURCE_FILE_ON_LOAD),

  readyForPublicationSong: (payload: { songUuid: string }) =>
    createTypedAction(READY_FOR_PUBLICATION_SONG, payload),
  readyForPublicationSongSuccess: (payload: { data: Nl.Api.Song }) =>
    createTypedAction(READY_FOR_PUBLICATION_SONG_SUCCESS, payload),
  readyForPublicationSongError: (payload: { error: string }) =>
    createTypedAction(READY_FOR_PUBLICATION_SONG_ERROR, payload),
};

export const { initSingleSong } = actions;
export const { fetchSingleSong } = actions;
export const { fetchSingleSongSuccess } = actions;
export const { createSong } = actions;
export const { editSong } = actions;
export const { generateSongZips } = actions;
export const { downloadZip } = actions;
export const { pollSourceFiles } = actions;
export const { stopSourceFilesPolling } = actions;
export const { fetchSourceFiles } = actions;
export const { clearSongData } = actions;
export const { publishSong } = actions;
export const { takeDownSong } = actions;
export const { readyForPublicationSong } = actions;
export const { playSourceFileOnLoad } = actions;

export const songFormInitialValues = {
  uuid: '' as string,
  work_title: '' as string,
  work_id: '' as string,
  description: '' as string,
  description_hidden: '' as string,
  lyrics: '' as string,
  publisher_ruleset_uuid: '' as string,
  artist_uuid: '' as string,
  assigned_to_name: '' as string,
  genre_uuids: [] as string[],
  theme_uuids: [] as string[],
  instrument_uuids: [] as string[],
  tags: [] as string[],
  tags_hidden: [] as string[],
  artist_suggested_instruments: '' as string,
  artist_suggested_usage: '' as string,
  artist_notes: '' as string,
  artist_suggested_description: '' as string,
  artist_suggested_lyrics: '' as string,
  version_type: '' as string | null,
  original_composer: '' as string,
  duration: 0 as number,
  bpm: 0 as number | null,
  isrc: '' as string,
  curation_state: null as any,
  song_collection_uuids: [] as string[],
  writer_shares: [] as Nl.WriterShares[],
  source_files: [] as Nl.SourceFileExtended[],
  content_tier: null as string | null,
  additional_lyrics_subjects: '' as string,
  auto_tagging_enabled: true as boolean,
  taxonomy: {} as Nl.Api.TaxonomyFields,
  revision_note: '' as string | null,
  revision_requested_at: '' as string | null,
  inspection_state: '' as string,
};

export type SongFormInitialValues = typeof songFormInitialValues;

// Selector
export const getEditFormInitialValues = (
  song?: Nl.Api.Song,
): SongFormInitialValues => {
  if (!song) {
    return songFormInitialValues;
  }

  return {
    uuid: song.uuid,
    work_title: song.work_title,
    artist_uuid: song.artist?.uuid,
    assigned_to_name: song?.artist?.assigned_to?.full_name ?? 'Unassigned',
    publisher_ruleset_uuid: song.publisher_ruleset?.uuid,
    version_type: song.version_type,
    bpm: song.bpm,
    duration: song.duration,
    original_composer: song.original_composer || '',
    isrc: song.isrc,
    work_id: song.work_id,
    curation_state: song.curation_state ? song.curation_state.uuid : null,
    song_collection_uuids: song.song_collections.map(
      (collection) => collection.uuid,
    ),
    writer_shares: song.writer_shares.map((writerShare) => {
      return {
        writer_uuid: writerShare.writer.uuid,
        name: writerShare.writer.full_name,
        ipi_number: writerShare.writer.ipi_number,
        pro: writerShare.writer.performing_rights_organization?.name,
        share: writerShare.share,
      };
    }),
    source_files: [
      ...song.source_files.map((sourceFile) => ({
        uuid: sourceFile.uuid,
        asset_status: sourceFile.asset_status,
        file_type: sourceFile.file_type,
        original_file_name: sourceFile.original_file_name,
        status: sourceFile.status,
        meets_quality_requirements: sourceFile.meets_quality_requirements,
        label: sourceFile.label,
        duration: sourceFile.duration,
        sample_rate: sourceFile.sample_rate,
        channels: sourceFile.channels,
        bit_depth: sourceFile.bit_depth,
        revision_note: sourceFile.revision_note || null,
        created_at: sourceFile.created_at,
        updated_at: sourceFile.updated_at,
      })),
      ...buildSourceFilesTable(song.source_files),
    ],
    description: song.description,
    description_hidden: song.description_hidden,
    genre_uuids: song.genres.map((genre) => genre.uuid),
    theme_uuids: song.themes.map((theme) => theme.uuid),
    instrument_uuids: song.instruments.map((instrument) => instrument.uuid),
    tags: song.tags,
    tags_hidden: song.tags_hidden,
    lyrics: song.lyrics,
    artist_suggested_instruments: song.artist_suggested_instruments || '',
    artist_suggested_usage: song.artist_suggested_usage || '',
    artist_notes: song.artist_notes || '',
    artist_suggested_description: song.artist_suggested_description || '',
    artist_suggested_lyrics: song.artist_suggested_lyrics || '',
    content_tier: song.content_tier?.uuid ?? null,
    additional_lyrics_subjects: song.additional_lyrics_subjects || '',
    auto_tagging_enabled: song.auto_tagging_enabled,
    taxonomy: song.taxonomy || {},
    revision_note: song.revision_note || null,
    revision_requested_at: song.revision_requested_at || null,
    inspection_state: song.inspection_state,
  };
};

/**
 * Reducer
 */
const initialState = {
  data: {
    total_size: 0,
  } as Partial<Nl.Api.SongResponse>,
  isLoading: false,
  isLoaded: false,
  sourceFileUploads: [] as Nl.Api.SourceFileUpload[],
};

export type SingleSongState = Readonly<typeof initialState>;

type Actions = ActionsUnion<typeof actions>;

const reducer = (state: SingleSongState = initialState, action: Actions) => {
  switch (action.type) {
    case INIT_SINGLE_SONG: {
      return initialState;
    }

    case FETCH_SINGLE_SONG: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SINGLE_SONG_SUCCESS:
    case EDIT_SONG_SUCCESS:
    case GENERATE_SONG_ZIPS_SUCCESS: {
      const { data } = action.payload;
      return assign(state, {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        sourceFileUploads: data.song?.source_file_uploads.filter(
          (s) => s.status === 'pending',
        ),
        isLoaded: true,
        isLoading: false,
      });
    }

    case FETCH_SINGLE_SONG_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }

    case FETCH_SOURCE_FILES_SUCCESS: {
      const { data } = action.payload;
      return assign(state, {
        data: {
          ...state.data,
          song: {
            ...state.data.song,
            ...data.song,
          } as Nl.Api.Song,
        },
        sourceFileUploads: data.song.source_file_uploads.filter(
          (s) => s.status === 'pending',
        ),
      });
    }

    case PUBLISH_SONG_SUCCESS:
    case TAKE_DOWN_SONG_SUCCESS:
    case READY_FOR_PUBLICATION_SONG_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        data: {
          ...state.data,
          song: {
            ...state.data.song,
            ...payload.data,
          },
        },
      };
    }

    case CLEAR_SONG_DATA: {
      return assign(state, {
        data: {
          ...state.data,
          song: initialState.data.song,
        },
      });
    }

    default:
      return state;
  }
};

const prepareData = (data: SongFormInitialValues): SongFormInitialValues => {
  return {
    ...data,
    source_files: Array.isArray(data.source_files)
      ? data.source_files.filter((f) => f.state !== 'init')
      : [],
    version_type: data.version_type || null,
    bpm: data.bpm ? Number(data.bpm) : null,
  };
};

/**
 * This function is called from the saga to start the sourceFiles data polling
 */
function* pollSourceFilesLoop(songUuid: string) {
  try {
    while (true) {
      const isLoaded: boolean = yield select(
        (state: GlobalState) => state.singleSong.data.song !== undefined,
      );
      if (isLoaded) {
        // If the song data is already loaded then update the source files data
        yield put(actions.fetchSourceFiles({ songUuid }));
      }
      // Poll with an interval of 10 seconds
      yield delay(10000);
    }
  } finally {
    // Dispatch an action to log if the loop stop because it was cancelled
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put({ type: 'EVENT LOOP CANCELLED' });
    } else {
      yield put({ type: 'EVENT LOOP ENDED' });
    }
  }
}

// Sagas
const sagas = {
  *fetchSingleSongSaga(action: ReturnType<typeof actions.fetchSingleSong>) {
    const { uuid } = action.payload;
    const results: FetchResponse<Nl.Api.SongResponse> = yield call(
      apiFetch,
      `/song/${uuid}`,
    );
    if (results.success) {
      const sourceFiles = results.data.song?.source_files;
      // Fetch the waveforms for the asset already processed
      const sourceFilesWithAssetCompleted = sourceFiles?.filter(
        (sourceFile) => sourceFile.asset_status === 'processing_complete',
      );
      if (sourceFilesWithAssetCompleted!.length > 0) {
        yield put(
          fetchWaveforms({
            uuids: sourceFilesWithAssetCompleted!.map((s) => s.uuid),
          }),
        );
      }
      yield put(actions.fetchSingleSongSuccess({ data: results.data }));
    } else {
      yield put(actions.fetchSingleSongError({ error: results.msg }));
      yield put(
        addErrorNotification({
          message: `Impossible to fetch the song ${uuid}`,
        }),
      );
    }
  },
  *createSongSaga(action: ReturnType<typeof actions.createSong>) {
    const { formData, formActions } = action.payload;
    const formattedData = prepareData(formData);
    const results: FetchResponse<{ song: Nl.Api.Song }> = yield call(
      apiFetch,
      '/song',
      {
        method: 'POST',
        body: formattedData,
      },
    );
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.work_title} has been created`,
        }),
      );
      yield put({
        type: 'route/SONG_EDIT',
        payload: { uuid: results.data.song.uuid },
      });
    } else {
      yield put(actions.createSongError({ error: results.msg }));
      yield put(
        addErrorNotification({
          message: 'An error occurred. Please check your submission.',
        }),
      );
      formActions.setStatus(results.errors);
    }
    formActions.setSubmitting(false);
  },
  *editSongSaga(action: ReturnType<typeof actions.editSong>) {
    const { formData, formActions } = action.payload;
    const formattedData = prepareData(formData);

    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/song/${formattedData.uuid}`,
      { method: 'PUT', body: formattedData },
    );
    if (results.success) {
      // Creative inspirations can be auto-created -> get the freshest list
      invalidateNovaQueries('/tag/select?tag_field=creative_inspiration');

      yield put(actions.editSongSuccess({ data: results.data }));
      yield put(
        addSuccessNotification({
          message: `${results.data.song.work_title} has been updated`,
        }),
      );
      // Available content tiers for the song can be affected by the change
      yield invalidateNovaQueries('/content_tier/select');
    } else {
      yield put(
        addErrorNotification({
          message: 'An error occurred. Please check your submission.',
        }),
      );
      formActions.setStatus(results.errors);
    }
    formActions.setSubmitting(false);
  },
  *publishSong(action: ReturnType<typeof actions.publishSong>) {
    const { songUuid } = action.payload;
    const results: FetchResponse<{
      songs: Nl.Api.Song[];
    }> = yield call(postPublishSong, [songUuid]);
    if (results.success) {
      const song = results.data.songs[0];
      yield put(actions.publishSongSuccess({ data: song }));
      yield put(
        addSuccessNotification({
          message: `The song ${song.work_title} is publishing`,
        }),
      );
    } else {
      yield put(actions.publishSongError({ error: results.msg }));
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *takeDownSong(action: ReturnType<typeof actions.publishSong>) {
    const { songUuid } = action.payload;
    const results: FetchResponse<{
      songs: Nl.Api.Song[];
    }> = yield call(postTakeDownSong, [songUuid]);
    if (results.success) {
      const song = results.data.songs[0];
      yield put(actions.takeDownSongSuccess({ data: song }));
      yield put(
        addSuccessNotification({
          message: `The song ${song.work_title} is taking down`,
        }),
      );
    } else {
      yield put(actions.takeDownSongError({ error: results.msg }));
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *readyForPublicationSong(
    action: ReturnType<typeof actions.readyForPublicationSong>,
  ) {
    const { songUuid } = action.payload;
    const results: FetchResponse<{
      songs: Nl.Api.Song[];
    }> = yield call(postReadyForPublication, [songUuid]);
    if (results.success) {
      const song = results.data.songs[0];
      yield put(actions.readyForPublicationSongSuccess({ data: song }));
      yield put(
        addSuccessNotification({
          message: `The song ${song.work_title} is now ready for publication`,
        }),
      );
    } else {
      yield put(actions.readyForPublicationSongError({ error: results.msg }));
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *pollSourceFiles(action: ReturnType<typeof actions.pollSourceFiles>) {
    const { songUuid } = action.payload;

    // starts the task in the background
    const pollForEventsTask: Task = yield fork(pollSourceFilesLoop, songUuid);

    // wait for the user stop action
    yield take(
      (takenAction: SimpleActionType) =>
        (takenAction.type === STOP_SOURCE_FILE_POLLING &&
          takenAction.payload.songUuid === songUuid) ||
        (takenAction.type === FETCH_SOURCE_FILES_ERROR &&
          takenAction.payload.songUuid === songUuid),
    );
    // user clicked stop. cancel the background task
    // this will throw a SagaCancellationException into the forked bgSync task
    yield cancel(pollForEventsTask);
  },
  *fetchSourceFiles(action: ReturnType<typeof actions.fetchSourceFiles>) {
    const response: FetchResponse<Nl.Api.SongResponse> = yield call(
      fetchProcessingSourceFiles,
      action.payload.songUuid,
    );

    if (response.success) {
      // Fetch the waveforms for the asset already processed
      const sourceFilesWithAssetCompleted = response.data.song.source_files.filter(
        (sourceFile) => sourceFile.asset_status === 'processing_complete',
      );
      const uuids = sourceFilesWithAssetCompleted.map(
        (sourceFile) => sourceFile.uuid,
      );
      yield put(fetchWaveforms({ uuids }));
      yield put(actions.fetchSourceFileSuccess(response));
    } else {
      yield put(
        actions.fetchSourceFileError({
          songUuid: action.payload.songUuid,
          error: response.msg,
        }),
      );
    }
  },
  *generateSongZips(action: ReturnType<typeof actions.generateSongZips>) {
    const response: FetchResponse<Nl.Api.SongResponse> = yield call(
      postGenerateSongZips,
      action.payload.songUuid,
    );

    if (response.success) {
      yield put(actions.generateSongZipsSuccess(response));
    } else {
      yield put(actions.generateSongZipsError({ error: response.msg }));
    }
  },
  *downloadZips(action: ReturnType<typeof actions.downloadZip>) {
    const response: FetchResponse<Nl.Api.SongZipsResponse> = yield call(
      getSongZips,
      action.payload.songUuid,
    );

    if (response.success) {
      const url = response.data.song.zip_urls[action.payload.type];
      downloadFile(url);
    } else {
      // eslint-disable-next-line no-console
      console.warn(response.msg);
    }
  },
  *playSourceFileOnLoad() {
    // get the source_file_uuid params from the url
    const queryParams: ReturnType<typeof getRawUrlQueryParams> = yield select(
      getRawUrlQueryParams,
    );
    const sourceFileUuid = queryParams.source_file_uuid;

    // is the source_file_uuid param exist in the url
    if (sourceFileUuid) {
      // then wait for the fetch song to be finished
      const actionSuccess: ReturnType<
        typeof fetchSingleSongSuccess
      > = yield take([FETCH_SINGLE_SONG_SUCCESS, FETCH_SINGLE_SONG_ERROR]);
      if (actionSuccess.type === FETCH_SINGLE_SONG_SUCCESS) {
        // and then start to play the requested source file
        const { song } = actionSuccess.payload.data;

        const sourceFile = song!.source_files.find(
          (sf) => sf.uuid === sourceFileUuid,
        );

        if (!sourceFile) {
          yield put(
            addErrorNotification({
              message: `The source file ${sourceFileUuid} does not exist`,
            }),
          );
        } else {
          const track = {
            uuid: sourceFile.uuid,
            trackType: sourceFile.file_type,
            url: sourceFile.original_wav_url,
            trackName: sourceFile.label,
            duration: sourceFile.duration,
          };

          yield put(loadPlayerTrack({ track }));
        }
      }
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(FETCH_SINGLE_SONG, sagas.fetchSingleSongSaga),
    takeLatest(CREATE_SONG, sagas.createSongSaga),
    takeLatest(EDIT_SONG, sagas.editSongSaga),
    takeLatest(GENERATE_SONG_ZIPS, sagas.generateSongZips),
    takeLatest(DOWNLOAD_SONG_ZIP, sagas.downloadZips),
    takeLatest(START_POLL_SOURCE_FILES, sagas.pollSourceFiles),
    takeLatest(FETCH_SOURCE_FILES, sagas.fetchSourceFiles),
    takeLeading(PUBLISH_SONG, sagas.publishSong),
    takeLeading(TAKE_DOWN_SONG, sagas.takeDownSong),
    takeLeading(READY_FOR_PUBLICATION_SONG, sagas.readyForPublicationSong),
    takeLeading(PLAY_SOURCE_FILE_ON_LOAD, sagas.playSourceFileOnLoad),
  ]);
}

export { sagas };
export default reducer;
