import * as React from 'react';
import { useDispatch } from 'react-redux';
import { editWriter } from 'store/writers';
import FormikWrapper from 'components/Formik';
import WriterForm, { getEditFormInitialValues } from './WriterForm';
import validationSchema from './validation';
import { useWriter } from 'novaApi/hooks/writer';
import { useNlSelector } from 'utils/redux';
import { getUrlPayloadParams } from 'novaApi/apiUtils';

const WriterEditPage = () => {
  const dispatch = useDispatch();

  const { uuid } = useNlSelector(getUrlPayloadParams);
  const writer = useWriter(uuid!);
  const initialValues = getEditFormInitialValues(writer, true);

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) =>
        dispatch(editWriter({ formData, formActions }))
      }
      title='Writers Form'
    >
      <WriterForm />
    </FormikWrapper>
  );
};

export default WriterEditPage;
