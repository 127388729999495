import { createAction, SimpleActionType, assign } from 'utils/storeUtils';
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { apiFetch } from 'novaApi/apiUtils';

const FIND_SONGS = 'FIND_SONGS';
const FIND_SONGS_SUCCESS = 'FIND_SONGS_SUCCESS';
const CLEAR_SONG_FINDER_DATA = 'CLEAR_SONG_FINDER_DATA';

export const findSongs = createAction(FIND_SONGS);
const findSongsSuccess = createAction(FIND_SONGS_SUCCESS);
export const clearSongFinderData = createAction(CLEAR_SONG_FINDER_DATA);

const initialState = {
  data: {
    items: [] as Nl.Api.SongFinderItem[],
  },
  isLoading: false,
  isLoaded: false,
};

export type SongFinderState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FIND_SONGS:
      return assign(initialState, {
        isLoading: true,
        isLoaded: false,
      });

    case FIND_SONGS_SUCCESS:
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });

    case CLEAR_SONG_FINDER_DATA:
      return initialState;

    default:
      return state;
  }
};

const sagas = {
  *findSongs(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const { success, errors, data } = yield call(
      apiFetch,
      `/song/finder?song_id=${formData.song_id}`,
    );
    if (success) {
      yield put(findSongsSuccess({ data }));
    } else {
      formActions.setErrors(errors);
    }
    formActions.setSubmitting(false);
  },
};

export function* rootSaga() {
  yield all([takeLatest(FIND_SONGS, sagas.findSongs)]);
}

export { sagas };
export default reducer;
