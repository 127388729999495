import React from 'react';
import { useNlSelector } from 'utils/redux';
import { selectPermission } from 'store/selectors';

import { getUrlPayloadParams } from 'novaApi/apiUtils';
import FormikWrapper from 'components/Formik';
import TagForm, { getFormInitialValues } from './TagsForm';
import { useTag, useTagUpdate } from 'novaApi/hooks/tag';
import { goPreviousPage } from 'store/router';
import { useDispatch } from 'react-redux';
import { getValidationSchema } from './validation';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import ChipMeter from 'components/Chip/ChipMeter';

const TagEditPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useNlSelector(getUrlPayloadParams);

  const { data, isLoaded } = useTag(uuid!);
  const { updateTag, updatedTag, isSuccess } = useTagUpdate(uuid!);

  const canUpdateTag = useNlSelector((state) =>
    selectPermission(state, 'CAN_UPDATE_TAG'),
  );

  const handleFormSubmit = (formData: Nl.Api.Tag, formActions) => {
    formData.numerical_value = formData.numerical_value
      ? formData.numerical_value
      : null;
    formData.average_tempo = formData.average_tempo
      ? formData.average_tempo
      : null;
    updateTag({ formData, formActions });
  };

  if (!data && isLoaded) return null;
  if (updatedTag && isSuccess) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={getFormInitialValues(data?.tag)}
      validationSchema={getValidationSchema('EDIT')}
      submitForm={(formData, formActions) => {
        handleFormSubmit(formData as Nl.Api.Tag, formActions);
      }}
      title='Tag details'
      customSize='md'
      extraActions={
        canUpdateTag ? undefined : (
          <ChipMeter label='Read only' indicator='blue' />
        )
      }
    >
      <TagForm isEdit readOnly={!canUpdateTag} />

      {uuid && <CollapsableUserLogs activityType='tags' resourceUuid={uuid} />}
    </FormikWrapper>
  );
};

export default TagEditPage;
