import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Icon, Tooltip } from '@material-ui/core';
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone';
import AudioControlIcon from 'components/AudioControlIcon';
import { destroyTrackSubmission } from 'store/trackSubmissions';

interface AllProps {
  track: Nl.Api.TrackSubmission | Nl.Api.SingleTrackSubmission;
  disablePlayBtn?: boolean;
}

export const MyTracksActionsView: React.FunctionComponent<AllProps> = ({
  track,
  disablePlayBtn,
}) => {
  const dispatch = useDispatch();

  const renderEmptyOrDisabledIcon = (icon?: React.ReactElement) => (
    <IconButton disabled>{icon || <Icon />}</IconButton>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {track.source_file && !disablePlayBtn ? (
        <AudioControlIcon
          audioTrack={{
            uuid: track.source_file?.uuid,
            trackType: 'song',
            url: track.source_file?.url,
            trackName: track.name,
            duration: track.source_file?.duration ?? 0,
            waveform_json_url: track.source_file.waveform_json_url,
          }}
        />
      ) : (
        renderEmptyOrDisabledIcon()
      )}
      {track.is_deletable ? (
        <Tooltip title='Delete Submission Permanently'>
          <IconButton
            onClick={() => {
              if (
                window.confirm('Are you sure you want to delete this track?')
              ) {
                dispatch(destroyTrackSubmission({ uuid: track.uuid }));
              }
            }}
            className='destroy'
            disabled={!track.is_deletable}
            data-e2e='deleteForm'
          >
            <DeleteForeverTwoToneIcon color='action' />
          </IconButton>
        </Tooltip>
      ) : (
        renderEmptyOrDisabledIcon(<DeleteForeverTwoToneIcon color='disabled' />)
      )}
    </div>
  );
};

export default MyTracksActionsView;
