import React from 'react';
import { Grid } from '@material-ui/core';
import NoteBox from './NoteBox';
import notesConfig from './config';
import { useNotes } from 'novaApi/hooks/note';
import { invalidateNovaQueries, fetchNovaApi } from 'novaApi/useNovaApi';
import { addErrorNotification } from 'store/notifications';
import { useDispatch } from 'react-redux';

interface AllProps {
  config: Nl.ParentNoteType;
  uuid: string;
}

const NotesWrapper = ({ config, uuid }: AllProps) => {
  const dispatch = useDispatch();
  const { noteTypes, parentType } = notesConfig[config];
  const notes = useNotes(parentType, uuid);

  const postNote = async (text: string, type: Nl.Api.NoteType) => {
    try {
      await fetchNovaApi<Nl.Api.NoteResponse>('/note', {
        method: 'POST',
        body: {
          text,
          note_type: type,
          parent_type: parentType,
          parent_uuid: uuid,
        },
      });
      invalidateNovaQueries(`/note`);
    } catch (error) {
      dispatch(addErrorNotification({ message: error.message }));
    }
  };

  return (
    <Grid container>
      {noteTypes.map(({ type, label, apiNoteType }: Nl.NoteParameters) => (
        <Grid key={type} item xs>
          <NoteBox
            notes={notes?.[type] ?? []}
            label={label}
            sendNote={(text) => postNote(text, apiNoteType)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default NotesWrapper;
