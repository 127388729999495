import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import {
  getEditFormInitialValues,
  editPublisher as editPublisherAction,
} from 'store/publishers';
import PublisherForm from './PublisherForm';
import validationSchema from './validation';
import { useNlSelector } from 'utils/redux';

export default function PublisherEditPage() {
  const publisher = useNlSelector((state) => state.publishers.data.publisher);
  const dispatch = useDispatch();
  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues(publisher)}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) =>
        dispatch(editPublisherAction({ formData, formActions }))
      }
      title='Edit Publisher'
    >
      <PublisherForm />
      {publisher && (
        <CollapsableUserLogs
          activityType='publishers'
          resourceUuid={publisher?.uuid}
        />
      )}
    </FormikWrapper>
  );
}
