import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import { createUser, getEditFormInitialValues } from 'store/users';
import { selectNotificationStatus } from 'store/selectors';
import UserForm from './UserForm';
import validationSchema from './validation';
import { useNlSelector } from 'utils/redux';

export default function UserCreatePage() {
  const notificationStatus = useNlSelector(selectNotificationStatus);
  const dispatch = useDispatch();

  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues()}
      validationSchema={validationSchema(true, notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(createUser({ formData, formActions }))
      }
      title='Create New User'
    >
      <UserForm />
    </FormikWrapper>
  );
}
