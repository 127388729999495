import React from 'react';
import { Field, getIn } from 'formik';
import { get } from 'lodash';
import Link from 'components/Link/InternalLink';
import { FormSubTitle } from 'components/Formik/utils';
import DeleteButton from 'components/Button/DeleteButton';
import {
  Switch,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Typography,
} from '@material-ui/core';
import { getReadableDate, multipleSelectFormat } from 'utils/utils';
import { FormikProps } from 'formik/dist/types';
import Typeahead from 'components/Typeahead/Typeahead';
import AddIcon from '@material-ui/icons/Add';

// Export if ever used elsewhere into Formik folder
const FormikSwitch: React.SFC<any> = ({ field, form }) => {
  return (
    <Switch
      color='primary'
      edge='start'
      checked={!!field.value}
      onChange={() => form.setFieldValue(field.name, !field.value)}
    />
  );
};

interface AllProps {
  form: FormikProps<any>;
  name: string;
  push: (object: any) => void;
  remove: (index: number) => void;
  writers: Nl.Api.SelectorEntity[];
}

const ArtistWritersTable: React.SFC<AllProps> = ({
  form,
  name,
  push,
  remove,
  writers = [],
}) => {
  const writersFields: Nl.Api.ArtistWriter[] = getIn(form.values, name) || [];
  const pastWritersFields: Nl.Api.ArtistPastWriter[] =
    getIn(form.values, 'past_writers') || [];
  const pastWritersDescription =
    "This table lists all writers that are not included in this artist's current writer(s) that they have worked with.";

  const renderWriterLink = (full_name: string, uuid: string) => (
    <TableCell>
      <Link
        to={{
          type: 'route/WRITERS_EDIT',
          payload: { uuid },
        }}
        data-e2e='writerLink'
      >
        {full_name}
      </Link>
    </TableCell>
  );
  const getNonUsedWriters = (
    currentWriterUUID: string,
    selectedWriters: Nl.Api.ArtistWriter[],
    allWriters: Nl.Api.SelectorEntity[],
  ) => {
    return allWriters.filter(
      ({ uuid }) =>
        currentWriterUUID === uuid ||
        !selectedWriters.find((w) => w.uuid === uuid),
    );
  };
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>IPI</TableCell>
            <TableCell>PRO</TableCell>
            <TableCell>Is Default?</TableCell>
            <TableCell>Songs Together</TableCell>
            <TableCell>Added On</TableCell>
            <TableCell>Last Modified</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {writersFields.map(
            (
              {
                full_name,
                uuid,
                ipi_number,
                performing_rights_organization,
                songs_together,
                created_at,
                updated_at,
                nameIsEditable,
              },
              index,
            ) => {
              return (
                <TableRow key={`writers.${index}.full_name`}>
                  {nameIsEditable ? (
                    <TableCell>
                      <Field
                        name={`writers.${index}.uuid`}
                        label="Writer's name"
                        noClearable
                        isSubSection
                        component={Typeahead}
                        options={multipleSelectFormat(
                          getNonUsedWriters(uuid, writersFields, writers),
                        )}
                      />
                    </TableCell>
                  ) : (
                    renderWriterLink(full_name, uuid)
                  )}
                  <TableCell>{ipi_number}</TableCell>
                  <TableCell>
                    {get(performing_rights_organization, 'name', '')}
                  </TableCell>
                  <TableCell>
                    <Field
                      name={`writers.${index}.is_default`}
                      component={FormikSwitch}
                    />
                  </TableCell>
                  <TableCell>{songs_together}</TableCell>
                  <TableCell>
                    {created_at && getReadableDate(created_at)}
                  </TableCell>
                  <TableCell>
                    {updated_at && getReadableDate(updated_at)}
                  </TableCell>
                  <TableCell>
                    <DeleteButton
                      aria-label='Remove writer'
                      onClick={() => remove(index)}
                    />
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </TableBody>
      </Table>
      <div
        style={{
          width: '250px',
          marginTop: '.5rem',
        }}
      >
        <Button
          data-e2e='addButton'
          onClick={() => {
            push({
              uuid: undefined,
              full_name: undefined,
              nameIsEditable: true,
            });
          }}
        >
          <AddIcon />
          Add
        </Button>
      </div>
      <br />
      {!!pastWritersFields.length && (
        <>
          <FormSubTitle title='Past Writers' />
          <Typography variant='caption'>{pastWritersDescription}</Typography>
          <Table data-e2e='pastWritersTable'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>IPI</TableCell>
                <TableCell>PRO</TableCell>
                <TableCell>Songs Together</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pastWritersFields.map(
                ({
                  performing_rights_organization,
                  full_name,
                  uuid,
                  ipi_number,
                  songs_together,
                }) => (
                  <TableRow key={full_name}>
                    {renderWriterLink(full_name, uuid)}
                    <TableCell>{ipi_number}</TableCell>
                    <TableCell>
                      {get(performing_rights_organization, 'name', '')}
                    </TableCell>
                    <TableCell>{songs_together}</TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

export default ArtistWritersTable;
