import { useNovaApi } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';

export const useTrackSubmissions = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.TrackSubmissionsResponse>(`/new_track_submission`, {
    query,
  }).data;
};
