// eslint-disable-next-line import/extensions
const ChecksumWorker = require('./checksum.worker.ts');

/**
 * The checksum worker compute a file's checksum in a web worker and return the hash as a promise
 */
export default async function checksumWorker(file: File): Promise<Nl.Hash> {
  const checksumListener = new ChecksumWorker();
  // use webWorker to compute the checksum
  checksumListener.postMessage({ file });

  return new Promise<Nl.Hash>((resolve, reject) => {
    checksumListener.onmessage = (event: { data: string }) => {
      const hash = event.data;
      if (hash) {
        resolve(hash);
      } else {
        reject(new Error('Impossible to compute the checksum'));
      }
    };
    checksumListener.onerror = () => {
      reject(new Error('Failed to spawn checksum worker'));
    };
  });
}
