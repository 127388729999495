import { put, call, select, takeLatest, all } from 'redux-saga/effects';
import { ActionsUnion, createTypedAction, assign } from 'utils/storeUtils';
import { getUrlQueryParams, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification, addNotification } from './notifications';

import infoMessages from 'constants/infoMessages';
import { getSalesPerTrack } from 'novaApi/NovaApi';

/**
 * Action Constants
 */
const FETCH_SALES_PER_TRACK = 'FETCH_SALES_PER_TRACK';
const FETCH_SALES_PER_TRACK_SUCCESS = 'FETCH_SALES_PER_TRACK_SUCCESS';
const FETCH_SALES_PER_TRACK_ERROR = 'FETCH_SALES_PER_TRACK_ERROR';

/**
 * Action Creators
 */
export const actions = {
  fetchSalesPerTrack: () => createTypedAction(FETCH_SALES_PER_TRACK),
  fetchSalesPerTrackSuccess: (payload: {
    data: Nl.Api.SalesPerTrackResponse;
  }) => createTypedAction(FETCH_SALES_PER_TRACK_SUCCESS, payload),
  fetchSalesPerTrackError: (payload: { error: string }) =>
    createTypedAction(FETCH_SALES_PER_TRACK_ERROR, payload),
};

export const { fetchSalesPerTrack } = actions;

/**
 * Reducer
 */
const initialState = {
  data: {
    songs: [],
    total_size: 0,
    current_size: 0,
    current_page: 0,
    offset: 0,
    total_pages: 0,
  } as Nl.Api.SalesPerTrackResponse,
  isLoading: false,
  isLoaded: false,
};

export type SalesPerTrackState = Readonly<typeof initialState>;

type Actions = ActionsUnion<typeof actions>;

const reducer = (state: SalesPerTrackState = initialState, action: Actions) => {
  switch (action.type) {
    case FETCH_SALES_PER_TRACK: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SALES_PER_TRACK_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSalesPerTrackSaga() {
    const queryParams: string = yield select(getUrlQueryParams);
    const results: FetchResponse<Nl.Api.SalesPerTrackResponse> = yield call(
      getSalesPerTrack,
      queryParams,
    );
    if (results.success) {
      yield put(actions.fetchSalesPerTrackSuccess({ data: results.data }));
      yield put(
        addNotification({
          message: infoMessages.premiumBeatDataOnly,
          persist: true,
        }),
      );
    } else {
      yield put(
        addErrorNotification({
          message: 'Impossible to fetch the sales per track.',
        }),
      );
      yield put(actions.fetchSalesPerTrackError({ error: results.msg }));
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([takeLatest(FETCH_SALES_PER_TRACK, sagas.fetchSalesPerTrackSaga)]);
}

export { sagas };
export default reducer;
