import * as React from 'react';
import InputField from 'components/Formik/InputField';
import { FormSubTitle } from 'components/Formik/utils';
import { Field, Form } from 'formik';

const tagRelationTypeInitialValues = {
  uuid: '',
  name: '',
  description: '',
};

export const getFormInitialValues = (
  tagRelationType?: Nl.Api.TagRelationType,
) => {
  if (!tagRelationType) return { ...tagRelationTypeInitialValues };

  return {
    uuid: tagRelationType.uuid,
    name: tagRelationType.name,
    description: tagRelationType.description,
  };
};

const TagRelationTypeForm = () => {
  return (
    <Form>
      <FormSubTitle title='Tag Relation Type Information' />
      <Field
        name='name'
        label='Tag Relation Type name'
        component={InputField}
      />
      <Field
        name='description'
        label='Description'
        component={InputField}
        multiline
        rows={1}
        rowsMax={4}
      />
    </Form>
  );
};

export default TagRelationTypeForm;
