import React from 'react';
import ChipMeter from 'components/Chip/ChipMeter';

interface AllProps {
  isRoyalty?: boolean;
  isPROFree?: boolean;
  isDormant?: boolean;
}

interface ChipConfig {
  prop: keyof AllProps;
  label: string;
  indicator: 'low' | 'high' | 'blue';
}

const chipsConfig: ChipConfig[] = [
  { prop: 'isRoyalty', label: 'Royalty', indicator: 'low' },
  { prop: 'isPROFree', label: 'PRO Free', indicator: 'high' },
  { prop: 'isDormant', label: 'Dormant', indicator: 'blue' },
];

const ArtistIndications: React.FunctionComponent<AllProps> = (props) => {
  return (
    <>
      {chipsConfig
        .filter((chipConfig) => props[chipConfig.prop])
        .map((chipConfig) => (
          <ChipMeter
            key={chipConfig.label}
            label={chipConfig.label}
            indicator={chipConfig.indicator}
          />
        ))}
    </>
  );
};

export default ArtistIndications;
