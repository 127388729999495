import * as React from 'react';
import {
  Typography,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(8),
      color: '#777',
    },
  });

type AllProps = WithStyles<typeof styles>;

const NotFoundPage: React.SFC<AllProps> = ({ classes }) => (
  <div className={classes.container}>
    <Typography variant='h1' align='center'>
      404
    </Typography>
    <Typography variant='h4' align='center'>
      Page not found.
    </Typography>
    <Typography variant='h3' align='center'>
      Sorry!
    </Typography>
  </div>
);

export default withStyles(styles)(NotFoundPage);
