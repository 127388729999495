import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Collapse, TextField } from '@material-ui/core';
import data from '@emoji-mart/data';
import { get } from 'lodash';
import Picker from '@emoji-mart/react';

interface EmojiPickerProps {
  label: string;
  placeholder: string;
  field: any;
  form: any;
}

const useStyles = makeStyles(() => ({
  root: {
    '& em-emoji-picker': {
      width: '100%',
    },
  },
}));

export default function EmojiPicker({
  label,
  placeholder,
  field,
  form,
}: EmojiPickerProps) {
  const [showPicker, setShowPicker] = useState(false);
  const classes = useStyles();

  const handleEmojiSelect = (emojiObject) => {
    form.setFieldValue(field.name, emojiObject.native);
    setShowPicker(false);
  };

  const handleOutsideClick = () => {
    if (showPicker) setShowPicker(false);
  };

  return (
    <Box className={classes.root}>
      <TextField
        style={{
          width: '100%',
          marginTop: '1rem',
        }}
        placeholder={placeholder}
        variant='outlined'
        value={field.value ?? ''}
        inputProps={{ name: field.name, 'aria-label': label, readOnly: true }}
        InputLabelProps={{ style: { pointerEvents: 'auto' } }}
        label={label}
        onClick={() => setShowPicker(true)}
        error={!!get(form.errors, field.name)}
        helperText={get(form.errors, field.name)}
        data-e2e='emojiPickerInput'
      />
      <Collapse in={showPicker}>
        <Box data-e2e='emoji-picker'>
          <Picker
            data={data}
            dynamicWidth
            previewPosition='none'
            onEmojiSelect={handleEmojiSelect}
            onClickOutside={handleOutsideClick}
          />
        </Box>
      </Collapse>
    </Box>
  );
}
