import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { uploadFile } from 'store/upload';
import UploadDropzone from 'components/Dropzone/UploadDropzone';

interface OwnProps {
  songUuid: string;
  contentType: 'application/zip' | 'audio/wav';
  sourceFile: any;
  type: string;
  state?: Nl.SourceFileUploadState;
  formValues: Nl.UploadFormValues;
}

interface DispatchProps {
  onUpload: (file: File) => void;
}

type AllProps = OwnProps & DispatchProps;

const SongEditUploadDropzone: React.SFC<AllProps> = ({
  onUpload,
  type,
  state,
  contentType,
  sourceFile,
  formValues,
}) => {
  const fileType = contentType === 'application/zip' ? 'zip' : 'wav';

  return (
    <UploadDropzone
      accept={contentType}
      displayFileType={fileType}
      onDrop={(accepted) => {
        if (accepted.length > 0) {
          onUpload(accepted[0]);
        }
      }}
      onClear={() => {
        formValues.set(`${formValues.field}.state`, 'init');
      }}
      state={state}
      disabled={!type}
      doneMessage={sourceFile.filename}
    />
  );
};

export default compose(
  connect(
    null,
    (dispatch, { songUuid, type, contentType, formValues }: OwnProps) => ({
      onUpload: (file: File) => {
        dispatch(
          uploadFile({
            contentType,
            uuid: songUuid,
            file,
            type,
            formValues,
          }),
        );
      },
    }),
  ),
)(SongEditUploadDropzone);
