import * as React from 'react';
import Typeahead from 'components/Typeahead/Typeahead';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { multipleSelectFormat } from 'utils/utils';
import { useNlSelector } from 'utils/redux';

interface OwnProps {
  name: keyof Nl.QueryFilter;
  label: string;
  optionsSelector: (state) => Nl.Api.SelectorEntity[];
  value: string;
  onChange: (selected: string | string[], name: Nl.QueryFilterType) => any;
}

const TypeaheadFilterForm = ({ name, label, options, value, onChange }) => {
  const { setFieldValue } = useFormikContext();
  React.useEffect(() => {
    setFieldValue(name, value);
  }, [value, name, setFieldValue]);
  return (
    <Form>
      <Field
        name={name}
        label={label}
        component={Typeahead}
        options={options}
        onChange={(option: Nl.SelectFieldOptionsType) =>
          onChange(option?.value, name)
        }
      />
    </Form>
  );
};

export default ({
  name,
  label,
  optionsSelector,
  value,
  onChange,
}: OwnProps) => {
  const options = multipleSelectFormat(useNlSelector(optionsSelector));
  return (
    <Formik initialValues={{ [name]: value }} onSubmit={() => {}}>
      <TypeaheadFilterForm {...{ name, label, options, value, onChange }} />
    </Formik>
  );
};
