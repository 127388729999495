import React from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';
import { Formik } from 'formik';
import { Card, Theme, createStyles, makeStyles } from '@material-ui/core';
import { validateRegistrationToken, setFirstPassword } from 'store/register';
import { passwordValidationSchema } from './validation';
import RegistrationPasswordForm from './RegistrationPasswordForm';
import { selectors as authSelectors } from 'store/auth';
import {
  selectRegistrationUser,
  selectRegistrationTokenIsValid,
  selectIsLoaded,
  selectRegistrationToken,
} from 'store/selectors';
import { useNlSelector } from 'utils/redux';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      marginTop: theme.spacing(8),
      width: theme.spacing(80),
    },
  });
const useStyles = makeStyles(styles);

const makeInitialValues = (user: Nl.Api.User) => ({
  new_password: '',
  confirm_password: '',
  email: user.email,
});

const RegistrationPage = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const isLoggedIn = useNlSelector(authSelectors.isLoggedIn);
  const user = useNlSelector(selectRegistrationUser);
  const tokenIsValid = useNlSelector(selectRegistrationTokenIsValid);
  const token = useNlSelector(selectRegistrationToken);
  const isLoaded = useNlSelector((state) => selectIsLoaded(state, 'register'));

  // If a token is found in state, then the user has already completed
  // registration. Take them to the home page
  if (isLoggedIn) {
    dispatch(redirect({ type: 'route/MY_TRACKS' }));
    return null;
  }

  // Unable to use onRoute sagas for this because the user cannot complete
  // authentication flow until after registration. Dispatch is done manually here.
  if (!isLoaded) {
    dispatch(validateRegistrationToken({ token }));
    return null;
  }

  // Show the link-expired page if the token validation fails
  if (!tokenIsValid) {
    dispatch(redirect({ type: 'route/LINK_EXPIRED' }));
    return null;
  }

  /*
   * If we reach here, the reset token is valid, the user is not already logged in,
   * and the register store is in a loaded state. It is safe to render the password
   * form.
   */
  return (
    <Card className={classes.root}>
      <Formik
        initialValues={makeInitialValues(user)}
        validationSchema={passwordValidationSchema}
        validateOnChange={false}
        onSubmit={(formData, formActions) => {
          dispatch(setFirstPassword({ formActions, formData }));
        }}
      >
        {(formikProps) => RegistrationPasswordForm(formikProps, user)}
      </Formik>
    </Card>
  );
};

export default RegistrationPage;
