import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  createAction,
  createTypedAction,
  assign,
  SimpleActionType,
} from 'utils/storeUtils';
import { apiFetch } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';

/**
 * Action Constants
 */
// @todo understand ACTION system
const INIT_SINGLE_ARTIST_SUBMISSION = 'INIT_SINGLE_ARTIST_SUBMISSION';

const FETCH_SINGLE_ARTIST_SUBMISSION = 'FETCH_SINGLE_ARTIST_SUBMISSION';
const FETCH_SINGLE_ARTIST_SUBMISSION_SUCCESS =
  'FETCH_SINGLE_ARTIST_SUBMISSION_SUCCESS';
const FETCH_SINGLE_ARTIST_SUBMISSION_ERROR =
  'FETCH_SINGLE_ARTIST_SUBMISSION_ERROR';

const EDIT_ARTIST_SUBMISSION = 'EDIT_ARTIST_SUBMISSION';
const EDIT_ARTIST_SUBMISSION_ERROR = 'EDIT_ARTIST_SUBMISSION_ERROR';

/**
 * Action Creators
 */
export const initSingleArtistSubmission = createAction(
  INIT_SINGLE_ARTIST_SUBMISSION,
);

export const fetchSingleArtistSubmission = (payload: { uuid: string }) =>
  createTypedAction(FETCH_SINGLE_ARTIST_SUBMISSION, payload);
export const fetchSingleArtistSubmissionSuccess = createAction(
  FETCH_SINGLE_ARTIST_SUBMISSION_SUCCESS,
);
const fetchSingleArtistSubmissionError = createAction(
  FETCH_SINGLE_ARTIST_SUBMISSION_ERROR,
);
export const editArtistSubmission = createAction(EDIT_ARTIST_SUBMISSION);

/**
 * Reducer
 */
const initialState = {
  data: {
    new_artist_submission: {} as Nl.Api.ArtistSubmission,
  },
  isLoading: false,
  isLoaded: false,
};

export type SingleArtistSubmissionState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case INIT_SINGLE_ARTIST_SUBMISSION: {
      return initialState;
    }

    case FETCH_SINGLE_ARTIST_SUBMISSION: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_SINGLE_ARTIST_SUBMISSION_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    case EDIT_ARTIST_SUBMISSION_ERROR:
    case FETCH_SINGLE_ARTIST_SUBMISSION_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }

    default:
      return state;
  }
};

/**
 * Sagas
 */
const sagas = {
  *fetchSingleArtistSubmission(
    action: ReturnType<typeof fetchSingleArtistSubmission>,
  ) {
    const { uuid } = action.payload;
    const { data, success, msg } = yield call(
      apiFetch,
      `/new_artist_submission/${uuid}`,
    );

    if (success) {
      yield put(fetchSingleArtistSubmissionSuccess({ data }));
    } else {
      yield put(fetchSingleArtistSubmissionError());
      yield put(addErrorNotification({ message: msg }));
    }
  },
  *editArtistSubmission(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const { success, data, msg, errors } = yield call(
      apiFetch,
      `/new_artist_submission/${formData.uuid}`,
      {
        method: 'PUT',
        body: formData,
      },
    );

    if (success) {
      yield put(fetchSingleArtistSubmissionSuccess({ data }));
      yield put(
        addSuccessNotification({ message: 'Artist submission updated' }),
      );
    } else {
      formActions.setErrors(errors);
      yield put(addErrorNotification({ message: msg }));
    }
    formActions.setSubmitting(false);
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(
      FETCH_SINGLE_ARTIST_SUBMISSION,
      sagas.fetchSingleArtistSubmission,
    ),
    takeLatest(EDIT_ARTIST_SUBMISSION, sagas.editArtistSubmission),
  ]);
}

export { sagas };
export default reducer;
