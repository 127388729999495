import { AnyAction, combineReducers } from 'redux';
import { LocationState } from 'redux-first-router';
import { reducer as location } from '../routes'; // key for route reducer must be 'location'
import router, { RouterState } from './router';
import audioPlayer, { AudioPlayerState } from './audioPlayer';
import auth, { AuthState, ON_LOGOUT } from './auth';
import publishers, { PublishersState } from './publishers';
import publisherRuleset, { PublisherRulesetState } from './publisherRuleset';
import users, { UsersState } from './users';
import songs, { SongsState } from './songs';
import singleSong, { SingleSongState } from './singleSong';
import songCollections, { SongCollectionsState } from './songCollections';
import songGenres, { SongGenres } from './songGenre';
import songThemes, { SongThemes } from './songTheme';
import songInstruments, { SongInstruments } from './songInstrument';
import songTags, { SongTags } from './songTags';
import songHiddenTags, { SongHiddenTags } from './songTagsHidden';
import taxonomyTags, { TaxonomyTags } from './taxonomyTags';
import artistSubmissions, { ArtistSubmissionsState } from './artistSubmissions';
import singleTrackSubmission, {
  SingleTrackSubmissionState,
} from './singleTrackSubmission';
import singleArtistSubmission, {
  SingleArtistSubmissionState,
} from './singleArtistSubmission';
import originalComposer, { OriginalComposerState } from './originalComposer';
import notifications, { NotificationState } from './notifications';
import batchUpdate, { BatchUpdateState } from './batchUpdate';
import waveform, { WaveformState } from './songWaveform';
import salesPerTrack, { SalesPerTrackState } from './salesPerTrack';
import salesBreakdown, { SalesBreakdownState } from './salesBreakdown';
import salesTotal, { SalesTotalState } from './salesTotal';
import myWriters, { MyWritersState } from './myWriters';
import myProfile, { MyProfileState } from './myProfile';
import register, { RegisterState } from './register';
import songFinder, { SongFinderState } from './songFinder';
import proSongRegistrations, {
  PROSongRegistrationsState,
} from './proSongRegistrations';
import header, { HeaderState } from 'store/header';

export interface GlobalState {
  audioPlayer: AudioPlayerState;
  auth: AuthState;
  publishers: PublishersState;
  publisherRuleset: PublisherRulesetState;
  proSongRegistrations: PROSongRegistrationsState;
  users: UsersState;
  location: LocationState<Nl.RouteMapProps>;
  router: RouterState;
  notifications: NotificationState;
  songs: SongsState;
  singleSong: SingleSongState;
  songFinder: SongFinderState;
  songCollections: SongCollectionsState;
  songGenres: SongGenres;
  songThemes: SongThemes;
  songInstruments: SongInstruments;
  songTags: SongTags;
  songHiddenTags: SongHiddenTags;
  taxonomyTags: TaxonomyTags;
  artistSubmissions: ArtistSubmissionsState;
  singleArtistSubmission: SingleArtistSubmissionState;
  singleTrackSubmission: SingleTrackSubmissionState;
  originalComposer: OriginalComposerState;
  waveform: WaveformState;
  salesPerTrack: SalesPerTrackState;
  salesBreakdown: SalesBreakdownState;
  salesTotal: SalesTotalState;
  batchUpdate: BatchUpdateState;
  myWriters: MyWritersState;
  myProfile: MyProfileState;
  register: RegisterState;
  header: HeaderState;
}

const rootReducer = combineReducers<GlobalState>({
  audioPlayer,
  auth,
  publishers,
  publisherRuleset,
  proSongRegistrations,
  users,
  location,
  router,
  notifications,
  songs,
  singleSong,
  songFinder,
  songCollections,
  songGenres,
  songThemes,
  songInstruments,
  songTags,
  songHiddenTags,
  taxonomyTags,
  artistSubmissions,
  singleArtistSubmission,
  singleTrackSubmission,
  originalComposer,
  waveform,
  salesPerTrack,
  salesBreakdown,
  salesTotal,
  batchUpdate,
  myWriters,
  myProfile,
  register,
  header,
});

// Wrap our rootReducer so we can intercept logout calls and nuke the state from orbit
export default (state: GlobalState | undefined, action: AnyAction) => {
  if (action.type === ON_LOGOUT) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return rootReducer(state, action);
};
