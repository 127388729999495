import * as React from 'react';
import { useDispatch } from 'react-redux';
import { updateMyProfile } from 'store/myProfile';
import FormikWrapper from 'components/Formik';
import { editMyProfileValidationSchema } from './validation';
import EditMyProfileForm from './EditMyProfileForm';
import { selectIsLoaded, selectMyProfile } from 'store/selectors';
import { formatAsForm } from './EditMyProfileData';
import { useNlSelector } from 'utils/redux';
import EditMyProfileMessage from './EditMyProfileMessage';

const EditMyProfilePage = () => {
  const dispatch = useDispatch();

  const myProfile = useNlSelector(selectMyProfile);
  const isLoaded = useNlSelector((state) => selectIsLoaded(state, 'myProfile'));

  if (!isLoaded || !myProfile) {
    return null;
  }
  return (
    <FormikWrapper
      initialValues={formatAsForm(myProfile)}
      validationSchema={editMyProfileValidationSchema}
      submitForm={(formData, formActions) =>
        dispatch(updateMyProfile({ formData, formActions }))
      }
      title='Edit Profile'
    >
      <EditMyProfileMessage
        isUpdateAllowed={myProfile.artist_can_update_own_profile}
        assignedToEmail={myProfile.assigned_to.email}
      />
      <EditMyProfileForm />
    </FormikWrapper>
  );
};

export default EditMyProfilePage;
