import { union } from 'lodash';

interface TypeOption {
  value: Nl.SongSourceFileType;
  label: string;
}

interface SourceFileTabsConfig {
  label: Nl.ChannelLayoutType;
  typeOptions: TypeOption[];
}

// Arrangement of source file types into channel layouts
// each channel layout is intended to be contained in a tab
export const sourceFileTabsConfig: SourceFileTabsConfig[] = [
  {
    label: 'Stereo',
    typeOptions: [
      { value: 'Full', label: 'Full' },
      { value: 'Full (Instrumental)', label: 'Full Instrumental' },
      { value: 'Shorts', label: 'Shorts' },
      { value: 'Loops', label: 'Loops' },
      { value: 'Stems', label: 'Stems' },
    ],
  },
  {
    label: 'Ambisonic',
    typeOptions: [
      { value: 'Ambisonic Full', label: 'Ambisonic Full' },
      {
        value: 'Ambisonic Full (Instrumental)',
        label: 'Ambisonic Full Instrumental',
      },
      { value: 'Ambisonic Shorts', label: 'Ambisonic Shorts' },
      { value: 'Ambisonic Loops', label: 'Ambisonic Loops' },
      { value: 'Ambisonic Stems', label: 'Ambisonic Stems' },
    ],
  },
  {
    label: '5.1 Surround',
    typeOptions: [
      { value: '5.1 Surround Full', label: '5.1 Surround Full' },
      {
        value: '5.1 Surround Full (Instrumental)',
        label: '5.1 Surround Full Instrumental',
      },
      { value: '5.1 Surround Shorts', label: '5.1 Surround Shorts' },
      { value: '5.1 Surround Loops', label: '5.1 Surround Loops' },
      { value: '5.1 Surround Stems', label: '5.1 Surround Stems' },
    ],
  },
];

// Combine all the typeOptions from all possible channel layouts into 1 array
export const allTypeOptions: TypeOption[] = union(
  ...sourceFileTabsConfig.map((cfg) => cfg.typeOptions),
);
