import * as React from 'react';
import { CircularProgress, WithStyles, withStyles } from '@material-ui/core';
import { Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px !important',
      minHeight: 'inherit',
    },
  });

const CircularLoading = ({ classes }: WithStyles) => (
  <div className={classes.container}>
    <CircularProgress
      data-e2e='circularProgress'
      size={30}
      className={classes.progress}
    />
  </div>
);

export default withStyles(styles)(CircularLoading);
