import React from 'react';

import FormikWrapper from 'components/Formik';
import { NovaApiUpserted } from 'novaApi/hooks/types';

interface OwnProps {
  handleFormSubmit: (action, formData, formActions) => void;
  children: React.ReactNode;
  title: string;
  validationSchema?: object;
  [key: string]: any;
}

export const UpsertPage = <T, R>(upsertHook: () => NovaApiUpserted<T, R>) => ({
  validationSchema,
  handleFormSubmit,
  children,
  ...otherFormikProps
}: OwnProps) => {
  const { action, data } = upsertHook();
  return (
    <FormikWrapper
      initialValues={data!}
      validationSchema={validationSchema}
      submitForm={(formData, formActions) =>
        handleFormSubmit(action, formData, formActions)
      }
      {...otherFormikProps}
    >
      {children}
    </FormikWrapper>
  );
};
