import * as React from 'react';
import { connect } from 'react-redux';
import SecondaryActionButton from 'components/Button/SecondaryActionButton';
import { generateSalesBreakdownReport } from 'store/salesBreakdown';

interface DispatchProps {
  downloadCSV: () => void;
}

type AllProps = DispatchProps;

const EarningsSummaryActions: React.SFC<AllProps> = ({ downloadCSV }) => (
  <SecondaryActionButton onClick={downloadCSV}>
    Download CSV
  </SecondaryActionButton>
);

export default connect(null, (dispatch) => ({
  downloadCSV: () => dispatch(generateSalesBreakdownReport()),
}))(EarningsSummaryActions);
