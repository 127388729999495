import { put, call, takeLatest, all } from 'redux-saga/effects';
import { createAction, SimpleActionType } from 'utils/storeUtils';
import { apiFetch, FetchResponse } from 'novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';
import { invalidateNovaQueries } from 'novaApi/useNovaApi';

const CREATE_ARTIST = 'CREATE_ARTIST';
const EDIT_ARTIST = 'EDIT_ARTIST';
const DELETE_ARTIST = 'DELETE_ARTIST';
const ONBOARD_ARTIST = 'ONBOARD_ARTIST';

export const createArtist = createAction(CREATE_ARTIST);
export const editArtist = createAction(EDIT_ARTIST);
export const deleteArtist = createAction(DELETE_ARTIST);
export const onboardArtist = createAction(ONBOARD_ARTIST);

const sagas = {
  *createArtistSaga(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const results: FetchResponse<Nl.Api.ArtistResponse> = yield call(
      apiFetch,
      '/artist',
      { method: 'POST', body: formData },
    );

    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been created`,
        }),
      );
      yield put({
        type: 'route/ARTIST_EDIT',
        payload: { uuid: results.data.artist.uuid },
      });
      invalidateNovaQueries('/artist');
    } else {
      yield put(
        addErrorNotification({
          message: 'There was an issue creating the artist',
        }),
      );
      formActions.setErrors(results.errors);
      formActions.setSubmitting(false);
    }
  },
  *editArtistSaga(action: SimpleActionType) {
    const { formData, formActions } = action.payload;
    const results: FetchResponse<Nl.Api.ArtistResponse> = yield call(
      apiFetch,
      `/artist/${formData.uuid}`,
      { method: 'PUT', body: formData },
    );
    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${results.data.artist.name} has been updated`,
        }),
      );
      invalidateNovaQueries('/artist');
      invalidateNovaQueries(`/artist/${formData.uuid}`);
    } else {
      yield put(
        addErrorNotification({
          message: 'There was an issue updating the artist',
        }),
      );
      formActions.setErrors(results.errors);
      formActions.setSubmitting(false);
    }
  },
  *onboardArtistSaga(action: SimpleActionType) {
    const { uuid } = action.payload;
    const results: FetchResponse<never> = yield call(
      apiFetch,
      `/artist/${uuid}/onboard`,
      { method: 'POST' },
    );
    if (results.success) {
      invalidateNovaQueries('/artist');
      invalidateNovaQueries(`/artist/${uuid.uuid}`);
      yield put(addSuccessNotification({ message: 'Invitation sent' }));
    } else {
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *deleteArtistSaga(action: SimpleActionType) {
    try {
      yield call(apiFetch, `/artist/${action.payload.uuid}`, {
        method: 'DELETE',
      });
      invalidateNovaQueries('/artist');
      yield put(
        addSuccessNotification({ message: 'The Artist has been deleted' }),
      );
    } catch (error) {
      yield put(
        addErrorNotification({
          message: 'There was an error deleting the Artist ',
        }),
      );
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(CREATE_ARTIST, sagas.createArtistSaga),
    takeLatest(EDIT_ARTIST, sagas.editArtistSaga),
    takeLatest(DELETE_ARTIST, sagas.deleteArtistSaga),
    takeLatest(ONBOARD_ARTIST, sagas.onboardArtistSaga),
  ]);
}

export { sagas };
