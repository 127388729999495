import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import { editPro as editProAction } from 'store/pros';
import { selectNotificationStatus } from 'store/selectors';
import PROForm, { getEditFormInitialValues } from './PROForm';
import validationSchema from './validation';
import { useNlSelector } from 'utils/redux';
import { usePRO } from 'novaApi/hooks/performing_rights_organization';
import { getUrlPayloadParams } from 'novaApi/apiUtils';

export default function PROEditPage() {
  const { uuid } = useNlSelector(getUrlPayloadParams);
  const pro = usePRO(uuid!);
  const notificationStatus = useNlSelector(selectNotificationStatus);
  const dispatch = useDispatch();

  return (
    <FormikWrapper
      initialValues={getEditFormInitialValues(pro)}
      validationSchema={validationSchema(notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(editProAction({ formData, formActions }))
      }
      title='Edit PRO'
    >
      <PROForm />
      {pro && (
        <CollapsableUserLogs
          activityType='performing-rights-organizations'
          resourceUuid={pro.uuid}
        />
      )}
    </FormikWrapper>
  );
}
