import { object, string, boolean } from 'yup';
import errorMessages from 'constants/errorMessages';

export default object().shape({
  first_name: string().required(errorMessages.basicRequired),
  last_name: string().required(errorMessages.basicRequired),
  is_default: boolean(),
  performing_rights_organization_uuid: string().nullable(),
  ipi_number: string()
    .matches(/^[0-9]+$/, { message: errorMessages.numbersOnly })
    .nullable(),
});
