import * as React from 'react';
import { Form, Field } from 'formik';
import InputField from 'components/Formik/InputField';

const SongInstrumentForm: React.SFC = () => (
  <Form>
    <Field name='name' label='Name' component={InputField} />
  </Form>
);

export default SongInstrumentForm;
