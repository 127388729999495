import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from 'redux-first-router';
import { selectors } from 'store/auth';
import { useNlSelector } from 'utils/redux';

/*
 * This page shouldn't actually render anything except the loading message.
 * Using state, make a decision here on which page the user should be redirected to.
 */
const HomePage = () => {
  const dispatch = useDispatch();

  const isAuthLoaded = useNlSelector(selectors.isLoaded);
  const isLoading = useNlSelector(selectors.isLoading);
  const isUserArtist = useNlSelector(selectors.isUserArtist);

  useEffect(() => {
    if (isLoading || !isAuthLoaded) return;

    dispatch(
      redirect({
        type: isUserArtist ? 'route/MY_TRACKS' : 'route/USER_ACTIVITY',
      }),
    );
  }, [isLoading, isAuthLoaded, isUserArtist, dispatch]);

  return isLoading ? <h2>Loading...</h2> : null;
};

export default HomePage;
