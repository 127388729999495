import * as React from 'react';
import classnames from 'classnames';
import { withStyles, WithStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => ({
  root: {},
  animation: {
    transition: 'transform 0.3s ease, max-height 0.3s ease-out',
    height: 'auto',
    maxHeight: '300px',
    transformOrigin: 'top',
    transform: 'scaleY(1)',
  },
  expanded: {},
  close: {
    maxHeight: 0,
    transform: 'scaleY(0)',
  },
});

type OwnProps = {
  expanded?: boolean;
};

type Allprops = OwnProps & WithStyles<typeof styles>;

class ExpansionPanel extends React.PureComponent<Allprops> {
  render() {
    const { classes, expanded, children } = this.props;
    return (
      <div
        data-e2e='expansionPanel'
        className={classnames(
          classes.root,
          classes.animation,
          expanded ? classes.expanded : classes.close,
        )}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(ExpansionPanel);
