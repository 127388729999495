import {
  put,
  call,
  select,
  takeLatest,
  takeEvery,
  all,
} from 'redux-saga/effects';
import { createAction, SimpleActionType, assign } from '../utils/storeUtils';
import {
  apiFetch,
  getUrlPayloadParams,
  getUrlQueryParams,
  convertQueryObjToUrlString,
  FetchResponse,
} from '../novaApi/apiUtils';
import { addErrorNotification, addSuccessNotification } from './notifications';

import { GlobalState } from './rootReducer';
import { goPreviousPage } from 'store/router';
import { isEmpty, map } from 'lodash';

// Action Constants
const FETCH_SINGLE_PUBLISHER = 'FETCH_SINGLE_PUBLISHER';
const FETCH_SINGLE_PUBLISHER_SUCCESS = 'FETCH_SINGLE_PUBLISHER_SUCCESS';
const FETCH_SINGLE_PUBLISHER_ERROR = 'FETCH_SINGLE_PUBLISHER_ERROR';
const FETCH_PUBLISHERS = 'FETCH_PUBLISHERS';
const FETCH_PUBLISHERS_SUCCESS = 'FETCH_PUBLISHERS_SUCCESS';
const FETCH_PUBLISHERS_ERROR = 'FETCH_PUBLISHERS_ERROR';
const CREATE_PUBLISHER = 'CREATE_PUBLISHER';
const CREATE_PUBLISHER_ERROR = 'CREATE_PUBLISHER_ERROR';
const EDIT_PUBLISHER = 'EDIT_PUBLISHER';
const EDIT_PUBLISHER_SUCCESS = 'EDIT_PUBLISHER_SUCCESS';
const EDIT_PUBLISHER_ERROR = 'EDIT_PUBLISHER_ERROR';
const FETCH_PUBLISHER_CONSTANTS = 'FETCH_PUBLISHER_CONSTANTS';
const FETCH_PUBLISHER_CONSTANTS_SUCCESS = 'FETCH_PUBLISHER_CONSTANTS_SUCCESS';

// Action Creators
export const fetchSinglePublisher = createAction(FETCH_SINGLE_PUBLISHER);
const fetchSinglePublisherSuccess = createAction(
  FETCH_SINGLE_PUBLISHER_SUCCESS,
);
const fetchSinglePublisherError = createAction(FETCH_SINGLE_PUBLISHER_ERROR);
export const fetchPublishers = createAction(FETCH_PUBLISHERS);
export const fetchPublishersSuccess = createAction(FETCH_PUBLISHERS_SUCCESS);
const fetchPublishersError = createAction(FETCH_PUBLISHERS_ERROR);
export const createPublisher = createAction(CREATE_PUBLISHER);
const createPublisherError = createAction(CREATE_PUBLISHER_ERROR);
export const editPublisher = createAction(EDIT_PUBLISHER);
const editPublisherSuccess = createAction(EDIT_PUBLISHER_SUCCESS);
const editPublisherError = createAction(EDIT_PUBLISHER_ERROR);
export const fetchPublisherConstants = createAction(FETCH_PUBLISHER_CONSTANTS);
export const fetchPublisherConstantsSuccess = createAction(
  FETCH_PUBLISHER_CONSTANTS_SUCCESS,
);

const publisherInitialValues = {
  uuid: '' as string,
  name: '' as string,
  type: '' as string,
  ipi_name: '' as string,
  ipi_number: '' as string,
  tax_id: '' as string,
  performing_rights_organization_uuid: '' as string,
  performing_rights_organization_member_id: '' as string,
  country_uuids: [] as string[],
  is_visible: false as boolean,
};

export type PublisherInitialValues = typeof publisherInitialValues;

// Selector
export const getEditFormInitialValues = (
  publisher?: Nl.Api.Publisher,
): PublisherInitialValues => {
  if (!publisher) {
    return publisherInitialValues;
  }
  return {
    uuid: publisher.uuid,
    name: publisher.name,
    type: publisher.type,
    ipi_name: publisher.ipi_name || '',
    ipi_number: publisher.ipi_number || '',
    tax_id: publisher.tax_id || '',
    performing_rights_organization_uuid:
      publisher.performing_rights_organization.uuid,
    performing_rights_organization_member_id:
      publisher.performing_rights_organization_member_id || '',
    country_uuids: map(publisher.countries, (country) => country.uuid),
    is_visible: publisher.is_visible,
  };
};

// Reducer
const initialState = {
  data: {
    publisher: undefined as Nl.Api.Publisher | undefined,
    publishers: [] as Nl.Api.Publisher[],
    total_size: 0,
    current_size: undefined as number | undefined,
    current_page: undefined as number | undefined,
    offset: undefined as number | undefined,
    total_pages: undefined as number | undefined,
  } as Nl.Api.PublishersResponse & Partial<Nl.Api.PublisherResponse>,
  constants: {
    types: [] as string[],
  } as Nl.Api.PublisherConstants,
  isLoading: false,
  isLoaded: false,
};

export type PublishersState = Readonly<typeof initialState>;

const reducer = (state = initialState, action = {} as SimpleActionType) => {
  switch (action.type) {
    case FETCH_PUBLISHER_CONSTANTS_SUCCESS: {
      return assign(state, { constants: action.payload });
    }

    case FETCH_PUBLISHERS:
    case FETCH_SINGLE_PUBLISHER: {
      return assign(state, {
        isLoading: true,
      });
    }

    case FETCH_PUBLISHERS_SUCCESS: {
      return assign(state, {
        data: action.payload.data,
        isLoading: false,
        isLoaded: true,
      });
    }

    case FETCH_SINGLE_PUBLISHER_SUCCESS:
    case EDIT_PUBLISHER_SUCCESS: {
      return assign(state, {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        isLoading: false,
      });
    }

    case FETCH_PUBLISHERS_ERROR:
    case FETCH_SINGLE_PUBLISHER_ERROR: {
      return assign(state, {
        isLoading: false,
        isLoaded: false,
      });
    }

    default:
      return state;
  }
};

// Sagas
const sagas = {
  *fetchSinglePublisherSaga(action: SimpleActionType) {
    const { uuid } = yield select(getUrlPayloadParams);
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/publisher/${uuid}`,
    );
    if (results.success) {
      yield put(fetchSinglePublisherSuccess({ data: results.data }));
    } else {
      yield put(fetchSinglePublisherError());
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *fetchPublishersSaga(action: SimpleActionType) {
    const queryParams: string = action?.payload
      ? convertQueryObjToUrlString(action.payload)
      : yield select(getUrlQueryParams);
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/publisher${queryParams}`,
    );
    if (results.success) {
      yield put(fetchPublishersSuccess({ data: results.data }));
    } else {
      yield put(fetchPublishersError({ error: results.msg }));
    }
  },
  *createPublisherSaga({ payload }: SimpleActionType) {
    const { formData } = payload;
    const results: FetchResponse<any> = yield call(apiFetch, '/publisher', {
      method: 'POST',
      body: { ...formData },
    });

    if (results.success) {
      yield put(
        addSuccessNotification({
          message: `${formData.name} has been created`,
        }),
      );
      yield put(goPreviousPage());
    } else {
      yield put(createPublisherError({ error: results.msg }));
      yield put(addErrorNotification({ message: results.msg }));
    }
  },
  *editPublisherSaga({ payload }: SimpleActionType) {
    const { formData, formActions } = payload;
    const results: FetchResponse<any> = yield call(
      apiFetch,
      `/publisher/${formData.uuid}`,
      {
        method: 'PUT',
        body: { ...formData },
      },
    );

    if (results.success) {
      yield put(editPublisherSuccess({ data: results.data }));
      yield put(
        addSuccessNotification({
          message: `${results.data.publisher.name} has been updated`,
        }),
      );
    } else {
      yield put(editPublisherError({ error: results.msg }));
      yield put(addErrorNotification({ message: results.msg }));
    }
    formActions.setSubmitting(false);
  },
  *fetchPublisherConstantsSaga() {
    const selector = (state: GlobalState) => state.publishers.constants.types;
    const storeConstants: ReturnType<typeof selector> = yield select(selector);
    if (isEmpty(storeConstants)) {
      const results: FetchResponse<any> = yield call(
        apiFetch,
        '/publisher/constants',
      );
      if (results.success) {
        yield put(
          fetchPublisherConstantsSuccess(results.data.publisher_constants),
        );
      } else {
        yield put(addErrorNotification({ message: results.msg }));
      }
    }
  },
};

// Root Saga
export function* rootSaga() {
  yield all([
    takeLatest(FETCH_SINGLE_PUBLISHER, sagas.fetchSinglePublisherSaga),
    takeLatest(FETCH_PUBLISHERS, sagas.fetchPublishersSaga),
    takeEvery(CREATE_PUBLISHER, sagas.createPublisherSaga),
    takeLatest(EDIT_PUBLISHER, sagas.editPublisherSaga),
    takeLatest(FETCH_PUBLISHER_CONSTANTS, sagas.fetchPublisherConstantsSaga),
  ]);
}

export { sagas };
export default reducer;
