import * as React from 'react';
import { styles } from 'components/Table/Table.styles';
import { makeStyles, Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(styles);

interface AllProps {
  suggestedWriter: Nl.Api.MyWriter | null;
  onSubmit: (writer: Nl.Api.MyWriter) => void;
}

const SuggestedWriter: React.FunctionComponent<AllProps> = ({
  suggestedWriter,
  onSubmit,
}) => {
  const classes = useStyles({});
  if (!suggestedWriter) {
    return null;
  }
  const infoText = `We found a matching writer in our system: ${suggestedWriter.full_name} (IPI ${suggestedWriter.ipi_number})`;
  return (
    <div className={classes.infoTextInForm}>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          {infoText}
          <br />
          Do you want to add them to your profile?
        </Grid>
        <Grid item xs={3}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => onSubmit(suggestedWriter)}
          >
            Add this Writer
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SuggestedWriter;
