// TODO looks like the code in this file is dupplicated (see form/validators.ts)
// One file should be removed
export const required = (
  value: string | number | undefined,
): string | undefined => {
  const isEmpty =
    (typeof value === 'string' && !value.trim()) || (value !== 0 && !value);
  return isEmpty ? 'This field is required' : undefined;
};

export const email = (value: string): string | undefined =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const strongPassword = (value: string): string => {
  // calculating the length
  const lengthError = value.length < 8 ? 'at least 8 characters. ' : '';

  // searching for digits
  const digitError = !/\d/.test(value) ? 'at least 1 number. ' : '';

  // searching for uppercase
  const uppercaseError = !/[A-Z]/.test(value)
    ? 'at least 1 uppercase character. '
    : '';

  // searching for lowercase
  const lowercaseError = !/[a-z]/.test(value)
    ? 'at least 1 lowercase character. '
    : '';

  // searching for symbols
  const symbolError = !/\W/.test(value) ? 'at least 1 symbol. ' : '';

  const containsError =
    lengthError || digitError || uppercaseError || lowercaseError || symbolError
      ? 'Password must contain: '
      : '';

  return `${containsError}${lengthError}${digitError}${uppercaseError}${lowercaseError}${symbolError}`;
};
