import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import InputField from 'components/Formik/InputField';
import { forgotPasswordValidationSchema } from './validation';
import { addErrorNotification } from 'store/notifications';
import { apiFetch } from 'novaApi/apiUtils';
import { useStyles, Wrapper } from './styles';

const ForgotPasswordPage: React.FunctionComponent = () => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onForgotPassword = async ({ email }: { email: string }) => {
    try {
      const results = await apiFetch('/user/forgot_password', {
        method: 'POST',
        body: { email },
      });
      return results.success
        ? setIsSubmitted(true)
        : dispatch(addErrorNotification({ message: results.msg }));
    } catch (e) {
      dispatch(
        addErrorNotification({
          message: 'Error requesting /user/forgot-password',
        }),
      );
    }
  };

  return (
    <Wrapper>
      <Card className={classes.root}>
        {isSubmitted ? (
          <CardContent>
            <Typography variant='h5' gutterBottom>
              Request Submitted
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
              If an account with your e-mail address exists, we have sent you a
              password reset link.
            </Typography>
          </CardContent>
        ) : (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={forgotPasswordValidationSchema}
            onSubmit={onForgotPassword}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ isSubmitting }) => (
              <Form>
                <CardContent>
                  <Typography variant='h5' gutterBottom>
                    Forgot Password
                  </Typography>
                  <Typography variant='subtitle1' gutterBottom>
                    Enter the e-mail address associated with your account and we
                    will send you a reset password link.
                  </Typography>
                  <Field
                    name='email'
                    label='Email'
                    type='email'
                    component={InputField}
                  />
                </CardContent>
                <CardActions>
                  <Button color='primary' type='submit' disabled={isSubmitting}>
                    Submit
                  </Button>
                </CardActions>
              </Form>
            )}
          </Formik>
        )}
      </Card>
    </Wrapper>
  );
};

export default ForgotPasswordPage;
