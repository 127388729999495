import * as React from 'react';
import { useDispatch } from 'react-redux';
import ConnectedPagination from 'components/Pagination/ConnectedPagination';
import {
  Checkbox,
  TableHead,
  TableRow as Row,
  TableCell as Cell,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import { TableConfigEntry } from 'utils/tableConfig';
import { reverseSortOrder } from 'utils/routeUtils';
import { selectQuery } from 'store/selectors';
import { filterBy } from 'store/queryParams';
import { ClassNameMap } from '@material-ui/styles';
import { useNlSelector } from 'utils/redux';

const useStyles = makeStyles(styles);

interface Props<Data> {
  config: TableConfigEntry<Data>;
  selectionStatus?: 0 | 1 | 2;
  selectAll?: () => void;
  paginationData?: Nl.Api.PaginationData;
}

function TableHeader<Data>({
  config,
  selectAll,
  selectionStatus,
  paginationData,
}: Props<Data>) {
  const classes = useStyles({}) as ClassNameMap;
  const dispatch = useDispatch();

  const query = useNlSelector(selectQuery);
  const onSort = (filters: Nl.QueryFilter) => {
    dispatch(filterBy({ query: filters, useSearchSaga: true }));
  };

  const currentSortBy = query?.sort_by || 'title';
  const currentSortOrder = (query?.sort_order ||
    'asc') as Nl.QueryFilterSortOrderType;

  const onSortClick = (sortValue: string) => {
    onSort({
      sort_by: sortValue,
      sort_order:
        sortValue === currentSortBy
          ? reverseSortOrder(currentSortOrder)
          : currentSortOrder,
    });
  };

  const renderCell = (
    id: string,
    isSortable: boolean,
    checkbox: boolean | undefined,
    label: string | undefined,
  ) => {
    if (isSortable) {
      return (
        <TableSortLabel
          active={id === currentSortBy}
          direction={currentSortOrder}
          onClick={() => {
            onSortClick(id);
          }}
        >
          {label}
        </TableSortLabel>
      );
    }

    if (checkbox) {
      return (
        <Checkbox
          aria-label='selectAll'
          checked={selectionStatus === 2}
          indeterminate={selectionStatus === 1}
          color='primary'
          onClick={selectAll}
        />
      );
    }

    return <span>{label}</span>;
  };

  return (
    <TableHead>
      <Row className='Pagination'>
        <ConnectedPagination
          storeKey={config.storeKey}
          {...{ paginationData }}
        />
      </Row>
      <Row>
        {config.columnData.map(
          ({ id, label, isSortable, checkbox, cellProps = {} }) => (
            <Cell
              key={id}
              sortDirection={id === currentSortBy ? 'asc' : 'desc'}
              className={classes[id]}
              {...cellProps}
            >
              {renderCell(id, !!isSortable, checkbox, label)}
            </Cell>
          ),
        )}
      </Row>
    </TableHead>
  );
}

export default TableHeader;
