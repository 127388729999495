import * as React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import { styles } from 'components/Table/Table.styles';
import TableToolbar from 'components/TableToolbar';
import Table from 'components/Table/Table';
import { useNlSelector } from 'utils/redux';
import { selectors as authSelectors } from 'store/auth';
import { TableConfigEntry } from 'utils/tableConfig';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import ConnectedButton from 'components/Button/ConnectedButton';
import InternalLink from 'components/Link/InternalLink';
import ArtistFilters from './ArtistFilters';
import ArtistIndications from './ArtistIndications';
import { getReadableDate } from 'utils/utils';
import { useArtists } from 'novaApi/hooks/artist';

const useStyles = makeStyles(styles);

const EditArtistName = ({ name, uuid }: { name: string; uuid: string }) => {
  const userPermissions = useNlSelector(authSelectors.getUserPermissions);
  const editPermissions = userPermissions.includes('CAN_EDIT_ARTIST');

  if (editPermissions) {
    return (
      <InternalLink
        color='inherit'
        to={{ type: 'route/ARTIST_EDIT', payload: { uuid } }}
      >
        {name}
      </InternalLink>
    );
  }
  return <>{name}</>;
};

const tableConfig: TableConfigEntry<Nl.Api.BaseArtist> = {
  columnData: [
    {
      id: 'name',
      label: 'Name',
      isSortable: true,
      render: ({ name, uuid }) => <EditArtistName {...{ name, uuid }} />,
    },
    {
      id: 'indications',
      label: '',
      render: ({ is_royalty, is_pro_free, is_dormant }) => (
        <ArtistIndications
          isRoyalty={is_royalty || false}
          isPROFree={is_pro_free || false}
          isDormant={is_dormant}
        />
      ),
    },
    { id: 'song_count', label: 'Song Count' },
    {
      id: 'updated_at',
      label: 'Last Modified',
      render: ({ updated_at }) => getReadableDate(updated_at),
    },
  ],
};

export default function ArtistsPage() {
  const data = useArtists();
  const classes = useStyles({});

  return (
    <Paper elevation={1} className={classes.root}>
      <TableToolbar
        title='Artists'
        search={
          <ConnectedTableFilters
            filters={[
              {
                type: 'search',
                size: 6,
                label: 'Search by name',
                queryFilterKey: 'name',
                e2e: 'name',
              },
            ]}
          />
        }
        actions={
          <ConnectedButton
            icon='add'
            fab
            route='route/ARTIST_CREATE'
            label='Create new Artist'
            permission='CAN_CREATE_ARTIST'
          />
        }
        filters={<ArtistFilters />}
      />
      <Table
        config={tableConfig}
        data={data?.artists ?? []}
        isLoaded={!!data}
        paginationData={data}
      />
    </Paper>
  );
}
