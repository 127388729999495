import * as React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/ControlPoint';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { selectRequireAction } from 'store/singleSong';
import { GlobalState } from 'store/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.grey[300]}`,
      marginTop: `${theme.spacing(2)}px !important`,
      marginBottom: `${theme.spacing(2)}px !important`,
    },
    panelTitle: {
      paddingLeft: theme.spacing(2),
    },
    title: {
      alignItems: 'center',
    },
    icon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(1),
    },
    list: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    item: {
      paddingTop: 0,
      paddingBotton: 0,
    },
  }),
);

interface Props {
  requireActions: string[];
}

const SongEditRequiredActions = ({ requireActions }: Props) => {
  const classes = useStyles();

  return (
    <>
      {requireActions && requireActions.length > 0 ? (
        <Accordion
          className={classes.root}
          elevation={0}
          disabled={!requireActions || requireActions.length < 1}
        >
          <AccordionSummary
            classes={{ root: classes.panelTitle, content: classes.title }}
            expandIcon={<ExpandMoreIcon />}
          >
            <InfoIcon className={classes.icon} />
            <Typography>
              {requireActions.length} actions are required to gain the Ready
              state.
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense className={classes.list}>
              {requireActions.map((action) => (
                <ListItem className={classes.item} key={action}>
                  <ListItemText primary={action} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </>
  );
};

export default connect((state: GlobalState) => ({
  requireActions: selectRequireAction(state),
}))(SongEditRequiredActions);
