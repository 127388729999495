import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import {
  getEditFormInitialValues,
  createSongInstrument,
} from 'store/songInstrument';
import { selectNotificationStatus } from 'store/selectors';
import SongInstrumentForm from './SongInstrumentForm';
import validationSchema from 'utils/basicYupValidation';
import { useNlSelector } from 'utils/redux';

const CreateSongInstrumentPage = () => {
  const dispatch = useDispatch();

  const initialValues = useNlSelector(getEditFormInitialValues);
  const notificationStatus = useNlSelector(selectNotificationStatus);

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={validationSchema(notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(createSongInstrument({ formData, formActions }))
      }
      title='Create New Song Instrument'
    >
      <SongInstrumentForm />
    </FormikWrapper>
  );
};

export default CreateSongInstrumentPage;
