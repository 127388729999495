export const getColor = (type: Nl.SongSourceFileType | 'all' | 'song') => {
  switch (type) {
    case 'all':
      return '#ffffff';
    case 'Full':
    case 'Ambisonic Full':
    case '5.1 Surround Full':
      return '#f79a88';
    case 'Full (Instrumental)':
    case 'Ambisonic Full (Instrumental)':
    case '5.1 Surround Full (Instrumental)':
      return '#f7e279';
    case 'Loops':
    case 'Ambisonic Loops':
    case '5.1 Surround Loops':
      return '#c6ed7d';
    case 'Shorts':
    case 'Ambisonic Shorts':
    case '5.1 Surround Shorts':
      return '#7ac9f4';
    case 'Stems':
    case 'Ambisonic Stems':
    case '5.1 Surround Stems':
      return '#bd89f9';
    default:
      return '#f79a88';
  }
};

interface FileType {
  [key: string]: Nl.SongSourceFileType;
}

export const stereoTypes: FileType = {
  full: 'Full',
  full_instrumental: 'Full (Instrumental)',
  shorts: 'Shorts',
  loops: 'Loops',
  stems: 'Stems',
};

export const binauralTypes: FileType = {
  binaural_full: 'Binaural Full',
  binaural_full_instrumental: 'Binaural Full (Instrumental)',
  binaural_shorts: 'Binaural Shorts',
  binaural_loops: 'Binaural Loops',
  binaural_stems: 'Binaural Stems',
};

export const ambisonicTypes: FileType = {
  ambisonic_full: 'Ambisonic Full',
  ambisonic_full_instrumental: 'Ambisonic Full (Instrumental)',
  ambisonic_shorts: 'Ambisonic Shorts',
  ambisonic_loops: 'Ambisonic Loops',
  ambisonic_stems: 'Ambisonic Stems',
};

export const surround51Types: FileType = {
  surround_5_1_full: '5.1 Surround Full',
  surround_5_1_full_instrumental: '5.1 Surround Full (Instrumental)',
  surround_5_1_shorts: '5.1 Surround Shorts',
  surround_5_1_loops: '5.1 Surround Loops',
  surround_5_1_stems: '5.1 Surround Stems',
};

export const fileTypes: FileType = {
  ...stereoTypes,
  ...binauralTypes,
  ...ambisonicTypes,
  ...surround51Types,
};

export const isFull = (fileType: Nl.SongSourceFileType) => {
  return [
    fileTypes.full,
    fileTypes.binaural_full,
    fileTypes.ambisonic_full,
    fileTypes.surround_5_1_full,
  ].includes(fileType);
};

export const isFullInstrumental = (fileType: Nl.SongSourceFileType) => {
  return [
    fileTypes.full_instrumental,
    fileTypes.binaural_full_instrumental,
    fileTypes.ambisonic_full_instrumental,
    fileTypes.surround_5_1_full_instrumental,
  ].includes(fileType);
};

export const isShort = (fileType: Nl.SongSourceFileType) => {
  return [
    fileTypes.shorts,
    fileTypes.binaural_shorts,
    fileTypes.ambisonic_shorts,
    fileTypes.surround_5_1_shorts,
  ].includes(fileType);
};

export const isLoop = (fileType: Nl.SongSourceFileType) => {
  return [
    fileTypes.loops,
    fileTypes.binaural_loops,
    fileTypes.ambisonic_loops,
    fileTypes.surround_5_1_loops,
  ].includes(fileType);
};

export const isAmbisonic = (fileType: Nl.SongSourceFileType) => {
  return [
    fileTypes.ambisonic_full,
    fileTypes.ambisonic_full_instrumental,
    fileTypes.ambisonic_shorts,
    fileTypes.ambisonic_loops,
    fileTypes.ambisonic_stems,
  ].includes(fileType);
};

export const isSurround51 = (fileType: Nl.SongSourceFileType) => {
  return [
    fileTypes.surround_5_1_full,
    fileTypes.surround_5_1_full_instrumental,
    fileTypes.surround_5_1_shorts,
    fileTypes.surround_5_1_loops,
    fileTypes.surround_5_1_stems,
  ].includes(fileType);
};

export const getWavRequirements = (fileType: Nl.SongSourceFileType) => {
  const wavRequirements: Nl.WavRequirements = {};
  if (isAmbisonic(fileType)) {
    wavRequirements.minNumChannels = 4;
  } else if (isSurround51(fileType)) {
    wavRequirements.minNumChannels = 6;
  }
  return wavRequirements;
};

export const audioLayouts = ['Stereo', 'Ambisonic', '5.1 Surround'];

export const showRevisionNote = ({
  revision_note,
  status,
}: Nl.Api.SourceFile) =>
  status === 'declined' || (revision_note && status !== 'approved');
