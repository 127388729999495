import React from 'react';
import { isEmpty } from 'lodash';

export const required = (value: any) => {
  const errMsg = value ? undefined : 'This field is required';
  return errMsg;
};

export const email = (value: any) => {
  const errMsg =
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) // eslint-disable-line wrap-regex
      ? 'Invalid email address'
      : undefined;
  return errMsg;
};

export const maxLength = (max: any) => (value: any) => {
  const errMsg =
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  return errMsg;
};

export const strongPassword = (value: any) => {
  const errors: string[] = [];

  if (value.length < 8) errors.push('at least 8 characters');
  if (!/\d/.test(value)) errors.push('at least 1 number');
  if (!/[A-Z]/.test(value)) errors.push('at least 1 uppercase character');
  if (!/[a-z]/.test(value)) errors.push('at least 1 lowercase character');
  if (!/\W/.test(value)) errors.push('at least 1 symbol');

  return isEmpty(errors)
    ? undefined
    : `Password must contain: ${errors.join(', ')}`;
};

export const passwordHelp = (
  <>
    {[
      'A secure password must contain:',
      '- at least 8 characters',
      '- at least 1 number',
      '- at least 1 uppercase character',
      '- at least 1 lowercase character',
      '- at least 1 symbol',
    ].map((item, index) => (
      <React.Fragment key={`pwh${index}`}>
        {item}
        <br />
      </React.Fragment>
    ))}
  </>
);

export const integer = (value: string | number) => {
  const parsedVal = Math.floor(Number(value));
  const errMsg = !(String(parsedVal) === value && parsedVal >= 0)
    ? 'Must be an integer value'
    : undefined;
  return errMsg;
};
