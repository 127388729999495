import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { red } from '@material-ui/core/colors';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const theme = createMuiTheme({
  // override MUI instances
  overrides: {
    MuiInputBase: {
      input: {
        height: 'inherit',
        fontSize: 14,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#e0e0e0',
      },
      input: {
        padding: '10.5px 14px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
        pointerEvents: 'auto',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiChip: {
      root: {
        height: 'inherit',
      },
      label: {
        paddingLeft: 0,
      },
      outlined: {
        border: 'none',
        paddingRight: '8px',
      },
      deleteIcon: {
        fontSize: '16px',
      },
    },
    MuiTablePagination: {
      select: {
        fontSize: '12px',
        paddingRight: '20px',
      },
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          color: lighten('#BDBDBD', 0.6),
        },
      },
    },
    MuiButton: {
      // raisedPrimary: {
      //   '&:disabled': {
      //     opacity: 0.8,
      //     color: '#FFF',
      //     backgroundColor: `${blueGrey['500']} !important`,
      //   },
      // },
    },
    MuiDialogContent: {
      root: {
        willChange: 'transform',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
        whiteSpace: 'nowrap',
        maxWidth: '200px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    MuiBadge: {
      badge: {
        borderRadius: 25,
      },
    },
  },
  typography: {
    body1: {
      fontSize: 14,
    },
    h2: {
      fontSize: 21,
    },
    h6: {
      fontSize: 18,
    },
  },
  layout: {
    maxWidth: '1920px',
  },
  palette: {
    primary: {
      main: '#1799da',
    },
    secondary: {
      light: '#E0E0E0',
      main: '#BDBDBD',
      dark: '#9E9E9E',
    },
    error: red,
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
  },
  customPalette: {
    text: '#212121',
    success: '#4CAF50',
    warning: '#ffa000',
    info: '#0e7cb8',
    error: '#ee3625',
  },
});

export default theme;
