import React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useGetScreenSize } from 'components/ResponsiveLayout';
import DoneIcon from '@material-ui/icons/Done';
import DeleteButton from 'components/Button/DeleteButton';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    uploaded: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    uploadedFile: {
      display: 'flex',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: theme.spacing(5),
      borderWidth: '1px',
      borderRadius: '5px',
      borderStyle: 'dotted',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
      outline: 'none', // Avoid the highlighting of the dropzone when focused
    },
    accepted: {
      backgroundColor: theme.customPalette.success,
      borderStyle: 'solid',
    },
    rejected: {
      // @ts-ignore
      backgroundColor: theme.palette.error[400],
      borderStyle: 'solid',
    },
    disabled: {
      opacity: 0.75,
      cursor: 'progress',
    },
    required: {
      borderColor: '#f44336',
      color: '#f44336',
    },
    loadingContainer: {
      position: 'relative',
      ...theme.typography.overline,
    },
    spinner: {
      position: 'absolute',
      width: theme.spacing(5),
      top: '3px',
      left: '26px',
      color: theme.customPalette.success,
    },
  });

const useStyles = makeStyles(styles);

interface OwnProps {
  accept?: string;
  fileType?: Nl.FinalTrackSourceFileType | 'mp3';
  displayFileType: string;
  onDrop: (accepted: File[], rejected: FileRejection[]) => void;
  onClear: () => void;
  state?: Nl.SourceFileUploadState;
  disabled?: boolean;
  isRequiredError?: boolean;
  doneMessage: React.ReactNode;
}

const UploadDropzone = ({
  accept,
  fileType,
  displayFileType,
  onDrop,
  onClear,
  state,
  disabled,
  isRequiredError,
  doneMessage,
}: OwnProps) => {
  const isProcessing = ['uploading', 'validating'].includes(state!);
  const { isMobile } = useGetScreenSize();
  const classes = useStyles({});
  const isDisabled = disabled || isProcessing;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept, onDrop, disabled: isDisabled });

  const renderUploaded = () => (
    <div className={classes.uploaded}>
      <div className={classes.uploadedFile}>
        <DoneIcon />
        {doneMessage}
      </div>
      <DeleteButton
        data-e2e='clearUpload'
        aria-label='Clear upload'
        onClick={onClear}
      />
    </div>
  );

  const renderDropzone = () => (
    <div
      {...getRootProps()}
      className={classnames(
        classes.container,
        isDragActive && isDragAccept && classes.accepted,
        isDragActive && isDragReject && classes.rejected,
        isRequiredError && classes.required,
        isDisabled && classes.disabled,
      )}
    >
      <input {...getInputProps()} name={fileType} data-e2e='dropzoneInput' />
      {isProcessing ? (
        <div className={classes.loadingContainer} data-e2e='dropzoneLoading'>
          <CircularProgress size={24} className={classes.spinner} />{' '}
          <div>{state}</div>
        </div>
      ) : (
        <div className={classes.loadingContainer}>
          {!isDragReject
            ? isMobile
              ? `Click to upload your ${displayFileType}`
              : `Drop your ${displayFileType} file here or click to upload`
            : `The file has to be a ${displayFileType}`}
        </div>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      {state === 'uploaded' ? renderUploaded() : renderDropzone()}
    </div>
  );
};

export default UploadDropzone;
