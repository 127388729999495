import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

export const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
  },
}));
// For the typeahead, just keeping the files separate for tidyness

export const SelectionChip = (props) => {
  const classes = useStyles();

  return <Chip classes={classes} data-e2e='multiValue' {...props} />;
};
