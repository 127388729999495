import React from 'react';
import { TextField } from '@material-ui/core';
import { get, isEqual } from 'lodash';
import { FieldProps } from 'formik';

interface Props extends FieldProps {
  label: string;
  internal?: boolean;
  disabled?: boolean;
  helperText?: string;
}

const InputField: React.SFC<Props> = ({
  form,
  field,
  label,
  internal,
  helperText,
  ...rest
}) => {
  const fieldError = get(form.errors, field.name);
  const statusError = get(form.status, field.name);
  return (
    <TextField
      style={{
        width: '100%',
        marginTop: internal ? '0rem' : '1rem',
      }}
      variant='outlined'
      value={field.value ?? ''}
      inputProps={{ name: field.name, 'aria-label': label }}
      InputLabelProps={{ style: { pointerEvents: 'auto' } }}
      label={label}
      onChange={(e) => form.setFieldValue(field.name, e.target.value)}
      error={Boolean(fieldError) || Boolean(statusError)}
      helperText={fieldError || statusError || helperText}
      {...rest}
    />
  );
};

export function areEqual(prevProps: Props, nextProps: Props) {
  if (prevProps.field.value !== nextProps.field.value) return false;
  if (prevProps.disabled !== nextProps.disabled) return false;
  if (!isEqual(prevProps.form.errors, nextProps.form.errors)) return false;
  if (!isEqual(prevProps.form.status, nextProps.form.status)) return false;

  return true;
}

export default React.memo(InputField, areEqual);
