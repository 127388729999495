import { useNovaApi, useNovaApiCreate, useNovaApiUpdate } from '../useNovaApi';
import { useNlSelector } from 'utils/redux';
import { getSearchParamsFromCurrentUrl } from 'novaApi/apiUtils';
import { useDispatch } from 'react-redux';
import { addSuccessNotification } from 'store/notifications';

export const useTerms = () => {
  const query = useNlSelector(getSearchParamsFromCurrentUrl);
  return useNovaApi<Nl.Api.TermsResponse>(`/term`, {
    query,
  });
};

export const useTerm = (uuid: string) => {
  return useNovaApi<Nl.Api.TermResponse>(`/term/${uuid}`);
};

export const useTermCreate = () => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiCreate<
    Nl.Api.TermResponse,
    Nl.Api.Term
  >(`/term`, (createdTerm) => {
    dispatch(
      addSuccessNotification({
        message: `Term ${createdTerm.term.name} has been created`,
      }),
    );
  });

  return {
    createTerm: mutation.mutate,
    createdTerm: data,
    isSuccess,
  };
};

export const useTermUpdate = (uuid: string) => {
  const dispatch = useDispatch();
  const { mutation, data, isSuccess } = useNovaApiUpdate<
    Nl.Api.TermResponse,
    Nl.Api.Term
  >(`/term/${uuid}`, (updatedTerm) => {
    dispatch(
      addSuccessNotification({
        message: `Term ${updatedTerm.term.name} has been updated`,
      }),
    );
  });

  return {
    updateTerm: mutation.mutate,
    updatedTerm: data,
    isSuccess,
  };
};
