import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Box } from '@material-ui/core';
import { getReadableDate } from 'utils/utils';
import AudioControlIcon from 'components/AudioControlIcon';
import DownloadButton from 'components/Button/DownloadButton';
import SongWaveform from 'components/SongWaveform';
import { MobileWrapper, DesktopWrapper } from 'components/ResponsiveLayout';

const useStyles = makeStyles({
  waveform: {
    minWidth: 100,
    width: '80%',
  },
});

interface AllProps {
  sourceFile: Nl.Api.SubmissionSourceFile;
}

const TrackSubmissionPlayer: React.SFC<AllProps> = ({ sourceFile }) => {
  const classes = useStyles({});
  const trackType = 'song';
  const sourceFileInfo: {
    label: string;
    value: string | number | null;
  }[] = sourceFile && [
    {
      label: 'Sample rate',
      value: sourceFile.sample_rate,
    },
    {
      label: '# of channels',
      value: sourceFile.channels,
    },
    {
      label: 'Duration',
      value: sourceFile.duration,
    },
    {
      label: 'Filename',
      value: sourceFile.original_filename,
    },
  ];
  return sourceFile ? (
    <>
      <DesktopWrapper>
        <Grid container alignItems='center' justify='space-around'>
          <Grid item xs={2}>
            {sourceFileInfo.map((item) => (
              <Typography
                variant='caption'
                display='block'
                key={item.label}
                style={{ lineHeight: 'unset' }}
                noWrap
              >
                <b>{item.label}: </b>
                {item.value}
              </Typography>
            ))}
          </Grid>
          <Grid item xs={8}>
            <Grid container alignItems='center'>
              <AudioControlIcon
                audioTrack={{
                  uuid: sourceFile.uuid,
                  trackType,
                  url: sourceFile.url,
                  trackName: sourceFile.original_filename,
                  duration: sourceFile.duration!,
                }}
              />
              <SongWaveform
                uuid={sourceFile.uuid}
                className={classes.waveform}
                type={trackType}
              />
              <DownloadButton href={sourceFile.url} />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            {getReadableDate(sourceFile.created_at)}
          </Grid>
        </Grid>
      </DesktopWrapper>
      <MobileWrapper>
        <Grid container alignItems='center' justify='space-around'>
          <Grid item xs={12}>
            <Box
              border={1}
              borderColor='grey.300'
              style={{ padding: '8px', margin: '8px 0' }}
              borderRadius='borderRadius'
            >
              <Grid container alignItems='center'>
                <Grid item xs={8}>
                  <SongWaveform
                    uuid={sourceFile.uuid}
                    className={classes.waveform}
                    type={trackType}
                  />
                </Grid>
                <Grid item xs={2}>
                  <AudioControlIcon
                    audioTrack={{
                      uuid: sourceFile.uuid,
                      trackType,
                      url: sourceFile.url,
                      trackName: sourceFile.original_filename,
                      duration: sourceFile.duration!,
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DownloadButton href={sourceFile.url} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {sourceFileInfo.map((item) => (
              <Typography
                variant='caption'
                display='block'
                key={item.label}
                style={{ lineHeight: 'unset' }}
                noWrap
              >
                <b>{item.label}: </b>
                {item.value}
              </Typography>
            ))}
            <b>Created at: </b>
            {getReadableDate(sourceFile.created_at)}
          </Grid>
        </Grid>
      </MobileWrapper>
    </>
  ) : null;
};

export default TrackSubmissionPlayer;
