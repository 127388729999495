import { createTypedAction, ActionsUnion } from 'utils/storeUtils';
import { Reducer } from 'redux';

interface NotificationOptions {
  message?: string;
  url?: string;
  persist?: boolean;
}

export interface NotificationState extends NotificationOptions {
  status: 'default' | 'success' | 'error';
  isOpen: boolean;
}

export const initialState: NotificationState = {
  isOpen: false,
  status: 'default',
  message: undefined,
  url: undefined,
};

const defaultErrorMessage = 'There was an error';

// Action Constants
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const ADD_SUCCESS_NOTIFICATION = 'ADD_SUCCESS_NOTIFICATION';
const ADD_ERROR_NOTIFICATION = 'ADD_ERROR_NOTIFICATION';
const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

// Action Creators
export const addNotification = (payload: NotificationOptions) =>
  createTypedAction(ADD_NOTIFICATION, payload);
export const addSuccessNotification = (payload: NotificationOptions) =>
  createTypedAction(ADD_SUCCESS_NOTIFICATION, payload);
export const addErrorNotification = (payload: NotificationOptions) =>
  createTypedAction(ADD_ERROR_NOTIFICATION, payload);
export const dismissNotification = () =>
  createTypedAction(DISMISS_NOTIFICATION);

const actions = {
  addNotification,
  addSuccessNotification,
  addErrorNotification,
  dismissNotification,
};

type Actions = ActionsUnion<typeof actions>;

// Root Reducer
const reducer: Reducer = (
  state: NotificationState = initialState,
  action: Actions,
) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      return {
        ...state,
        isOpen: true,
        status: 'default',
        message: action.payload.message,
        url: action.payload.url,
        persist: action.payload.persist,
      };
    }

    case ADD_SUCCESS_NOTIFICATION: {
      return {
        ...state,
        isOpen: true,
        status: 'success',
        message: action.payload.message,
        url: action.payload.url,
        persist: action.payload.persist,
      };
    }

    case ADD_ERROR_NOTIFICATION: {
      return {
        ...state,
        isOpen: true,
        status: 'error',
        message: action.payload.message || defaultErrorMessage,
        url: action.payload.url,
        persist: action.payload.persist,
      };
    }

    case DISMISS_NOTIFICATION: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
