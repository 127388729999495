import * as React from 'react';
import { Typography } from '@material-ui/core';
import UserLogItem from '../UserLogItem';
import CodeBlock from 'components/CodeBlock';

interface OwnProps {
  logs: Nl.SongIngestionLog;
  alignRight?: boolean;
}

type AllProps = OwnProps;

const IngestionLogItem = ({ alignRight, logs }: AllProps) => {
  return (
    <UserLogItem timestamp={logs.timestamp} alignRight={alignRight}>
      {logs.status === 'ERROR' ? (
        <>
          <Typography>
            An error occurs while pushing the song to {logs.channel}.
          </Typography>
          <Typography color='error'>Error: {logs.errorMessage}</Typography>
          <CodeBlock>{logs.stackTrace}</CodeBlock>
        </>
      ) : (
        <Typography>
          {logs.status === 'COMPLETE'
            ? 'The song was pushed with success to'
            : 'The song is pushing to'}
          &nbsp;
          {logs.channel}
        </Typography>
      )}
    </UserLogItem>
  );
};

export default IngestionLogItem;
