import * as React from 'react';
import { useDispatch } from 'react-redux';
import FormikWrapper from 'components/Formik';
import { changeMyPassword } from 'store/myProfile';
import { selectNotificationStatus } from 'store/selectors';
import ChangeMyPasswordForm from './ChangeMyPasswordForm';
import validationSchema from './validation';
import { useNlSelector } from 'utils/redux';

const initialValues = {
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
};

const ChangeMyPasswordPage = () => {
  const dispatch = useDispatch();

  const notificationStatus = useNlSelector(selectNotificationStatus);

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={validationSchema(notificationStatus)}
      submitForm={(formData, formActions) =>
        dispatch(changeMyPassword({ formData, formActions }))
      }
      title='Change My Password'
    >
      <ChangeMyPasswordForm />
    </FormikWrapper>
  );
};

export default ChangeMyPasswordPage;
