export default {
  basicRequired: 'This field is required',
  notValidChoice: 'Not a valid choice.',
  minimumLengthOne: 'Shorter than minimum length 1.',
  invalidUUID: 'Not a valid UUID.',
  invalidEmail: 'Invalid email address',
  uniqueEmailRequired: 'Email must be unique',
  numbersOnly: 'Only numbers allowed',
  notValidNumber: 'Not a valid number',
  timeFormatRequired: 'Must be in hours:minutes:seconds format',
  endPointMustBePastStartPoint: 'End Point must be past Start Point',
};
