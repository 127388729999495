import * as Yup from 'yup';
import errorMessages from 'constants/errorMessages';

export default Yup.object().shape({
  name: Yup.string().required(errorMessages.basicRequired),
  type: Yup.string().required(errorMessages.notValidChoice).nullable(),
  performing_rights_organization_uuid: Yup.string()
    .required(errorMessages.notValidChoice)
    .nullable(),
  country_uuids: Yup.array()
    .of(Yup.string())
    .required(errorMessages.minimumLengthOne),
});
