import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { multipleSelectFormat } from 'utils/utils';
import { Form, Field, useFormikContext } from 'formik';
import FormikWrapper from 'components/Formik';
import DateSelectField from 'components/Formik/DateSelectField';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import { earningsReportUploadValidation } from '../validation';
import { usePROSelect } from 'novaApi/hooks/performing_rights_organization';
import { Box, FormHelperText } from '@material-ui/core';
import {
  ProEarningsFormValues,
  submitEarningsReport,
  uploadEarningsReport,
} from './PROEarningsFormData';
import { FieldAttributes } from 'formik/dist/Field';
import UploadDropzone from 'components/Dropzone/UploadDropzone';
import { isEmpty } from 'lodash';

const ProEarningsForm = () => {
  const pros = usePROSelect({ earnable_only: true });
  const ascap = pros?.find((pro) => pro.name === 'ASCAP');

  // clear region when pro changes
  const { values, setFieldValue } = useFormikContext<ProEarningsFormValues>();
  useEffect(() => {
    setFieldValue('region', undefined);
  }, [values.pro, setFieldValue]);

  return (
    <Form>
      <Field
        name='pro'
        label='Select a PRO'
        component={AutocompleteSelect}
        options={multipleSelectFormat(pros)}
      />
      <Field name='region'>
        {({ form, field }: FieldAttributes<any>) =>
          form.values.pro === ascap?.uuid && (
            <AutocompleteSelect
              label='Domestic or International (ASCAP only)'
              options={multipleSelectFormat([
                { name: 'domestic' },
                { name: 'international' },
              ])}
              {...{ form, field }}
            />
          )
        }
      </Field>
      <Box mt='1rem'>
        <Field name='date' label='Report date' component={DateSelectField} />
      </Box>
      <Box mt='1rem'>
        <Field name='source_file'>
          {({ form, field }: FieldAttributes<any>) => {
            const errorMessage =
              form.errors?.source_file?.url ||
              form.errors?.url?.join('\n') ||
              form.errors?._message;

            return (
              <>
                <UploadDropzone
                  accept='text/csv'
                  displayFileType='.csv'
                  onDrop={([file]) => {
                    if (file) {
                      uploadEarningsReport(file, form);
                    }
                  }}
                  onClear={() => {
                    form.setFieldValue(field.name, { url: '' });
                  }}
                  state={field?.value?.state}
                  isRequiredError={!isEmpty(form?.errors?.source_file)}
                  doneMessage={field?.value?.original_filename}
                />
                {errorMessage && (
                  <FormHelperText data-e2e='proSourceFileError' error>
                    {errorMessage === 'Header validation failed'
                      ? 'The report has failed column header validation. Please update the column headers and re-upload'
                      : errorMessage}
                  </FormHelperText>
                )}
              </>
            );
          }}
        </Field>
      </Box>
    </Form>
  );
};

const initialValues = {
  source_file: { url: '' },
  date: '',
  region: '',
  pro: '',
};

export default function PROEarningsAddPage() {
  const dispatch = useDispatch();

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={earningsReportUploadValidation}
      submitForm={submitEarningsReport(dispatch)}
      title='Add new PRO earnings report'
      customSize='sm'
    >
      <ProEarningsForm />
    </FormikWrapper>
  );
}
