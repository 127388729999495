import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Icon, Tooltip } from '@material-ui/core';
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { GlobalState } from 'store/rootReducer';
import {
  approveArtistSubmission,
  declineArtistSubmission,
  destroyArtistSubmission,
  onboardArtistSubmission,
} from 'store/artistSubmissions';
import { selectPermission, selectUserUuid } from 'store/selectors';
import { get } from 'lodash';
import { Dispatch } from 'redux';

interface AllProps {
  artistSubmission: Nl.Api.ArtistSubmission;
  isForm?: boolean;
  userUuid: string | undefined;
  hasCuratePermission: boolean;
  hasDeletePermission: boolean;
  approve: (uuid: string) => void;
  onboard: (uuid: string) => void;
  decline: (uuid: string) => void;
  destroy: (uuid: string) => void;
}

export const ArtistSubmissionsActionsView: React.FunctionComponent<AllProps> = ({
  artistSubmission,
  userUuid,
  hasCuratePermission,
  hasDeletePermission,
  approve,
  onboard,
  decline,
  destroy,
  isForm = false,
}) => {
  const approveAllowed: boolean =
    userUuid !== undefined &&
    hasCuratePermission &&
    get(artistSubmission, 'is_approvable', false) &&
    get(artistSubmission, 'last_approver.uuid', null) !== userUuid;

  const onboardingAllowed: boolean =
    isForm && hasCuratePermission && artistSubmission.is_onboardable;

  const declineAllowed: boolean =
    hasCuratePermission && get(artistSubmission, 'is_rejectable', false);

  const deleteAllowed: boolean =
    hasDeletePermission && get(artistSubmission, 'is_deletable', false);

  const renderEmptyIcon = () =>
    isForm ? null : (
      <IconButton disabled>
        <Icon />
      </IconButton>
    );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {approveAllowed ? (
        <Tooltip title='Approve submission'>
          <IconButton
            data-e2e='approve'
            onClick={() => approve(artistSubmission.uuid)}
          >
            <FavoriteBorder />
          </IconButton>
        </Tooltip>
      ) : (
        renderEmptyIcon()
      )}
      {isForm &&
        (onboardingAllowed ? (
          <Tooltip title='Onboard artist'>
            <IconButton
              data-e2e='onboard'
              onClick={() => onboard(artistSubmission.uuid)}
            >
              <PersonAddOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          renderEmptyIcon()
        ))}
      {declineAllowed ? (
        <Tooltip title='Decline submission'>
          <IconButton
            data-e2e='decline'
            onClick={() => decline(artistSubmission.uuid)}
          >
            <NotInterestedOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        renderEmptyIcon()
      )}
      {deleteAllowed ? (
        <Tooltip title='Delete submission'>
          <IconButton
            data-e2e='destroy'
            onClick={() => {
              if (
                window.confirm(
                  'Are you sure you want to delete this artist submission?',
                )
              ) {
                destroy(artistSubmission.uuid);
              }
            }}
          >
            <DeleteForeverOutlinedIcon color='action' />
          </IconButton>
        </Tooltip>
      ) : (
        renderEmptyIcon()
      )}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  userUuid: selectUserUuid(state),
  hasCuratePermission: selectPermission(
    state,
    'CAN_UPDATE_NEW_ARTIST_SUBMISSIONS',
  ),
  hasDeletePermission: selectPermission(
    state,
    'CAN_DELETE_NEW_ARTIST_SUBMISSIONS',
  ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  approve: (uuid: string) => dispatch(approveArtistSubmission({ uuid })),
  onboard: (uuid: string) => dispatch(onboardArtistSubmission({ uuid })),
  decline: (uuid: string) => dispatch(declineArtistSubmission({ uuid })),
  destroy: (uuid: string) => dispatch(destroyArtistSubmission({ uuid })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArtistSubmissionsActionsView);
