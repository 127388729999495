import * as React from 'react';
import { Form, Field } from 'formik';
import InputField from 'components/Formik/InputField';
import { multipleSelectFormat } from 'utils/utils';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import { useCountries } from 'novaApi/hooks/country';

export const getEditFormInitialValues = (pro?: Nl.Api.PRO) => ({
  uuid: pro?.uuid ?? '',
  name: pro?.name ?? '',
  country_uuid: pro?.country?.uuid ?? '',
});

const PROForm = () => {
  const countries = useCountries();

  return (
    <Form>
      <Field name='name' label='Name' component={InputField} />
      <Field
        name='country_uuid'
        label='Country'
        component={AutocompleteSelect}
        options={multipleSelectFormat(countries)}
      />
    </Form>
  );
};

export default PROForm;
