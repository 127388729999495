import * as React from 'react';
import ConnectedTableFilters from 'components/Table/ConnectedTableFilters';
import { PRO_TYPE_OPTIONS, ROYALTY_FILTER_OPTIONS } from 'constants/staticData';
import { audioLayouts } from 'utils/songSourceFile';
import { useArtistEntities } from 'novaApi/hooks/artist';
import { useWriterSelect } from 'novaApi/hooks/writer';
import { useContentTiersSelect } from 'novaApi/hooks/content_tier';

const SongsFilters = () => {
  const artists = useArtistEntities();
  const writers = useWriterSelect();
  const contentTiers = useContentTiersSelect();

  return (
    <ConnectedTableFilters
      filters={[
        {
          type: 'single_select',
          label: 'Artist',
          queryFilterKey: 'artist_uuid',
          optionsSelector: () => artists,
          e2e: 'select_artist',
        },
        {
          type: 'single_select',
          label: 'Genre',
          queryFilterKey: 'genre_uuid',
          optionsSelector: (state) => state.songGenres.entities,
          e2e: 'select_genre',
        },
        {
          type: 'single_select',
          label: 'Theme',
          queryFilterKey: 'theme_uuid',
          optionsSelector: (state) => state.songThemes.entities,
          e2e: 'select_theme',
        },
        {
          type: 'date',
          label: 'Published date',
          queryFilterKey: 'initial_published_date_ms',
          e2e: 'select_publised_date',
        },
        {
          type: 'single_select',
          label: 'PRO',
          queryFilterKey: 'is_pro_affiliated',
          optionsSelector: () => PRO_TYPE_OPTIONS,
          e2e: 'select_pro',
        },
        {
          type: 'single_select',
          label: 'Curation State',
          queryFilterKey: 'curation_state_uuid',
          optionsSelector: (state) => state.songs.curationStateOptions,
          e2e: 'select_curation_state',
        },
        {
          type: 'single_select',
          label: 'Inspection State',
          queryFilterKey: 'inspection_state',
          optionsSelector: (state) =>
            state.songs.constants.inspectionStates.map((inspectionState) => ({
              uuid: inspectionState,
              name: inspectionState,
            })),
          e2e: 'select_inspection_states',
        },
        {
          type: 'single_select',
          label: 'Publish State',
          queryFilterKey: 'publish_state',
          optionsSelector: (state) =>
            state.songs.constants.publishStates.map((nextState) => ({
              uuid: nextState,
              name: nextState,
            })),
          e2e: 'select_publish_state',
        },
        {
          type: 'single_select',
          label: 'Song Collections',
          queryFilterKey: 'song_collection_uuid',
          optionsSelector: (state) => state.songCollections.entities,
          e2e: 'select_song_collection',
        },
        {
          type: 'single_select',
          label: 'Instrument',
          queryFilterKey: 'instrument_uuid',
          optionsSelector: (state) => state.songInstruments.entities,
          e2e: 'select_instrument',
        },
        {
          type: 'single_select',
          label: 'Tag',
          queryFilterKey: 'tag_value',
          optionsSelector: (state) => state.songTags.data.song_tags,
          e2e: 'select_tag',
        },
        {
          type: 'typeahead',
          label: 'Hidden Tag',
          queryFilterKey: 'hidden_tag_value',
          optionsSelector: (state) =>
            state.songHiddenTags.data.song_tags_hidden,
          e2e: 'select_tag_hidden',
        },
        {
          type: 'typeahead',
          label: 'Taxonomy Tag',
          queryFilterKey: 'taxonomy_tag_uuid',
          optionsSelector: (state) => state.taxonomyTags.data.taxonomy_tags,
          e2e: 'select_taxonomy_tag',
        },
        {
          type: 'single_select',
          label: 'Writer',
          queryFilterKey: 'writer_uuid',
          optionsSelector: () => writers,
          e2e: 'select_writer',
        },
        {
          type: 'single_select',
          label: 'Audio Layout',
          queryFilterKey: 'audio_layout_versions',
          optionsSelector: () =>
            audioLayouts.map((layout) => ({ uuid: layout, name: layout })),
          e2e: 'select_audio_layout',
        },
        {
          type: 'single_select',
          label: 'Content Tier',
          queryFilterKey: 'content_tier_uuid',
          optionsSelector: () => contentTiers || [],
          e2e: 'select_audio_layout',
        },
        {
          type: 'single_select',
          label: 'Royalty',
          queryFilterKey: 'is_royalty',
          optionsSelector: () => ROYALTY_FILTER_OPTIONS,
          e2e: 'select_artist',
        },
      ]}
    />
  );
};
export default SongsFilters;
