import * as React from 'react';
import {
  Typography,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    codeBlock: {
      cursor: 'pointer',
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
      border: '1px solid #d4d4d4',
      background: '#f7f7f7',
      borderRadius: 8,
      padding: 8,
      overflow: 'hidden',
    },
  });

type AllProps = WithStyles<typeof styles>;

const CodeBlock: React.SFC<AllProps> = ({ classes, children }) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <pre className={classes.codeBlock}>
      <Typography
        component='code'
        variant='inherit'
        noWrap={!isOpen}
        onClick={() => setOpen(!isOpen)}
      >
        {children}
      </Typography>
    </pre>
  );
};

export default withStyles(styles)(CodeBlock);
