import React from 'react';
import { AppBar, Toolbar, IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Link from 'redux-first-router-link';
import { useHeaderStyles } from './Header.styles';
import DrawerMenu from './Drawer';
import { useDispatch } from 'react-redux';
import { actions } from 'store/header';
import LogoNovaLibrary from 'components/LogoNovaLibrary/LogoNovaLibrary';

export interface HeaderProps {
  tabData: Nl.HeaderTopLevelTab[];
  isLoggedIn: boolean;
  isLoading?: boolean;
  userPermissions: Nl.UserPermissionType[];
  toggleDrawer(): void;
  isDrawerOpen?: boolean;
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const { isLoading, isLoggedIn, isDrawerOpen } = props;
  const classes = useHeaderStyles();
  const dispatch = useDispatch();

  const noDrawer = !isLoggedIn;

  const toggleDrawer = () => {
    dispatch(actions.toggleDrawer());
  };

  const renderLogo = () => (
    <Link className={classes.appLogoLink} to={{ type: 'route/HOME' }}>
      <LogoNovaLibrary className={classes.appLogo} altText='Nova Home' />
    </Link>
  );

  if (noDrawer || isLoading) {
    return (
      <AppBar position='fixed' color='inherit'>
        <Toolbar>{renderLogo()}</Toolbar>
      </AppBar>
    );
  }

  const drawerMenu = <DrawerMenu {...props} toggleDrawer={toggleDrawer} />;

  return (
    <div className={classes.root}>
      <AppBar position='fixed' color='inherit'>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer}
            name='drawer_button'
            data-e2e='drawerButton'
          >
            <MenuIcon />
          </IconButton>
          {renderLogo()}
        </Toolbar>
      </AppBar>
      <Drawer
        variant='temporary'
        anchor='left'
        open={isDrawerOpen}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawerMenu}
      </Drawer>
    </div>
  );
};

export default Header;
