import React from 'react';
import { useNlSelector } from 'utils/redux';

import { getUrlPayloadParams } from 'novaApi/apiUtils';
import FormikWrapper from 'components/Formik';
import ExampleForm, { getFormInitialValues } from './ExampleForm';
import { getValidationSchema } from './validation';
import { useExample, useExampleUpdate } from 'novaApi/hooks/example';
import { useDispatch } from 'react-redux';
import { goPreviousPage } from 'store/router';
import CollapsableUserLogs from 'components/UserLogs/CollapsableUserLogs';
import { duration } from 'moment';

export default function ExamplesEditPage() {
  const dispatch = useDispatch();
  const { uuid } = useNlSelector(getUrlPayloadParams);
  const { data } = useExample(uuid!);
  const exampleToEdit = getFormInitialValues(data?.example);
  const { updateExample, isSuccess, updatedExample } = useExampleUpdate(uuid!);

  const handleFormSubmit = (formData, formActions) => {
    const start = formData.start_point;
    const end = formData.end_point;

    const transformedFormData = {
      ...formData,
      start_point: start ? duration(start).asSeconds() : null,
      end_point: end ? duration(end).asSeconds() : null,
    };
    updateExample({ formData: transformedFormData, formActions });
  };

  if (!data) return null;
  if (updatedExample && isSuccess) {
    dispatch(goPreviousPage());
  }

  return (
    <FormikWrapper
      initialValues={exampleToEdit}
      validationSchema={getValidationSchema()}
      submitForm={handleFormSubmit}
      title='Edit example'
      customSize='sm'
    >
      <ExampleForm />

      {uuid && (
        <CollapsableUserLogs activityType='examples' resourceUuid={uuid} />
      )}
    </FormikWrapper>
  );
}
