import * as React from 'react';
import { Form, Field } from 'formik';
import { multipleSelectFormat } from 'utils/utils';
import InputField from 'components/Formik/InputField';
import AutocompleteSelect from 'components/Formik/AutocompleteSelect';
import { useSongGenreParents } from 'novaApi/hooks/song_genre';

const SongGenreForm = () => {
  const songGenres = useSongGenreParents();

  return (
    <Form>
      <Field name='name' label='Name' component={InputField} />
      <Field
        name='parent_genre_uuid'
        label='Parent Song Genre'
        component={AutocompleteSelect}
        options={multipleSelectFormat(songGenres)}
      />
    </Form>
  );
};

export default SongGenreForm;
