import * as React from 'react';
import range from 'lodash/range';
import {
  IconButton,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { TablePaginationProps } from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { Theme, StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules => ({
  root: { flexShrink: 0 },
  pageNumberButton: {
    height: `${theme.spacing(6)}px`,
    width: `${theme.spacing(6)}px`,
  },
});

/**
 * Calculate the range of page numbers to display
 *
 * @param initial The 'active' page
 * @param max Upper bound of pages we have
 * @param padding=5 Max pages to show on both sides of initial
 */
export const getPaginationRange = (
  initial: number,
  max: number,
  padding = 3,
): number[] => {
  // eslint-disable-next-line no-param-reassign
  initial = Math.max(initial, 1);
  return range(
    Math.max(initial - padding, 0),
    Math.min(initial + padding, max),
  );
};

type AllProps = TablePaginationProps & WithStyles<typeof styles>;

const TablePaginationActions = (props: AllProps) => {
  const { classes, count, page, rowsPerPage, onChangePage } = props;
  const currentPage = page + 1;
  const pageCount = Math.ceil(count / rowsPerPage);

  return (
    <div className={classes.root}>
      <div className={classes.rowPerPage}>
        <IconButton
          onClick={(event) => {
            onChangePage(event, 1);
          }}
          disabled={currentPage <= 1}
          aria-label='First Page'
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={(event) => {
            onChangePage(event, currentPage - 1);
          }}
          disabled={currentPage <= 1}
          aria-label='Previous Page'
        >
          <KeyboardArrowLeft />
        </IconButton>
        {getPaginationRange(currentPage, Math.max(0, pageCount)).map(
          (rangeVal) => {
            const value = rangeVal + 1;
            return (
              <IconButton
                key={value}
                data-e2e={`page_${value}`}
                disabled={value === currentPage}
                className={classes.pageNumberButton}
                onClick={() => onChangePage(null, value)}
              >
                <Typography variant='caption'>{value}</Typography>
              </IconButton>
            );
          },
        )}
        <IconButton
          onClick={(event) => {
            onChangePage(event, currentPage + 1);
          }}
          disabled={currentPage > pageCount - 1}
          aria-label='Next Page'
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={(event) => {
            onChangePage(event, Math.max(0, pageCount));
          }}
          disabled={currentPage > pageCount - 1}
          aria-label='Last Page'
        >
          <LastPageIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(TablePaginationActions);
